import * as React from 'react';

import styles from '../styles.module.scss';
import JobHazardSummarySection from './Sections/JobHazardSummarySection';
import PermitsRequiredSection from './Sections/PermitsRequired';
import SafetySection from './Sections/SafetySection';
import EnvironmentalProtectionSection from './Sections/EnvironmentalProtectionSection';
import PublicSafetySection from './Sections/PublicSafetySection';
import StatusSection from './Sections/StatusSection';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	formName: string;
}

const JobHazardAssessmentFormTab: React.FC<Props> = (props) => {
	const { change, formName } = props;
	const jobHazzardSummaryRef = React.useRef<Nullable<HTMLDivElement>>(null);

	React.useEffect(() => {
		if (jobHazzardSummaryRef.current) {
			jobHazzardSummaryRef.current.scrollIntoView({ block: 'end' });
		}
	}, []);

	return (
		<div className={styles['job-form__tab-content']}>
			<JobHazardSummarySection jobHazzardSummaryRef={jobHazzardSummaryRef} />
			<PermitsRequiredSection />
			<SafetySection />
			<EnvironmentalProtectionSection />
			<PublicSafetySection />
			<StatusSection change={change} formName={formName} />
		</div>
	);
};

export default React.memo(JobHazardAssessmentFormTab);
