import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { History } from 'history';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import CustomModal from 'af-components/CustomModal';

import * as WorkOrderActions from 'af-actions/workOrder';

import RelatedWorkOrders from 'af-root/scenes/Company/Resources/Shared/RelatedWorkOrders';

import TableNameEnum from 'ab-enums/tableName.enum';

import styles from './styles.module.scss';

interface OwnProps {
	showEquipmentWOHistoryModal: boolean;
	closeEquipmentWOModal: () => void;
	companyName: string;
	orgAlias: string;
	equipmentName: string;
	equipmentSpecification: Nullable<string>;
	equipmentCode: string;
	equipmentId: number;
	history: History;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const EquipmentWorkOrderHistoryModal: React.FC<Props> = (props: Props) => {
	const {
		showEquipmentWOHistoryModal,
		closeEquipmentWOModal,
		companyName,
		orgAlias,
		equipmentName,
		equipmentSpecification,
		equipmentCode,
		equipmentId,
		history,
	} = props;

	const fetchWorkOrders = React.useCallback((tableRequestModel: TableQuery, startDate: Date, endDate: Date) => {
		const { findWorkOrdersByEquipmentId } = props;
		const wos = findWorkOrdersByEquipmentId(equipmentId, tableRequestModel, startDate, endDate);

		return wos;
	}, [equipmentId, props]);

	const baseClassName = 'equipment-wo-history-modal';
	const headerClassName = `${baseClassName}__big-header`;
	const headerLeft = `${baseClassName}__big-header-left`;
	const headerRight = `${baseClassName}__big-header-right`;
	const iconClassName = 'header-close-icon';
	const modalBody = 'modal-body';
	const titleSpecification = `${baseClassName}__title-equipment-specification`;

	return (
		<>
			<CustomModal
				className={styles[baseClassName]}
				closeModal={closeEquipmentWOModal}
				modalStyle="info"
				showModal={showEquipmentWOHistoryModal}
			>
				<CustomModal.Header className={styles[headerClassName]}>
					<div className={`${styles[headerLeft]} `}>
						<span>{` WORK ORDER HISTORY | ${equipmentName} `}</span>
						{equipmentSpecification
							? <span className={`${styles[titleSpecification]} `}> {equipmentSpecification}</span>
							: null
						}
					</div>
					<div className={`${styles[headerRight]} `}>
						<span
							className={`icon-close ${styles[iconClassName]}`}
							onClick={closeEquipmentWOModal}
						/>
					</div>
				</CustomModal.Header>
				<CustomModal.Body className={`${styles[modalBody]} `}>
					<RelatedWorkOrders
						companyName={companyName}
						csvName={`${companyName}_${equipmentCode}_work_orders.csv`}
						fetchWorkOrders={fetchWorkOrders}
						history={history}
						orgAlias={orgAlias}
						tableName={TableNameEnum.EQUIPMENT_WORK_ORDERS}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeEquipmentWOModal}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</CustomModal>
		</>
	);
};

const mapDispatchToProps = () => {
	return {
		findWorkOrdersByEquipmentId: WorkOrderActions.findWorkOrdersByEquipmentId,
	};
};

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(EquipmentWorkOrderHistoryModal);
