import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import type CDLStatus from 'acceligent-shared/enums/cdlStatus';
import { CDLStatusLabel } from 'acceligent-shared/enums/cdlStatus';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import type { EmployeeVM } from 'ab-viewModels/employee/employee.viewModel';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { AVAILABLE_EMPLOYEE_STATUS } from 'ab-common/constants/employee';

import type { RootState } from 'af-reducers';

import * as EmployeeActions from 'af-actions/employee';
import * as WorkOrderActions from 'af-actions/workOrder';

import CLIENT from 'af-constants/routes/client';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import TableNameEnum from 'ab-enums/tableName.enum';

import ImageTag from 'af-components/Image';
import LockedValue from 'af-components/LockedValue';
import LabelWithPills from 'af-components/LockedPillsValue';
import Breadcrumbs from 'af-components/Breadcrumbs';
import StatusLabel from 'af-components/StatusLabel';

import ResourcePreview from '../../Shared/ResourcePreview';
import RelatedWorkOrders from '../../Shared/RelatedWorkOrders';
import Loading from './Loading';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	employee: Nullable<EmployeeVM>;
}

class Preview extends React.PureComponent<Props, State> {

	state: State = {
		employee: null,
	};

	async componentDidMount() {
		const { findById, match: { params: { id } } } = this.props;
		const employee = await findById(id);
		this.setState(() => ({ employee }));
	}
	formatCDLStatus = (cdlStatus: CDLStatus) => CDLStatusLabel[cdlStatus];

	formatShowOnScheduleBoard = (showOnScheduleBoard: boolean) => showOnScheduleBoard ? 'Yes' : 'No';

	fetchWorkOrders = async (tableRequestModel: TableQuery, startDate: Date, endDate: Date) => {
		const { findWorkOrdersByEmployeeId, match: { params: { id } } } = this.props;
		return await findWorkOrdersByEmployeeId(+id, tableRequestModel, startDate, endDate);
	};

	render() {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		const { employee } = this.state;

		const breadcrumbs = [
			{ label: 'Labor', url: CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST(orgAlias, companyName) },
			{ label: 'Preview' },
		];

		return (
			<>
				{employee ?
					<ResourcePreview
						backToListLabel="Back to Labor List"
						backToListUrl={CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST(orgAlias, companyName)}
						breadcrumbs={breadcrumbs}
						editUrl={CLIENT.COMPANY.RESOURCES.EMPLOYEE.EDIT(`${employee.id}`, orgAlias, companyName)}
					>
						<Row className="row row--flex">
							<div className="avatar__container">
								<div className="avatar">
									<ImageTag
										fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
										minSize={BlobStorageImageSizeContainer.SIZE_200X200}
										src={employee.imageUrl as string}
										tryOriginal={true}
										tryRoot={true}
									/>
								</div>
							</div>
							<div className="avatar__neighbour">
								<Row className="row--padded">
									<Col md={6}><LockedValue defaultValue="N/A" label="Name" value={employee.fullName} /></Col>
									<Col md={6}>
										<LockedValue
											defaultValue="N/A"
											label="Current Status"
											value={
												<StatusLabel
													isAvailable={!!employee.isStatusAvailable}
													label={employee.employeeStatusName ?? AVAILABLE_EMPLOYEE_STATUS}
												/>
											}
										/>
									</Col>
									<Col md={6}><LockedValue defaultValue="N/A" label="Mobile Phone" value={employee.phoneNumber} /></Col>
									<Col md={6}><LockedValue defaultValue="N/A" label="SMS Subscription" value={employee.subscriptionStatus} /></Col>
								</Row>
								<Row className="row--padded-bottom">
									<Col md={6}><LockedValue defaultValue="N/A" label="Location" value={employee.locationNickname} /></Col>
									<Col md={6}><LockedValue defaultValue="N/A" label="CDL Status" value={this.formatCDLStatus(employee.cdlStatus)} /></Col>
									<Col md={12}><LockedValue defaultValue="N/A" label="E-mail" value={employee.email} /></Col>
								</Row>
								<Row>
									<Col md={6}><LockedValue defaultValue="N/A" label="Labor Type" value={employee?.wageRate?.wageClassification} /></Col>
									<Col md={6}><LockedValue defaultValue="N/A" label="Wage Classification" value={employee?.wageRate?.type} /></Col>
									<Col md={6}><LockedValue defaultValue="N/A" label="Union Type" value={employee?.unionType?.name} /></Col>
									<Col md={6}><LockedValue defaultValue="N/A" label="Visible on Schedule Board" value={this.formatShowOnScheduleBoard(employee.showOnScheduleBoard)} /></Col>
								</Row>
							</div>
						</Row>
						<Row>
							<Col md={24}>
								<LabelWithPills
									colorKey="color"
									items={employee.skills}
									label="Skills"
									labelKey="name"
								/>
							</Col>
						</Row>
					</ResourcePreview> :
					<Loading />
				}
				<div className="form-segment">
					<Breadcrumbs items={[{ label: 'Related Work Orders' }]} />
					<RelatedWorkOrders
						companyName={companyName}
						csvName={`${companyName}_${employee?.formattedCode}_work_orders.csv`}
						fetchWorkOrders={this.fetchWorkOrders}
						history={history}
						orgAlias={orgAlias}
						tableName={TableNameEnum.EMPLOYEE_WORK_ORDERS}
					/>
				</div>
			</>
		);
	}

}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findById: EmployeeActions.findById,
		findWorkOrdersByEmployeeId: WorkOrderActions.findWorkOrdersByEmployeeId,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(Preview);
