import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { ExtendedColorValue } from 'acceligent-shared/enums/color';
import { DefaultColor } from 'acceligent-shared/enums/color';

import type { RootState } from 'af-reducers';

import type ScheduleBoardEquipmentViewModel from 'ab-viewModels/scheduleBoardEquipment.viewModel';

import { setCopiedEquipmentId } from 'af-actions/scheduleBoard/scheduleBoard.actions';

import MultiAssignmentsBadge from 'af-root/scenes/Company/ScheduleBoard/Shared/MultiAssignmentsBadge';
import CopyProgressBar from 'af-root/scenes/Company/ScheduleBoard/Shared/CopyProgressBar';
import PressAndHoldAnchor from 'af-root/scenes/Company/ScheduleBoard/Shared/PressAndHoldAnchor';

import DownEquipmentBadge from '../DownEquipmentBadge';

import * as ColorUtils from 'ab-utils/color.util';

import { generateEquipmentSearchItemId, generateWorkOrderEquipmentSearchItemId, isInToolbar } from 'af-utils/scheduleBoard.util';

interface OwnProps {
	draggableProps: Metadata;
	dragHandleProps: Metadata;
	onClick: () => void;
	innerRef: string;
	equipmentId: number;
	resourceId: number;
	isToolbar: boolean;
	isDragging: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	dueDate: string;
	isCardDisabled: boolean;
	isWorkOrderCanceled?: boolean;
	isDropAnimating?: boolean;
	draggingOver?: string;
	isWOLocked: boolean;
}
interface StateProps {
	equipment: ScheduleBoardEquipmentViewModel | undefined;
	isActive: boolean;
	searchId: Nullable<string>;
	copiedEquipmentId: Nullable<number>;
	isDisabled: boolean;
	equipmentAssignmentCount: number;
	isDailyView: boolean;
	isToolbarShown: boolean;
	isDeletedResource: boolean;
	isAvailable: boolean;
}

interface DispatchProps {
	setCopiedEquipmentId: typeof setCopiedEquipmentId;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	draggableItemClassName: string;
	categoryColor: ExtendedColorValue;
}

class Draggable extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		equipmentAssignmentCount: 0,
	};

	state: State = {
		draggableItemClassName: '',
		categoryColor: DefaultColor.CATEGORY,
	};

	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		const {
			isActive,
			isDisabled,
			isWorkOrderCanceled,
			isToolbar,
			equipment: { color, isFilteredOnToolbar, isFilteredOnBoard, isMatched } = {},
			isDropAnimating,
			draggingOver,
		} = nextProps;

		const categoryColor = color ?? DefaultColor.CATEGORY;

		let draggableItemClassName = 'sb-resource-item sb-resource-item--equipment';

		if (isDropAnimating && !isToolbar && (!draggingOver || isInToolbar(draggingOver))) { // FIXME: animation is still rendering when dropping item in toolbar
			draggableItemClassName += ' sb-resource-item--hidden';
		} else {
			draggableItemClassName += isDisabled && !isWorkOrderCanceled ? ' sb-resource-item--disabled' : '';
			draggableItemClassName += isMatched ? ' sb-resource-item--highlighted' : '';
			draggableItemClassName += isActive ? ' sb-resource-item--active' : '';
			draggableItemClassName += (isToolbar && isFilteredOnToolbar) || (!isToolbar && isFilteredOnBoard) ? ' filtered' : '';
			draggableItemClassName += ` ${ColorUtils.getColorBackgroundClass(categoryColor)}`;
			draggableItemClassName = draggableItemClassName.trim();
		}
		return prevState.draggableItemClassName !== draggableItemClassName || prevState.categoryColor !== categoryColor ? {
			draggableItemClassName,
			categoryColor,
		} : null;
	}

	onHoldEnd = () => {
		const { setCopiedEquipmentId: _setCopiedEquipmentId, equipment } = this.props;
		_setCopiedEquipmentId(equipment?.id ?? null);
	};

	onRelease = () => {
		const { setCopiedEquipmentId: _setCopiedEquipmentId, isDragging } = this.props;
		if (!isDragging) {
			_setCopiedEquipmentId(null);
		}
	};

	renderEquipmentDraggable = (isCopying: boolean, copyFinished: boolean) => {
		const { equipment, isToolbar } = this.props;
		const { name, spec: specification } = equipment ?? {};

		const body = (
			<div className="sb-resource-item__content">
				<span className="sb-resource-item__full-name sb-resource-item__full-name--transparent-bg">{name}</span>
				{specification && <span className="sb-resource-item__specification">{specification}</span>}
			</div>
		);

		return isToolbar || equipment?.isDeleted ? body : (
			<>
				{body}
				<CopyProgressBar
					copyFinished={copyFinished}
					isCopying={isCopying}
				/>
			</>
		);
	};

	render() {
		const {
			draggableProps,
			dragHandleProps,
			innerRef,
			onClick,
			equipment,
			isToolbar,
			equipmentAssignmentCount,
			isDragging,
			searchId,
			copiedEquipmentId,
			isWorkOrderCanceled,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			isDailyView,
			isToolbarShown,
			isDeletedResource,
			isAvailable,
			isWOLocked,
		} = this.props;
		const { id: equipmentId } = equipment ?? {};
		const { draggableItemClassName } = this.state;

		if (isDeletedResource) {
			return null;
		}

		return (
			<>
				<div
					id={searchId ?? undefined}
					ref={innerRef}
					{...draggableProps}
					{...dragHandleProps}
					className={draggableItemClassName}
				>
					<PressAndHoldAnchor
						onClick={onClick}
						onHoldEnd={this.onHoldEnd}
						onRelease={this.onRelease}
						regularAnchor={
							(!isToolbar && !isAvailable)
							|| isToolbar
							|| !hasPermissionsToEditScheduleBoard
							|| (isDragAndDropDisabled && !isWOLocked)
							|| (!isDailyView && !isToolbarShown)
						}
					>
						{this.renderEquipmentDraggable}
					</PressAndHoldAnchor>
					{!isAvailable && <DownEquipmentBadge />}
					{isAvailable && !isWorkOrderCanceled && equipmentAssignmentCount > 1 &&
						<MultiAssignmentsBadge count={equipmentAssignmentCount} />
					}
				</div>
				{isDragging && copiedEquipmentId && copiedEquipmentId === equipmentId && (
					<div className={draggableItemClassName}>
						{this.renderEquipmentDraggable(false, false)}
						{!isAvailable && <DownEquipmentBadge />}
						{isAvailable && !isWorkOrderCanceled && equipmentAssignmentCount > 1 &&
							<MultiAssignmentsBadge count={equipmentAssignmentCount} />
						}
					</div>
				)}
			</>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const {
		equipment: _equipmentDict,
		draggedEquipmentId,
		copiedEquipmentId,
		workOrdersByDateDictionary,
		dates,
		weeklyViewDateWithToolbar,
		searchResultItems,
		activeSearchItemIndex,
	} = state.scheduleBoard;
	const { equipmentId, dueDate, isCardDisabled, isToolbar, isWorkOrderCanceled, resourceId } = ownProps;

	const dataOnDate = workOrdersByDateDictionary?.[dueDate];
	const equipment = _equipmentDict?.[equipmentId] ?? undefined;

	let searchId: Nullable<string> = null;
	let isDeletedResource = false;
	if (!isToolbar && equipment) {
		const _resources = (isWorkOrderCanceled ? dataOnDate?.canceledWorkOrderResourceLookups : dataOnDate?.workOrderResourceLookups) ?? {};
		if (_resources[resourceId]) {
			searchId = _resources[resourceId].workOrderEquipmentId !== undefined
				? generateWorkOrderEquipmentSearchItemId(_resources[resourceId].workOrderEquipmentId!.toString())
				: null;
		} else {
			isDeletedResource = true;
		}
	} else {
		searchId = generateEquipmentSearchItemId(equipmentId.toString());
	}

	const isAvailable = !equipment?.id || !dataOnDate?.equipmentUnavailabilityDetails?.[equipment?.id];
	const isDraggedFromToolbarInOtherTab = isToolbar && !!equipment?.isDisabled && !draggedEquipmentId;

	return {
		equipment,
		isActive: searchResultItems[activeSearchItemIndex] === searchId,
		searchId,
		isAvailable,
		copiedEquipmentId,
		isDisabled: (!isCardDisabled && draggedEquipmentId === equipmentId) || isDraggedFromToolbarInOtherTab,
		equipmentAssignmentCount: dataOnDate?.equipmentAssignments?.[equipmentId]?.length ?? 0,
		isDailyView: dates.length === 1,
		isToolbarShown: weeklyViewDateWithToolbar === dueDate,
		isDeletedResource,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setCopiedEquipmentId,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(Draggable);
