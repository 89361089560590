import * as React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';
import { AdditionalColors } from 'acceligent-shared/enums/color';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import ImageTag from 'af-components/Image';
import CustomModal from 'af-components/CustomModal';
import SegmentLabel from 'af-components/SegmentLabel';
import LockedValue from 'af-components/LockedValue';
import LabelWithColor from 'af-components/LabelWithColor';

import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';
import ResourceSkillList from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceModals/SkillsList';

import type ScheduleBoardEquipmentModalVM from 'ab-viewModels/scheduleBoardEquipmentModal.viewModel';
import type ScheduleBoardWorkOrderVM from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';
import { MAX_EQUIPMENT_ASSIGNMENTS_PER_DAY } from 'ab-common/constants/scheduleBoard';

import type { RootState } from 'af-reducers';

import AssignmentsModalSection from '../../AssignmentsModalSection';
import SettingsButton from './SettingsButton';
import styles from './styles.module.scss';

interface OwnProps {
	date: Nullable<string>;
	createEquipmentAssignment: (
		type: AssignableResourceType,
		date: string,
		workOrderId: number,
		isAvailable: boolean,
		resource: ScheduleBoardEquipmentModalVM
	) => boolean;
	equipment: Nullable<ScheduleBoardEquipmentModalVM>;
	hasPermissionsToEditScheduleBoard: boolean;
	removeEquipmentAssignment: (dueDate: string, workOrderId: number, workOrderCode: string, equipmentId: number) => void;
	setEquipmentModalData: (equipment: Nullable<ScheduleBoardEquipmentModalVM>, date: Nullable<string>) => void;
	openEquipmentWorkOrderHistoryModal: () => void;
	setEquipmentModalVisibility: (showEquipmentModal: boolean) => void;
	showEquipmentModal: boolean;
}

interface StateProps {
	assignableWorkOrders: ScheduleBoardWorkOrderVM[];
	assignedWorkOrders: ScheduleBoardWorkOrderVM[];
}

type Props = OwnProps & StateProps;

class EquipmentModal extends React.PureComponent<Props> {

	closeModal = () => {
		const { setEquipmentModalVisibility } = this.props;
		setEquipmentModalVisibility(false);
	};

	createEquipmentAssignment = async (workOrderId: number) => {
		const { createEquipmentAssignment, date, equipment, hasPermissionsToEditScheduleBoard } = this.props;

		if (!hasPermissionsToEditScheduleBoard) {
			return false;
		}

		if (!equipment || !date) {
			throw new Error('Equipment assignment not properly defined');
		}

		return await createEquipmentAssignment(
			AssignableResourceType.EQUIPMENT,
			date,
			workOrderId,
			equipment.equipmentStatus?.available ?? true,
			equipment
		);
	};

	removeEquipmentAssignment = (workOrderId: number, workOrderCode: string, itemId: number) => {
		const { removeEquipmentAssignment, date, hasPermissionsToEditScheduleBoard } = this.props;
		if (!hasPermissionsToEditScheduleBoard) {
			return;
		}

		if (!date) {
			throw new Error('Equipment assignment not properly defined');
		}

		removeEquipmentAssignment(date, workOrderId, workOrderCode, itemId);
	};

	renderDataModalContent = () => {
		const { assignableWorkOrders, assignedWorkOrders, equipment, hasPermissionsToEditScheduleBoard, openEquipmentWorkOrderHistoryModal } = this.props;
		if (!equipment) {
			throw new Error('Equipment assignment not properly defined');
		}
		const { id, name, specification, equipmentCategory, equipmentStatus, frontImageUrl, backImageUrl, licenses, jobCode, skills } = equipment;

		const equipmentStatusName = equipmentStatus?.name ?? 'Available';

		const assignedInOffices = assignedWorkOrders.filter((wo) => !!wo.officeNickname);

		const scheduleBoardBaseClassName = 'schedule-board-modal';
		const headerClassName = `${scheduleBoardBaseClassName}__big-header`;
		const headerLeft = `${scheduleBoardBaseClassName}__big-header-left`;
		const headerRight = `${scheduleBoardBaseClassName}__big-header-right`;

		return (
			<>
				<CustomModal.Header className={`${styles[headerClassName]}`}>
					<div className={`${styles[headerLeft]} `}>
						<span className="schedule-board-modal__resource__header__title">
							{name}
							{specification
								? <span className="schedule-board-modal__resource__header__subtitle"> {specification}</span>
								: null
							}
						</span>
					</div>
					<div className={`${styles[headerRight]} `}>
						<LabelWithColor color={equipment.color ?? AdditionalColors.GREY} text={equipmentCategory} />
						<SettingsButton
							openEquipmentWorkOrderHistoryModal={openEquipmentWorkOrderHistoryModal}
						/>
					</div>
				</CustomModal.Header >
				<CustomModal.Body>
					<div className="schedule-board-modal__resource__row schedule-board-modal__resource__row--even">
						<div className="schedule-board-modal__resource__avatar-wrapper schedule-board-modal__resource__avatar-wrapper--non-marginated">
							<ImageTag
								className="schedule-board-modal__resource__avatar"
								fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
								minSize={BlobStorageImageSizeContainer.SIZE_200X200}
								src={frontImageUrl}
								tryOriginal={true}
								tryRoot={true}
							/>
						</div>
						<div className="schedule-board-modal__resource__avatar-wrapper schedule-board-modal__resource__avatar-wrapper--non-marginated">
							<ImageTag
								className="schedule-board-modal__resource__avatar"
								fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
								minSize={BlobStorageImageSizeContainer.SIZE_200X200}
								src={backImageUrl}
								tryOriginal={true}
								tryRoot={true}
							/>
						</div>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Requirements" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Needed Licenses"
									value={licenses}
								/>
							</Col>
							<Col md={18}>
								<ResourceSkillList skills={skills} title="Needed skills" />
							</Col>
						</Row>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Current Status" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Status"
									value={equipmentStatusName}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job"
									value={jobCode}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job Locations"
									value={assignedInOffices.length > 0
										? assignedInOffices.map((wo) => wo.officeNickname).join(', ')
										: '-'
									}
								/>
							</Col>
							<Col md={12} />
						</Row>
					</div>
					<hr />
					<AssignmentsModalSection
						assignableWorkOrders={assignableWorkOrders}
						assignedWorkOrders={assignedWorkOrders}
						createAssignment={this.createEquipmentAssignment}
						isEditable={!equipment.isDeleted && hasPermissionsToEditScheduleBoard}
						itemId={id}
						maxItems={MAX_EQUIPMENT_ASSIGNMENTS_PER_DAY}
						removeAssignment={this.removeEquipmentAssignment}
						showPerDiem={false}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={this.closeModal}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</>
		);
	};

	renderLoadingModalContent = () => {
		return (
			<Loading onHide={this.closeModal} />
		);
	};

	render() {
		const { equipment, showEquipmentModal } = this.props;

		return (
			<CustomModal
				className="resource-modal equipment"
				closeModal={this.closeModal}
				modalStyle="info"
				showModal={showEquipmentModal}
				size="xl"
			>
				{equipment ? this.renderDataModalContent() : this.renderLoadingModalContent()}
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState, props: OwnProps): StateProps {
	const { equipment, date } = props;

	const workOrdersDictionary = date ? state.scheduleBoard.workOrdersByDateDictionary[date] : null;
	const assignedWorkOrderCodes = equipment?.id
		? workOrdersDictionary?.equipmentAssignments?.[equipment?.id] ?? []
		: [];
	const assignedWorkOrders = assignedWorkOrderCodes
		.map((workOrderCode) => workOrdersDictionary?.workOrders?.[workOrderCode])
		.filter((_wo): _wo is ScheduleBoardOnDateViewModel['workOrders'][string] => (!!_wo));

	return {
		assignedWorkOrders,
		assignableWorkOrders: Object.keys(workOrdersDictionary?.workOrders ?? {})
			.reduce((_acc, _woCode) => {
				if (
					!assignedWorkOrderCodes.includes(_woCode)
					&& workOrdersDictionary?.workOrders?.[_woCode]?.status !== WorkOrderStatusEnum.CANCELED
					&& workOrdersDictionary?.workOrders?.[_woCode]?.status !== WorkOrderStatusEnum.LOCKED
					&& !workOrdersDictionary?.workOrders?.[_woCode]?.isPaused
				) {
					_acc.push(workOrdersDictionary!.workOrders[_woCode]);
				}
				return _acc;
			}, [] as ScheduleBoardWorkOrderVM[]),
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(EquipmentModal);
