import * as React from 'react';
import type { WrappedFieldProps } from 'redux-form';
import { FormGroup } from 'react-bootstrap';
import type { SelectComponentsConfig, FormatOptionLabelMeta } from 'react-select';
import type { ValueType, OptionsType, OptionTypeBase } from 'react-select/src/types';
import type {
	getOptionLabel as getOptionLabelType,
	getOptionValue as getOptionValueType,
} from 'react-select/src/builtins';

import AsyncSelect from 'af-components/Controls/AsyncSelect';

type DefaultOptionType = {
	label: string;
	value: number;
};

interface Props<OptionType extends OptionTypeBase = DefaultOptionType> extends WrappedFieldProps {
	placeholder?: string;
	className?: string;
	isClearable?: boolean;
	allowNew?: boolean;
	label: string;
	disabled?: boolean;
	components?: SelectComponentsConfig<OptionType, boolean>;
	grouped?: boolean;
	menuStyle?: React.CSSProperties;
	getOptionLabel?: getOptionLabelType<OptionType>;
	getOptionValue?: getOptionValueType<OptionType>;
	onSearch: (inputValue: string, callback: (options: OptionsType<OptionType>) => void) => Promise<OptionType[]>;
	onClear?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onValueChange: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onBlur?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onCreateNew?: (option: string) => Promise<void> | void;
	formatOptionLabel?: (option: OptionType, labelMeta: FormatOptionLabelMeta<OptionType, boolean>) => React.ReactNode;
	isValidNewOption?: (inputValue: string, value: OptionType, options: OptionType[]) => boolean;
	fixed?: boolean;
	defaultValue?: OptionType;
}

export default class SelectField<OptionType extends OptionTypeBase = DefaultOptionType> extends React.Component<Props<OptionType>> {
	static defaultProps: Partial<Props> = {
		grouped: true,
		fixed: false,
	};

	onChange = (selectedOption: ValueType<OptionType, boolean>) => {
		const { input, onValueChange } = this.props;
		input.onChange(selectedOption as unknown as React.ChangeEvent<ValueType<OptionType, boolean>>);

		if (onValueChange) {
			onValueChange(selectedOption);
		}
	};

	renderSelect = () => {
		const { input, defaultValue, ...rest } = this.props;
		return (
			<AsyncSelect
				{...rest}
				onValueChange={this.onChange}
				value={!!input.value ? input.value : defaultValue}
			/>
		);
	};

	render() {
		const { grouped } = this.props;

		if (grouped) {
			return (
				<FormGroup>
					{this.renderSelect()}
				</FormGroup>
			);
		}
		return this.renderSelect();
	}
}
