import * as React from 'react';
import type { ConnectedProps} from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { CellContext } from '@tanstack/react-table';

import SimpleTable from 'af-components/Controls/SimpleTable/index';
import type { SimpleTableProps } from 'af-components/Controls/SimpleTable/types';

import * as WorkRequestActions from 'af-actions/workRequests';

import type { BillingCodeVM } from 'ab-viewModels/jobPreview.viewModel';

import { previewSectionClass, previewSectionTitleClass } from '../../helpers';
type OwnProps = {
	workRequestId: number;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const BillingCodesSection: React.FC<Props> = (props) => {
	const {
		workRequestId,
		findAllBillingCodes,
	} = props;

	const [billingCodes, setBillingCodes] = React.useState<BillingCodeVM[]>([]);

	const renderTextCell = React.useCallback((cell: CellContext<BillingCodeVM, string>) => {
		return (
			cell.getValue() ?? 'N/A'
		);
	}, []);

	const fetchAndSetBillingCodes = React.useCallback(async () => {
		const fetchedBillingCodes = await findAllBillingCodes(workRequestId, { page: 0, pageSize: 10000 });
		setBillingCodes(fetchedBillingCodes.rows);
	}, [findAllBillingCodes, workRequestId]);

	React.useEffect(() => {
		fetchAndSetBillingCodes();
	}, [fetchAndSetBillingCodes]);

	const columns: SimpleTableProps<BillingCodeVM>['columns'] = React.useMemo(() => [
		{
			id: 'lineItemNumber',
			accessor: 'lineItemNumber',
			header: 'Line Item',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'customerNumber',
			accessor: 'customerNumber',
			header: 'Customer No.',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'customerId',
			accessor: 'customerId',
			header: 'Customer ID',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'ownerNumber',
			accessor: 'ownerNumber',
			header: 'Owner Number',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'ownerId',
			accessor: 'ownerId',
			header: 'Owner ID',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'bidQuantity',
			accessor: 'bidQuantity',
			header: 'Bid Qty',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'unit',
			accessor: 'unit',
			header: 'Unit',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'unitPrice',
			accessor: 'unitPrice',
			header: 'Unit Price',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'group',
			accessor: 'group',
			header: 'SRC',
			cell: renderTextCell,
			enableSorting: true,
		},
		{
			id: 'description',
			accessor: 'description',
			header: 'Description',
			cell: renderTextCell,
			enableSorting: true,
		},
	], [renderTextCell]);

	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Billing Codes
			</div>
			<SimpleTable
				columns={columns}
				rows={billingCodes}
			/>
		</div>
	);
};

function mapDispatchToProps() {
	return {
		findAllBillingCodes: WorkRequestActions.findAllBillingCodesTable,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector
);

export default enhance(BillingCodesSection);
