import * as React from 'react';
import { compose } from 'redux';
import type { FormErrorsWithArray, InjectedFormProps } from 'redux-form';
import { Field, FieldArray, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';

import type { EquipmentListItemVM } from 'ab-viewModels/timeSplitEquipment/timeSplitEquipment';
import OrderStatus from 'acceligent-shared/enums/orderStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import OrderDeliveryMethod from 'acceligent-shared/enums/orderDeliveryMethod';
import OrderType from 'acceligent-shared/enums/orderType';

import { getUserName } from 'acceligent-shared/utils/user';

import * as EmployeeAction from 'af-actions/employee';
import * as EquipmentActions from 'af-actions/equipment';
import * as ItemActions from 'af-actions/item';
import * as JobActions from 'af-actions/jobs';
import * as LocationActions from 'af-actions/location';
import * as OrderActions from 'af-actions/orders';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { dollarFormatter } from 'af-utils/format.util';
import { isAllowed } from 'ab-utils/auth.util';

import { ORDER_FORM } from 'af-constants/reduxForms';

import CLIENT from 'af-routes/client';

import type { RootState } from 'af-reducers';

import type { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';
import type JobOrderFormVM from 'ab-viewModels/workRequest/jobOrderForm.viewModel';
import type LocationDepartmentsVM from 'ab-viewModels/location/locationDepartment.viewModel';

import ColorSquare from 'af-components/ColorSquare';
import TextHighlight from 'af-components/TextHighlight';
import ConfirmationModal from 'af-components/ConfirmationModal';
import Form from 'af-components/Form';
import Breadcrumbs from 'af-components/Breadcrumbs';

import DateInput from 'af-fields/DateInput';
import Dropdown from 'af-fields/Dropdown';
import Textarea from 'af-fields/Textarea';
import Checkbox from 'af-fields/Checkbox';

import { useLazyLoad, useToggle } from 'af-utils/react.util';

import type { OwnProps as OrderItemsProps } from './OrderItems';
import OrderItems from './OrderItems';
import type { OrderItemFM } from './formModel';
import OrderUpsertFM from './formModel';
import styles from './styles.module.scss';
import DeliveryMethodFields from './DeliveryMethodFields';

type PathParams = {
	orderId?: string;
};

interface LocationByDepartment {
	name: string;
	departmentId: number;
	departmentName: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;
type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<OrderUpsertFM>;

const flattendLocationDepartments = (locationDepartments: LocationDepartmentsVM[]): LocationByDepartment[] => {
	return locationDepartments.flatMap((_locationWithDepartments) => {
		return _locationWithDepartments.departments.map((_department) => (
			{
				name: _locationWithDepartments.name,
				departmentId: _department.id,
				departmentName: _department.name,
			}
		));
	});
};

const resolveBreadcrumbs = (label: string, orgAlias: string, companyName: string) => {
	const items: { label: string; url?: string; }[] =
		[
			{
				label: 'Orders',
				url: CLIENT.COMPANY.ORDER.TABLE(orgAlias, companyName),
			},
			{
				label,
			},
		];
	return items;
};

const renderOperatorMenuItem = (option: EmployeeOptionVM, searchText: string) => {
	return (
		<TextHighlight searchText={searchText} text={`${option.firstName} ${option.lastName}`} />
	);
};

const renderEquipmentOptionItem = (option: EquipmentListItemVM, searchText: string) => {
	return (
		<div className={styles['order-form__equipment']} key={option.id}>
			{option.color && <ColorSquare color={option.color} />}
			<TextHighlight searchText={searchText} text={option.code} />
			{option.specification &&
				<span className="resource-lookup__resource-info">
					(<TextHighlight searchText={searchText} text={option.specification} />)
				</span>}
		</div>
	);
};

const renderJobOptionItem = (option: JobOrderFormVM, searchText: string) => {
	return (
		<div key={option.id}>
			<div>
				<TextHighlight searchText={searchText} text={option.jobCode} />
			</div>
			<div>
				{option.title ? (
					<small className={styles['order-form__menu-option__sub-text']}>
						<TextHighlight searchText={searchText} text={option.title} />
					</small>
				) : null}
			</div>
		</div>
	);
};

const renderSelectedEquipment = (option: EquipmentListItemVM) => {
	return (
		<div className={styles['order-form__equipment']}>
			{option.color && <ColorSquare color={option.color} />}
			{option.code}
			{option.specification && ` (${option.specification}) `}
		</div>
	);
};

const renderSelectedJob = (option: JobOrderFormVM) => {
	return (
		<>
			{option.jobCode}
		</>
	);
};

const renderOrderDetail = (label: string, value: string) => {
	return (
		<div className={styles['order-detail']}>
			<div className={styles['order-detail__label']}>
				{label}
			</div>
			<div className={styles['order-detail__value']}>
				{value}
			</div>
		</div>
	);
};

const OrderStatusLabel: Record<OrderStatus, { label: string; classModifier: string; }> = {
	[OrderStatus.NEW]: { label: 'New', classModifier: styles['order-header__status--new'] },
	[OrderStatus.IN_PROGRESS]: { label: 'In Progress', classModifier: styles['order-header__status--in-progress'] },
	[OrderStatus.COMPLETED]: { label: 'Completed', classModifier: styles['order-header__status--completed'] },
};

const renderSelectedStatus = (option: OrderStatus) => {

	const status = OrderStatusLabel[option] ?? OrderStatusLabel.NEW;
	return (
		<div className={`${styles['order-header__status']} ${status.classModifier}`}>
			{status.label}
		</div>
	);
};

const leavePageModalBody = <>
	You are about to leave this page without saving or submitting. <br />
	Changes that you made will not be saved.
</>;

const completeOrderModalBody = <>
	You are about to change the status of this Order to <i>Completed</i>. <br />
	This form will no longer be editable. Complete Order?
</>;

const changeOrderTypeModal = <>
	Changing order type to <i>Order for Stock</i> will remove sections <i>Order Details</i> <br />
	and <i>Delivery Details</i> and their contents as they will no longer be relevant. <br />
	This action cannot be undone. <br /> <br />
	Continue?
</>;

const OrderUpsertForm: React.FC<Props> = (props) => {
	const {
		match: { params: { orderId } },
		location: { state: { orgAlias, originUrl }, search },
		findForEdit,
		initialize,
		initialized,
		companyName,
		findAllEquipmentForCompany,
		findAllJobsForCompany,
		change: _change,
		formDeliveryMethod,
		findAllLocationsForCompany,
		findAllDepartmentsForCompany,
		findAllFieldWorkers,
		form,
		createOrder,
		editOrder,
		valid,
		errors,
		history,
		formData,
		canManage,
		status,
		dirty,
		isOrderForStock,
		findItems,
	} = props;
	const { options: equipmentOptions, lazyLoad: lazyLoadEquipment } = useLazyLoad(findAllEquipmentForCompany);
	const { options: jobOptions, lazyLoad: lazyLoadJobs } = useLazyLoad(findAllJobsForCompany);
	const { options: accountOptions, lazyLoad: lazyLoadAccounts } = useLazyLoad(findAllFieldWorkers);
	const { lazyLoad: lazyLoadLocations, options: locationDepartmentOptions } = useLazyLoad(findAllDepartmentsForCompany);

	const change = React.useMemo(() => _change,
		// We don't want nothing here, that is the purpose of this
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]);

	const [total, setTotal] = React.useState(0);
	const [order, setOrder] = React.useState<Nullable<OrderUpsertFM>>(null);

	const [isLeavePageModalOpen, setIsLeavePageModalOpen] = React.useState(false);
	const [isCompleteOrderModalOpen, setIsCompleteOrderModalOpen] = React.useState(false);
	const [hasItemsInEditMode, setHasItemsInEditMode] = React.useState(false);

	const queryParams = new URLSearchParams(search);
	const forPrint = queryParams.get('forPrint') === 'true';
	const initiatePrint = queryParams.get('initiatePrint') === 'true';

	const formLocation = React.useMemo(() => `${formData?.locationDepartmentForStock?.name} (${formData?.locationDepartmentForStock?.departmentName})`,
		[formData?.locationDepartmentForStock?.departmentName, formData?.locationDepartmentForStock?.name]);

	const {
		value: isChangeOrderTypeModalOpen,
		setToTrue: openChangeOrderTypeModal,
		setToFalse: closeChangeOrderTypeModal,
	} = useToggle(false);

	const isInEditMode = !!orderId;

	const handleEdit = React.useCallback(() => {
		const url = new URL(CLIENT.COMPANY.ORDER.EDIT(orgAlias, companyName, `${orderId}`), location.origin);

		// history.push(url) will mess up styling and redux form, so we do it like this, this works fine
		window.location.href = url.toString();

	}, [companyName, orderId, orgAlias]);

	const iframeRef = React.useRef<HTMLIFrameElement>(null);

	const handlePrint = React.useCallback(() => {
		const iframe = iframeRef.current;

		// Find the print button by its ID
		const printButton = document.getElementById('print-button');
		const editButton = document.getElementById('edit-button');

		const checkboxesToHide = document.querySelectorAll('[id*="form-check-checkbox"]');
		const checkboxesToShow = document.querySelectorAll('[id*="print-checkbox"]');

		// Get all input fields
		const inputFields = document.querySelectorAll('input');

		// Store the original border styles to restore them later
		const originalInputStyles = Array.from(inputFields).map((input) => ({
			element: input,
			originalBorder: input.style.border,
		}));

		// Hide print and edit buttons
		if (printButton) {
			printButton.style.display = 'none';
		}
		if (editButton) {
			editButton.style.display = 'none';
		}

		// Hide checkboxesDivsToHide
		checkboxesToHide.forEach((checkboxDiv) => {
			(checkboxDiv as HTMLElement).style.setProperty('display', 'none', 'important');
		});

		// Show checkboxesToShow
		checkboxesToShow.forEach((checkbox) => {
			(checkbox as HTMLElement).style.display = 'block';
		});

		// Set border to none for all input fields
		inputFields.forEach((input) => {
			(input as HTMLElement).style.border = 'none';
			(input as HTMLElement).style.top = '-10px';
		});

		if (iframe) {
			const iframeWindow = iframe.contentWindow;

			if (iframeWindow) {
				// Get the content to print
				const formElement = document.getElementById('form');
				if (!formElement) {
					console.error('Form element not found');
					return;
				}

				// Collect all <style> and <link rel="stylesheet"> elements from the main document
				const _styles = Array.from(document.querySelectorAll('style, link[rel="stylesheet"]'))
					.map((style) => {
						const styleText = style.outerHTML.toString();

						if (styleText.includes('.styles_form__section') || styleText.includes('.styles_simple-table')) {
							// Use regex to remove margin and padding properties
							const modifiedStyle = styleText.replace(/margin\s*[^;]+;/g, '')
								.replace(/padding\s*[^;]+;/g, '')
								.replace(/gap\s*[^;]+;/g, 'gap: 5px;')
								.replace(/width\s*[^;]+;/g, '');

							return modifiedStyle;

						} else {
							return styleText;
						}

					})
					.join('');

				// Define the print-specific styles
				const printStyles = `
					@media print {
						/* Target classes using attribute selectors with partial matches */
						[class*="simple-table__body"] {
							display: table;
							width: 100%;
							table-layout: fixed;
						}

						[class*="simple-table__row"],
						[class*="simple-table__header-row"] {
							display: table-row;
						}

						[class*="simple-table__cell"],
						[class*="simple-table__header-cell"] {
							display: table-cell;
							padding: 5px;
							width: auto;
							white-space: normal;
							word-wrap: break-word;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						[class*="simple-table__header-cell"] {
							font-weight: bold;
						}

						[class*="form__section"],
						[class*="field-value"] {
							margin: 0;
							padding: 0;
						}

						[class*="simple-table__header-cell"],
						[class*="simple-table__cell"] {
							padding: 3px;
						}

						.row {
							display: flex;
							flex-wrap: nowrap;
							justify-content: start;
						}

						@page {
							size: A4 portrait;
							margin: 10mm; 
						}
					}
				`;

				// Write the content into the iframe
				iframeWindow.document.open();
				iframeWindow.document.write('<html><head><title>Print</title>');
				iframeWindow.document.write(_styles); // Inject collected styles
				iframeWindow.document.write(`<style>${printStyles}</style>`); // Inject print-specific styles
				iframeWindow.document.write('</head><body>');
				iframeWindow.document.write(formElement.innerHTML); // Inject content from formElement
				iframeWindow.document.write('</body></html>');
				iframeWindow.document.close();

				// Print when the iframe content is loaded
				iframeWindow.onload = () => {
					iframeWindow.focus();
					iframeWindow.print();
				};
			} else {
				console.error('Iframe contentWindow is not accessible');
			}
		} else {
			console.error('Iframe reference is null');
		}

		// Restore the print button after printing
		if (printButton) {
			printButton.style.display = 'block';
		}
		if (editButton) {
			editButton.style.display = 'block';
		}

		// Show hidden checkbox divs
		checkboxesToHide.forEach((checkboxDiv) => {
			(checkboxDiv as HTMLElement).style.display = 'block';
		});

		// Hide newly showed checkbox divs
		checkboxesToShow.forEach((checkbox) => {
			(checkbox as HTMLElement).style.display = 'none';
		});

		// Restore the original border style for input fields
		originalInputStyles.forEach((inputStyle) => {
			inputStyle.element.style.border = inputStyle.originalBorder;
			inputStyle.element.style.top = '0';
		});
	}, []);

	const fetchAndInitializeForEdit = React.useCallback(async () => {
		if (initialized) {
			return;
		}
		if (isInEditMode) {
			const fetchedOrder = await findForEdit(+orderId);
			const orderFM = new OrderUpsertFM(fetchedOrder);

			setOrder(orderFM);
			initialize(orderFM);
		} else {
			initialize({ deliveryMethod: OrderDeliveryMethod.DELIVERY, orderType: OrderType.REGULAR });
		}
	}, [findForEdit, initialize, initialized, isInEditMode, orderId]);

	const openCompleteOrderModal = React.useCallback(() => {
		setIsCompleteOrderModalOpen(true);
	}, [setIsCompleteOrderModalOpen]);

	const closeCompleteOrderModal = React.useCallback(() => {
		setIsCompleteOrderModalOpen(false);
	}, [setIsCompleteOrderModalOpen]);

	const onEquipmentClear = React.useCallback(() => {
		change(OrderUpsertFM.getAttributeName('equipment'), null);
		change(OrderUpsertFM.getAttributeName('equipmentId'), null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onOperatorClear = React.useCallback(() => {
		change(OrderUpsertFM.getAttributeName('operator'), null);
		change(OrderUpsertFM.getAttributeName('operatorId'), null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onJobClear = React.useCallback(() => {
		change(OrderUpsertFM.getAttributeName('job'), null);
		change(OrderUpsertFM.getAttributeName('jobId'), null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submit = React.useCallback(async (_form: OrderUpsertFM) => {
		let rm;
		if (isOrderForStock) {
			const itemsWithDepartment = _form.items.map((_item) => {
				return { ..._item, departmentId: _form.departmentIdForStock! };
			});
			rm = OrderUpsertFM.toRM({ ..._form, items: itemsWithDepartment });
		} else {
			rm = OrderUpsertFM.toRM(_form);
		}
		if (isInEditMode) {
			await editOrder(+orderId, rm);
		} else {
			await createOrder(rm);
		}
		history.push(CLIENT.COMPANY.ORDER.TABLE(orgAlias, companyName));
	}, [companyName, createOrder, editOrder, history, isInEditMode, orderId, orgAlias, isOrderForStock]);

	const onCreate = React.useCallback(() => {
		const _form = { ...formData, status: OrderStatus.NEW };
		submit(_form);
	}, [formData, submit]);

	const onSave = React.useCallback(() => {
		submit(formData);
	}, [formData, submit]);

	const onSubmit = React.useCallback(() => {
		const _form = { ...formData, status: OrderStatus.IN_PROGRESS };
		submit(_form);
	}, [formData, submit]);

	const onComplete = React.useCallback(() => {
		const _form = { ...formData, status: OrderStatus.COMPLETED };
		submit(_form);
	}, [formData, submit]);

	const calculateAndSetTotal = React.useCallback((orderItems: OrderItemFM[] = []) => {
		const newTotal = orderItems.reduce((_acc, _currOI) => {
			if (_currOI.excludeFromTotalPrice) {
				return _acc;
			}

			const currentPrice = +_currOI.quantity * (_currOI?.price ?? 0);
			return _acc + currentPrice;
		}, 0);

		setTotal(newTotal);
	}, []);

	const completeOrderModalFooter = React.useCallback(() => {
		return (
			<>
				<Button onClick={closeCompleteOrderModal} variant="info">
					Cancel
				</Button>
				<Button onClick={onComplete} variant="danger">
					Complete
				</Button>
			</>
		);
	}, [closeCompleteOrderModal, onComplete]);

	React.useEffect(() => {
		fetchAndInitializeForEdit();
	}, [fetchAndInitializeForEdit]);

	const onBack = React.useCallback(() => {
		history.push(originUrl || CLIENT.COMPANY.ORDER.TABLE(orgAlias, companyName));
	}, [companyName, history, orgAlias, originUrl]);

	const closeLeavePageModal = React.useCallback(() => {
		setIsLeavePageModalOpen(false);
	}, [setIsLeavePageModalOpen]);

	const openLeavePageModal = React.useCallback(() => {
		setIsLeavePageModalOpen(true);
	}, [setIsLeavePageModalOpen]);

	const close = React.useCallback(() => {
		if (dirty) {
			openLeavePageModal();
		} else {
			onBack();
		}
	}, [dirty, openLeavePageModal, onBack]);

	const leavePageModalFooter = React.useCallback(() => {
		return (
			<>
				<Button onClick={closeLeavePageModal} variant="info">
					Stay
				</Button>
				<Button onClick={onBack} variant="danger">
					Leave
				</Button>
			</>
		);
	}, [closeLeavePageModal, onBack]);

	const attemptToToggleOrderForStock = React.useCallback(() => {

		// If toggle attempt is to for stock, intercept it
		if (!isOrderForStock) {
			openChangeOrderTypeModal();
			change('isOrderForStock', false);
			change('locationDepartmentForStock', null);
			change('items', []);
			return;
		} else {
			// Remove values that exist on both form states
			change(OrderUpsertFM.getAttributeName('orderType'), OrderType.REGULAR);
			change(OrderUpsertFM.getAttributeName('deliveryMethod'), OrderDeliveryMethod.DELIVERY);
			change('departmentIdForStock', null);
			change('dateNeededForStock', null);
			change('items', []);
		}
	}, [change, isOrderForStock, openChangeOrderTypeModal]);

	const confirmToggleOnOrderForStock = React.useCallback(() => {
		change(OrderUpsertFM.getAttributeName('orderType'), OrderType.FOR_STOCK);
		change(OrderUpsertFM.getAttributeName('deliveryMethod'), OrderDeliveryMethod.UNKNOWN);
		change('locationId', null);
		change('location', null);
		change('dateNeeded', null);
		change('carrier', null);
		change('attendee', null);
		change('trackingNumber', null);
		change('addressId', null);
		change('address', null);
		change('accountId', null);
		change('operator', null);
		change('equipmentId', null);
		change('equipment', null);
		change('jobId', null);
		change('job', null);
		change(OrderUpsertFM.getAttributeName('deliveryNote'), null);

		change('isOrderForStock', true);
		closeChangeOrderTypeModal();
	}, [change, closeChangeOrderTypeModal]);

	const cancelToggleOnOrderForStock = React.useCallback(() => {
		closeChangeOrderTypeModal();
	}, [closeChangeOrderTypeModal]);

	const changeOrderTypeModalFooter = React.useCallback(() => {
		return (
			<div className={styles['change-order-type-footer']}>
				<Button onClick={cancelToggleOnOrderForStock} variant="info">
					Cancel
				</Button>
				<Button onClick={confirmToggleOnOrderForStock} variant="danger">
					Continue
				</Button>
			</div>
		);
	}, [cancelToggleOnOrderForStock, confirmToggleOnOrderForStock]);

	const renderDepartmentLocationOption = React.useCallback((option: LocationByDepartment) => (
		<div className={styles['item-option__to-department-container']}>
			<span>{option.name}</span><span>({option.departmentName})</span>
		</div >
	), []);

	const renderDepartmentLocationSelected = React.useCallback((option: LocationByDepartment) => {
		return (<div className={styles['item-option__to-department-container']}>
			<span>{option.name}</span><span>({option.departmentName})</span>
		</div >);
	}, []);

	const handleDepartmentForStockChange = React.useCallback((event) => {

		const department = flattendLocationDepartments(locationDepartmentOptions).find((_department) => _department.departmentId === +event);

		formData?.items.forEach((_item, index) => {
			const _departmentOption = _item.departmentOptions.find((_department) => _department.id === department?.departmentId);

			change(`items[${index}].itemDepartmentId`, _departmentOption?.itemDepartmentId ?? null);
		});
	}, [change, formData?.items, locationDepartmentOptions]);

	const hasItemErrors = React.useMemo(() => errors?.items?.some((_itemError) => !!Object.keys(_itemError).length), [errors]);

	const calendarSettings = React.useMemo(() => ({ minDate: order?.dateSubmitted ? new Date(order.dateSubmitted) : new Date() }), [order]);

	const operatorName = React.useMemo(() => {
		return formData?.operator ? getUserName(formData?.operator) : 'N/A';
	}, [formData?.operator]);

	const equipmentOption = React.useMemo(() => {
		return formData?.equipment;
	}, [formData]);

	const dateNeeded = React.useMemo(() => {
		return formData?.dateNeeded;
	}, [formData]);

	const job = React.useMemo(() => {
		return formData?.job;
	}, [formData?.job]);

	const notes = React.useMemo(() => {
		return formData?.notes ?? '-';
	}, [formData?.notes]);

	React.useEffect(() => {
		if (initialized && initiatePrint && orderId) {
			// Add a delay of 500 milliseconds before triggering the print so that everything is loaded
			const timeoutId = setTimeout(() => {
				handlePrint();
			}, 500);

			return () => clearTimeout(timeoutId);
		}
	}, [initialized, initiatePrint, handlePrint, orderId]);

	const requestedBy = React.useMemo(() => {
		if (order) {
			return order.requestedByFullName ?? 'N/A';
		}

		return 'N/A';
	}, [order]);

	const requestedAt = React.useMemo(() => {
		if (order) {
			return order.createdAt;
		}

		return 'N/A';
	}, [order]);

	const submittedAt = React.useMemo(() => {
		if (order) {
			return order.dateSubmitted ?? 'N/A';
		}

		return 'N/A';
	}, [order]);

	const breadcrumbLabel = React.useMemo(() => {
		if (forPrint || (isInEditMode && order?.status === OrderStatus.COMPLETED)) {
			return 'Preview';
		}
		if (isInEditMode && order?.status !== OrderStatus.COMPLETED) {
			return 'Edit';
		}

		return 'New Order';
	}, [forPrint, isInEditMode, order?.status]);

	const hasItemsWithNoDepartment = React.useMemo(() => formData?.items.some((_item) => !_item.departmentId), [formData?.items]);

	if (isInEditMode && !order) {
		return <Breadcrumbs items={resolveBreadcrumbs('Loading', orgAlias, companyName)} />;
	}

	const disabled = isInEditMode && ((status !== OrderStatus.NEW && !canManage) || status === OrderStatus.COMPLETED) || forPrint;

	const showCompleteButton = status === OrderStatus.IN_PROGRESS && canManage && !forPrint;
	const showCreateButton = !isInEditMode && !forPrint;
	const showSubmitButton = canManage && (!isInEditMode || status === OrderStatus.NEW) && !forPrint;
	const showSaveButton = isInEditMode && (status === OrderStatus.NEW || (status === OrderStatus.IN_PROGRESS && canManage)) && !forPrint;

	const showOrderForStock = (isOrderForStock || !isInEditMode) && canManage;
	const showOrderForStockEditable = isOrderForStock && !forPrint && status !== OrderStatus.COMPLETED;
	const showOrderForStockPrint = forPrint || status === OrderStatus.COMPLETED;

	return (
		<>
			<Breadcrumbs items={resolveBreadcrumbs(breadcrumbLabel, orgAlias, companyName)} />
			<Form id="form">
				<Form.Header id="form-header" sticky={true}>
					<div className={styles['order-header__left']}>
						<span className={styles['order-header__cost']}>
							{`Cost for Order: ${dollarFormatter.format(total)}`}
						</span>
						<div className={styles['order-header__status-label']}>
							<span>Order Status</span>
							{renderSelectedStatus(status)}
						</div>
					</div>
					<div className={styles['order-header__right']}>
						{
							forPrint &&
							<>
								<Button id="print-button" onClick={handlePrint}>
									Print
								</Button>
								<Button id="edit-button" onClick={handleEdit}>
									Edit
								</Button>
							</>
						}
						{
							!forPrint &&
							<Button onClick={close} variant="info">
								Cancel
							</Button>
						}

						{showCreateButton && (
							<Button disabled={!valid || hasItemErrors || hasItemsInEditMode} onClick={onCreate} variant="primary">Create</Button>
						)}
						{showSaveButton && (
							<Button disabled={!valid || hasItemErrors || hasItemsInEditMode} onClick={onSave} variant="primary">Save</Button>
						)}
						{showSubmitButton && (
							<Button disabled={!valid || hasItemErrors || hasItemsInEditMode} onClick={onSubmit} variant="primary">Submit</Button>
						)}
						{showCompleteButton && (
							<Button disabled={!valid || hasItemErrors || hasItemsInEditMode || hasItemsWithNoDepartment} onClick={openCompleteOrderModal} variant="primary">Complete</Button>
						)}
					</div>
				</Form.Header>
				{
					showOrderForStock &&
					<Form.Section title="Order For Stock">
						<Row className={styles['order-row']}>
							{
								!forPrint &&
								<Col md={2}>
									<Field
										component={Checkbox}
										id="order_for_stock"
										isDisabled={isInEditMode}
										label="Order for Stock"
										name="isOrderForStock"
										onValueChange={attemptToToggleOrderForStock}
									/>
								</Col>
							}
							<Col md={5}>
								{
									showOrderForStockEditable &&
									<Field
										component={Dropdown}
										id="location_select_for_stock"
										label="To Location (Department)*"
										name="departmentIdForStock"
										onChange={handleDepartmentForStockChange}
										onLazyLoad={lazyLoadLocations}
										options={flattendLocationDepartments(locationDepartmentOptions)}
										placeholder="Select Location"
										propName={OrderUpsertFM.getAttributeName('locationDepartmentForStock')}
										renderMenuItem={renderDepartmentLocationOption}
										renderSelected={renderDepartmentLocationSelected}
										valueKey="departmentId"
										withCaret={true}
									/>
								}
								{
									showOrderForStockPrint &&
									<>
										<div className={styles['field-value']}>To Location (Department)*</div>
										{
											!formLocation &&
											<div className={styles['field-value']}>-</div>
										}
										{
											formLocation &&
											<div className={styles['field-value']}>{formLocation}</div>
										}
									</>
								}
							</Col>
							<Col md={12}>
								<div className={styles['datepicker-field']}>
									{
										showOrderForStockEditable &&
										<Field
											calendarSettings={calendarSettings}
											component={DateInput}
											id="dateNeeded"
											label="Date Needed *"
											name={OrderUpsertFM.getAttributeName('dateNeededForStock')}
											originalDateFormat={TimeFormat.DATE_ONLY}
											placeholderText={TimeFormat.DATE_ONLY}
										/>
									}
									{
										showOrderForStockPrint &&
										<>
											<div>Date Needed</div>
											<div className={styles['field-value']}>{dateNeeded}</div>
										</>
									}
								</div>
							</Col>
						</Row>
					</Form.Section>
				}
				{
					!isOrderForStock &&
					<Form.Section title="Order Details">
						<Row className={styles['order-details-row']}>
							{renderOrderDetail('Requested By', requestedBy)}
							{renderOrderDetail('Date Requested', requestedAt)}
							{renderOrderDetail('Date Submitted', submittedAt)}
						</Row>
						<Row className={styles['order-row']}>
							<Col md={12}>
								{!disabled &&
									<Field
										component={Dropdown}
										filterable={true}
										filterBy={['firstName', 'lastName']}
										id="operator_select"
										label="Operator"
										name={OrderUpsertFM.getAttributeName('operatorId')}
										onClear={onOperatorClear}
										onLazyLoad={lazyLoadAccounts}
										options={accountOptions}
										placeholder="Select Operator"
										propName={OrderUpsertFM.getAttributeName('operator')}
										renderMenuItem={renderOperatorMenuItem}
										valueKey="accountId"
										withCaret={true}
									/>
								}
								{
									disabled &&
									<>
										<div>Operator</div>
										<div className={styles['field-value']}>{operatorName}</div>
									</>
								}
								{
									!disabled &&
									<Field
										component={Dropdown}
										filterable={true}
										filterBy={['code']}
										id="equipment_select"
										label="Equipment"
										name="equipmentId"
										onClear={onEquipmentClear}
										onLazyLoad={lazyLoadEquipment}
										options={equipmentOptions.list}
										placeholder="Select Equipment"
										propName={OrderUpsertFM.getAttributeName('equipment')}
										renderMenuItem={renderEquipmentOptionItem}
										renderSelected={renderSelectedEquipment}
										valueKey="equipmentId"
										withCaret={true}
									/>
								}
								{
									disabled &&
									<>
										<div>Equipment</div>
										{
											equipmentOption ? (
												<div className={styles['row-value']}>
													<ColorSquare color={equipmentOption.color} />
													<div className={styles['field-value']}>{equipmentOption.code} {equipmentOption.specification ? `(${equipmentOption.specification})` : ''}</div>
												</div>
											) : (
												<div className={styles['field-value']}>N/A</div>
											)
										}
									</>
								}
							</Col>
							<Col md={12}>
								<div className={styles['datepicker-field']}>
									{
										!disabled &&
										<Field
											calendarSettings={calendarSettings}
											component={DateInput}
											id="dateNeeded"
											label="Date Needed *"
											name={OrderUpsertFM.getAttributeName('dateNeeded')}
											originalDateFormat={TimeFormat.DATE_ONLY}
											placeholderText={TimeFormat.DATE_ONLY}
										/>
									}
									{
										disabled &&
										<>
											<div>Date Needed</div>
											<div className={styles['field-value']}>{dateNeeded}</div>
										</>
									}
								</div>
								{
									!disabled &&
									<Field
										component={Dropdown}
										disabled={disabled}
										filterable={true}
										filterBy={['jobCode', 'title']}
										id="job_select"
										label="Job"
										name="jobId"
										onClear={onJobClear}
										onLazyLoad={lazyLoadJobs}
										options={jobOptions}
										placeholder="Select Job"
										propName={OrderUpsertFM.getAttributeName('job')}
										renderMenuItem={renderJobOptionItem}
										renderSelected={renderSelectedJob}
										valueKey="id"
										withCaret={true}
									/>
								}
								{
									disabled &&
									<>
										<div>Job</div>
										<div className={styles['field-value']}>{job?.jobCode ?? 'N/A'}</div>
									</>
								}
							</Col>
						</Row>
					</Form.Section>
				}
				{
					!isOrderForStock &&
					<Form.Section title="delivery details">
						<DeliveryMethodFields
							change={change}
							disabled={disabled}
							findAllLocationsForCompany={findAllLocationsForCompany}
							formDeliveryMethod={formDeliveryMethod}
							formName={form}
							formValues={formData}
						/>
					</Form.Section>
				}
				<Form.Section title="notes">
					<Row className={styles['order-row']}>
						<Col md={24}>
							{
								!disabled &&
								<Field
									component={Textarea}
									disabled={disabled}
									label="Notes"
									maxCharacters={300}
									name={OrderUpsertFM.getAttributeName('notes')}
									placeholder="Enter Note"
									rows={1}
								/>
							}
							{
								disabled &&
								<>
									<div>Notes</div>
									<div className={styles['field-value']}>{notes}</div>
								</>
							}
						</Col>
					</Row>
				</Form.Section>
				<Form.Section>
					<Row className={styles['order-row']}>
						<Col md={24}>
							<div className={styles[`order-row-table${formData?.isOrderForStock ? '-stock' : ''}`]}>
								<FieldArray<OrderItemsProps>
									calculateAndSetTotal={calculateAndSetTotal}
									canManage={canManage}
									change={change}
									component={OrderItems}
									disabled={disabled}
									errors={errors}
									findItems={findItems}
									formValues={formData}
									initialized={isInEditMode ? initialized : true}
									isInEditMode={isInEditMode}
									itemsForStock={formData?.isOrderForStock}
									name={OrderUpsertFM.getAttributeName('items')}
									rerenderOnEveryChange={true}
									setHasItemsInEditMode={setHasItemsInEditMode}
								/>
							</div>

						</Col>
					</Row>
				</Form.Section>
			</Form>
			<ConfirmationModal
				body={leavePageModalBody}
				closeModal={closeLeavePageModal}
				footer={leavePageModalFooter()}
				modalStyle="danger"
				showModal={isLeavePageModalOpen}
				size="md"
				title="Leave Page?"
			/>
			<ConfirmationModal
				body={completeOrderModalBody}
				closeModal={closeCompleteOrderModal}
				footer={completeOrderModalFooter()}
				modalStyle="danger"
				showModal={isCompleteOrderModalOpen}
				size="md"
				title="Complete Order?"
			/>
			<ConfirmationModal
				body={changeOrderTypeModal}
				closeModal={closeChangeOrderTypeModal}
				footer={changeOrderTypeModalFooter()}
				modalStyle="danger"
				showModal={isChangeOrderTypeModalOpen}
				size="md"
				title="Change Order Type?"
			/>
			{/* Hidden iframe for printing */}
			<iframe
				ref={iframeRef}
				style={{ display: 'none' }}
				title="print-iframe"
			></iframe>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	const formState = getFormValues(ORDER_FORM);
	const errors = getFormSyncErrors(ORDER_FORM)(state) as FormErrorsWithArray<OrderUpsertFM, string>;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}
	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const {
		deliveryMethod,
		status,
		orderType,
		isOrderForStock,
	} = formState(state) as OrderUpsertFM ?? ({} as Partial<OrderUpsertFM>);

	return {
		companyName: companyData.name,
		formDeliveryMethod: deliveryMethod,
		status,
		formData: getFormValues(ORDER_FORM)(state) as OrderUpsertFM,
		errors,
		canCreate: isAllowed(PagePermissions.COMPANY.ORDER.CREATE, permissions, isCompanyAdmin, role),
		canManage: isAllowed(PagePermissions.COMPANY.ORDER.MANAGE, permissions, isCompanyAdmin, role),
		orderType,
		isOrderForStock,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

function mapDispatchToProps() {
	return {
		findForEdit: OrderActions.findForEdit,
		findAllEquipmentForCompany: EquipmentActions.findAllForCompanyList,
		findAllJobsForCompany: JobActions.getAllJobsForOrderForm,
		findAllLocationsForCompany: LocationActions.findList,
		findAllDepartmentsForCompany: LocationActions.findDepartmentList,
		findAllFieldWorkers: EmployeeAction.findAllEmployeesForWorkOrders,
		findItems: ItemActions.findAllForCompany,
		createOrder: OrderActions.createOrder,
		editOrder: OrderActions.editOrder,
	};
}

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	reduxForm({
		form: ORDER_FORM,
		validate: OrderUpsertFM.validate,
	}),
	connector
);

export default enhance(OrderUpsertForm);
