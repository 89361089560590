import * as qs from 'query-string';

import { USE_WILDCARDS } from 'af-constants/values';

import { removeSpaces } from 'ab-utils/text.util';

const clientAliasPrefix = ':clientAlias';
const companyNamePrefix = ':companyName';
const contactIdAlias = ':contactId';
const deliverablePrefix = ':deliverableId';
const idPrefix = ':id';
const jobIdAlias = ':jobId';
const workRequestIdAlias = ':workRequestId';
const logIdAlias = ':logId';
const memberIdAlias = ':memberId';
const orderIdAlias = ':orderId';
const organizationPrefix = ':orgAlias';
const personalPublicLinkPrefix = ':personalPublicLink';
const publicLinkPrefix = ':publicLink';
const submissionPrefix = ':submissionId';
const userGroupPrefix = ':userGroupId';
export const workOrderPrefix = ':workOrderId';
const wrCodeAlias = ':wrId';
const temporaryEmployeeIdAlias = ':temporaryEmployeeId';
const messageAlias = ':messageId';
const purchaseOrderAlias = ':purchaseOrderId';
const invoiceIdAlias = ':invoiceId';

const getOrgAlias = (orgAlias: string) => USE_WILDCARDS ? '' : `/${orgAlias}`;

const CLIENT = {
	APP: {
		PRIVACY_POLICY: `${getOrgAlias('app')}/privacyPolicy`,
		TERMS_OF_SERVICE: `${getOrgAlias('app')}/termsOfService`,
		ORGANIZATIONS: `${getOrgAlias('app')}/org/`,
		ORGANIZATION_FORGOTTEN: `${getOrgAlias('app')}/org/forgotten`,
		ORGANIZATION_FORGOTTEN_SUCCESS: `${getOrgAlias('app')}/org/forgotten/success`,
	},
	HOME: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/`,
	LMS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/lmsLogin`,
	PLATFORM: {
		SIDENAV: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/platform/*`,
		ALL_ORGANIZATIONS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/platform/organizations`,
		SYSTEM_NOTIFICATIONS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/platform/systemNotifications`,
		CREATE: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/platform/organization/create`,
		NOTIFY_EMPLOYEES: (orgAlias: string = organizationPrefix, clientAlias: string = clientAliasPrefix) => `${getOrgAlias(orgAlias)}/platform/organization/${clientAlias}/notify`,
		USERS: {
			LIST: (orgAlias: string = organizationPrefix, clientAlias: string = clientAliasPrefix) => `${getOrgAlias(orgAlias)}/platform/organization/${clientAlias}/users/list`,
		},
	},
	ORGANIZATION: {
		SIDENAV: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/*`,
		ROOT: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/*`,
		HOME: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}`,
		SETTINGS: {
			SIDENAV: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/settings`,
			INFO: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/settings/info`,
			LOGS: {
				TABLE: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/settings/logs`,
				PREVIEW: (orgAlias: string = organizationPrefix, logId: string = logIdAlias) => `${getOrgAlias(orgAlias)}/organization/settings/logs/${logId}`,
			},
		},
		WORK_REQUESTS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/wr`,
		JOBS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/jobs`,
		COST_CODES: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/cc`,
		CONTACTS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/contacts`,
		MEMBERS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/members`,
		RESOURCES: {
			SIDENAV: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/`,
			ROOT: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/*`,
			WAGE_RATE: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/wageRate`,
			EMPLOYEE: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/employee`,
			EQUIPMENT: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/equipment`,
			EQUIPMENT_COST: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/eqCost`,
			CREW_TYPE: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/crewType`,
			DIVISION: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/division`,
			SKILL: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/skill`,
			DOCUMENT: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/document`,
			LOCATION: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/location`,
			SALE: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/resources/sale`,
		},
	},
	AUTH: {
		FINALIZE: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/finalize`,
		LOGIN: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/login`,
		LOGIN_CODE: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/login/code`,
		PLATFORM_ADMIN_LOGIN: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/platformAdmin/login`,
		REFFERED_LOGIN: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/reffered/login/`,
		LOGOUT: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/logout`,
		PASS_FORGOTTEN: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/passwordForgotten`,
		PASS_FORGOTTEN_SUCCESS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/passwordForgotten/success`,
		PASS_OUTDATED: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/passwordOutdated`,
		RESET_PASS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/resetPassword`,
		RESET_PASS_SUCCESS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/resetPassword/success`,
	},
	COMPANY: {
		SIDENAV: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/company/${companyNamePrefix}/*`,
		CREATE: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/organization/create-company`,
		CONTACTS: {
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/contacts`,
			LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/contacts`,
			CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/contacts/create`,
			EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, contactId: string = contactIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/contacts/edit/${contactId}`,
			PREVIEW: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, contactId: string = contactIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/contacts/preview/${contactId}`,
			BULK_UPLOAD: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/contacts/upload`,
		},
		TOOL_REPAIR: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/toolRepair`,
			TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.TOOL_REPAIR.ROOT(orgAlias, companyName)}/table`,
			CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.TOOL_REPAIR.ROOT(orgAlias, companyName)}/create`,
			EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.TOOL_REPAIR.ROOT(orgAlias, companyName)}/${id}/edit`,
			PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.TOOL_REPAIR.ROOT(orgAlias, companyName)}/${id}/preview`,
		},
		RESOURCES: {
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources`,
			WAGE_RATE: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/wageRate`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.WAGE_RATE.ROOT(orgAlias, companyName)}`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.WAGE_RATE.ROOT(orgAlias, companyName)}/create`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.WAGE_RATE.ROOT(orgAlias, companyName)}/${id}/edit`,
				PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.WAGE_RATE.ROOT(orgAlias, companyName)}/${id}/preview`,
				BULK_UPLOAD: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.WAGE_RATE.ROOT(orgAlias, companyName)}/upload`,
			},
			FIELD_WORK_CLASSIFICATION_CODE: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/fieldWorkClassificationCode`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.ROOT(orgAlias, companyName)}`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.ROOT(orgAlias, companyName)}/create`,
				PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.ROOT(orgAlias, companyName)}/${id}/preview`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.ROOT(orgAlias, companyName)}/${id}/edit`,
			},
			EMPLOYEE: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/employee`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EMPLOYEE.ROOT(orgAlias, companyName)}/list`,
				PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EMPLOYEE.ROOT(orgAlias, companyName)}/${id}/preview`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EMPLOYEE.ROOT(orgAlias, companyName)}/${id}/edit`,
				STATUS_HISTORY: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EMPLOYEE.ROOT(orgAlias, companyName)}/${id}/statusHistory`,
				BULK_UPLOAD: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EMPLOYEE.ROOT(orgAlias, companyName)}/upload`,
				BULK_UPDATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EMPLOYEE.ROOT(orgAlias, companyName)}/update`,
			},
			EMPLOYEE_STATUS: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/status/employee`,
			EQUIPMENT: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/equipment`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT.ROOT(orgAlias, companyName)}/list`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT.ROOT(orgAlias, companyName)}/create`,
				PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT.ROOT(orgAlias, companyName)}/${id}/preview`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT.ROOT(orgAlias, companyName)}/${id}/edit`,
				STATUS_HISTORY: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT.ROOT(orgAlias, companyName)}/${id}/statusHistory`,
				BULK_UPLOAD: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT.ROOT(orgAlias, companyName)}/upload`,
			},
			ITEM_CATEGORY: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/accessoryCategory`,
			ITEM: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/item`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.ITEM.ROOT(orgAlias, companyName)}`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.ITEM.ROOT(orgAlias, companyName)}/${id}`,
			},
			TOOL: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/tool`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.TOOL.ROOT(orgAlias, companyName)}`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.TOOL.ROOT(orgAlias, companyName)}/${id}/edit`,
				PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.TOOL.ROOT(orgAlias, companyName)}/${id}/preview`,
			},
			EQUIPMENT_STATUS: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/status/equipment`,
			EQUIPMENT_COST: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/eqCost`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.ROOT(orgAlias, companyName)}`,
				TREE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.ROOT(orgAlias, companyName)}/tree`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.ROOT(orgAlias, companyName)}/create`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.ROOT(orgAlias, companyName)}/${id}/edit`,
				BULK_UPLOAD: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.ROOT(orgAlias, companyName)}/upload`,
			},
			LOCATION: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/location`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.LOCATION.ROOT(orgAlias, companyName)}`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.LOCATION.ROOT(orgAlias, companyName)}/create`,
				PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.LOCATION.ROOT(orgAlias, companyName)}/${id}/preview`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.LOCATION.ROOT(orgAlias, companyName)}/${id}/edit`,
			},
			SALE: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/sale`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.SALE.ROOT(orgAlias, companyName)}`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.SALE.ROOT(orgAlias, companyName)}/create`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.SALE.ROOT(orgAlias, companyName)}/${id}/edit`,
				PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.SALE.ROOT(orgAlias, companyName)}/${id}/preview`,
			},
			CREW_TYPE: {
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/crewType`,
			},
			DIVISION: {
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/division`,
			},
			SKILL: {
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/skill`,
			},
			DOCUMENT: {
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/document`,
			},
			DELIVERABLE: {
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/deliverable`,
			},

			JOB_STATUS: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/jobStatus`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.JOB_STATUS.ROOT(orgAlias, companyName)}`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.JOB_STATUS.ROOT(orgAlias, companyName)}/create`,
				PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.JOB_STATUS.ROOT(orgAlias, companyName)}/${id}/preview`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.JOB_STATUS.ROOT(orgAlias, companyName)}/${id}/edit`,
			},
			AGENCY: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/agency`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.AGENCY.ROOT(orgAlias, companyName)}/list`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.AGENCY.ROOT(orgAlias, companyName)}/create`,
				EDIT: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.AGENCY.ROOT(orgAlias, companyName)}/${id}/edit`,
				PREVIEW: (id: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.AGENCY.ROOT(orgAlias, companyName)}/${id}/preview`,
			},
			TEMPORARY_EMPLOYEE: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/temporaryEmployee`,
				LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.ROOT(orgAlias, companyName)}/list`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.ROOT(orgAlias, companyName)}`,
				EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, temporaryEmployeeId: string = temporaryEmployeeIdAlias) => `${CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.ROOT(orgAlias, companyName)}/${temporaryEmployeeId}`,
				PREVIEW: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, temporaryEmployeeId: string = temporaryEmployeeIdAlias) => `${CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.ROOT(orgAlias, companyName)}/${temporaryEmployeeId}/preview`,
			},
		},
		COMPLIANCE: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/compliance`,
			PAGE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, pageId: string = ':pageId') => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/compliance/${pageId}`,
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/compliance`,
		},
		DASHBOARD: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/dashboard`,
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/dashboard`,
		},
		COMMUNICATION: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/communication`,
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.COMMUNICATION.ROOT(orgAlias, companyName)}`,
			URL_SHORTENER: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.COMMUNICATION.ROOT(orgAlias, companyName)}/shortener`,
			NOTIFY_EMPLOYEES: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, userGroupId?: number, originUrl?: string) => `${CLIENT.COMPANY.COMMUNICATION.ROOT(orgAlias, companyName)}/notifyUsers${originUrl ? `?${qs.stringify({ originUrl, userGroupId })}` : ''}`,
			COMMUNICATION_HISTORY: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.COMMUNICATION.ROOT(orgAlias, companyName)}/communicationHistory`,
			MESSAGE_PREVIEW: (id: string = messageAlias, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.COMMUNICATION.ROOT(orgAlias, companyName)}/communicationHistory/${id}`,
		},
		KNOWLEDGE_CENTER: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/knowledgeCenter`,
		LEARNING_MANAGEMENT_SYSTEM: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/learningManagementSystem`,
		SETTINGS: {
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings`,
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings`,
			ACCOUNT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/account/edit`,
			COMPANY: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/company`,
			SCHEDULE_BOARD: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/scheduleBoard`,
			WORK_ORDER: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/workOrder`,
			NOTIFICATION: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/notification`,
			CONNECTED_DEVICES: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/connectedDevices`,
			EQUIPMENT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/equipment`,
			LABOR: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/labor`,
			MEMBERS: {
				INVITE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/users/invite`,
				BULK_UPLOAD: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/users/bulk`,
				ROLES_PERMISSIONS: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/users/rolesAndPermissions`,
				EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, memberId: string = memberIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/users/${memberId}`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/users`,
			},
			LOGS: {
				PREVIEW: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, logId: string = logIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/logs/${logId}`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/logs`,
			},
			USER_GROUPS: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/userGroups`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.USER_GROUPS.ROOT(orgAlias, companyName)}/table`,
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.USER_GROUPS.ROOT(orgAlias, companyName)}`,
				EDIT: (userGroupId: string = userGroupPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.USER_GROUPS.ROOT(orgAlias, companyName)}/${userGroupId}`,
			},
			REPORT: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/settings/report`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.REPORT.ROOT(orgAlias, companyName)}/table`,
				REPORT_BLOCK: {
					CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.REPORT.ROOT(orgAlias, companyName)}/block`,
					EDIT: (reportBlockId: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.REPORT.ROOT(orgAlias, companyName)}/block/${reportBlockId}`,
				},
				REPORT_TYPE: {
					CUSTOM: {
						CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.REPORT.ROOT(orgAlias, companyName)}/type/custom`,
						EDIT: (reportTypeId: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.REPORT.ROOT(orgAlias, companyName)}/type/custom/${reportTypeId}`,
					},
					CREATE_CUSTOM: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.REPORT.ROOT(orgAlias, companyName)}/type/custom`,
					EDIT_CUSTOM: (reportTypeId: string = idPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.SETTINGS.REPORT.ROOT(orgAlias, companyName)}/type/custom/${reportTypeId}`,
				},
			},
		},
		WORK_ORDERS: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => CLIENT.COMPANY.WORK_ORDERS.LIST(orgAlias, companyName),
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/orders/`,
			FLOATING_SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/orders/table`,
			TABLE_JOBS: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/orders/activeJobs`,
			LIST: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, dueDate?: string) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/orders/table${dueDate ? `?dueDate=${encodeURIComponent(dueDate)}` : ''}`,
			ORDER: (orderId: Nullable<string> = workOrderPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) =>
				`${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/orders/order/${orderId ?? ''}${orderId === workOrderPrefix ? '?' : ''}`,
			CONFIRMATION: (workOrderId: string = workOrderPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/orders/order/${workOrderId}/confirmation`,
		},
		JOBS: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName),
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/jobs`,
			DASHBOARD: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, wrId: string = wrCodeAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/jobs/${wrId}/dashboard`,
			TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/jobs/table`,
			PREVIEW: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, jobId: string = jobIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/jobs/preview/${jobId}`,
			CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/jobs/create`,
			EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, jobId: string = jobIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/jobs/edit/${jobId}`,
			INVOICE_PREVIEW: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, jobId: string = jobIdAlias, invoiceId: string = invoiceIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/jobs/${jobId}/invoices/${invoiceId}`,
			INVOICE_CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, jobId: string = jobIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/jobs/${jobId}/invoices/create`,
			INVOICE_EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, jobId: string = jobIdAlias, invoiceId: string = invoiceIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/jobs/${jobId}/invoices/${invoiceId}/edit`,

		},
		WORK_REQUESTS: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => CLIENT.COMPANY.WORK_REQUESTS.TABLE(orgAlias, companyName),
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/workRequests`,
			TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/workRequests/table`,
			CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/workRequests/create`,
			EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, workRequestId: string = workRequestIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/workRequests/${workRequestId}/edit`,
			PREVIEW: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, workRequestId: string = workRequestIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/workRequests/${workRequestId}/preview`,
		},
		ACCOUNTING: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/accounting`,
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}`,
			TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/table`,
			PER_DIEM_REPORT: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/perDiemReport`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.PER_DIEM_REPORT.ROOT(orgAlias, companyName)}/table`,
			},
			PREVAILING_WAGE: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/prevailingWage`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE.ROOT(orgAlias, companyName)}/table`,
			},
			PREVAILING_WAGE_EXCEPTION_REPORT: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/prevailingWageExceptionReport`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE_EXCEPTION_REPORT.ROOT(orgAlias, companyName)}/table`,
			},
			JOB_PAYROLL_EXPORT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/jobPayrollExport`,
			JOB_PAYROLL_EXPORT_BETA: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/jobPayrollBetaExport`,
			JOB_PAYROLL_REPORT: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/jobPayrollReport`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_REPORT.ROOT(orgAlias, companyName)}/table`,
			},
			JOB_PAYROLL_REPORT_BETA: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/jobPayrollBetaReport`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_REPORT_BETA.ROOT(orgAlias, companyName)}/table`,
			},
			JOB_WORK_SUMMARY: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/jobWorkSummary`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.JOB_WORK_SUMMARY.ROOT(orgAlias, companyName)}/table`,
			},
			INVOICES: {
				ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.ROOT(orgAlias, companyName)}/invoices`,
				TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ACCOUNTING.INVOICES.ROOT(orgAlias, companyName)}/table`,
			},
		},
		FIELD_REPORT: {
			PARENT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/fieldReport/*`,
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => CLIENT.COMPANY.FIELD_REPORT.TABLE(orgAlias, companyName),
			SIDENAV: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/fieldReport`,
			TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/fieldReport/workOrders`,
			ALL_REPORTS: (workOrderId: string = workOrderPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/fieldReport/workOrder/${workOrderId}`,
			PREVIEW: (publicLink: string = publicLinkPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/fieldReport/preview/${publicLink}`,
			PUBLIC_REPORT: (publicLink: string = publicLinkPrefix, orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/fieldReport/publicReport/${publicLink}`,
		},
		SCHEDULE_BOARD: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/scheduleBoard`,
			DAILY_VIEW: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/scheduleBoard/dailyView`,
			DAILY_METRICS: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/scheduleBoard/dailyView/metrics`,
			WEEKLY_VIEW: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/scheduleBoard/weeklyView`,
			SMS_NOTIFICATION_CONFIRMATION: (orgAlias: string = organizationPrefix, personalPublicLink: string = personalPublicLinkPrefix) => `${getOrgAlias(orgAlias)}/availableEmployeeSmsNotification/confirmation/${personalPublicLink}`,
		},
		DISPLAY_VIEW: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/displayView`,
		},
		DELIVERABLE: {
			DASHBOARD: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/deliverable`,
			JOB: {
				EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, id: string = idPrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/deliverable/job/${id}/edit`,
			},
			SUBMISSION: {
				EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, deliverableId: string = deliverablePrefix, id: string = idPrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/deliverable/${deliverableId}/submission/${id}/edit`,
			},
			ASSIGNMENT: {
				CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, deliverableId: string = deliverablePrefix, submissionId: string = submissionPrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/deliverable/${deliverableId}/submission/${submissionId}/assignment`,
				EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, deliverableId: string = deliverablePrefix, submissionId: string = submissionPrefix, id: string = idPrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/deliverable/${deliverableId}/submission/${submissionId}/assignment/${id}/edit`,
			},
		},
		PURCHASE_ORDER: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/purchaseOrder`,
			TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.PURCHASE_ORDER.ROOT(orgAlias, companyName)}/table`,
			CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.PURCHASE_ORDER.ROOT(orgAlias, companyName)}/create`,
			PREVIEW: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, purchaseOrderId: string = purchaseOrderAlias) => `${CLIENT.COMPANY.PURCHASE_ORDER.ROOT(orgAlias, companyName)}/${purchaseOrderId}/preview`,
		},
		ORDER: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/order`,
			TABLE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/order/table`,
			CREATE: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/order/create`,
			EDIT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix, id: string = orderIdAlias) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/order/${id}/edit`,
		},
		MECHANIC_VIEW: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/mechanicView`,
		},
		ANALYTICS: {
			ROOT: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${getOrgAlias(orgAlias)}/company/${removeSpaces(companyName)}/analytics`,
			EQUIPMENT_UTILIZATION: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ANALYTICS.ROOT(orgAlias, companyName)}/equipmentUtilization`,
			LABOR_UTILIZATION: (orgAlias: string = organizationPrefix, companyName: string = companyNamePrefix) => `${CLIENT.COMPANY.ANALYTICS.ROOT(orgAlias, companyName)}/laborUtilization`,
		},
	},
	USER: {
		INVITATIONS: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/user/invitations`,
		EDIT: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/user/edit`,
	},
	FEEDBACK: {
		REQUEST: {
			APPROVE: (publicLink: string = publicLinkPrefix, orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/feedback/request/${publicLink}`,
			/** @deprecated UNUSED */
			PRINT: (publicLink: string = publicLinkPrefix, orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/feedback/request/${publicLink}/print`,
			SUCCESS: (publicLink: string = publicLinkPrefix, orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/feedback/request/${publicLink}/success`,
		},
		WORK_ORDER: (publicLink: string = publicLinkPrefix, orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/feedback/workOrder/${publicLink}`,
		WORK_ORDER_PERSONAL: (personalPublicLink: string = personalPublicLinkPrefix, orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/feedback/workOrder/personal/${personalPublicLink}`,
		WORK_ORDER_PERSONAL_UNASSIGNED: (orgAlias: string = organizationPrefix, code?: string, dueDate?: string) => `${getOrgAlias(orgAlias)}/feedback/workOrder/personal/unassigned${code || dueDate ? `?${qs.stringify({ code, dueDate })}` : ''}`,
	},
	ERROR: {
		ERR403: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/403`,
		ERR404: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/404`,
		ERR500: (orgAlias: string = organizationPrefix) => `${getOrgAlias(orgAlias)}/500`,
		ERR500_GENERAL: '/500',
	},
};

export const PUBLIC_ROUTES_LIST: readonly string[] = [
	CLIENT.COMPANY.FIELD_REPORT.PUBLIC_REPORT(),
];

export default CLIENT;
