import * as React from 'react';
import type { FieldArrayFieldsProps, FormErrorsWithArray } from 'redux-form';
import type { CellContext, Row } from '@tanstack/react-table';

import SimpleTableControl from 'af-components/Controls/SimpleTable';
import type { Column, FooterButton } from 'af-components/Controls/SimpleTable/types';

import styles from './styles.module.scss';
import DeleteCell from './DeleteCell';
import EditCell from './EditCell';

type SharedProps<T> = {
	fields: FieldArrayFieldsProps<T>;
	// when setting fields value, redux-forms firstly set fields to empty array and only then to actual values,
	// so there is no other way to know when are fields actually initialized
	/** redux-form initialized */
	initialized: boolean;
	footerButtons?: FooterButton[];
	footerActionButtons?: FooterButton[];
	label?: string;
	footerComponent?: () => React.ReactNode;
	errors?: FormErrorsWithArray<T, string>[];
	onDelete?: (id: number) => void;
	notifyAreThereItemsInEditMode?: (itemsInEditMode: boolean) => void;
	emptyTableMessage?: string;
	onFieldsUpdated?: (arrayItems: T[]) => void;
};

export type SimpleTableRow = {
	isInEditMode: boolean;
	/** Name inside redux fields array */
	name: string;
	/** Index inside redux fields array */
	index: number;
};

type EditableProps<T> = {
	allowEdit: boolean;
	allowDeleteOnly?: true;
	columns: Column<T & SimpleTableRow>[];
};

type NonEditableProps<T> = {
	allowEdit?: false;
	allowDeleteOnly?: never;
	columns: Column<T>[];
};

type Props<T> = SharedProps<T> & (EditableProps<T> | NonEditableProps<T>);

const initializeEditableFields = <T,>(fields: FieldArrayFieldsProps<T>): (T & SimpleTableRow)[] => {
	return fields.map<T & SimpleTableRow>((name, index) => ({ index, name, isInEditMode: false, ...fields.get(index) }));
};

const errorsReducer = ((_acc, _curr, _index) => {
	if (!!Object.keys(_curr).length) {
		_acc.push(_index);
	}
	return _acc;
});

function SimpleTable<T extends { id: number | string; }>(props: React.PropsWithChildren<Props<T>>) {
	const {
		columns,
		fields,
		footerButtons,
		allowEdit = false,
		allowDeleteOnly = false,
		label,
		footerComponent,
		errors,
		emptyTableMessage,
		onFieldsUpdated,
		notifyAreThereItemsInEditMode,
		onDelete,
		initialized,
		footerActionButtons,
	} = props;

	const [rows, setRows] = React.useState<typeof allowEdit extends true
		// eslint-disable-next-line func-call-spacing
		? (T & SimpleTableRow)[]
		: T[]
	>();

	const [bottomRows, setBottomRows] = React.useState<(T & SimpleTableRow)[]>([]);
	const [newlySavedFieldId, setNewlySavedFieldId] = React.useState<Nullable<string | number>>(null);
	const [rowIndexesWithError, setRowIndexesWithError] = React.useState<Set<number>>(new Set());

	React.useEffect(() => {
		if (notifyAreThereItemsInEditMode) {
			if (bottomRows.length || rows?.some((row) => (row as T & SimpleTableRow).isInEditMode)) {
				notifyAreThereItemsInEditMode(true);
			} else {
				notifyAreThereItemsInEditMode(false);
			}
		}
	}, [bottomRows, notifyAreThereItemsInEditMode, rows]);

	const onEditClick = React.useCallback((id: string | number) => () => {
		if (!allowEdit || !rows) {
			return;
		}

		// check if its newly added row
		const newlyAddedIndex = bottomRows.findIndex((_bottomRow) => _bottomRow.id === id);
		if (newlyAddedIndex > -1) {
			const bottomRowToAdd = (bottomRows[newlyAddedIndex] as T & SimpleTableRow);

			// if its a row with an error, do not allow save
			if (rowIndexesWithError.has(bottomRowToAdd.index) && bottomRowToAdd.isInEditMode) {
				return;
			}
			const fieldsValues = fields.get(bottomRowToAdd.index);

			const newRows = [...rows, { ...fieldsValues, isInEditMode: false, index: bottomRowToAdd.index, name: bottomRowToAdd.name }];
			setRows(newRows);
			if (onFieldsUpdated) {
				onFieldsUpdated(newRows ?? []);
			}

			const newBottomRows = bottomRows.toSpliced(newlyAddedIndex, 1);
			setBottomRows(newBottomRows);
			setNewlySavedFieldId(id);
			return;
		}

		// check if it in already saved rows
		const currentlyEditedRowIndex = rows.findIndex((_row) => _row.id === id);
		if (currentlyEditedRowIndex > -1) {
			const rowToUpdate = (rows[currentlyEditedRowIndex] as T & SimpleTableRow);

			// if its a row with an error, do not allow save
			if (rowIndexesWithError.has(rowToUpdate.index) && rowToUpdate.isInEditMode) {
				return;
			}
			const newRows = (rows as (T & SimpleTableRow)[]).toSpliced(currentlyEditedRowIndex, 1, {
				...fields.get(rowToUpdate.index),
				isInEditMode: !rowToUpdate.isInEditMode,
				index: rowToUpdate.index,
				name: rowToUpdate.name,
			});
			setRows(newRows);
			if (onFieldsUpdated) {
				onFieldsUpdated(newRows);
			}
			rowToUpdate.isInEditMode ? setNewlySavedFieldId(id) : setNewlySavedFieldId(null);
		} else {
			console.error('Row to save is not found');
		}
	}, [allowEdit, bottomRows, fields, onFieldsUpdated, rowIndexesWithError, rows]);

	const resolveRowClassName = React.useCallback((_row) => {
		const resolvedClassName: string[] = [];
		if (allowEdit) {
			(_row as Row<T & SimpleTableRow>).original?.isInEditMode && resolvedClassName.push(styles['row-edit-mode']);
			(_row as Row<T & SimpleTableRow>).original?.id === newlySavedFieldId && resolvedClassName.push(styles['newly-saved-row']);
			rowIndexesWithError.has((_row as Row<T & SimpleTableRow>).original?.index) && resolvedClassName.push(styles['row-with-error']);
		}
		return resolvedClassName.join(' ');
	}, [allowEdit, newlySavedFieldId, rowIndexesWithError]);

	const onDeleteClick = React.useCallback((_cell: CellContext<T & SimpleTableRow, unknown>) => () => {
		if (!allowEdit) {
			return;
		}
		if (onDelete) {
			onDelete(_cell.row.original.index);
		}
		fields.remove(_cell.row.original.index);
		if (onFieldsUpdated) {
			const fieldItems = fields.getAll();
			fieldItems.splice(_cell.row.original.index, 1);
			onFieldsUpdated(fieldItems);
		}
	}, [fields, onFieldsUpdated, onDelete, allowEdit]);

	const simpleTableFieldColumns = React.useMemo(() => {
		if (!allowEdit) {
			return columns as Column<T>[];
		}

		const columnsWithActions = [...columns] as Column<T & SimpleTableRow>[];
		if (allowEdit && !allowDeleteOnly) {
			return [
				...columnsWithActions,
				{
					id: 'edit',
					isDisplayColumn: true,
					cell: (_cell) => {
						return (
							<EditCell isInEditMode={!!_cell.row.original.isInEditMode} onEditClick={onEditClick(_cell.row.original.id)} />
						);
					},
					header: null,
					size: 16,
				},
				{
					id: 'delete',
					isDisplayColumn: true,
					header: null,
					cell: (_cell) => <DeleteCell onDeleteClick={onDeleteClick(_cell)} />,
					size: 16,
				}];
		} else if (allowEdit && allowDeleteOnly) {
			return [
				...columnsWithActions,
				{
					id: 'delete',
					isDisplayColumn: true,
					header: null,
					cell: (_cell) => <DeleteCell onDeleteClick={onDeleteClick(_cell)} />,
					size: 16,
				}];
		}
		return columnsWithActions;
	}, [allowEdit, allowDeleteOnly, columns, onDeleteClick, onEditClick]);

	React.useEffect(() => {
		if (allowEdit) {

			if (!fields || !initialized) {
				return;
			}

			if (!rows) {
				setRows(initializeEditableFields(fields));
				return;
			}
			// an item was added
			if (fields.length > rows.length + bottomRows.length) {
				const lastAdded = fields.get(fields.length - 1);
				const newBottomRows = Array.from(bottomRows);
				newBottomRows.push({ ...lastAdded, isInEditMode: true, index: fields.length - 1, name: `${fields.name}[${fields.length - 1}]` });
				setBottomRows(newBottomRows);
				return;
			}

			// an item was deleted
			if (fields.length < (rows.length + bottomRows.length)) {
				const fieldsMap = fields.getAll().reduce((_acc, _field: T & SimpleTableRow, _index) => {
					_acc[_field.id] = { ..._field, index: _index, name: `${fields.name}[${_index}]` };
					return _acc;
				}, {} as Record<string | number, T & SimpleTableRow>);

				setRows(rows.reduce((_acc, _row: T & SimpleTableRow) => {
					if (fieldsMap[_row.id]) {
						_acc.push({ ..._row, name: fieldsMap[_row.id].name, index: fieldsMap[_row.id].index });
					}
					return _acc;
				}, [] as (T & SimpleTableRow)[]));

				setBottomRows(bottomRows.reduce((_acc, _bottomRow: T & SimpleTableRow) => {
					if (fieldsMap[_bottomRow.id]) {
						_acc.push({ ..._bottomRow, name: fieldsMap[_bottomRow.id].name, index: fieldsMap[_bottomRow.id].index });
					}
					return _acc;
				}, [] as (T & SimpleTableRow)[]));
			}
		} else {
			setRows(fields.getAll());
		}
	}, [allowEdit, bottomRows, fields, initialized, rows]);

	React.useEffect(() => {
		if (newlySavedFieldId) {
			const interval = setInterval(() => {
				setNewlySavedFieldId(null);
			}, 2000);
			return () => clearInterval(interval);
		}
	}, [newlySavedFieldId]);

	React.useEffect(() => {
		if (errors) {
			setRowIndexesWithError(new Set(errors.reduce(errorsReducer, [])));
		}
	}, [errors]);

	return (
		<SimpleTableControl<typeof allowEdit extends true ? T & SimpleTableRow : T>
			bottomRows={bottomRows}
			columns={simpleTableFieldColumns as typeof allowEdit extends true ? Column<T & SimpleTableRow>[] : Column<T>[]}
			disableAllSortings={!!rows?.find((_row: T & SimpleTableRow) => _row.isInEditMode)}
			emptyTableMessage={emptyTableMessage}
			footerActionButtons={footerActionButtons}
			footerButtons={footerButtons}
			footerComponent={footerComponent}
			label={label}
			rowClassName={resolveRowClassName}
			rows={rows ?? []}
		/>
	);
}

export default React.memo(SimpleTable) as typeof SimpleTable;

