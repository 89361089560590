import * as React from 'react';
import { Table } from 'react-bootstrap';

import { capitalizeText } from 'acceligent-shared/utils/text';

import type { WorkOrderConfirmationResourceViewModel } from 'ab-viewModels/workOrderConfirmation.viewModel';
import type WorkOrderConfirmationViewModel from 'ab-viewModels/workOrderConfirmation.viewModel';

import DownEquipmentBadge from 'af-root/scenes/Company/ScheduleBoard/Shared/DownEquipmentBadge';

import SegmentLabel from 'af-components/SegmentLabel';
import LabelWithColor from 'af-components/LabelWithColor';

import { getColorTextClass, getColorBackgroundClass } from 'ab-utils/color.util';
import { prettyBytes } from 'ab-utils/formatting.util';

interface OwnProps {
	workOrder: WorkOrderConfirmationViewModel;
}

type Props = OwnProps;

class WorkOrderConfirmation extends React.PureComponent<Props> {

	static getResourceClassName = (resource: WorkOrderConfirmationResourceViewModel): string => {
		let className = 'confirmation-preview__resource-content ';
		if (resource.type === 'LABOR' || resource.type === 'TEMPORARY LABOR') {
			className += getColorTextClass(resource.color);
		} else if (resource.type === 'EQUIPMENT') {
			className += getColorBackgroundClass(resource.color);
		}
		className += resource.noCDL ? ' confirmation-preview__no-cdl' : '';
		return className;
	};

	static renderResources = (resource: WorkOrderConfirmationResourceViewModel, index: number) => {
		const { name, description, isAvailable, type } = resource;

		return (
			<div className={this.getResourceClassName(resource)} key={`resource-${index}`}>
				{type === 'TEMPORARY LABOR' &&
					<span className="icon-temp_labor sb-resource-item__temp-labor-icon" />
				}
				<span>{capitalizeText(`${name} ${description}`)}</span>
				{!isAvailable && <DownEquipmentBadge />}
			</div>
		);
	};

	render() {
		const { workOrder } = this.props;

		if (!workOrder) {
			return null;
		}

		const {
			code,
			crewNumber,
			crewTypeColor,
			crewTypeName,
			dueDate,
			jobCustomerCompany,
			jobDivision,
			jobNote,
			jobOffice,
			jobTitle,
			jobTravelLocationShort,
			projectManagerName,
			projectManagerPhoneNumber,
			revision,
			scopeOfWork,
			shift,
			siteContactCompanyName,
			siteContactEmails,
			siteContactName,
			siteContactPhoneNumbers,
			siteContactRole,
			siteContactStreet,
			supervisorName,
			supervisorPhoneNumber,
			workEnd,
			workLocations,
			workOrderAttachmentsFromDirectories,
			workOrderNotes,
			workOrderResources,
			workStart,
			deliverableCodeStandard,
			deliverableFileFormat,
			deliverableSoftware,
		} = workOrder;

		return (
			<div className="confirmation-preview">
				<div className="confirmation-preview__general">
					<div className="confirmation-preview__general-content confirmation-preview__general-content--primary-header">
						<div className="confirmation-preview__order-info">WORK ORDER</div>
						<div className="confirmation-preview__order-info">{code} CR {crewNumber} {revision ? `REV ${revision}` : ''}</div>
					</div>
					<div className="confirmation-preview__general-content confirmation-preview__general-content--centered bg-grey">
						<div className="confirmation-preview__order-info">
							{projectManagerName &&
								<div>
									<div>{projectManagerName}/Proj Mgr</div>
									<div>{projectManagerPhoneNumber}</div>
								</div>
							}
							<div>
								<div>{supervisorName}/Supt</div>
								<div>{supervisorPhoneNumber}</div>
							</div>
						</div>
						{
							!!crewTypeColor && !!crewTypeName &&
							<div className="confirmation-preview__order-info">
								<LabelWithColor color={crewTypeColor} text={crewTypeName} />
							</div>
						}
						<div className="confirmation-preview__order-info">
							<div>{dueDate}</div>
							<div>{shift}</div>
							<div>{workStart} - {workEnd}</div>
						</div>
					</div>
					<div className="confirmation-preview__general-content confirmation-preview__general-content--centered">
						<div className="confirmation-preview__job-info">
							<div>{jobCustomerCompany}</div>
							<div>{jobTitle}</div>
							<div>{jobTravelLocationShort}</div>
						</div>
						<div className="confirmation-preview__job-info">
							<div>{jobOffice}</div>
							<div>{jobDivision}</div>
						</div>
					</div>
				</div>
				<div className="confirmation-preview__general">
					<div className="confirmation-preview__general-content">
						<div className="confirmation-preview__work-locations">
							<SegmentLabel label="Work Locations" reduced="all" />
							{
								workLocations?.length
									? workLocations.map((l, i) => (
										<div className="confirmation-preview__location" key={`work-location-${i}`}>
											<div>{l.street}</div>
											<div>
												{l.mapLink ?
													<a className="map-link" href={l.mapLink}>View on Google Maps</a> :
													<span>Google Maps link is not available</span>
												}
											</div>
										</div>
									))
									: <div>None</div>
							}
						</div>
						<div className="confirmation-preview__site-contact">
							<SegmentLabel label="Site Contact" reduced="all" />
							{
								siteContactName
									? <>
										<div>{siteContactName}, {siteContactRole}</div>
										<div>{siteContactCompanyName}</div>
										<div>{siteContactStreet}</div>
										<div>{siteContactEmails.map((e, i) => <div key={`${e}#${i}`}>{e}</div>)}</div>
										<div>{siteContactPhoneNumbers.map((pn, i) => <div key={`${pn}#${i}`}>{pn}</div>)}</div>
									</>
									: <div>N/A</div>
							}
						</div>
					</div>
				</div>

				{deliverableSoftware &&
					<div className="confirmation-preview__general">
						<div className="confirmation-preview__general-content">
							<div className="confirmation-preview__deliverables">
								<div className="confirmation-preview__deliverables-content">
									<SegmentLabel label="Deliverable Software" reduced="all" />
									{deliverableSoftware}
								</div>
								<div className="confirmation-preview__deliverables-content">
									<SegmentLabel label="Deliverable File Format" reduced="all" />
									{deliverableFileFormat}
								</div>
								<div className="confirmation-preview__deliverables-content">
									<SegmentLabel label="Deliverable Code Standard" reduced="all" />
									{deliverableCodeStandard}
								</div>
							</div>
						</div>
					</div>
				}
				<div className="confirmation-preview__general">
					<div className="confirmation-preview__general-content">
						<div className="confirmation-preview__notes">
							<div className="confirmation-preview__notes-content">
								<SegmentLabel label="Work Order Notes" reduced="all" />
								{workOrderNotes ?? 'No Note'}
							</div>
							<div className="confirmation-preview__notes-content">
								<SegmentLabel label="Scope Of Work" reduced="all" />
								{scopeOfWork ?? 'No Note'}
							</div>
						</div>
						<div className="confirmation-preview__notes">
							<div className="confirmation-preview__notes-content">
								<SegmentLabel label="Job Notes" reduced="all" />
								{jobNote ?? 'No Note'}
							</div>
						</div>
					</div>
				</div>
				<div className="confirmation-preview__resources">
					<SegmentLabel label="Labor/Equipment" reduced="all" />
					{
						workOrderResources?.length
							? workOrderResources.map(WorkOrderConfirmation.renderResources)
							: <span>No assignments available</span>
					}
				</div>
				{
					workOrderAttachmentsFromDirectories?.length ?
						<div className="confirmation-preview__attachments">
							<div className="confirmation-preview__attachments-label">
								<SegmentLabel label="Work Order Attachments" reduced="all" />
							</div>
							<Table striped={true}>
								<thead>
									<tr>
										<th>File Name</th>
										<th className="hide-on-mobile">Size</th>
									</tr>
								</thead>
								<tbody>
									{workOrderAttachmentsFromDirectories.map((a, i) => (
										<tr key={`attachment-${i}`}>
											<td>
												<div><a className="btn-link" download={a.name} href={a.url}>{a.name}</a></div>
												<div className="show-on-mobile">{prettyBytes(a.size)}</div>
											</td>
											<td className="hide-on-mobile">{prettyBytes(a.size)}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div> :
						null
				}
			</div>
		);
	}
}

export default WorkOrderConfirmation;
