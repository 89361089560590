import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import EquipmentDraggable from 'af-root/scenes/Company/ScheduleBoard/Shared/EquipmentDraggable';

import type { EquipmentModalProps } from '../../Shared/ModalProps';

interface Props extends EquipmentModalProps {
	droppableId: string;
	draggablePrefix: string; // to distinguish if it's board or toolbar dragged equipment
	equipmentIds: number[];
	isToolbar: boolean;
	isDisabled: boolean;
	className: string;
	startIndex: number;
	endIndex: number;
	title?: string;
	dueDate: string;
	isWorkOrderCanceled?: boolean;
	isCopyPlaceholder?: boolean;
	isCardDisabled?: boolean;
	hasReason?: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

export default class DroppableComponent extends React.PureComponent<Props> {

	renderEquipment = (equipmentIds: number[], startIndex: number) => {
		const {
			draggablePrefix,
			droppableId,
			isToolbar,
			dueDate,
			isCardDisabled,
			isWorkOrderCanceled,
			isCopyPlaceholder,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			hasReason,
			setEquipmentModalData,
			setEquipmentModalVisibility,
		} = this.props;

		return equipmentIds.map((_id: number, _index: number) => {
			return (
				<EquipmentDraggable
					draggableId={`${draggablePrefix}#${ScheduleBoardProperty.EQUIPMENT}#${dueDate}#${_id}`}
					droppableId={droppableId}
					dueDate={dueDate}
					equipmentId={_id}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					hasReason={hasReason}
					index={startIndex + _index}
					isCardDisabled={isCardDisabled}
					isCopyPlaceholder={isCopyPlaceholder}
					isDragAndDropDisabled={isDragAndDropDisabled}
					isToolbar={isToolbar}
					isWOLocked={false}
					isWorkOrderCanceled={isWorkOrderCanceled}
					key={`${draggablePrefix}#${ScheduleBoardProperty.EQUIPMENT}#${dueDate}#${_id}`}
					setEquipmentModalData={setEquipmentModalData}
					setEquipmentModalVisibility={setEquipmentModalVisibility}
				/>
			);
		});
	};

	render() {
		const {
			title,
			droppableId,
			startIndex,
			endIndex,
			isDisabled,
			equipmentIds: _equipmentIds,
			className,
			isCopyPlaceholder,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
		} = this.props;

		const equipmentIds = _equipmentIds.slice(startIndex, endIndex);

		if (isCopyPlaceholder || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard) {
			return (
				<div className={className}>
					{title && <label className="droppable-title">{title}</label>}
					{this.renderEquipment(equipmentIds, startIndex)}
				</div>
			);
		}

		return (
			<Droppable
				droppableId={droppableId}
				isDropDisabled={isDisabled}
			>
				{(provided, snapshot) => {
					return (
						<div
							className={`${className} ${snapshot.isDraggingOver ? 'drag-over' : ''}`}
							ref={provided.innerRef}
						>
							{title && <label className="droppable-title">{title}</label>}
							{this.renderEquipment(equipmentIds, startIndex)}
							{provided.placeholder}
						</div>
					);
				}}
			</Droppable>
		);
	}
}
