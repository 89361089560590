import * as React from 'react';
import type { CustomLocationState, CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { v6Props } from 'react-router-dom-v5-compat';

import TimeSheetApprovalStatus from 'acceligent-shared/enums/timeSheetApprovalStatus';
import TimeSheetSignatureStatus from 'acceligent-shared/enums/timeSheetSignatureStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import FieldReportAccessRoleEnum from 'acceligent-shared/enums/fieldReportAccessRole';
import WorkOrderReviewStatus, { isRejectable, isReviewable, WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import { CalculatedReportDisplayStatus } from 'acceligent-shared/enums/reportDisplayStatus';
import TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';

import type RejectReviewRM from 'ab-requestModels/workOrder/rejectReview';
import type { TimeSheetsBulkUpdateRM } from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type TimeSheetUpdateRM from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type { TimeSheetInfoVM, TimeSheetEntryInfoVM } from 'ab-viewModels/workOrder/submitForReviewRecap';
import type { EquipmentListItemVM } from 'ab-viewModels/timeSplitEquipment/timeSplitEquipment';
import type TimeSplitEquipmentVM from 'ab-viewModels/timeSplitEquipment/timeSplitEquipment';
import type FieldWorkClassificationCodeListItemVM from 'ab-viewModels/fieldWorkClassificationCode/listItem';

import { shouldReportForAccountBeReadOnly } from 'acceligent-shared/utils/workOrderFieldReport';
import { findOverlaps } from 'acceligent-shared/utils/timeSheetEntry';
import * as TimeUtils from 'acceligent-shared/utils/time';
import * as UserUtils from 'acceligent-shared/utils/user';
import * as ReportDisplayStatusUtils from 'acceligent-shared/utils/workOrderReport';
import * as TimeSheetUtils from 'acceligent-shared/utils/timeSheet';

import type SyncWorkOrderReviewStatusVM from 'ab-socketModels/viewModels/workOrder/syncReviewStatus.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';
import ConfirmationModal from 'af-components/ConfirmationModal';
import LoadingOverlay from 'af-components/LoadingOverlay';
import withRouterV6 from 'af-components/V6Wrapper';
import Label from 'af-components/Label';

import SocketEvent from 'ab-enums/socketEvent.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import CLIENT from 'af-constants/routes/client';

import * as CompanyActions from 'af-actions/companies';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as TimeSheetActions from 'af-actions/timeSheet';
import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as FieldReportActions from 'af-actions/fieldReport';
import * as FieldWorkClassificationCodeActions from 'af-actions/fieldWorkClassificationCode';
import * as EquipmentActions from 'af-actions/equipment';
import * as WorkSummaryDetailsActions from 'af-actions/workSummaryDetails';

import type { RootState } from 'af-reducers/index';

import socket from 'af-utils/socket.util';
import * as FieldReportUtil from 'af-utils/fieldReport.util';
import { getQueryParamsFromQueryString } from 'af-utils/window.util';

import { isAllowed } from 'ab-utils/auth.util';
import type { Differences } from 'ab-utils/workOrder.util';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';
import type { FieldReportListItemVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';
import type WorkOrderFieldReportCardVM from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';
import type WorkOrderReviewRecapVM from 'ab-viewModels/workOrder/workOrderReviewRecap.viewModel';
import type TimeSheetCurrentTrackedEntryVM from 'ab-viewModels/timeSheet/currentTrackedEntry.viewModel';
import type TimeSheetTrackedEntriesVM from 'ab-viewModels/timeSheet/trackedEntries.viewModel';
import { TimeSheetEntriesForTimelineVM } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';
import type { AllocatedWorkSummaryDetailsVM } from 'ab-viewModels/workSummaryDetails/allocatedWorkSummaryDetails.viewModels';
import type AssociatedSubjobVM from 'ab-viewModels/workRequest/associatedSubjob.viewModel';
import type WorkOrderModalVM from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import type * as SignatureRequestModel from 'ab-requestModels/signature.requestModel';
import type ApproveWorkOrderRM from 'ab-requestModels/workOrder/approveWorkOrder.requestModel';
import type SubmitWorkOrderForReviewRM from 'ab-requestModels/workOrder/submitForReview.requestModel';

import type { WorkSummaryStatusChangedVM } from 'ab-socketModels/viewModels/workOrder/workSummaryStatusChanged.viewModel';

import Loading from './Loading';
import TimeSheets from './TimeSheets';
import WorkOrderInfoCard from './WorkOrderInfoCard';
import Tabs from './Tabs';
import { TIME_SHEET_TAB_ID } from './Tabs/Tab';
import FieldReport from './FieldReport';

import SubmitToModal from './Modals/SubmitToModal';
import SubmitForReviewModal from './Modals/SubmitForReviewModal';
import ApproveModal from './Modals/ApproveModal';
import RejectModal from './Modals/RejectModal';
import FinalizeModal from './Modals/FinalizeModal';
import { offsetAllTimes, revertOffsetAllTimes } from './helpers';
import OutdatedChangesModal from './TimeSheets/OutdatedChangesModal';
import TimeSheetsEditModal, { BulkOrSingleEditModal } from './TimeSheets/TimeSheetEditModal';

import SignatureForm from '../Shared/SignatureModal/FormModel';

import styles from './styles.module.scss';
import OrderInfoModal from '../../ScheduleBoard/Shared/OrderInfoModal';

type Props = v6Props<CustomLocationState> & ConnectedProps<typeof connector> & CustomRouteComponentProps<void>;

type TimeSplitEntryEquipmentSections = {
	title: string;
	options: EquipmentListItemVM[];
};
interface State {
	workOrder: Nullable<WorkOrderFieldReportCardVM>;
	timeSheets: TimeSheetVM[];
	workOrderReviewRecap: Nullable<WorkOrderReviewRecapVM>;
	isLoadingTimeSheets: boolean;
	/** Value of TIME_SHEET_TAB_ID (0) for Time sheets and ID of FR for FR tab */
	currentTabId: number;
	/** Show Modals booleans*/
	showSubmitToModal: boolean;
	showSubmitForReviewModal: boolean;
	showApproveModal: boolean;
	showRejectModal: boolean;
	showFinalizeModal: boolean;
	showInvalidEquipmentTimeWarningModal: boolean;
	showInvalidAllocationsTimeWarningModal: boolean;
	showOutdatedModal: boolean;
	showFinalizeOutdatedModal: boolean;
	showDownloadingModal: boolean;
	showDeletingFRModal: boolean;
	showEndShiftConfirmationModal: boolean;
	showEndAllShiftsConfirmationModal: boolean;
	showCheckWorkSummaryModal: boolean;
	showFinalizeDangerModal: boolean;
	showTimeSheetsEditModal: boolean;
	/** Callback function called after confirming end of shift */
	endShiftCallBack: Nullable<() => void>;
	/** Callback function called after confirming end of all shifts */
	endAllShiftsCallBack: Nullable<() => void>;
	areFRsReadOnly: boolean;
	areTSsReadOnly: boolean;
	/** Level to submit WO for review */
	submitToLevel: Nullable<WorkOrderReviewLevel>;
	/** Does any Time Sheet have total time split time > total job time */
	hasInvalidTimeDurations: boolean;
	/** Body of error message modal to display */
	timeSheetErrorModalMessage: Nullable<JSX.Element>;
	areTimeSheetsReadOnly: boolean;
	rejectedTimeSheetsUsers: Nullable<string[]>;
	modifiedTimeSheetUsers: Nullable<string[]>;
	timeSheetEntriesByAccountIdMap: { [accountId: number]: TimeSheetEntryInfoVM[]; };
	trackedEntries: Nullable<TimeSheetTrackedEntriesVM>;
	timeSheetEntriesForTimeline: Nullable<TimeSheetEntriesForTimelineVM>;
	/** Keeps track of work order / job / company time zone */
	timeZone: Nullable<string>;
	/** Keeps track of which time zone we're using in the form */
	timeZoneInUse: Nullable<string>;
	isLoadingDifferences: boolean;
	workOrderOutdatedDifferences: Nullable<Differences>;
	revertOutdated: boolean;
	isSubmit: boolean;
	canApproveOrReject: boolean;
	canSubmit: boolean;
	canCreate: boolean;
	canDelete: boolean;
	isManagerAdminSI: boolean;
	isCanceled: boolean;
	isFRStatusOfInterest: boolean;
	calculatedReportDisplayStatus: Nullable<CalculatedReportDisplayStatus>;
	timeSheetsInEdit: Nullable<TimeSheetVM[]>;
	classificationCodes: FieldWorkClassificationCodeListItemVM[];
	sections: Nullable<TimeSplitEntryEquipmentSections>[];
	showedAssociatedSubjob: Nullable<AssociatedSubjobVM>;
	allocatedWorkSummaryDetails: Nullable<AllocatedWorkSummaryDetailsVM[]>;
	associatedWRId: Nullable<number>;
	defaultTab: Nullable<number>;
	showWorkOrderModal: boolean;
	workOrderModal: Nullable<WorkOrderModalVM>;
	workOrderModalRevision: Nullable<WorkOrderModalVM>;
	isRevisionOpened: boolean;
	showCancelWarningModal: boolean;
	showCancelModal: boolean;
}

class FieldReportsList extends React.PureComponent<Props, State> {

	params = new URLSearchParams(this.props.location.search);

	state: State = {
		workOrder: null,
		isLoadingTimeSheets: false,
		timeSheets: [],
		workOrderReviewRecap: null,
		currentTabId: this.params.get('defaultTab') ? +this.params.get('defaultTab')! : TIME_SHEET_TAB_ID,
		showSubmitToModal: false,
		showSubmitForReviewModal: false,
		showApproveModal: false,
		showRejectModal: false,
		showFinalizeModal: false,
		showTimeSheetsEditModal: false,
		showDownloadingModal: false,
		showDeletingFRModal: false,
		showEndShiftConfirmationModal: false,
		showEndAllShiftsConfirmationModal: false,
		showCheckWorkSummaryModal: false,
		endShiftCallBack: null,
		endAllShiftsCallBack: null,
		areFRsReadOnly: false,
		areTSsReadOnly: false,
		submitToLevel: null,
		areTimeSheetsReadOnly: false,
		rejectedTimeSheetsUsers: [],
		modifiedTimeSheetUsers: [],
		hasInvalidTimeDurations: false,
		timeSheetErrorModalMessage: null,
		timeSheetEntriesByAccountIdMap: {},
		trackedEntries: null,
		timeSheetEntriesForTimeline: null,
		timeZone: null,
		timeZoneInUse: null,
		showInvalidEquipmentTimeWarningModal: false,
		showInvalidAllocationsTimeWarningModal: false,
		showFinalizeDangerModal: false,
		showOutdatedModal: false,
		showFinalizeOutdatedModal: false,
		isLoadingDifferences: false,
		workOrderOutdatedDifferences: null,
		revertOutdated: false,
		isSubmit: false,
		canApproveOrReject: false,
		canSubmit: false,
		canCreate: false,
		canDelete: false,
		isManagerAdminSI: false,
		isCanceled: false,
		isFRStatusOfInterest: false,
		calculatedReportDisplayStatus: null,
		timeSheetsInEdit: null,
		classificationCodes: [],
		sections: [],
		showedAssociatedSubjob: null,
		allocatedWorkSummaryDetails: null,
		associatedWRId: this.params.get('associatedWRId') ? +this.params.get('associatedWRId')! : null,
		defaultTab: this.params.get('defaultTab') ? +this.params.get('defaultTab')! : null,
		showWorkOrderModal: false,
		workOrderModal: null,
		isRevisionOpened: false,
		showCancelWarningModal: false,
		showCancelModal: false,
		workOrderModalRevision: null,
	};

	static END_SHIFT_MODAL_BODY = (
		<>
			You are about to submit Field Reports.
			<br />
			Your Time Card is still recording your time and this action will end your shift.
			<br />
			Do you want to end your shift and submit Report for review?
		</>
	);

	static FINALIZE_OUTDATED_MODAL_BODY = (
		<>
			Finalizing Field Report of Outdated Work Order,
			<br />
			will revert Work Order to previously Published version.
			<br />
			This means all latest edits on Published Work Order will be discarded.
		</>
	);

	static END_ALL_SHIFTS_MODAL_BODY = (
		<>
			You are about to approve all Field Reports.
			<br />
			Some of the Time Cards are still recording time and this action will end those shifts.
			<br />
			Do you want to proceed?
		</>
	);

	static readonly OVERLAP_ERROR_MESSAGE = (
		<>
			<br />
			Time sheets entries overlap with each other.
			<br />
		</>
	);
	static readonly TIME_SPLIT_ERROR_MESSAGE = (
		<>
			<br />
			Time split entries total time is greater then total Time Sheet Job duration.
			<br />
		</>
	);

	static INVALID_EQUIPMENT_TIME_WARNING_BODY = (
		<>
			<span>You cannot finalize Field Report and Time Sheets.</span>
			<span>Total equipment time exceeds total job time.</span>
			<span>Please resolve error and proceed with Finalize.</span>
		</>
	);

	static INVALID_ALLOCATIONS_TIME_WARNING_BODY = (
		<>
			<span>You cannot approve Field Report and Time Sheets.</span>
			<span>Total allocation times per work type don't correspond to total times per work type.</span>
			<span>Please resolve error and then proceed with approving.</span>
		</>
	);

	static CHECK_WORK_SUMMARY_MODAL_BODY = (
		<>
			<span>Please check the work summary because not all billable work has a billing code assigned. </span>
			<span>All items in the Work Summary table should either have a billing code or marked as non-billable.</span>
		</>
	);

	static FINALIZE_DANGER_BODY = (
		<>
			<span>Finalized Field Reports cannot be edited any further and are forever locked in read-only state.</span>
			<span>Continue with finalization?</span>
		</>
	);

	static INVALID_EQUIPMENT_TIME_WARNING_HEADER = 'Total Equipment time is not valid';
	static INVALID_ALLOCATIONS_TIME_WARNING_HEADER = 'Total allocation times are not valid';
	static FINALIZE_DANGER_HEADER = 'Finalize Field Report?';

	static getTimeSheetErrorModalBody = (overlaps: boolean, invalidTimes?: boolean) => {
		let message: JSX.Element = FieldReportsList.TIME_SPLIT_ERROR_MESSAGE;
		if (overlaps && invalidTimes) {
			message = (
				<>
					{FieldReportsList.OVERLAP_ERROR_MESSAGE}
					{FieldReportsList.TIME_SPLIT_ERROR_MESSAGE}
				</>
			);
		} else if (overlaps) {
			message = FieldReportsList.OVERLAP_ERROR_MESSAGE;
		}

		return (
			<>
				You cannot submit Field Reports and Time Sheets.
				{message}
				Please resolve error and proceed with Submit.
			</>
		);
	};

	static unapprovedFilter = (timeSheet: TimeSheetVM) => {
		return timeSheet.superintendentApprovalStatus?.approvalStatus !== TimeSheetApprovalStatus.APPROVED;
	};

	static isAllowedToApproveOrReject = (
		isManagerOrAdmin: boolean,
		isManagement: boolean,
		hasSIUnassignedPermission: boolean,
		reviewLevel: WorkOrderReviewLevel,
		accessRole: Nullable<FieldReportAccessRoleEnum>
	) => {
		return isManagerOrAdmin
			|| (reviewLevel < WorkOrderReviewLevel.LEVEL_2 && (accessRole === FieldReportAccessRoleEnum.SUPERINTENDENT || hasSIUnassignedPermission))
			|| (isManagement && reviewLevel < WorkOrderReviewLevel.LEVEL_3);
	};

	static isAllowedToSubmit = (
		isManagerOrAdmin: boolean,
		isManagement: boolean,
		hasSIUnassignedPermission: boolean,
		reviewLevel: WorkOrderReviewLevel,
		accessRole: Nullable<FieldReportAccessRoleEnum>
	) => {
		return isManagerOrAdmin
			|| (reviewLevel < WorkOrderReviewLevel.LEVEL_3 && isManagement)
			|| (reviewLevel < WorkOrderReviewLevel.LEVEL_2 && (accessRole === FieldReportAccessRoleEnum.SUPERINTENDENT || hasSIUnassignedPermission))
			|| (reviewLevel < WorkOrderReviewLevel.LEVEL_1 && accessRole === FieldReportAccessRoleEnum.FIELD_WORKER);
	};

	static isAllowedToCreate = (isManagerOrAdmin: boolean, isManagement: boolean, accessRole: Nullable<FieldReportAccessRoleEnum>) => {
		return isManagerOrAdmin || isManagement || accessRole !== FieldReportAccessRoleEnum.FIELD_WORKER;
	};

	static isAllowedToDelete = (
		isManagerOrAdmin: boolean,
		isManagement: boolean,
		accessRole: Nullable<FieldReportAccessRoleEnum>,
		reportCount: number,
		areReportsReadOnly: boolean
	) => {
		return reportCount > 1 && !areReportsReadOnly && FieldReportsList.isAllowedToCreate(isManagerOrAdmin, isManagement, accessRole);
	};

	static isRejected = (timeSheet: TimeSheetVM) => timeSheet.superintendentApprovalStatus?.approvalStatus === TimeSheetApprovalStatus.REJECTED;

	static isModified = (timeSheet: TimeSheetVM) => {
		return timeSheet.wasRejected
			&& timeSheet.employeeApprovalStatus?.signatureStatus === TimeSheetSignatureStatus.SIGNED
			&& timeSheet.superintendentApprovalStatus?.approvalStatus !== TimeSheetApprovalStatus.APPROVED;
	};

	static getUserName = (timeSheet: TimeSheetVM) => timeSheet.userFullName;

	static timeSheetReducer = (acc: { modifiedTimeSheetUsers: string[]; rejectedTimeSheetsUsers: string[]; }, timeSheet: TimeSheetVM) => {
		if (FieldReportsList.isModified(timeSheet)) {
			acc.modifiedTimeSheetUsers.push(FieldReportsList.getUserName(timeSheet));
		} else if (FieldReportsList.isRejected(timeSheet)) {
			acc.rejectedTimeSheetsUsers.push(FieldReportsList.getUserName(timeSheet));
		}
		return acc;
	};

	static findOverlap = (entries: TimeSheetEntryInfoVM[]) => {
		const overlaps = findOverlaps(entries);
		return !!Object.keys(overlaps).length;
	};

	static hasOverlaps = (timeSheetEntriesByAccountId: Nullable<Record<number, TimeSheetEntryInfoVM[]>>) => {
		if (timeSheetEntriesByAccountId) {
			return Object.values(timeSheetEntriesByAccountId).some(FieldReportsList.findOverlap);
		}
		return false;
	};

	static isTotalTimeSplitTimeGreaterThenJobTime = (timeSheet: TimeSheetInfoVM) => timeSheet.totalTimeSplitsTime > timeSheet.totalJobDuration;

	static shouldShowSubmitToModal = (isManagerAdminSI: boolean, canSubmitToAccounting: boolean, reviewLevel: WorkOrderReviewLevel) => {
		return (isManagerAdminSI && reviewLevel === WorkOrderReviewLevel.LEVEL_0)
			|| (canSubmitToAccounting && reviewLevel === WorkOrderReviewLevel.LEVEL_1);
	};

	static isWorkOrderReadOnly = (
		canEditInFinalizing: boolean,
		isManagerOrAdmin: boolean,
		isManagement: boolean,
		hasPermissionToEdit: boolean,
		fieldReportAccessRole: Nullable<FieldReportAccessRoleEnum>,
		status: WorkOrderStatus
	) => {
		return (reviewLevel: WorkOrderReviewLevel) => {
			return shouldReportForAccountBeReadOnly(
				WorkOrderReviewStatus.IN_REVIEW,
				reviewLevel,
				status,
				false, // we are looking for harder constraint, so ignore special case (old disposal)
				isManagement ? FieldReportAccessRoleEnum.MANAGEMENT : fieldReportAccessRole,
				hasPermissionToEdit,
				isManagerOrAdmin,
				canEditInFinalizing
			);
		};
	};

	static setNextReviewLevel = (reviewLevel: WorkOrderReviewLevel, canManagementReview: boolean) => {
		if (reviewLevel === WorkOrderReviewLevel.LEVEL_3) {
			return reviewLevel;
		} else if (!canManagementReview && reviewLevel === WorkOrderReviewLevel.LEVEL_1) {
			return reviewLevel + 2;
		}
		return reviewLevel + 1;
	};

	static sumTotalEquipmentTime = (sum: number, split: TimeSheetVM['timeSplitEntries'][0]) => sum + split.time;

	static sumTotalAllocationTimesPerType = (
		timeAllocationEntries: TimeSheetVM['timeAllocationEntries']
	) => {
		return timeAllocationEntries.reduce((_acc, _entry) => {
			if (_entry.workType === TimeSheetEntryWorkType.JOB) {
				_acc.jobAllocationsTime += _entry.time;
			} else if (_entry.workType === TimeSheetEntryWorkType.BREAK) {
				_acc.breakAllocationsTime += _entry.time;
			} else if (_entry.workType === TimeSheetEntryWorkType.SHOP) {
				_acc.shopAllocationsTime += _entry.time;
			} else if (_entry.workType === TimeSheetEntryWorkType.TRAVEL) {
				_acc.travelAllocationsTime += _entry.time;
			}
			return _acc;
		}, {
			jobAllocationsTime: 0,
			breakAllocationsTime: 0,
			shopAllocationsTime: 0,
			travelAllocationsTime: 0,
		});
	};

	static getSections = (equipmentOptions: Nullable<TimeSplitEquipmentVM>) => {
		return [
			{
				title: 'Suggested',
				options: equipmentOptions?.suggested ?? [],
			},
			{
				title: 'All',
				options: equipmentOptions?.all ?? [],
			},
		];
	};

	async componentDidMount() {
		const { params: { workOrderId }, fetchCompany, location: { search } } = this.props;
		await fetchCompany();
		this.getData();

		if (workOrderId === undefined) {
			throw new Error('WorkOrder Id not present in path.');
		}

		FieldReportUtil.joinFieldReportList(+workOrderId);
		if (socket.connection) {
			socket.connection.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.FIELD_REPORT_LIST_UPDATED, this.getData);
			socket.connection.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.UPDATE_INTERNAL_REVIEW_STATUS, this.updateInternalReviewStatus);
			socket.connection.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.UPDATE_TIME_SHEET, this.updateTimeSheetHandler);
			socket.connection.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.WORK_SUMMARY_STATUS_CHANGED, this.updateWorkSummaryStatus);
		}
		fetchCompany();
		const { fieldReportId } = getQueryParamsFromQueryString(search, ['fieldReportId']);

		if (fieldReportId) {
			this.setState(({ currentTabId: +fieldReportId }));
		}
	}

	componentWillUnmount() {
		const { params: { workOrderId } } = this.props;

		if (workOrderId === undefined) {
			throw new Error('WorkOrder Id not present in path.');
		}

		if (socket.connection) {
			FieldReportUtil.leaveFieldReportList(+workOrderId);
			socket.connection.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.FIELD_REPORT_LIST_UPDATED);
			socket.connection.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.UPDATE_INTERNAL_REVIEW_STATUS);
			socket.connection.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.UPDATE_TIME_SHEET);
			socket.connection.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.WORK_SUMMARY_STATUS_CHANGED);
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State): void {
		if (prevState.timeSheetEntriesForTimeline !== this.state.timeSheetEntriesForTimeline) {
			this.fetchWorkOrderChange();
		}
		if (this.state.workOrder?.status === WorkOrderStatus.OUTDATED
			&& (!prevState.showOutdatedModal && this.state.showOutdatedModal)
			&& !this.state.isLoadingDifferences) {
			this.getOutdatedDifferences();
		}
		if (!this.state.showSubmitForReviewModal && prevState.showSubmitForReviewModal) {
			this.getData();
		}
		if (this.state.showedAssociatedSubjob?.id !== prevState.showedAssociatedSubjob?.id && !!this.state.showedAssociatedSubjob) {
			this.getAllocatedWorkSummaryDetails();
		}
	}

	breadcrumbs = () => {
		const { location: { state: { orgAlias } }, companyData } = this.props;
		const endLabel = this.state.workOrder?.code ?? 'Loading...';
		return [
			{ label: 'Field Reports', url: CLIENT.COMPANY.FIELD_REPORT.TABLE(orgAlias, companyData.name) },
			{ label: endLabel },
		];
	};

	fetchWorkOrderChange = async () => {
		const { findWorkOrderFieldReportCardById, params: { workOrderId } } = this.props;
		const workOrder = await findWorkOrderFieldReportCardById(workOrderId);
		this.setState(() => ({ workOrder }));
	};

	getAllocatedWorkSummaryDetails = async () => {
		const { params: { workOrderId }, findAllocatedWorkSummaryDetails } = this.props;
		const { showedAssociatedSubjob } = this.state;
		if (!showedAssociatedSubjob) {
			return;
		}

		if (workOrderId === undefined) {
			throw new Error('WorkOrder Id not present in path.');
		}

		const allocatedWSDs = await findAllocatedWorkSummaryDetails(showedAssociatedSubjob.id, +workOrderId);
		this.setState(() => ({ allocatedWorkSummaryDetails: allocatedWSDs }));
	};

	updateInternalReviewStatus = (event: SyncWorkOrderReviewStatusVM) => {
		const { hasPermissionToEdit, isAccountingOrAdmin, isManagement, canEditInFinalizing } = this.props;
		const { oldStatus, newStatus, newLevel, status, rejectReason, updatedAt, updatedBy } = event;

		this.setState((state: State) => {
			if (!state.workOrder) {
				return null;
			}

			return {
				areFRsReadOnly: shouldReportForAccountBeReadOnly(
					newStatus,
					newLevel,
					status,
					false, // we are looking for harder constraint, so ignore special case (old disposal)
					isManagement ? FieldReportAccessRoleEnum.MANAGEMENT : state.workOrder!.fieldReportAccessRole,
					hasPermissionToEdit,
					isAccountingOrAdmin,
					canEditInFinalizing
				),
				areTSsReadOnly: shouldReportForAccountBeReadOnly(
					newStatus,
					newLevel,
					status,
					false, // we are looking for harder constraint, so ignore special case (old disposal)
					isManagement ? FieldReportAccessRoleEnum.MANAGEMENT : state.workOrder!.fieldReportAccessRole,
					hasPermissionToEdit,
					isAccountingOrAdmin,
					false
				),
				workOrder: {
					...state.workOrder!,
					reviewStatus: newStatus,
					reviewLevel: newLevel,
					currentReviewHistoryItem: {
						oldStatus,
						newStatus,
						rejectReason,
						userName: UserUtils.getUserName(updatedBy && { firstName: updatedBy.firstName ?? null, lastName: updatedBy.lastName ?? null }),
						time: TimeUtils.formatDate(updatedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME),
					},
				},
			};
		});

		// Reload TSs because some might have been approved in the process
		this.loadTimeSheets(true);
	};

	redirectToVirtualFieldReport = (workRequest: AssociatedSubjobVM) => {
		const { location: { state: { orgAlias } }, companyData: { name: companyName } } = this.props;
		const { workOrder } = this.state;

		if (!workOrder) {
			return;
		}

		const url = new URL(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(`${workOrder.id}`, orgAlias, companyName), location.origin);
		url.searchParams.append('associatedWRId', `${workRequest.id}`);

		window.open(url.toString(), '_blank');
	};

	redirectToFieldReport = () => {
		const { location: { state: { orgAlias } }, companyData: { name: companyName } } = this.props;
		const { workOrder } = this.state;

		if (!workOrder) {
			return;
		}

		const url = new URL(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(`${workOrder.id}`, orgAlias, companyName), location.origin);
		url.searchParams.append('defaultTab', `${workOrder.fieldReportList[0].id}`);

		window.open(url.toString(), '_blank');
	};

	redirectToJob = (jobId: number) => {
		const { location: { state: { orgAlias } }, companyData: { name: companyName } } = this.props;
		const { workOrder } = this.state;

		if (!workOrder) {
			return;
		}

		const url = new URL(CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, `${jobId}`), location.origin);
		window.open(url.toString(), '_blank');
	};

	updateWorkSummaryStatus = async (event: WorkSummaryStatusChangedVM) => {
		const { getWorkSummaryStatus } = this.props;
		const { fieldReportId } = event;

		const workSummaryStatus = await getWorkSummaryStatus(fieldReportId);

		this.setState((state) => {
			if (!state.workOrder) {
				return null;
			}
			const fr = state.workOrder.fieldReportList.find((_fr) => _fr.id === fieldReportId);
			if (!fr) {
				return null;
			}

			const newFieldReportList = [
				...state.workOrder.fieldReportList.filter((_fr) => _fr.id !== fieldReportId),
				{
					...fr,
					workSummaryStatus,
				},
			].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

			return {
				workOrder: {
					...state.workOrder,
					fieldReportList: newFieldReportList,
				},
			};
		});
	};

	setStatePropertiesDerivedFromWorkOrder = async () => {
		const {
			accountId,
			isAccountingOrAdmin,
			isManagement,
			hasSIUnassignedPermission,
			companyData,
			findClassificationCodes,
			findEquipment,
		} = this.props;
		const { workOrder, timeSheets, areFRsReadOnly } = this.state;

		if (!workOrder) {
			return;
		}

		const canApproveOrReject = FieldReportsList.isAllowedToApproveOrReject(
			isAccountingOrAdmin,
			isManagement,
			hasSIUnassignedPermission,
			workOrder.reviewLevel,
			workOrder.fieldReportAccessRole
		);
		const canSubmit = FieldReportsList.isAllowedToSubmit(
			isAccountingOrAdmin,
			isManagement,
			hasSIUnassignedPermission,
			workOrder.reviewLevel,
			workOrder.fieldReportAccessRole
		);
		const canCreate = FieldReportsList.isAllowedToCreate(isAccountingOrAdmin, isManagement, workOrder.fieldReportAccessRole);
		const canDelete = FieldReportsList.isAllowedToDelete(
			isAccountingOrAdmin,
			isManagement,
			workOrder.fieldReportAccessRole,
			workOrder.fieldReportList?.length,
			areFRsReadOnly
		);

		const isManagerAdminSI = isAccountingOrAdmin
			|| isManagement
			|| hasSIUnassignedPermission
			|| workOrder.fieldReportAccessRole === FieldReportAccessRoleEnum.SUPERINTENDENT;

		const isCanceled = workOrder.status === WorkOrderStatus.CANCELED;

		const {
			hasRejectedEmployeeTimeSheets,
			hasRejectedTemporaryEmployeeTimeSheets,
			hasAllTimeSheetsApproved,
			hasCurrentUserRejectedTimeSheet,
		} = timeSheets.reduce((_acc, _ts: TimeSheetVM) => {
			if (workOrder.activeAccounts[_ts.accountId]
				&& !workOrder.temporaryEmployeeAccounts[_ts.accountId]
				&& _ts.superintendentApprovalStatus.approvalStatus === TimeSheetApprovalStatus.REJECTED
			) {
				_acc.hasRejectedEmployeeTimeSheets = true;
			}
			if (workOrder.activeAccounts[_ts.accountId]
				&& workOrder.temporaryEmployeeAccounts[_ts.accountId]
				&& _ts.superintendentApprovalStatus.approvalStatus === TimeSheetApprovalStatus.REJECTED
			) {
				_acc.hasRejectedTemporaryEmployeeTimeSheets = true;
			}
			if (_ts.superintendentApprovalStatus.approvalStatus !== TimeSheetApprovalStatus.APPROVED) {
				_acc.hasAllTimeSheetsApproved = false;
			}
			if (_ts.superintendentApprovalStatus.approvalStatus === TimeSheetApprovalStatus.REJECTED && _ts.accountId === accountId) {
				_acc.hasCurrentUserRejectedTimeSheet = true;
			}
			return _acc;
		}, {
			hasRejectedEmployeeTimeSheets: false,
			hasRejectedTemporaryEmployeeTimeSheets: false,
			hasAllTimeSheetsApproved: true,
			hasCurrentUserRejectedTimeSheet: false,
		} as
		{
			hasRejectedEmployeeTimeSheets: boolean;
			hasRejectedTemporaryEmployeeTimeSheets: boolean;
			hasAllTimeSheetsApproved: boolean;
			hasCurrentUserRejectedTimeSheet: boolean;
		});

		const admissibleForSubmittedStatus = !companyData.assignableAsAccounting
			&& !companyData.assignableAsManagement
			&& !companyData.assignableAsProjectManager
			&& (!companyData.assignableAsSuperintendent
				||
				(companyData.assignableAsSuperintendent && workOrder.fieldReportAccessRole !== FieldReportAccessRoleEnum.SUPERINTENDENT))
			&& companyData.assignableToWorkOrder;

		const reportDisplayReviewStatus = ReportDisplayStatusUtils.resolveStatus(
			workOrder?.reviewStatus,
			workOrder?.reviewLevel,
			workOrder?.reportLastUpdatedAt ? true : false,
			hasRejectedEmployeeTimeSheets,
			hasRejectedTemporaryEmployeeTimeSheets,
			hasAllTimeSheetsApproved,
			admissibleForSubmittedStatus
		);
		const reportDisplayLevel = ReportDisplayStatusUtils.resolveDisplayLevel(
			reportDisplayReviewStatus,
			workOrder?.reviewLevel,
			hasRejectedEmployeeTimeSheets,
			hasRejectedTemporaryEmployeeTimeSheets
		);

		let isFRStatusOfInterest = false;
		if (!!workOrder?.fieldReportAccessRole && reportDisplayReviewStatus && reportDisplayLevel) {
			isFRStatusOfInterest = ReportDisplayStatusUtils.isReportDisplayStatusOfInterestForRole(
				workOrder?.fieldReportAccessRole,
				reportDisplayReviewStatus,
				reportDisplayLevel,
				accountId,
				workOrder.assignedToIds ?? null,
				hasCurrentUserRejectedTimeSheet
			) ?? false;
		}

		const calculatedReportDisplayStatus = ReportDisplayStatusUtils.resolveCalculatedReportDisplayStatusFromStatusAndLevel(
			reportDisplayReviewStatus,
			reportDisplayLevel
		);

		const classificationCodes = await findClassificationCodes();
		const equipment = await findEquipment(workOrder.id);
		const sections = FieldReportsList.getSections(equipment);

		this.setState(() => ({
			canApproveOrReject,
			canSubmit,
			canCreate,
			canDelete,
			isManagerAdminSI,
			isCanceled,
			isFRStatusOfInterest,
			calculatedReportDisplayStatus,
			classificationCodes: classificationCodes ?? [],
			sections,
		}));
	};

	getData = async () => {
		const {
			canSubmitToManagement,
			hasPermissionToEdit,
			isAccountingOrAdmin,
			isManagement,
			canEditInFinalizing,
			findWorkOrderFieldReportCardById,
			params: { workOrderId },
		} = this.props;

		if (canSubmitToManagement === null) {
			throw new Error('canSubmitToManagement is required in this context');
		}

		if (workOrderId === undefined) {
			throw new Error('WorkOrder Id not present in path.');
		}

		const workOrder = await findWorkOrderFieldReportCardById(+workOrderId);

		this.setState(() => ({
			workOrder,
			areFRsReadOnly: shouldReportForAccountBeReadOnly(
				workOrder.reviewStatus,
				workOrder.reviewLevel,
				workOrder.status,
				false, // we are looking for harder constraint, so ignore special case (old disposal)
				isManagement ? FieldReportAccessRoleEnum.MANAGEMENT : workOrder.fieldReportAccessRole,
				hasPermissionToEdit,
				isAccountingOrAdmin,
				canEditInFinalizing
			),
			areTSsReadOnly: shouldReportForAccountBeReadOnly(
				workOrder.reviewStatus,
				workOrder.reviewLevel,
				workOrder.status,
				false, // we are looking for harder constraint, so ignore special case (old disposal)
				isManagement ? FieldReportAccessRoleEnum.MANAGEMENT : workOrder.fieldReportAccessRole,
				hasPermissionToEdit,
				isAccountingOrAdmin,
				false
			),
			submitToLevel: this.state.showSubmitForReviewModal
				?
				this.state.submitToLevel
				: FieldReportsList.setNextReviewLevel(workOrder.reviewLevel, canSubmitToManagement),
		}), () => {
			this.loadTimeSheets(false);
		});

	};

	getOutdatedDifferences = async () => {
		const { fetchOutdatedWorkOrderDifferences } = this.props;
		const { workOrder } = this.state;
		if (!workOrder) {
			throw new Error('Work order not defined');
		}
		this.setState(
			() => ({ isLoadingDifferences: true }),
			async () => {
				const { id } = workOrder;
				const workOrderOutdatedDifferences = await fetchOutdatedWorkOrderDifferences(id);
				this.setState(() => ({
					isLoadingDifferences: false,
					workOrderOutdatedDifferences,
				}));
			}
		);
	};

	sortSheets = (itemA: TimeSheetVM, itemB: TimeSheetVM) => {
		const { accountId } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder } = this.state;
		// show users TS first
		if (itemA.accountId === accountId) {
			return -1;
		}
		if (itemB.accountId === accountId) {
			return 1;
		}
		if (!workOrder) {
			return 0;
		}
		const { activeAccounts } = workOrder;
		// show removed users last
		if (!activeAccounts[itemA.accountId]) {
			return 1;
		}
		if (!activeAccounts[itemB.accountId]) {
			return -1;
		}
		return itemA.index - itemB.index;
	};

	updateTimeSheetHandler = () => this.loadTimeSheets(true);

	loadTimeSheets = (silent: boolean = false) => {
		if (!this.state.isLoadingTimeSheets) {
			this.setState(
				() => ({ isLoadingTimeSheets: !silent }),
				async () => {
					const {
						findWorkOrderTimeSheets,
						findWorkOrderReviewRecap,
						findTrackedEntriesForWorkOrder,
						findWorkOrderTimeSheetEntries,
						canSubmitToManagement,
					} = this.props;
					const { associatedWRId, workOrder, timeZone, timeZoneInUse } = this.state;
					if (!workOrder) {
						return this.setState(() => ({ isLoadingTimeSheets: false }));
					}

					if (canSubmitToManagement === null) {
						throw new Error('canSubmitToManagement is required in this context');
					}

					if (!workOrder) {
						throw new Error('Work Order should exist in this context');
					}

					const timeSheetsBulk = await findWorkOrderTimeSheets(workOrder.id);

					const timeSheetEntriesForTimeline = await findWorkOrderTimeSheetEntries(workOrder.id);
					const workOrderReviewRecap = await findWorkOrderReviewRecap(workOrder.id);
					const trackedEntries = await findTrackedEntriesForWorkOrder(workOrder.id);

					// Offset times if nothing was previously loaded or we already have a loaded view in the timezone
					const wasPreviouslyInLocalTimeZone = !!timeZone && timeZoneInUse === null;
					const offsetTimeSheets = wasPreviouslyInLocalTimeZone
						? timeSheetsBulk.timeSheets
						: offsetAllTimes(timeSheetsBulk.timeSheets, timeSheetsBulk.timeZone);

					const {
						rejectedTimeSheetsUsers,
						modifiedTimeSheetUsers,
					} = offsetTimeSheets.reduce(FieldReportsList.timeSheetReducer, { rejectedTimeSheetsUsers: [], modifiedTimeSheetUsers: [] });

					const timeZoneToUse = wasPreviouslyInLocalTimeZone ? null : timeSheetsBulk.timeZone;

					const filteredTimeSheets = !associatedWRId
						? offsetTimeSheets
						: offsetTimeSheets.filter((_ts) => _ts.timeAllocationEntries.some((_tsae) => _tsae.allocatedWorkRequestId === associatedWRId));

					this.setState(() => ({
						timeSheets: filteredTimeSheets.sort(this.sortSheets),
						timeZone: timeSheetsBulk.timeZone,
						timeZoneInUse: timeZoneToUse,
						isLoadingTimeSheets: false,
						rejectedTimeSheetsUsers,
						modifiedTimeSheetUsers,
						workOrderReviewRecap,
						hasInvalidTimeDurations: workOrderReviewRecap.timeSheets.some(FieldReportsList.isTotalTimeSplitTimeGreaterThenJobTime),
						submitToLevel: this.state.showSubmitForReviewModal
							?
							this.state.submitToLevel
							: FieldReportsList.setNextReviewLevel(workOrder.reviewLevel, canSubmitToManagement),
						trackedEntries,
						timeSheetEntriesForTimeline: TimeSheetEntriesForTimelineVM.offsetTimelineEntriesForTimeZone(timeSheetEntriesForTimeline, timeZoneToUse),
					}));
					this.setStatePropertiesDerivedFromWorkOrder();
				}
			);
		}
	};

	onTimeSheetsBulkSave = async (workOrderId: number, data: TimeSheetsBulkUpdateRM) => {
		const { bulkUpdateWorkOrderTimeSheetEntries } = this.props;

		await bulkUpdateWorkOrderTimeSheetEntries(workOrderId, data);
		// Reload data after TS gets submitted
		this.loadTimeSheets(true);
	};

	onTimeSheetSave = async (workOrderId: number, accountId: number, data: TimeSheetUpdateRM) => {
		const { updateWorkOrderTimeSheetEntriesForAccount } = this.props;

		await updateWorkOrderTimeSheetEntriesForAccount(workOrderId, accountId, data);
		// Reload data after TS gets submitted
		this.loadTimeSheets(true);
	};

	onTimeSheetSubmit = async (form: SignatureForm) => {
		const { signTimeSheetForAccount, accountId } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder } = this.state;

		await signTimeSheetForAccount(workOrder.id, accountId, SignatureForm.toSignatureRM(form));
		// Reload data after TS gets submitted
		this.loadTimeSheets(true);
	};

	onTimeSheetReject = async () => {
		// Reload data after TS gets rejected
		this.loadTimeSheets(true);
	};

	onTabChange = (tabId: number) => {
		this.setState(() => ({ currentTabId: tabId }));
	};

	createFieldReport = () => {
		const { createFieldReport } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder } = this.state;

		if (!workOrder) {
			return;
		}

		createFieldReport(workOrder.id);
	};

	deleteFieldReport = (fieldReportId: number) => {
		this.setState(() => ({ showDeletingFRModal: true }),
			async () => {
				const { deleteFieldReport } = this.props;
				if (!this.state.workOrder) {
					throw new Error('Work Order should exist in this context');
				}
				const { workOrder: { id: workOrderId } } = this.state;
				await deleteFieldReport(fieldReportId, workOrderId);
				this.setState(() => ({ showDeletingFRModal: false }));
			});
	};

	submitForReview = async (form: SubmitWorkOrderForReviewRM) => {
		const { submitForReview } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder } = this.state;

		if (!workOrder) {
			return;
		}

		await submitForReview(workOrder.id, form);

		// We need to reload TSs in case we submitted ours in the process
		this.loadTimeSheets(true);
	};

	reviewVirtual = async () => {
		const { reviewVirtualReports } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder, associatedWRId } = this.state;

		if (!workOrder || !associatedWRId) {
			return;
		}

		await reviewVirtualReports(workOrder.id, associatedWRId);
		this.setVirtualReportsReviewed();
	};

	approve = async (form: ApproveWorkOrderRM) => {
		const { approveReview } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder } = this.state;

		await approveReview(workOrder.id, form);
		// We need to reload TSs in case we approved all TSs
		this.loadTimeSheets(true);
	};

	reject = async (data: RejectReviewRM) => {
		const { rejectReview } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder } = this.state;

		await rejectReview(workOrder.id, data);
	};

	finalize = async (form: SignatureRequestModel.SignatureRequestModel) => {
		const { finalizeReview } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder } = this.state;

		await finalizeReview(workOrder.id, form);
	};

	confirmEndShift = async () => {
		const { endShift, findAllEntriesWithAccess, accountId } = this.props;
		const { endShiftCallBack } = this.state;

		const entries = await findAllEntriesWithAccess(accountId);
		if (!entries) {
			return;
		}
		const trackedEntryIds = entries.reduce((_acc, _entry) => {
			if (_entry.isInActiveShift) {
				_acc.push(_entry.id);
			}
			return _acc;
		}, [] as number[]);

		await endShift(accountId, {
			endTime: TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME),
			entryIds: trackedEntryIds,
		});

		this.hideEndShiftConfirmationModal();
		endShiftCallBack?.();
	};

	// Modal handler methods

	handleSubmitToModal = async (submitToLevel: WorkOrderReviewLevel) => {
		const { findTrackedEntriesForWorkOrder, hasWorkOrderOverlaps } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { hasInvalidTimeDurations, workOrder: { id, reviewStatus, reviewLevel } } = this.state;

		const { hasOverlaps } = await hasWorkOrderOverlaps(id);

		// If submitting to any level and overlaps exists, show error modal
		if (hasOverlaps || hasInvalidTimeDurations) {
			const message = FieldReportsList.getTimeSheetErrorModalBody(hasOverlaps, hasInvalidTimeDurations);
			this.setState(() => ({ timeSheetErrorModalMessage: message, showSubmitToModal: false }));
			return;
		}
		if (submitToLevel === WorkOrderReviewLevel.LEVEL_2 || submitToLevel === WorkOrderReviewLevel.LEVEL_3) {
			// If submitting to level 2(and approving) or level 3 and overlaps or invalid time durations exist
			// Show error modal
			// If there is somebody in active shift
			const trackedEntries = await findTrackedEntriesForWorkOrder(id);
			if (!!trackedEntries && trackedEntries.entries.length) {
				this.setState(() => ({
					showEndAllShiftsConfirmationModal: true,
					endAllShiftsCallBack: isReviewable(reviewStatus, reviewLevel) ? this.showSubmitForReviewModal : this.showApproveModal,
					showSubmitToModal: false,
					submitToLevel,
				}));
				return;
			}
		}
		// If WO in draft status go to submit, else go to approval
		if (isReviewable(reviewStatus, reviewLevel)) {
			this.setState(() => ({ showSubmitForReviewModal: true, showSubmitToModal: false, submitToLevel }));
		} else {
			this.setState(() => ({ showApproveModal: true, showSubmitToModal: false, submitToLevel }));
		}
	};

	handleSubmitForReviewModal = async (revertOutdated: boolean) => {
		const {
			accountId,
			canSubmitToAccounting,
			findCurrentTrackedEntry,
			findTrackedEntriesForWorkOrder,
			hasWorkOrderOverlapsForAccount,
			hasWorkOrderOverlaps,
			isAccountingOrAdmin,
			isCompanyAdmin,
			isManagement,
		} = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder } = this.state;

		this.setState(() => ({ revertOutdated }));

		let currentTrackedEntry: Nullable<TimeSheetCurrentTrackedEntryVM> = null;
		if (!isCompanyAdmin && !isManagement && !isAccountingOrAdmin) {
			currentTrackedEntry = await findCurrentTrackedEntry(accountId);
			const { hasOverlaps } = await hasWorkOrderOverlapsForAccount(workOrder.id, accountId);
			if (hasOverlaps) {
				const message = FieldReportsList.getTimeSheetErrorModalBody(hasOverlaps);
				this.setState(() => ({ timeSheetErrorModalMessage: message, showSubmitToModal: false }));
				return;
			}
		} else if (workOrder.reviewLevel > WorkOrderReviewLevel.LEVEL_0) {
			const { hasOverlaps } = await hasWorkOrderOverlaps(workOrder.id);
			if (hasOverlaps) {
				const message = FieldReportsList.getTimeSheetErrorModalBody(hasOverlaps);
				this.setState(() => ({ timeSheetErrorModalMessage: message, showSubmitToModal: false }));
				return;
			}
		}

		const isManagerAdminSI = isAccountingOrAdmin || isManagement || workOrder.fieldReportAccessRole === FieldReportAccessRoleEnum.SUPERINTENDENT;
		const trackedEntries = await findTrackedEntriesForWorkOrder(workOrder.id);

		if (currentTrackedEntry && currentTrackedEntry.workOrderId === workOrder.id) {
			const callBack = FieldReportsList.shouldShowSubmitToModal(isManagerAdminSI, canSubmitToAccounting, workOrder.reviewLevel)
				? this.showSubmitToModal
				: this.showSubmitForReviewModal;
			this.showEndShiftConfirmationModal(callBack);
		} else if (isManagement && !!trackedEntries && trackedEntries.entries.length) {
			this.setState(() => ({
				showEndAllShiftsConfirmationModal: true,
				endAllShiftsCallBack: isReviewable(workOrder.reviewStatus, workOrder.reviewLevel) ? this.showSubmitForReviewModal : this.showApproveModal,
				showSubmitToModal: false,
			}));
			return;
		} else if (FieldReportsList.shouldShowSubmitToModal(isManagerAdminSI, canSubmitToAccounting, workOrder.reviewLevel)) {
			this.showSubmitToModal();
		} else {
			this.showSubmitForReviewModal();
		}
	};

	handleSubmitModal = async (openTargetModal: () => void) => {
		const { findTrackedEntriesForWorkOrder, hasWorkOrderOverlaps } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder, hasInvalidTimeDurations } = this.state;

		const { hasOverlaps } = await hasWorkOrderOverlaps(workOrder.id);

		if (hasOverlaps || hasInvalidTimeDurations) {
			const message = FieldReportsList.getTimeSheetErrorModalBody(hasOverlaps, hasInvalidTimeDurations);
			this.setState(() => ({ timeSheetErrorModalMessage: message, showSubmitToModal: false }));
			return;
		}

		const trackedEntries = await findTrackedEntriesForWorkOrder(workOrder.id);
		if (!!trackedEntries && trackedEntries.entries.length) {
			this.showEndAllShiftsConfirmationModal(this.showApproveModal);
			return;
		} else {
			openTargetModal();
		}
	};

	checkAndShowOutdatedApproveModal = async () => {
		const { workOrder } = this.state;

		if (!workOrder) {
			return;
		}

		this.setState(() => ({ isSubmit: false }));

		if (workOrder.status === WorkOrderStatus.OUTDATED) {
			this.showOutdatedModal();
			return;
		}
		this.processApproval(false);
	};

	checkAndShowOutdatedSubmitModal = async () => {
		const { workOrder } = this.state;

		if (!workOrder) {
			return;
		}

		this.setState(() => ({ isSubmit: true }));

		if (workOrder.status === WorkOrderStatus.OUTDATED && workOrder.reviewLevel === WorkOrderReviewLevel.LEVEL_3) {
			this.showOutdatedModal();
			return;
		}
		this.handleSubmitForReviewModal(false);
	};

	processApproval = async (revertOutdated: boolean) => {
		const { canSubmitToAccounting, isAccountingOrAdmin, isManagement } = this.props;
		const { workOrder, timeSheets } = this.state;

		if (!workOrder) {
			return;
		}

		this.setState(() => ({ revertOutdated }));

		if (
			workOrder.isProjectOrSubjob
			&& workOrder.reviewLevel === WorkOrderReviewLevel.LEVEL_3
			&& (isAccountingOrAdmin || workOrder.fieldReportAccessRole === FieldReportAccessRoleEnum.ACCOUNTING)
		) {
			const hasInvalidAllocationTime = timeSheets.some((timeSheet) => {
				const {
					travelAllocationsTime,
					jobAllocationsTime,
					shopAllocationsTime,
					breakAllocationsTime,
				} = FieldReportsList.sumTotalAllocationTimesPerType(timeSheet.timeAllocationEntries);
				return timeSheet.totalJobDuration !== jobAllocationsTime
					|| timeSheet.totalShopDuration !== shopAllocationsTime
					|| timeSheet.totalTravelDuration !== travelAllocationsTime
					|| timeSheet.totalBreakDuration !== breakAllocationsTime;
			});
			if (hasInvalidAllocationTime) {
				this.showInvalidAllocationsTimeWarningModal();
				return;
			}
		}

		const isManagerAdminSI = isAccountingOrAdmin || isManagement || workOrder.fieldReportAccessRole === FieldReportAccessRoleEnum.SUPERINTENDENT;
		if (FieldReportsList.shouldShowSubmitToModal(isManagerAdminSI, canSubmitToAccounting, workOrder.reviewLevel)) {
			this.showSubmitToModal();
		} else {
			this.handleSubmitModal(this.showApproveModal);
		}
	};

	checkAndShowOutdatedFinalizeModal = () => {
		const { workOrder } = this.state;

		if (!workOrder) {
			return;
		}

		if (workOrder.status === WorkOrderStatus.OUTDATED) {
			this.showFinalizeOutdatedModal();
			return;
		}

		this.processFinalization();
	};

	processFinalization = async () => {
		const { checkIfCanCompleteWorkSummaries } = this.props;
		const { timeSheets, workOrder } = this.state;

		if (!workOrder) {
			return;
		}

		const hasInvalidEquipmentTime = timeSheets.some((timeSheet) => {
			const totalEquipmentTime = timeSheet.timeSplitEntries.reduce(FieldReportsList.sumTotalEquipmentTime, 0);
			return timeSheet.totalJobDuration < totalEquipmentTime;
		});
		let canCompleteWorkSummaries = false;
		const shouldCheckCanCompleteWorkSummaries = workOrder.reviewLevel === WorkOrderReviewLevel.LEVEL_3;
		if (shouldCheckCanCompleteWorkSummaries) {
			canCompleteWorkSummaries = await checkIfCanCompleteWorkSummaries(workOrder.id);
		}
		if (hasInvalidEquipmentTime) {
			this.showInvalidEquipmentTimeWarningModal();
		} else if (shouldCheckCanCompleteWorkSummaries && !canCompleteWorkSummaries) {
			// handles a special case where submitting from lvl 3 rejected it goes straight to approved
			this.showCheckWorkSummaryModal();
		} else {
			this.handleSubmitModal(this.showFinalizeModal);
		}
	};

	handleConfirmEndAllShifts = async () => {
		const { endShiftsForWorkOrder } = this.props;
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const { workOrder, endAllShiftsCallBack } = this.state;

		await endShiftsForWorkOrder(workOrder.id);
		endAllShiftsCallBack?.();
	};

	// Show Modals methods

	showSubmitToModal = () => this.setState(() => ({ showSubmitToModal: true }));
	hideSubmitToModal = () => this.setState(() => ({ showSubmitToModal: false }));

	showSubmitForReviewModal = () => this.setState(() => ({ showSubmitForReviewModal: true }));
	hideSubmitForReviewModal = () => this.setState(() => ({ showSubmitForReviewModal: false }));

	showApproveModal = () => this.setState(() => ({ showApproveModal: true }));
	hideApproveModal = () => this.setState(() => ({ showApproveModal: false }));

	showRejectModal = () => this.setState(() => ({ showRejectModal: true }));
	hideRejectModal = () => this.setState(() => ({ showRejectModal: false }));

	showFinalizeModal = () => this.setState(() => ({ showFinalizeModal: true }));
	hideFinalizeModal = () => this.setState(() => ({ showFinalizeModal: false }));

	showInvalidEquipmentTimeWarningModal = () => this.setState(() => ({ showInvalidEquipmentTimeWarningModal: true }));
	hideInvalidEquipmentTimeWarningModal = () => this.setState(() => ({ showInvalidEquipmentTimeWarningModal: false }));

	showInvalidAllocationsTimeWarningModal = () => this.setState(() => ({ showInvalidAllocationsTimeWarningModal: true }));
	hideInvalidAllocationsTimeWarningModal = () => this.setState(() => ({ showInvalidAllocationsTimeWarningModal: false }));

	showFinalizeDangerModal = () => this.setState(() => ({ showFinalizeDangerModal: true }));
	hideFinalizeDangerModal = () => this.setState(() => ({ showFinalizeDangerModal: false }));

	showOutdatedModal = () => this.setState(() => ({ showOutdatedModal: true }));
	hideOutdatedModal = () => this.setState(() => ({ showOutdatedModal: false }));

	showFinalizeOutdatedModal = () => this.setState(() => ({ showFinalizeOutdatedModal: true }));
	hideFinalizeOutdatedModal = () => this.setState(() => ({ showFinalizeOutdatedModal: false }));

	showTimeSheetsEditModal = () => this.setState(() => ({ showTimeSheetsEditModal: true, timeSheetsInEdit: this.state.timeSheets }));
	hideTimeSheetsEditModal = () => this.setState(() => ({ showTimeSheetsEditModal: false, timeSheetsInEdit: null }));

	showEndShiftConfirmationModal = (endShiftCallBack: () => void) => {
		this.setState(() => ({ showEndShiftConfirmationModal: true, endShiftCallBack }));
	};
	hideEndShiftConfirmationModal = () => this.setState(() => ({ showEndShiftConfirmationModal: false, endShiftCallBack: null }));

	showEndAllShiftsConfirmationModal = (endAllShiftsCallBack: () => void) => {
		this.setState(() => ({ showEndAllShiftsConfirmationModal: true, endAllShiftsCallBack }));
	};
	hideEndAllShiftsConfirmationModal = () => this.setState(() => ({ showEndAllShiftsConfirmationModal: false, endAllShiftsCallBack: null }));

	showTimeSheetErrorModal = (errorMessage: JSX.Element) => this.setState(() => ({ timeSheetErrorModalMessage: errorMessage }));
	hideTimeSheetErrorModal = () => this.setState(() => ({ timeSheetErrorModalMessage: null }));

	showCheckWorkSummaryModal = () => this.setState(() => ({ showCheckWorkSummaryModal: true }));
	hideCheckWorkSummaryModal = () => this.setState(() => ({ showCheckWorkSummaryModal: false }));

	setShowedAssociatedSubjob = (subjob: Nullable<AssociatedSubjobVM>) => this.setState(() => ({ showedAssociatedSubjob: subjob }));

	closeOrderInfoModal = () => this.setWorkOrderModalVisibility(false);

	openOrderInfoModal = async () => {
		const { workOrder } = this.state;
		await this.loadWorkOrder(workOrder!.id, true);
		this.setWorkOrderModalVisibility(true);
	};

	setWorkOrderModalVisibility = (isVisible: boolean) => {
		if (!isVisible) {
			this.setState(() => ({
				workOrderModalRevision: null,
				isRevisionOpened: false,
				showWorkOrderModal: isVisible,
			}));
		} else {
			this.setState(() => ({ showWorkOrderModal: isVisible }));
		}
	};

	loadWorkOrder = async (workOrderModalId: number, openModal: boolean = false) => {
		const { lazyLoadWorkOrder } = this.props;
		const workOrderModal = await lazyLoadWorkOrder(workOrderModalId);
		this.setState((state) => ({ workOrderModal, showWorkOrderModal: openModal ? true : state.showWorkOrderModal }));
	};

	setCancelWarningModalVisibility = (showCancelWarningModal: boolean) => {
		this.setState(() => ({ showCancelWarningModal }));
	};

	setCancelModalVisibility = (isVisible: boolean) => {
		this.setState(() => ({ showCancelModal: isVisible }));
	};

	selectWorkOrderRevision = async (revisionId: number, isCurrentWorkOrder: boolean) => {
		const { lazyLoadWorkOrder, lazyLoadWorkOrderRevision } = this.props;

		let workOrderModalRevision: Nullable<WorkOrderModalVM> = null;
		let isRevisionOpened = false;
		if (isCurrentWorkOrder) {
			workOrderModalRevision = await lazyLoadWorkOrder(revisionId);
		} else {
			workOrderModalRevision = await lazyLoadWorkOrderRevision(revisionId);
			isRevisionOpened = true;
		}
		this.setState(() => ({ workOrderModalRevision, isRevisionOpened }));
	};

	changeTimeZone = () => {
		const { timeZoneInUse, timeZone, timeSheets, timeSheetEntriesForTimeline } = this.state;

		if (!timeSheetEntriesForTimeline) {
			return;
		}

		if (timeZoneInUse === timeZone) {
			this.setState({
				timeZoneInUse: null,
				timeSheets: revertOffsetAllTimes(timeSheets, timeZone),
				timeSheetEntriesForTimeline: timeSheetEntriesForTimeline
					&& TimeSheetEntriesForTimelineVM.revertOffsetTimelineEntriesForTimeZone(timeSheetEntriesForTimeline, timeZone),
			});
			return;
		}

		this.setState({
			timeZoneInUse: timeZone,
			timeSheets: offsetAllTimes(timeSheets, timeZone),
			timeSheetEntriesForTimeline: timeSheetEntriesForTimeline
				&& TimeSheetEntriesForTimelineVM.offsetTimelineEntriesForTimeZone(timeSheetEntriesForTimeline, timeZone),
		});
	};

	invalidateWorkSummaryStatus = async () => {
		const { getWorkSummaryStatus } = this.props;
		const { currentTabId } = this.state;

		if (currentTabId === TIME_SHEET_TAB_ID) {
			return;
		}

		const workSummaryStatus = await getWorkSummaryStatus(currentTabId);

		this.setState((state) => {
			if (!state.workOrder) {
				return null;
			}
			const fr = state.workOrder.fieldReportList.find((_fr) => _fr.id === currentTabId);
			if (!fr) {
				return null;
			}
			return {
				workOrder: {
					...state.workOrder,
					fieldReportList: [
						...state.workOrder.fieldReportList.filter((_fr) => _fr.id !== currentTabId),
						{
							...fr,
							workSummaryStatus,
						},
					],
				},
			};
		});
	};

	setVirtualReportsReviewed = async () => {
		const { userName } = this.props;
		const { associatedWRId } = this.state;

		this.setState((state) => {
			if (!state.workOrder) {
				return null;
			}

			const filteredReviews = state.workOrder.workOrderWorkRequestAssociationReviews.filter(
				(review) =>
					review.workOrderId !== state.workOrder!.id ||
					review.workRequestId !== associatedWRId
			);

			return {
				workOrder: {
					...state.workOrder,
					workOrderWorkRequestAssociationReviews: [
						...filteredReviews,
						{
							createdAt: new Date(),
							createdBy: userName,
							workOrderId: state.workOrder.id,
							workRequestId: associatedWRId!,
						},
					],
				},
			};
		});
	};

	calculateAreAllTimeSheetsReadOnly = () => {
		const {
			accountId,
			isAccountingOrAdmin,
			isManagement,
			isManagementAllowedToEdit,
			hasSIUnassignedPermission,
		} = this.props;
		const { timeSheets, workOrder } = this.state;

		if (!workOrder || !timeSheets) {
			throw new Error('Work Order and/or time sheets should exist in this context');
		}

		let countOfReadOnlyTimeSheets = 0;
		timeSheets.forEach((timeSheet) => {
			const isSuperintendent = workOrder?.fieldReportAccessRole === FieldReportAccessRoleEnum.SUPERINTENDENT || hasSIUnassignedPermission;

			const isAllowedToEditTimeSheet = TimeSheetUtils.isUserAllowedToEdit(
				timeSheet.accountId === accountId,
				isAccountingOrAdmin || isManagement,
				isSuperintendent,
				isManagementAllowedToEdit
			);

			const isTimeSheetReadonly = !!timeSheet.superintendentApprovalStatus?.approvalStatus && TimeSheetUtils.shouldTsBeReadOnly(
				workOrder.reviewStatus,
				timeSheet.superintendentApprovalStatus?.approvalStatus,
				isAllowedToEditTimeSheet
			);

			if (isTimeSheetReadonly) {
				countOfReadOnlyTimeSheets += 1;
			}

		});

		if (countOfReadOnlyTimeSheets === timeSheets.length) {
			return true;
		}
		return false;
	};

	// Render methods

	renderTabContent = () => {
		if (!this.state.workOrder) {
			throw new Error('Work Order should exist in this context');
		}
		const {
			areFRsReadOnly,
			currentTabId,
			isLoadingTimeSheets,
			timeSheetEntriesForTimeline,
			timeSheets,
			timeZone,
			timeZoneInUse,
			trackedEntries,
			workOrder,
			classificationCodes,
			sections,
			areTSsReadOnly,
			calculatedReportDisplayStatus,
			showedAssociatedSubjob,
			allocatedWorkSummaryDetails,
			associatedWRId,
		} = this.state;
		const {
			isWorkSummaryAccessible,
			isAccountingOrAdmin,
			location: { pathname, search },
			hasSIUnassignedPermission,
			isManagement,
		} = this.props;

		if (!workOrder) {
			return null;
		}

		const {
			activeAccounts,
			code,
			dueDate,
			fieldReportAccessRole,
			fieldReportList,
			id,
			jobId,
			reviewStatus,
			reviewLevel,
			temporaryEmployeeAccounts,
			isPaused,
			status,
			isProjectOrSubjob,
			canViewTimeAllocations,
			hasPermissionToManageWS,
		} = workOrder;

		if (currentTabId === TIME_SHEET_TAB_ID) {
			return (
				<TimeSheets
					accessRole={fieldReportAccessRole}
					activeAccounts={activeAccounts}
					areReportsFinalOrDone={calculatedReportDisplayStatus === CalculatedReportDisplayStatus.FINALIZING
						|| calculatedReportDisplayStatus === CalculatedReportDisplayStatus.DONE}
					areReportsReadOnly={areTSsReadOnly}
					associatedWRId={associatedWRId}
					canSeeTimeAllocations={isProjectOrSubjob && canViewTimeAllocations}
					changeTimeZone={this.changeTimeZone}
					classificationCodes={classificationCodes}
					code={code}
					dueDate={dueDate}
					hasSIUnassignedPermission={hasSIUnassignedPermission}
					isPaused={isPaused}
					loading={isLoadingTimeSheets}
					onTimeSheetReject={this.onTimeSheetReject}
					onTimeSheetSave={this.onTimeSheetSave}
					onTimeSheetSubmit={this.onTimeSheetSubmit}
					sections={sections}
					temporaryEmployeeAccounts={temporaryEmployeeAccounts}
					timeSheetEntriesForTimeline={timeSheetEntriesForTimeline}
					timeSheets={timeSheets}
					timeZone={timeZone}
					timeZoneInUse={timeZoneInUse}
					trackedEntries={trackedEntries}
					workOrderId={id}
					workOrderReviewLevel={reviewLevel}
					workOrderReviewStatus={reviewStatus}
					workOrderStatus={status}
					workRequestId={jobId}
				/>
			);
		}

		// Tab IDs are using FR IDs to make navigating easier
		const activeFieldReport = fieldReportList.find((_fr) => _fr.id === currentTabId);

		if (!activeFieldReport) {
			// Should never happen
			return null;
		}

		const canCompleteWorkSummary = isAccountingOrAdmin;

		const canApproveOrReject = FieldReportsList.isAllowedToApproveOrReject(
			isAccountingOrAdmin,
			isManagement,
			hasSIUnassignedPermission,
			workOrder.reviewLevel,
			workOrder.fieldReportAccessRole
		);
		const isAbleToReject = isRejectable(workOrder.reviewStatus, workOrder.reviewLevel) && canApproveOrReject;

		return (
			<FieldReport
				allocatedWorkSummaryDetails={allocatedWorkSummaryDetails}
				canCompleteWorkSummary={canCompleteWorkSummary && !showedAssociatedSubjob}
				fieldReportAccessRole={fieldReportAccessRole}
				fieldReportId={currentTabId}
				hasPermissionToManageWS={hasPermissionToManageWS}
				invalidateWorkSummaryStatus={this.invalidateWorkSummaryStatus}
				isAbleToReject={isAbleToReject && !showedAssociatedSubjob}
				isPaused={workOrder.isPaused}
				isReadOnly={areFRsReadOnly || isPaused || !!showedAssociatedSubjob}
				isWorkSummaryAccessible={isWorkSummaryAccessible && !showedAssociatedSubjob}
				jobId={jobId}
				key={currentTabId}
				pathName={pathname}
				queryString={search}
				reviewLevel={reviewLevel}
				reviewStatus={reviewStatus}
				updatedBy={activeFieldReport.updateByFullName}
				workOrderCode={code}
				workOrderId={id}
				workOrderStatus={status}
				workSummaryStatus={activeFieldReport.workSummaryStatus}
			/>
		);
	};

	render() {
		const {
			accountId,
			hasPermissionToEdit,
			isAccountingOrAdmin,
			isCompanyAdmin,
			isManagement,
			hasPermissionToFinalize,
			location: { state: { orgAlias } },
			companyData,
			hasSIUnassignedPermission,
			canEditInFinalizing,
			isDigitalSignatureEnabled,
			showCreateDigitalSignature,
			history,
		} = this.props;
		const {
			areFRsReadOnly,
			currentTabId,
			modifiedTimeSheetUsers,
			rejectedTimeSheetsUsers,
			showApproveModal,
			showCheckWorkSummaryModal,
			showDeletingFRModal,
			showDownloadingModal,
			showEndAllShiftsConfirmationModal,
			showEndShiftConfirmationModal,
			showFinalizeModal,
			showInvalidEquipmentTimeWarningModal,
			showInvalidAllocationsTimeWarningModal,
			showRejectModal,
			showSubmitForReviewModal,
			showSubmitToModal,
			showFinalizeDangerModal,
			submitToLevel,
			timeSheetErrorModalMessage,
			timeSheets,
			workOrder,
			workOrderReviewRecap,
			showOutdatedModal,
			showFinalizeOutdatedModal,
			workOrderOutdatedDifferences,
			revertOutdated,
			isSubmit,
			canApproveOrReject,
			canCreate,
			canDelete,
			canSubmit,
			calculatedReportDisplayStatus,
			isCanceled,
			isFRStatusOfInterest,
			isManagerAdminSI,
			showTimeSheetsEditModal,
			timeZone,
			timeZoneInUse,
			timeSheetsInEdit,
			classificationCodes,
			sections,
			areTSsReadOnly,
			showedAssociatedSubjob,
			allocatedWorkSummaryDetails,
			associatedWRId,
			showWorkOrderModal,
			isRevisionOpened,
			workOrderModal,
			workOrderModalRevision,
		} = this.state;

		if (!workOrder || this.props.isLoadingCompany) {
			return <Loading breadcrumbItems={this.breadcrumbs()} />;
		}

		const isWorkOrderReadOnly = FieldReportsList.isWorkOrderReadOnly(
			canEditInFinalizing,
			isAccountingOrAdmin,
			isManagement,
			hasPermissionToEdit,
			workOrder.fieldReportAccessRole,
			workOrder.status
		);

		const areAllTimeSheetsReadOnly = this.calculateAreAllTimeSheetsReadOnly();

		const isSuperintendent = workOrder?.fieldReportAccessRole === FieldReportAccessRoleEnum.SUPERINTENDENT || hasSIUnassignedPermission;

		const timeAllocationEntries = timeSheets.map((ts) => ts.timeAllocationEntries).flat();

		const allocatedFieldReportTypesIdsSet = allocatedWorkSummaryDetails
			? allocatedWorkSummaryDetails.reduce((acc, wsd) => {
				acc.add(wsd.workTypeFieldReportTypeId);

				if (wsd.workQuantityFieldReportTypeId !== null) {
					acc.add(wsd.workQuantityFieldReportTypeId);
				}

				acc = new Set([...acc, ...wsd.definitionFieldReportTypeIds]);

				return acc;
			}, new Set<number>())
			: new Set<number>();

		const filterFieldReportTypes = (reports: FieldReportListItemVM[]) => {
			return reports.reduce((filteredReports, report) => {
				const filteredFieldReportTypes = report.fieldReportTypes.filter((type) =>
					allocatedFieldReportTypesIdsSet.has(type.id)
				);

				if (filteredFieldReportTypes.length > 0) {
					filteredReports.push(report);
				}

				return filteredReports;
			}, [] as FieldReportListItemVM[]);
		};

		const tabFieldReports = !!showedAssociatedSubjob
			? filterFieldReportTypes(workOrder.fieldReportList)
			: workOrder.fieldReportList;

		const virtualReportReview = workOrder.workOrderWorkRequestAssociationReviews.find((_r) => _r.workRequestId === associatedWRId);

		return (
			<div className="form-segment field-report-list">
				<Breadcrumbs items={this.breadcrumbs()} />
				<WorkOrderInfoCard
					approve={this.checkAndShowOutdatedApproveModal}
					associatedWRId={associatedWRId}
					calculatedReportDisplayStatus={calculatedReportDisplayStatus}
					canApproveOrReject={canApproveOrReject}
					canCreate={canCreate}
					canFinalize={hasPermissionToFinalize}
					canSubmit={canSubmit}
					companyName={companyData.name}
					createFieldReport={this.createFieldReport}
					fieldReportAccessDayDuration={this.props.fieldReportAccessDayDuration}
					finalize={this.showFinalizeDangerModal}
					isCanceled={isCanceled}
					isFRStatusOfInterest={isFRStatusOfInterest}
					isManagerAdminSI={isManagerAdminSI}
					isReadOnly={areFRsReadOnly}
					jobId={workOrder.jobId}
					orgAlias={orgAlias}
					redirectToFieldReport={this.redirectToFieldReport}
					redirectToJob={this.redirectToJob}
					redirectToVirtualFieldReport={this.redirectToVirtualFieldReport}
					redirectToWorkOrder={this.openOrderInfoModal}
					reject={this.showRejectModal}
					reviewVirtual={this.reviewVirtual}
					setShowedAssociatedSubjob={this.setShowedAssociatedSubjob}
					submitForReview={this.checkAndShowOutdatedSubmitModal}
					timeAllocationEntries={timeAllocationEntries}
					virtualReportLastReviewedBy={virtualReportReview?.createdBy ?? null}
					virtualReportReviewedAt={virtualReportReview?.createdAt ?? null}
					workOrder={workOrder}
				/>
				{
					!!showedAssociatedSubjob &&
					<div className={styles['associated-subjob-data-container']}>
						Showing data associated with
						<div className={styles['associated-subjob-data-container-label']}>
							<Label
								className={showedAssociatedSubjob.isProject ? undefined : 'subjob'}
								text={showedAssociatedSubjob.isProject ? 'PROJECT' : 'SUB-JOB'}
							/>
						</div>
						<div className={styles['associated-subjob-data-container-job']}>
							{showedAssociatedSubjob.jobCode}
						</div>
					</div>
				}
				<Tabs
					activeTabId={currentTabId}
					areAllTimeSheetsAndSplitsReadOnly={!!associatedWRId || (areAllTimeSheetsReadOnly && areTSsReadOnly)}
					canDelete={canDelete}
					deleteFieldReport={this.deleteFieldReport}
					fieldReports={tabFieldReports}
					modifiedTimeSheetUsers={modifiedTimeSheetUsers}
					onChange={this.onTabChange}
					onTimeSheetsBulkEditClick={this.showTimeSheetsEditModal}
					rejectedTimeSheetsUsers={rejectedTimeSheetsUsers}
					timeSheetCount={timeSheets.length}
				/>
				{this.renderTabContent()}
				<TimeSheetsEditModal
					accessRole={workOrder.fieldReportAccessRole}
					areAllTimeSheetsReadOnly={areAllTimeSheetsReadOnly}
					areFRsReadOnly={areFRsReadOnly || calculatedReportDisplayStatus === CalculatedReportDisplayStatus.FINALIZING
						|| calculatedReportDisplayStatus === CalculatedReportDisplayStatus.DONE}
					bulkOrSingleEditModal={BulkOrSingleEditModal.BULK}
					canSeeTimeAllocations={workOrder.isProjectOrSubjob && workOrder.canViewTimeAllocations}
					classificationCodes={classificationCodes}
					close={this.hideTimeSheetsEditModal}
					code={workOrder.code}
					dueDate={workOrder.dueDate}
					hasSIUnassignedPermission={hasSIUnassignedPermission}
					isPaused={workOrder.isPaused}
					isSuperintendent={isSuperintendent}
					isVirtual={!!associatedWRId}
					save={this.onTimeSheetsBulkSave}
					sections={sections}
					showModal={showTimeSheetsEditModal}
					timeSheets={timeSheetsInEdit}
					timeZone={timeZone}
					timeZoneInUse={timeZoneInUse}
					workOrderId={workOrder.id}
					workOrderReviewLevel={workOrder.reviewLevel}
					workOrderReviewStatus={workOrder.reviewStatus}
					workRequestId={workOrder.jobId}
				/>
				{showSubmitToModal &&
					<SubmitToModal
						canSubmitToAccounting={this.props.canSubmitToAccounting}
						canSubmitToManagement={this.props.canSubmitToManagement}
						closeModal={this.hideSubmitToModal}
						currentLevel={workOrder.reviewLevel}
						onConfirm={this.handleSubmitToModal}
						showModal={showSubmitToModal}
						title="Submit for Review"
					/>}
				<SubmitForReviewModal
					closeModal={this.hideSubmitForReviewModal}
					currentReviewLevel={workOrder.reviewLevel}
					currentUserAccountId={accountId}
					fieldReports={workOrder.fieldReportList}
					isCompanyAdmin={isCompanyAdmin}
					isDigitalSignatureEnabled={isDigitalSignatureEnabled}
					isManagerAdminSI={isManagerAdminSI}
					isWorkOrderReadOnly={isWorkOrderReadOnly}
					onSubmit={this.submitForReview}
					revertOutdated={revertOutdated}
					showCreateDigitalSignature={showCreateDigitalSignature}
					showModal={showSubmitForReviewModal}
					submitToLevel={submitToLevel!}
					timezone={timeZoneInUse}
					workOrderId={workOrder.id}
					workOrderReviewRecap={workOrderReviewRecap}
				/>
				<ApproveModal
					closeModal={this.hideApproveModal}
					fieldReports={workOrder.fieldReportList}
					isCompanyAdmin={isCompanyAdmin}
					isDigitalSignatureEnabled={isDigitalSignatureEnabled}
					isWorkOrderReadOnly={isWorkOrderReadOnly}
					onSubmit={this.approve}
					revertOutdated={revertOutdated}
					reviewLevel={workOrder.reviewLevel}
					showCreateDigitalSignature={showCreateDigitalSignature}
					showModal={showApproveModal}
					targetReviewLevel={submitToLevel}
					timezone={timeZoneInUse}
					workOrderId={workOrder.id}
					workOrderReviewRecap={workOrderReviewRecap}
				/>
				<OutdatedChangesModal
					approve={this.processApproval}
					closeModal={this.hideOutdatedModal}
					isSubmit={isSubmit}
					revision={workOrder.revision}
					revisionCreatedAt={workOrder.revisionCreatedAt}
					showModal={showOutdatedModal}
					submit={this.handleSubmitForReviewModal}
					woCode={workOrder.code}
					workOrderId={workOrder.id}
					workOrderOutdatedDifferences={workOrderOutdatedDifferences}
					woUpdatedAt={workOrder.updatedAt}
				/>
				<ConfirmationModal
					body={FieldReportsList.FINALIZE_OUTDATED_MODAL_BODY}
					closeModal={this.hideFinalizeOutdatedModal}
					closeText="Cancel"
					confirmAction={this.processFinalization}
					confirmText="Finalize"
					modalStyle="danger"
					showModal={showFinalizeOutdatedModal}
					title="Are you sure you want to Finalize Field Report?"
				/>
				<RejectModal
					accountants={workOrder.accountants}
					closeModal={this.hideRejectModal}
					employees={workOrder.employees}
					managers={workOrder.managers}
					onReject={this.reject}
					reviewLevel={workOrder.reviewLevel}
					reviewStatus={workOrder.reviewStatus}
					showModal={showRejectModal}
					superintendents={workOrder.superintendents}
					workOrderCode={workOrder.code}
				/>
				<FinalizeModal
					closeModal={this.hideFinalizeModal}
					dueDate={workOrder.dueDate}
					fieldReports={workOrder.fieldReportList}
					onSubmit={this.finalize}
					showModal={showFinalizeModal}
					timezone={timeZoneInUse}
					workOrderId={workOrder.id}
					workOrderReviewRecap={workOrderReviewRecap}
				/>
				<ConfirmationModal
					body={FieldReportsList.END_SHIFT_MODAL_BODY}
					closeModal={this.hideEndShiftConfirmationModal}
					closeText="Cancel"
					confirmAction={this.confirmEndShift}
					confirmText="End Shift & Submit Report"
					modalStyle="warning"
					showModal={showEndShiftConfirmationModal}
					title="End Shift & Submit for Review"
				/>
				<ConfirmationModal
					body={FieldReportsList.END_ALL_SHIFTS_MODAL_BODY}
					closeModal={this.hideEndAllShiftsConfirmationModal}
					closeText="Cancel"
					confirmAction={this.handleConfirmEndAllShifts}
					confirmText="Continue"
					modalStyle="warning"
					showModal={showEndAllShiftsConfirmationModal}
					title="End all shifts?"
				/>
				<ConfirmationModal
					body={timeSheetErrorModalMessage ?? ''}
					closeModal={this.hideTimeSheetErrorModal}
					confirmAction={this.hideTimeSheetErrorModal}
					confirmText="Close"
					modalStyle="warning"
					showCancel={false}
					showModal={!!timeSheetErrorModalMessage}
					title="Time Sheets are not valid"
				/>
				<ConfirmationModal
					body={FieldReportsList.INVALID_EQUIPMENT_TIME_WARNING_BODY}
					closeModal={this.hideInvalidEquipmentTimeWarningModal}
					confirmAction={this.hideInvalidEquipmentTimeWarningModal}
					confirmText="Close"
					modalStyle="info"
					showCancel={false}
					showModal={showInvalidEquipmentTimeWarningModal}
					title={FieldReportsList.INVALID_EQUIPMENT_TIME_WARNING_HEADER}
				/>
				<ConfirmationModal
					body={FieldReportsList.INVALID_ALLOCATIONS_TIME_WARNING_BODY}
					closeModal={this.hideInvalidAllocationsTimeWarningModal}
					confirmAction={this.hideInvalidAllocationsTimeWarningModal}
					confirmText="Close"
					modalStyle="info"
					showCancel={false}
					showModal={showInvalidAllocationsTimeWarningModal}
					title={FieldReportsList.INVALID_ALLOCATIONS_TIME_WARNING_HEADER}
				/>
				<ConfirmationModal
					body={FieldReportsList.CHECK_WORK_SUMMARY_MODAL_BODY}
					closeModal={this.hideCheckWorkSummaryModal}
					confirmAction={this.hideCheckWorkSummaryModal}
					confirmText="Close"
					modalStyle="warning"
					showCancel={false}
					showModal={showCheckWorkSummaryModal}
					title="Check Work Summary"
				/>
				<ConfirmationModal
					body={FieldReportsList.FINALIZE_DANGER_BODY}
					closeModal={this.hideFinalizeDangerModal}
					confirmAction={this.checkAndShowOutdatedFinalizeModal}
					confirmText="Finalize"
					modalStyle="danger"
					showModal={showFinalizeDangerModal}
					title={FieldReportsList.FINALIZE_DANGER_HEADER}
				/>
				<OrderInfoModal
					actionsEnabled={false}
					closeOrderInfoModal={this.closeOrderInfoModal}
					companyName={companyData.name}
					hasAccess
					hasPermissionsToEditScheduleBoard={false}
					history={history}
					isRevisionOpened={isRevisionOpened}
					loadWorkOrder={this.loadWorkOrder}
					orgAlias={orgAlias}
					selectRevision={this.selectWorkOrderRevision}
					showModal={showWorkOrderModal}
					workOrder={workOrderModal}
					workOrderRevision={workOrderModalRevision}
				/>
				<LoadingOverlay
					label="Exporting to PDF"
					show={showDownloadingModal}
				/>
				<LoadingOverlay
					label="Deleting"
					show={showDeletingFRModal}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData }, company: { company } } = state;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { isCompanyAdmin, permissions, accountId } = companyData;
	const { role, isDigitalSignatureEnabled, showCreateDigitalSignature } = userData;

	const isAccountingOrAdmin = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.ACCOUNTING, permissions, isCompanyAdmin, role);
	const isManagement = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.MANAGEMENT, permissions, isCompanyAdmin, role);
	const hasSIUnassignedPermission = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.SI_UNASSIGNED_MANAGE, permissions, isCompanyAdmin, role);
	const hasPermissionToEdit = isAccountingOrAdmin || isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FILL, permissions, isCompanyAdmin, role);
	const canSubmitToAccounting = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.SI_SKIP_MANAGEMENT, permissions, isCompanyAdmin, role);
	const isWorkSummaryAccessible = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.WORK_SUMMARY, permissions, isCompanyAdmin, role);
	const hasPermissionToFinalize = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FINALIZE, permissions, isCompanyAdmin, role);
	const isManagementAllowedToEdit = company?.isFRManageAllowedToEditTimeSheet ?? false;
	const canEditInFinalizing = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.FINALIZING, permissions, isCompanyAdmin, role);
	const companyInfo = company
		? ({
			isLoadingCompany: false as const,
			fieldReportAccessDayDuration: company.fieldReportAccessDayDuration,
			canSubmitToManagement: company.isFRManageLevelAllowedInReviewProcess,
		})
		: { isLoadingCompany: true as const, fieldReportAccessDayDuration: null, canSubmitToManagement: null };
	const userName = UserUtils.getUserName(userData);

	return {
		companyData: companyData,
		accountId,
		hasPermissionToEdit,
		isAccountingOrAdmin,
		canSubmitToAccounting,
		isCompanyAdmin,
		isManagement,
		isManagementAllowedToEdit,
		hasSIUnassignedPermission,
		isWorkSummaryAccessible,
		hasPermissionToFinalize,
		canEditInFinalizing,
		isDigitalSignatureEnabled,
		showCreateDigitalSignature,
		...companyInfo,
		userName,
	};
}

function mapDispatchToProps() {
	return {
		approveReview: WorkOrderActions.approveReview,
		checkIfCanCompleteWorkSummaries: WorkOrderActions.checkIfCanCompleteWorkSummaries,
		createFieldReport: FieldReportActions.createFieldReport,
		deleteFieldReport: FieldReportActions.deleteFieldReport,
		endShift: TimeSheetActions.endShift,
		endShiftsForWorkOrder: TimeSheetActions.endShiftsForWorkOrder,
		fetchCompany: CompanyActions.getCompany,
		finalizeReview: WorkOrderActions.finalizeReview,
		findAllEntriesWithAccess: TimeSheetActions.findAllEntriesWithAccess,
		findCurrentTrackedEntry: TimeSheetActions.findCurrentTrackedEntry,
		findTrackedEntriesForWorkOrder: TimeSheetActions.findTrackedEntriesForWorkOrder,
		findWorkOrderFieldReportCardById: WorkOrderActions.findWorkOrderFieldReportCardById,
		findWorkOrderReviewRecap: WorkOrderActions.findReviewRecap,
		findWorkOrderTimeSheetEntries: TimeSheetActions.findWorkOrderTimeSheetEntries,
		findWorkOrderTimeSheets: TimeSheetActions.findWorkOrderTimeSheets,
		getWorkSummaryStatus: WorkOrderActions.getWorkSummaryStatus,
		hasWorkOrderOverlaps: TimeSheetActions.hasWorkOrderOverlaps,
		hasWorkOrderOverlapsForAccount: TimeSheetActions.hasWorkOrderOverlapsForAccount,
		rejectReview: WorkOrderActions.rejectReview,
		signTimeSheetForAccount: TimeSheetActions.signTimeSheetForAccount,
		submitForReview: WorkOrderActions.submitForReview,
		reviewVirtualReports: WorkOrderActions.reviewVirtualReports,
		updateWorkOrderTimeSheetEntriesForAccount: TimeSheetActions.updateWorkOrderTimeSheetEntriesForAccount,
		bulkUpdateWorkOrderTimeSheetEntries: TimeSheetActions.bulkUpdateWorkOrderTimeSheetEntries,
		fetchOutdatedWorkOrderDifferences: WorkOrderActions.fetchOutdatedWorkOrderDifferences,
		findClassificationCodes: FieldWorkClassificationCodeActions.findAllForCompanyList,
		findEquipment: EquipmentActions.findListForTimeSplitsByWorkOrder,
		findAllocatedWorkSummaryDetails: WorkSummaryDetailsActions.findAllocatedWorkSummaryDetails,
		lazyLoadWorkOrder: ScheduleBoardActions.lazyLoadWorkOrder,
		lazyLoadWorkOrderRevision: ScheduleBoardActions.lazyLoadWorkOrderRevision,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	withRouterV6,
	withRouter,
	connect(mapStateToProps, mapDispatchToProps())
);
export default enhance(FieldReportsList);
