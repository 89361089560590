import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import * as generalActionCreators from 'af-actions/general/general.actionCreators';

import API from 'af-constants/routes/api';
import CLIENT from 'af-constants/routes/client';

import { http } from 'af-utils/http.util';
import type { ErrorOverride } from 'af-utils/actions.util';
import { errorHandler } from 'af-utils/actions.util';

import * as FormUtil from 'ab-utils/form.util';

import type { EquipmentCostRequestModel, EquipmentCostImportBatch, EquipmentCostCSVRequestModel } from 'ab-requestModels/equipmentCost.requestModel';
import type { EquipmentCostCategoryRequestModel } from 'ab-requestModels/equipmentCostCategory.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { BulkCreateResponseViewModel } from 'ab-viewModels/csv.viewModel';
import type { EquipmentCostTableRowViewModel } from 'ab-viewModels/equipmentCostTable.viewModel';
import type { EquipmentCostViewModel } from 'ab-viewModels/equipmentCost.viewModel';
import type { EquipmentCostTreeStructure, CreateEquipmentCostTreeNode } from 'ab-viewModels/editableTree.viewModel';
import type { EquipmentCostTreeData } from 'ab-viewModels/equipmentCostTree.viewModel';

import type { EquipmentCostDragEnd } from 'ab-paramModels/equipmentCost.params';

import type { GetRootState } from 'af-reducers';

export interface RemoveEquipmentCostTreeNode {
	id: number;
	isCategory: boolean;
}

export function create(data: EquipmentCostRequestModel, companyName: string, orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<void> => {

		const action = async () => {

			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(data, 'image');

			await http.post(API.V1.RESOURCES.EQUIPMENT_COST.CREATE(), fd, options);
			redirectTo(CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.TREE(orgAlias, companyName));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createEquipmentCostCategory(form: EquipmentCostCategoryRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<CreateEquipmentCostTreeNode>(API.V1.RESOURCES.EQUIPMENT_COST.CREATE_CATEGORY, form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createEquipmentCostGroup(form: EquipmentCostCategoryRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<CreateEquipmentCostTreeNode>(API.V1.RESOURCES.EQUIPMENT_COST.CREATE_GROUP, form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(data: EquipmentCostRequestModel, companyName: string, orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			if (!data.id) {
				throw new Error('Equipment Cost ID not provided');
			}

			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(data, 'image');

			await http.put(API.V1.RESOURCES.EQUIPMENT_COST.EDIT(data.id.toString()), fd, options);
			redirectTo(CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.TREE(orgAlias, companyName));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EquipmentCostRequestModel>(API.V1.RESOURCES.EQUIPMENT_COST.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function softDeleteEquipmentCost(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.EQUIPMENT_COST.DELETE(id.toString()));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function softDeleteEquipmentCostTreeNode(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.EQUIPMENT_COST.DELETE(id.toString()));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllByQuery(query: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EquipmentCostViewModel[]>(API.V1.RESOURCES.EQUIPMENT_COST.LIST(query, null));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<EquipmentCostTableRowViewModel>>(API.V1.RESOURCES.EQUIPMENT_COST.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<EquipmentCostViewModel[]>(API.V1.RESOURCES.EQUIPMENT_COST.LIST('', ''));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function softDeleteEquipmentCostCategory(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.EQUIPMENT_COST.CATEGORY_DELETE(id.toString()));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTree() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EquipmentCostTreeData>(API.V1.RESOURCES.EQUIPMENT_COST.TREE());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyEditableTree() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EquipmentCostTreeStructure>(API.V1.RESOURCES.EQUIPMENT_COST.TREE_EDITABLE());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function importBatch(data: EquipmentCostImportBatch) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<BulkCreateResponseViewModel<EquipmentCostCSVRequestModel>>(API.V1.RESOURCES.EQUIPMENT_COST.IMPORT_BATCH(), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function equipmentCostTreeNodeDragEnd(dragEndData: EquipmentCostDragEnd) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.RESOURCES.EQUIPMENT_COST.TREE_NODE_DRAG_END(), dragEndData);
		};

		const error: ErrorOverride = {
			err400: () => {
				dispatch(generalActionCreators.SHOW_REFRESH_MODAL());
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}
