import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import EquipmentCostCategoryEnum from 'acceligent-shared/enums/equipmentCostCategory';

import type { RootState } from 'af-reducers';

import * as EquipmentActions from 'af-actions/equipment';

import BulkImport from 'af-components/BulkUpsert/Import';
import CodeField from 'af-components/CodeField';
import ColorLegend from 'af-components/ColorLegend';

import { EQUIPMENT_CSV_SAMPLE } from 'af-constants/csvSamples';
import { EQUIPMENT_IMPORT } from 'af-constants/reduxForms';

import type { EquipmentRequestModel } from 'ab-requestModels/equipment.requestModel';

import CLIENT from 'af-constants/routes/client';

import parse from 'af-root/scenes/Company/Resources/Equipment/Equipment/BulkUpload/parse';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	importBatch: typeof EquipmentActions.importBatch;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<EquipmentRequestModel, FormOwnProps>;

class EquipmentBulkUpload extends React.PureComponent<Props> {

	static MANDATORY_FIELDS = [
		'id',
		'equipmentCost.type',
		'equipmentCost.group',
		'equipmentCost.category',
		'equipmentCost.categoryColor',
		'equipmentCost.subcategory',
	];

	static MANUAL = [
		'Import multiple equipment to your equipment list by using a CSV upload feature.',
		'Fields that should be located in the CSV are listed below.',
		'The ones marked with an asterisk are required and should not be left out.',
		'Elements such as Equipment Costs or Skills will not be updated, only created if they do not exist.',
	];

	static FIELDS = (
		<>
			<CodeField isBlue={true}>
				<>
					<b>id</b>                                   | * | Code / Unique ID <br />
					<b>specification</b>                        |   | Specification (max 8 characters)<br />
					<b>licenses</b>                             |   | Licenses <br />
					<b>primaryContact</b>                       |   | Primary Contact <br />
					<b>secondaryContact</b>                     |   | Secondary Contact <br />
					<b>equipmentStatus</b>                      |   | Equipment Status - non-existing ones will be ignored<br />
					<b>skills.0.name</b>                        |   | Equipment Skill name <br />
					<b>skills.0.color</b>                       |   | Equipment Skill color (from color legend) <br />
					<b>equipmentCost.type</b>                   | * | Type ( {Object.keys(EquipmentCostCategoryEnum).join(',')} )<br />
					<b>equipmentCost.group</b>                  | * | Equipment Cost Group <br />
					<b>equipmentCost.category</b>               | * | Equipment Cost Category <br />
					<b>equipmentCost.categoryColor</b>          | * | Category Color (from color legend) <br />
					<b>equipmentCost.subcategory</b>            | * | Subcategory <br />
					<b>equipmentCost.mobCharge</b>              |   | Mob Charge (default 0) <br />
					<b>equipmentCost.dailyCost</b>              |   | Daily Cost (default 0) <br />
					<b>equipmentCost.weeklyCost</b>             |   | Weekly Cost (default 0) <br />
					<b>equipmentCost.monthlyCost</b>            |   | Monthly Cost (default 0) <br />
					<b>equipmentCost.operatingCharge</b>        |   | Operating Hour Charge (default 0) <br />
					<b>equipmentCost.fuelCost</b>               |   | Fuel Cost (default 0) <br />
					<b>equipmentCost.licenses</b>               |   | Licenses <br />
					<b>equipmentCost.skills.0.name</b>          |   | Equipment Cost Skill name (Required if Skill color provided) <br />
					<b>equipmentCost.skills.0.color</b>         |   | Equipment Cost Skill color (from color legend) (Required if Skill name provided) <br />
				</>
			</CodeField>
			<ColorLegend />
		</>
	);

	render() {
		const { importBatch, location: { state: { orgAlias } }, companyName } = this.props;
		return (
			<BulkImport
				fields={EquipmentBulkUpload.FIELDS}
				important="Missing Equipment Costs will be created."
				importBatch={importBatch}
				listRoute={CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName)}
				mandatoryFields={EquipmentBulkUpload.MANDATORY_FIELDS}
				manual={EquipmentBulkUpload.MANUAL}
				parse={parse}
				sampleData={EQUIPMENT_CSV_SAMPLE}
				title="Equipment"
			/>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		importBatch: EquipmentActions.importBatch,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<EquipmentRequestModel, FormOwnProps>({
		form: EQUIPMENT_IMPORT,
		enableReinitialize: true,
	})
);
export default enhance(EquipmentBulkUpload);
