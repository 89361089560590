import type { Dispatch, AnyAction } from 'redux';
import { saveAs } from 'file-saver';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type WorkRequestBidStatus from 'acceligent-shared/enums/workRequestBidStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type WorkRequestUpsertRM from 'ab-requestModels/workRequest/workRequestUpsert.requestModel';
import type WorkRequestBidStatusRM from 'ab-requestModels/workRequest/workRequestBidStatusEdit.requestModel';
import type WorkRequestConvertToJobRM from 'ab-requestModels/workRequest/workRequestConvertToJob.requestModel';
import type FindOrCreateJobReportsLookupBulkGroupCodeRM from 'ab-requestModels/workRequest/findOrCreateJobsReportLookupBulkGroupCode.requestModel';
import type WorkRequestCopyRM from 'ab-requestModels/workRequest/workRequestCopy.requestModel';

import type WorkRequestUpsertVM from 'ab-viewModels/workRequest/workRequestUpsert.viewModel';
import type ConvertedToJobVM from 'ab-viewModels/workRequest/convertedToJob.viewModel';
import type { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';
import type WorkRequestPreviewVM from 'ab-viewModels/workRequest/workRequestPreview.viewModel';
import type { BillingCodeVM } from 'ab-viewModels/jobPreview.viewModel';
import type JobReportListVM from 'ab-viewModels/report/jobReportList.viewModel';
import type CopiedWorkRequestVM from 'ab-viewModels/workRequest/copiedWorkRequest.viewModel';
import type SiblingSubjobVM from 'ab-viewModels/workRequest/siblingSubjob.viewModel';
import type JobWithProjectInfoVM from 'ab-viewModels/workRequest/jobWithProjectInfo.viewModel';
import type WorkRequestOptionVM from 'ab-viewModels/workRequest/workRequestOption.viewModel';

import { HTTP_ACCEPTED_MESSAGE } from 'ab-common/constants/value';

import { CONVERT_TO_JOB, WORK_REQUEST_COPY, WORK_REQUEST_FORM } from 'af-constants/reduxForms';

import type AssociatedSubjobVM from 'ab-viewModels/workRequest/associatedSubjob.viewModel';

import type { GetRootState } from 'af-reducers';

import API from 'af-routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';
import { awaitableTimeout } from 'af-utils/timeout.util';

import * as WorkRequestsTableAPI from 'ab-api/web/workRequest/findWorkRequestsTable';

export function getWorkRequestsTable(tableRequestModel: TableQuery, bidStatus: WorkRequestBidStatus) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<WorkRequestsTableAPI.W_WorkRequest_FindWorkRequestsTable_VM>(WorkRequestsTableAPI.URL(data, bidStatus));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function create(workRequestRM: Partial<WorkRequestUpsertRM>) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<void>(API.V1.WORK_REQUEST.CREATE, workRequestRM);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function edit(id: number, workRequestRM: Partial<WorkRequestUpsertRM>) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<void>(API.V1.WORK_REQUEST.EDIT(id), workRequestRM, { submitting: WORK_REQUEST_FORM });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editBidStatus(id: number, workRequestBidStatusRM: WorkRequestBidStatusRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.put<void>(API.V1.WORK_REQUEST.EDIT_BID_STATUS(id), workRequestBidStatusRM);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function convertToJob(id: number, workRequestConvertToJobRM: WorkRequestConvertToJobRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<ConvertedToJobVM>(API.V1.WORK_REQUEST.CONVERT_TO_JOB(id), workRequestConvertToJobRM, { submitting: CONVERT_TO_JOB });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function copy(id: number, workRequestCopyRM: WorkRequestCopyRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<CopiedWorkRequestVM>(API.V1.WORK_REQUEST.COPY(id), workRequestCopyRM, { submitting: WORK_REQUEST_COPY });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findByIdForEdit(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<WorkRequestUpsertVM>(API.V1.WORK_REQUEST.FIND_BY_ID_FOR_EDIT(id));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findByIdForPreview(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<WorkRequestPreviewVM>(API.V1.WORK_REQUEST.FIND_BY_ID_FOR_PREVIEW(id));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAvailableSubjobs(id?: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<SubjobVM[]>(API.V1.WORK_REQUEST.FIND_ALL_AVAILABLE_SUBJOBS(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllSubjobs(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<SubjobVM[]>(API.V1.WORK_REQUEST.FIND_ALL_SUBJOBS(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findJobReportsList(startDate: Date, endDate: Date) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);

			return await http.get<JobReportListVM[]>(
				API.V1.WORK_REQUEST.FIND_REPORTS_LIST_FOR_BULK_SEND(startDateString, endDateString)
			);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllSubjobsTable(id: number, tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const _tableRequestModel = {
			...tableRequestModel,
			page: 1,
			pageSize: 100000,
		};

		const action = async () => {
			return await http.get<TableContent<SubjobVM>>(API.V1.WORK_REQUEST.FIND_ALL_SUBJOBS_TABLE(id, _tableRequestModel));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findOrCreateJobReportsBulkGroupCode(ids: number[], /** YYYY-MM-DD */ startDate: string, /** YYYY-MM-DD */ endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data: FindOrCreateJobReportsLookupBulkGroupCodeRM = { ids, startDate, endDate };
			return await http.post<string>(API.V1.WORK_REQUEST.FIND_OR_CREATE_JOBS_REPORT_BULK_GROUP_CODE, data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllBillingCodesTable(id: number, tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const _tableRequestModel = {
			...tableRequestModel,
			page: 1,
			pageSize: 100000,
		};

		const action = async () => {
			return await http.get<TableContent<BillingCodeVM>>(API.V1.WORK_REQUEST.FIND_ALL_BILLING_CODES_TABLE(id, _tableRequestModel));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downloadJobReportsPdf(bulkGroupCode: string, filename?: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = await http.get<string>(API.V1.WORK_REQUEST.DOWNLOAD_JOB_REPORTS_PDF(bulkGroupCode));
			if (data === HTTP_ACCEPTED_MESSAGE) {
				// Message received that we're still generating the report. We will ping in 2 seconds to check again
				// We're jumping through hoops to make sure the caller of this action doesn't have to worry about retrying mechanism
				await awaitableTimeout(action, 5000);
			} else {
				const fetchedFile = await fetch(data);
				const blob = await fetchedFile?.blob();
				saveAs(blob, `${filename}.pdf`);
				return;
			}
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkRequestsForAssociatedSubjobData(workRequestIds: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TableContent<AssociatedSubjobVM>>(API.V1.WORK_REQUEST.FIND_FOR_ASSOCIATED_SUBJOB_DATA(workRequestIds));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findSiblingSubjobs(jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<SiblingSubjobVM[]>(API.V1.WORK_REQUEST.FIND_SIBLING_SUBJOBS(jobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWithProjectInfo(jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<JobWithProjectInfoVM>(API.V1.WORK_REQUEST.FIND_WITH_PROJECT_INFO(jobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkRequestOptionsForPurchaseOrder() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<WorkRequestOptionVM[]>(API.V1.WORK_REQUEST.FIND_FOR_PURCHASE_ORDER_OPTIONS());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
