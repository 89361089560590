import { CompoundUnitEnum, ConstrainedUnitEnum, PlainUnitEnum } from 'acceligent-shared/enums/quantityUnit';

export enum UnitClass {
	LENGTH = 'LENGTH',
	AREA = 'AREA',
	VOLUME = 'VOLUME',
	WEIGHT = 'WEIGHT',
	TIME = 'TIME',
	PRESSURE = 'PRESSURE',
	TEMPERATURE = 'TEMPERATURE',
	QUANTITY = 'QUANTITY'
}

export const UnitClasses = {
	// Length Units
	[PlainUnitEnum.M]: UnitClass.LENGTH,
	[PlainUnitEnum.LM]: UnitClass.LENGTH,
	[PlainUnitEnum.KM]: UnitClass.LENGTH,
	[PlainUnitEnum.IN]: UnitClass.LENGTH,
	[PlainUnitEnum.LIN]: UnitClass.LENGTH,
	[PlainUnitEnum.FT]: UnitClass.LENGTH,
	[PlainUnitEnum.LF]: UnitClass.LENGTH,
	[PlainUnitEnum.YD]: UnitClass.LENGTH,
	[PlainUnitEnum.MIL]: UnitClass.LENGTH,
	[PlainUnitEnum.MI]: UnitClass.LENGTH,
	[CompoundUnitEnum.FT_IN]: UnitClass.LENGTH,
	[CompoundUnitEnum.LFT_LIN]: UnitClass.LENGTH,

	// Area Units
	[PlainUnitEnum.SM]: UnitClass.AREA,
	[PlainUnitEnum.SF]: UnitClass.AREA,
	[PlainUnitEnum.SY]: UnitClass.AREA,
	[PlainUnitEnum.AC]: UnitClass.AREA,
	[PlainUnitEnum.HA]: UnitClass.AREA,
	[PlainUnitEnum.SIN]: UnitClass.AREA,
	[CompoundUnitEnum.SFT_SIN]: UnitClass.AREA,

	// Volume Units
	[PlainUnitEnum.BF]: UnitClass.VOLUME,
	[PlainUnitEnum.MB]: UnitClass.VOLUME,
	[PlainUnitEnum.CF]: UnitClass.VOLUME,
	[PlainUnitEnum.CY]: UnitClass.VOLUME,
	[PlainUnitEnum.CM]: UnitClass.VOLUME,
	[PlainUnitEnum.GAL]: UnitClass.VOLUME,
	[PlainUnitEnum.HGA]: UnitClass.VOLUME,
	[PlainUnitEnum.L]: UnitClass.VOLUME,
	[CompoundUnitEnum.CFT_CIN]: UnitClass.VOLUME,

	// Weight Units
	[PlainUnitEnum.LB]: UnitClass.WEIGHT,
	[PlainUnitEnum.KG]: UnitClass.WEIGHT,
	[PlainUnitEnum.TN]: UnitClass.WEIGHT,

	// Time Units
	[PlainUnitEnum.HR]: UnitClass.TIME,
	[PlainUnitEnum.MIN]: UnitClass.TIME,
	[CompoundUnitEnum.HH_MM]: UnitClass.TIME,

	// Pressure Units
	[PlainUnitEnum.PSI]: UnitClass.PRESSURE,
	[PlainUnitEnum.BAR]: UnitClass.PRESSURE,

	// Temperature Units
	[PlainUnitEnum.F]: UnitClass.TEMPERATURE,

	// Quantity Units
	[PlainUnitEnum.LS]: UnitClass.QUANTITY,
	[PlainUnitEnum.EA]: UnitClass.QUANTITY,
	[PlainUnitEnum.AS]: UnitClass.QUANTITY,
	[PlainUnitEnum.LO]: UnitClass.QUANTITY,
	[PlainUnitEnum.PI]: UnitClass.QUANTITY,
	[PlainUnitEnum.PS]: UnitClass.QUANTITY,
	[PlainUnitEnum.PASS]: UnitClass.QUANTITY,
	[PlainUnitEnum.TL]: UnitClass.QUANTITY,
	[PlainUnitEnum.ROL]: UnitClass.QUANTITY,
	[PlainUnitEnum.TAPS]: UnitClass.QUANTITY,
	[PlainUnitEnum.BAGS]: UnitClass.QUANTITY,
	[PlainUnitEnum.PAILS]: UnitClass.QUANTITY,
	[PlainUnitEnum.CYC]: UnitClass.QUANTITY,
	[ConstrainedUnitEnum.PCT]: UnitClass.QUANTITY,
} as const;

const swapObjectAndTyping = <T extends Record<string, S>, S extends string>(obj: T) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const res = {} as any;
	Object.entries(obj).forEach(([key, value]) => {
		res[value] = key;
	});
	return res as { [K in keyof T as T[K]]: K };
};

const ClassUnits = swapObjectAndTyping(UnitClasses);
export type ClassUnitsType = typeof ClassUnits;

export const BaseUnitForClass: { [T in keyof ClassUnitsType]: ClassUnitsType[T] | undefined} = {
	[UnitClass.LENGTH]: PlainUnitEnum.M,
	[UnitClass.AREA]: PlainUnitEnum.SM,
	[UnitClass.VOLUME]: PlainUnitEnum.CM,
	[UnitClass.WEIGHT]: PlainUnitEnum.KG,
	[UnitClass.TIME]: PlainUnitEnum.MIN,
	[UnitClass.PRESSURE]: PlainUnitEnum.BAR,
	[UnitClass.TEMPERATURE]: PlainUnitEnum.F,
	[UnitClass.QUANTITY]: undefined,
};
