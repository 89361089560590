import * as React from 'react';
import { Button } from 'react-bootstrap';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { History } from 'history';

import CustomModal from 'af-components/CustomModal';

import * as WorkOrderActions from 'af-actions/workOrder';

import RelatedWorkOrders from 'af-root/scenes/Company/Resources/Shared/RelatedWorkOrders';

import TableNameEnum from 'ab-enums/tableName.enum';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import styles from './styles.module.scss';

interface OwnProps {
	showEmployeeWOHistoryModal: boolean;
	closeEmployeeWOModal: () => void;
	companyName: string;
	orgAlias: string;
	employeeId: number;
	employeeFirstName: string;
	employeeLastName: string;
	employeeFormattedCode: string;
	history: History;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const EmployeeWorkOrderHistoryModal: React.FC<Props> = (props: Props) => {
	const {
		showEmployeeWOHistoryModal,
		closeEmployeeWOModal,
		companyName,
		orgAlias,
		employeeId,
		employeeFirstName,
		employeeLastName,
		employeeFormattedCode,
		history,
	} = props;

	const fetchWorkOrders = React.useCallback((tableRequestModel: TableQuery, startDate: Date, endDate: Date) => {
		const { findWorkOrdersByEmployeeId } = props;
		const wos = findWorkOrdersByEmployeeId(employeeId, tableRequestModel, startDate, endDate);

		return wos;
	}, [employeeId, props]);

	const baseClassName = 'employee-wo-history-modal';
	const headerClassName = `${baseClassName}__big-header`;
	const headerLeft = `${baseClassName}__big-header-left`;
	const headerRight = `${baseClassName}__big-header-right`;
	const iconClassName = 'header-close-icon';
	const modalBody = 'modal-body';

	return (
		<>
			<CustomModal
				className={styles[baseClassName]}
				closeModal={closeEmployeeWOModal}
				modalStyle="info"
				showModal={showEmployeeWOHistoryModal}
			>
				<CustomModal.Header className={styles[headerClassName]}>
					<div className={`${styles[headerLeft]} `}>
						<span>{` WORK ORDER HISTORY | ${employeeFirstName} ${employeeLastName}`}</span>
					</div>
					<div className={`${styles[headerRight]} `}>
						<span
							className={`icon-close ${styles[iconClassName]}`}
							onClick={closeEmployeeWOModal}
						/>
					</div>
				</CustomModal.Header>
				<CustomModal.Body className={`${styles[modalBody]} `}>
					<RelatedWorkOrders
						companyName={companyName}
						csvName={`${companyName}_${employeeFormattedCode}_work_orders.csv`}
						fetchWorkOrders={fetchWorkOrders}
						history={history}
						orgAlias={orgAlias}
						tableName={TableNameEnum.EMPLOYEE_WORK_ORDERS}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeEmployeeWOModal}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</CustomModal>
		</>
	);
};

const mapDispatchToProps = () => {
	return {
		findWorkOrdersByEmployeeId: WorkOrderActions.findWorkOrdersByEmployeeId,
	};
};

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(EmployeeWorkOrderHistoryModal);

