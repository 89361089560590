import type SaleBase from 'ab-domain/models/sale/base';

export type W_Sale_DropdownOptions_VM = W_Sale_DropdownOptions_VM_Single[];

export class W_Sale_DropdownOptions_VM_Single {
	id: number;
	fullName: string;
	title: string;

	constructor(sale: SaleBase) {
		this.id = sale.id;
		this.fullName = sale.fullName;
		this.title = sale.title;
	}

	static bulkConstructor = (sales: SaleBase[]) => sales.map(W_Sale_DropdownOptions_VM_Single._constructorMap);

	private static _constructorMap = (sale: SaleBase) => new W_Sale_DropdownOptions_VM_Single(sale);
}

