import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import type { Option } from 'react-select/src/filters';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import RepairStatus from 'acceligent-shared/enums/repairStatus';

import { formatDate } from 'acceligent-shared/utils/time';

import type LocationOptionVM from 'ab-viewModels/location/option.viewModel';
import type ToolVM from 'ab-viewModels/tool/option.viewModel';
import type WorkRequestOptionVM from 'ab-viewModels/workRequest/workRequestOption.viewModel';
import type AccountOptionVM from 'ab-viewModels/account/option.viewModel';
import type { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';
import type EquipmentPOOptionVM from 'ab-viewModels/equipment/equipmentPurchaseOrderOption.viewModel';

import * as JobAction from 'af-actions/workRequests';
import * as LocationAction from 'af-actions/location';
import * as ToolAction from 'af-actions/tool';
import * as EquipmentAction from 'af-actions/equipment';

import Textarea from 'af-fields/Textarea';
import Select from 'af-fields/SelectField';
import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';

import ToolLabel from './ToolOptionLabel';
import EquipmentLabel from './EquipmentOptionLabel';
import styles from './styles.module.scss';
import StatusLabel from './StatusLabel';
import type FormModel from './formModel';
import { getTimeBetweenDateTimes } from './utils';

interface OwnProps {
	superintendents: EmployeeOptionVM[];
	operators: EmployeeOptionVM[];
	disabled?: boolean;
	initialToolId: Nullable<string>;
	initialEquipmentId: Nullable<string>;
	change: (field: string, value) => void;
	formData: FormModel;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const getSelectOptionValue = (option: { id: number; }) => option.id;
const getSelectAccountOptionValue = (option: { accountId: number; }) => option.accountId;

const formatLocationOptionLabel = (option: LocationOptionVM) => <div>{option.nickname}</div>;
const formatEmployeeOptionLabel = (option: EmployeeOptionVM) => <div>{option.firstName} {option.lastName}</div>;
const formatJobOptionLabel = (option: WorkRequestOptionVM) => <div>{option.jobCode}</div>;

const filterSelects = (textA?: string, textB?: string) => (textA ?? '').toLowerCase().includes((textB ?? '').toLowerCase());
const filterLocations = ({ data }: Option, text: string) => filterSelects(data.nickname, text);
const filterEmployee = ({ data }: Option, text: string) => filterSelects(`${data.firstName} ${data.lastName} ${data.uniqueId}`, text);
const filterJob = ({ data }: Option, text: string) => filterSelects(data?.jobCode ?? '', text);
const filterEquipment = ({ data }: Option, text: string) => filterSelects(`${data.code} ${data.specification}`, text);
const filterTool = ({ data }: Option, text: string) => filterSelects(`${data.serialNumber} ${data.toolType}`, text);

const LocationSelect = Select as unknown as new () => Select<LocationOptionVM>;
const ToolSelect = Select as unknown as new () => Select<ToolVM>;
const JobSelect = Select as unknown as new () => Select<WorkRequestOptionVM>;
const EquipmentSelect = Select as unknown as new () => Select<EquipmentPOOptionVM>;
const AccountSelect = Select as unknown as new () => Select<AccountOptionVM>;

const DetailsSection = (props: Props) => {
	const {
		superintendents,
		operators,
		findAllTools,
		findAllLocations,
		findAllJobs,
		findAllEquipment,
		disabled,
		initialToolId,
		initialEquipmentId,
		change,
		formData,
	} = props;

	const [locations, setLocations] = React.useState<LocationOptionVM[]>([]);
	const [tools, setTools] = React.useState<ToolVM[]>([]);
	const [jobs, setJobs] = React.useState<WorkRequestOptionVM[]>([]);
	const [equipment, setEquipment] = React.useState<EquipmentPOOptionVM[]>([]);

	const loadStuff = React.useCallback(async () => {

		const [
			_tools,
			_locations,
			_jobs,
			_equipments,
		] = await Promise.all([
			findAllTools(),
			findAllLocations(),
			findAllJobs(),
			findAllEquipment(),
		]);

		setTools(_tools);
		setLocations(_locations);
		setJobs(_jobs);
		setEquipment(_equipments);

		if (initialToolId) {
			const _tool = _tools.find((tool) => tool.id === +initialToolId);
			const _equipment = initialEquipmentId ? _equipments.find((__equipment) => __equipment.id === +initialEquipmentId) : null;
			change('tool', _tool);
			change('equipment', _equipment);
		}
	}, [change, findAllEquipment, findAllJobs, findAllLocations, findAllTools, initialEquipmentId, initialToolId]);

	React.useEffect(() => {
		loadStuff();
	}, [loadStuff]);

	const priceOverrideDisabled = React.useMemo(() => !formData.priceManualOverride || disabled, [disabled, formData.priceManualOverride]);

	const price = React.useMemo(() => {
		return priceOverrideDisabled ? formData.items?.reduce((acc, item) => acc + item.price, 0) ?? 0 : formData.price;
	}, [formData.items, formData.price, priceOverrideDisabled]);

	const totalTimeForRepair = React.useMemo(() => {
		if (!formData.completedAt) {
			return '';
		}

		return getTimeBetweenDateTimes(formData.createdAt, formData.completedAt);
	}, [formData]);

	return (
		<div className={styles.section}>̣
			<div className={styles['section-title']}>Tool Repair Details</div>
			<div className={styles['field-row']}>
				<Row>
					<Col md={4}>
						<div>Tool Repair Status</div>
						<StatusLabel forTable={false} status={formData.status ?? RepairStatus.NEW} />
					</Col>
					<Col md={4}>
						<div>Date Started</div>
						<div className={styles['field-value']}>
							{
								formData.startedAt
									? formatDate(formData.startedAt, TimeFormat.FULL_DATE_LONG)
									: 'N/A'
							}
						</div>
					</Col>
					<Col md={4}>
						<div>Date Completed</div>
						<div className={styles['field-value']}>
							{
								formData.startedAt
									? formatDate(formData.completedAt, TimeFormat.FULL_DATE_LONG)
									: 'N/A'
							}
						</div>
					</Col>
					<Col md={4}>
						<div>Total Time for Repair</div>
						<div className={styles['field-value']}>
							{totalTimeForRepair}
						</div>
					</Col>
					<Col md={4}>
						{
							priceOverrideDisabled &&
							<div className={styles['total-cost-container']}>
								<div>Total Cost ($)</div>
								<div className={styles['field-value']}>
									$ {price}
								</div>
							</div>
						}
						{
							!priceOverrideDisabled &&
							<Field
								component={Input}
								defaultValue={price}
								disabled={priceOverrideDisabled}
								label="Total Cost ($)"
								name="price"
								step={0.01}
								type="number"
							/>
						}
					</Col>
					<Col md={4}>

						<Field
							component={Checkbox}
							isDisabled={disabled}
							isStandaloneRow={true}
							label="Manual Override"
							name="priceManualOverride"
						/>
					</Col>
				</Row>
			</div>
			<Row className="row--padded">
				<Col md={6}>
					<Field
						component={ToolSelect}
						filterOption={filterTool}
						formatOptionLabel={ToolLabel}
						getOptionValue={getSelectOptionValue}
						isDisabled={disabled}
						label="Tool *"
						name="tool"
						options={tools}
						placeholder="Select tool"
					/>
					<Field
						component={JobSelect}
						filterOption={filterJob}
						formatOptionLabel={formatJobOptionLabel}
						getOptionValue={getSelectOptionValue}
						isDisabled={disabled}
						label="Job Number"
						name="workRequest"
						options={jobs}
						placeholder="Select JobNumber"
					/>
					<Field
						component={AccountSelect}
						filterOption={filterEmployee}
						formatOptionLabel={formatEmployeeOptionLabel}
						getOptionValue={getSelectAccountOptionValue}
						isDisabled={disabled}
						label="Superintendent *" // SELECT * FROM bamboohr_master bm LEFT JOIN bamboohr_role_master brm ON bm.bamboohr_role_id = brm.bamboohr_role_id WHERE bamboohr_status = 'A' AND brm.bamboohr_role_name = 'Superintendent' $append_sql ORDER BY first_name ASC
						name="superintendent"
						options={superintendents}
						placeholder="Select Superintendent"
					/>
				</Col>
				<Col md={6}>
					<Field
						component={LocationSelect}
						filterOption={filterLocations}
						formatOptionLabel={formatLocationOptionLabel}
						getOptionValue={getSelectOptionValue}
						isDisabled={disabled}
						label="Repair Location *"
						name="location"
						options={locations}
						placeholder="Select Repair Location"
					/>
					<Field
						component={EquipmentSelect}
						filterOption={filterEquipment}
						formatOptionLabel={EquipmentLabel}
						getOptionValue={getSelectOptionValue}
						isDisabled={disabled}
						label="Equipment"
						name="equipment"
						options={equipment}
						placeholder="Select Equipment"
					/>
					<Field
						component={AccountSelect}
						filterOption={filterEmployee}
						formatOptionLabel={formatEmployeeOptionLabel}
						getOptionValue={getSelectAccountOptionValue}
						isDisabled={disabled}
						label="Operator *" // SELECT * FROM bamboohr_master WHERE bamboohr_status = 'A' $append_sql ORDER BY first_name ASC
						name="operator"
						options={operators}
						placeholder="Select Operator"
					/>
				</Col>
				<Col md={12}>
					<Field
						component={Textarea}
						controlCursor={true}
						disabled={disabled}
						label="Reported Issue *"
						name="reportedIssue"
						rows={6}
					/>
				</Col>
			</Row>
		</div>
	);
};

function mapDispatchToProps() {
	return {
		findAllTools: ToolAction.findAllForList,
		findAllLocations: LocationAction.findList,
		findAllJobs: JobAction.findWorkRequestOptionsForPurchaseOrder,
		findAllEquipment: EquipmentAction.findForPurchaseOrderOptions,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(DetailsSection);
