import type { Dispatch, AnyAction } from 'redux';
import { SubmissionError } from 'redux-form';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import type { ErrorOverride} from 'af-utils/actions.util';
import { errorHandler } from 'af-utils/actions.util';

import type { PurchaseOrderVM } from 'ab-viewModels/purchaseOrder/purchaseOrder.viewModel';

import type { CreateOrUpdatePurchaseOrderRM } from 'ab-requestModels/purchaseOrder/createOrUpdatePurchaseOrder.requestModel';

import type { GetRootState } from 'af-reducers';

export function findPurchaseOrder(purchaseOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<PurchaseOrderVM>(
				API.V1.PURCHASE_ORDER.GET_PURCHASE_ORDER(purchaseOrderId)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deletePurchaseOrder(purchaseOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.delete<void>(
				API.V1.PURCHASE_ORDER.DELETE_PURCHASE_ORDER(purchaseOrderId)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createPurchaseOrder(form: CreateOrUpdatePurchaseOrderRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<PurchaseOrderVM>(
				API.V1.PURCHASE_ORDER.CREATE_OR_UPDATE(),
				form
			);
		};
		const error: ErrorOverride = {
			err409: () => {
				throw new SubmissionError({ orderNumber: 'Order Number already taken. Try to Submit again' });
			},
		};
		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function updatePurchaseOrder(form: CreateOrUpdatePurchaseOrderRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.put<PurchaseOrderVM>(
				API.V1.PURCHASE_ORDER.CREATE_OR_UPDATE(),
				form
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
