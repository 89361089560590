import AccountPermission from 'acceligent-shared/enums/accountPermission';

export interface PagePermissionType {
	isOrganizationMemberAllowed: boolean;
	isCompanyMemberAllowed: boolean;
	permissions: Nullable<AccountPermission[]>;
}

export interface PagePermissionsTree {
	[key: string]: PagePermissionType | PagePermissionsTree;
}

// NOTE: Type not attached in order to preserve intellisense
const PagePermissions = {
	EVERYONE: {
		isOrganizationMemberAllowed: true,
		isCompanyMemberAllowed: true,
		permissions: [],
	},
	HOME: {
		isOrganizationMemberAllowed: true,
		isCompanyMemberAllowed: false,
		permissions: [],
	},
	ORGANIZATION: {
		isOrganizationMemberAllowed: false,
		isCompanyMemberAllowed: false,
		permissions: [],
	},
	COMPANY: {
		CREATE: {
			isOrganizationMemberAllowed: false,
			isCompanyMemberAllowed: true,
			permissions: [],
		},
		SETTINGS: {
			ACCOUNT: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [],
			},
			COMPANY: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: false,
				permissions: [],
			},
			SCHEDULE_BOARD: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.SB_SETTINGS],
			},
			WORK_ORDER: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.WO_SETTINGS],
			},
			REPORT_MANAGE: {
				REPORT_TYPE: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.RT_MANAGE],
				},
				REPORT_BLOCK: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.RB_MANAGE],
				},
			},
			NOTIFICATIONS: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.NTFY_MANAGE],
			},
			MEMBERS: {
				MANAGE: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.UA_MANAGE],
				},
				VIEW: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.UA_READ],
				},
			},
			LOGS: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.LG_VIEW],
			},
			USER_GROUPS: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.UG_MANAGE],
			},
			EQUIPMENT: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.EQ_MANAGE],
			},
			LABOR: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.EM_MANAGE],
			},
			TEMP_LABOR: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.TEM_MANAGE],
			},
		},
		DASHBOARD: {
			isOrganizationMemberAllowed: true,
			isCompanyMemberAllowed: true,
			permissions: [AccountPermission.CR_ALL],
		},
		ANALYTICS: {
			LABOR_UTILIZATION: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.CR_EMPLOYEE_UTILIZATION],
			},
			EQUIPMENT_UTILIZATION: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.CR_EQUIPMENT_UTILIZATION],
			},
		},
		ACCOUNTING: {
			PER_DIEM: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.AC_PER_DIEM],
			},
			PREVAILING_WAGE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.AC_PREVAILING],
			},
			JOB_PAYROLL: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.AC_JOB_PAYROLL],
			},
			WORK_SUMMARY: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.AC_WORK_SUMMARY],
			},
			MANAGE_INVOICES: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.AC_MANAGE_INVOICES],
			},
		},
		PURCHASE_ORDER: {
			VIEW_AND_MANAGE_ALL: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.PO_VIEW_AND_MANAGE_ALL],
			},
			MANAGE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.PO_MANAGE],
			},
			CREATE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.PO_CREATE],
			},
		},
		ORDER: {
			MANAGE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.OR_MANAGE],
			},
			CREATE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.OR_CREATE, AccountPermission.OR_MANAGE],
			},
		},
		WORK_REQUEST: {
			isOrganizationMemberAllowed: true,
			isCompanyMemberAllowed: true,
			permissions: [AccountPermission.WR_MANAGE],
		},
		JOBS: {
			CREATE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.JB_MANAGE],
			},
			EDIT: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.JB_MANAGE, AccountPermission.JB_MANAGE_DELIVERABLE],
			},
			ATTACHMENTS: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.JB_MANAGE, AccountPermission.JB_MANAGE_DELIVERABLE],
			},
			COPY: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.JB_MANAGE, AccountPermission.JB_MANAGE_DELIVERABLE],
			},
			MANAGE_BILLING_CODES: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.JB_MANAGE_BILLING_CODES],
			},
			MANAGE_PROJECTS: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.JB_MANAGE_PROJECTS],
			},
			MANAGE_WORK_SUMMARY: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.JB_MANAGE_WORK_SUMMARY],
			},
			MANAGE_INVOICES: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.JB_MANAGE_INVOICES],
			},
		},
		WORK_ORDERS: {
			MANAGE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.WO_MANAGE],
			},
			SCHEDULE_BOARD: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.SB_VIEW],
			},
			SEND_SB_TEMPLATE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.SB_VIEW, AccountPermission.WO_MANAGE],
			},
		},
		PER_DIEMS: {
			MANAGE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.PD_MANAGE],
			},
		},
		PROD_DATA: {
			VIEW: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.PROD_DATA_VIEW],
			},
			EDIT: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.PROD_DATA_EDIT],
			},
		},
		RESOURCES: {
			WAGE_RATE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_WAGERATE_MANAGE],
			},
			FIELD_WORK_CLASSIFICATION_CODE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_FIELD_WORK_CLASSIFICATION_CODE_MANAGE],
			},
			EMPLOYEE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_EMPLOYEE_MANAGE],
			},
			EMPLOYEE_STATUS: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_EMPLOYEE_STATUS_MANAGE],
			},
			EQUIPMENT: {
				MANAGE: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.RS_EQUIPMENT_MANAGE],
				},
				MECHANIC_VIEW: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.MV_VIEW],
				},
				VIEW_SIDENAV: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [
						AccountPermission.RS_EQUIPMENT_MANAGE,
						AccountPermission.RS_SMALL_TOOL_VIEW,
						AccountPermission.RS_SMALL_TOOL_MANAGE,
						AccountPermission.RS_ACCESSORY_VIEW,
						AccountPermission.RS_ACCESSORY_MANAGE,
					],
				},
			},
			EQUIPMENT_STATUS: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_EQUIPMENT_STATUS_MANAGE],
			},
			EQUIPMENT_COST: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_EQUIPMENTCOST_MANAGE],
			},
			SMALL_TOOLS: {
				VIEW: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.RS_SMALL_TOOL_VIEW],
				},
				MANAGE: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.RS_SMALL_TOOL_MANAGE],
				},
			},
			ACCESSORIES: {
				VIEW: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.RS_ACCESSORY_VIEW],
				},
				MANAGE: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.RS_ACCESSORY_MANAGE],
				},
			},
			CREW_TYPE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_CREWTYPE_MANAGE],
			},
			DIVISION: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_DIVISION_MANAGE],
			},
			LOCATION: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_LOCATION_MANAGE],
			},
			SALE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_SALE_MANAGE],
			},
			DOCUMENT: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_DOCUMENT_MANAGE],
			},
			SKILL: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_SKILL_MANAGE],
			},
			DELIVERABLE: {
				MANAGE: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.RS_DELIVERABLE_MANAGE],
				},
			},
			JOB_STATUS: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.RS_SKILL_MANAGE],
			},
		},
		TOOL_REPAIR: {
			CREATE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.TR_MANAGE, AccountPermission.TR_CREATE],
			},
			MANAGE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.TR_MANAGE],
			},
		},
		FIELD_REPORT: {
			MANAGE: {
				MANAGEMENT: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.FR_MANAGEMENT_MANAGE],
				},
				ACCOUNTING: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.FR_ACCOUNTING_MANAGE],
				},
				FINALIZING: {
					isOrganizationMemberAllowed: true,
					isCompanyMemberAllowed: true,
					permissions: [AccountPermission.FR_EDIT_IN_FINALIZING],
				},
			},
			REORDER_RTS: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.FR_ORDER, AccountPermission.FR_EDIT_IN_FINALIZING],
			},
			FILL: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.FR_FILL, AccountPermission.FR_EDIT_IN_FINALIZING],
			},
			VIEW_ALL: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.FR_VIEW_ALL],
			},
			SI_UNASSIGNED_MANAGE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.FR_SUPERINTENDENT_UNASSIGNED_MANAGE],
			},
			SI_SKIP_MANAGEMENT: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.FR_SI_SKIP_MANAGEMENT],
			},
			FINALIZE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.FR_FINALIZE],
			},
			WORK_SUMMARY: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.WS_MANAGE, AccountPermission.FR_EDIT_IN_FINALIZING],
			},
			COMPLETE_SUMMARY: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.FR_MANAGEMENT_MANAGE, AccountPermission.WS_MANAGE],
			},
		},
		CONTACTS: {
			MANAGE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.CT_MANAGE],
			},
			VIEW: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.CT_VIEW],
			},
		},
		COMMUNICATION: {
			MANAGE: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.COMM_MANAGE],
			},
			VIEW_COMMUNICATION_HISTORY: {
				isOrganizationMemberAllowed: true,
				isCompanyMemberAllowed: true,
				permissions: [AccountPermission.COMM_HISTORY_VIEW],
			},
		},
	},
};

export default PagePermissions;
