import * as React from 'react';
import type { WrappedFieldProps } from 'redux-form';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';

import TimeSheetEntryWorkType, { TimeSheetEntryWorkTypeLabel, TimeSheetWorkTypeDisplayInfo } from 'acceligent-shared/enums/timeSheetEntryWorkType';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import LockedValue from 'af-components/LockedValue';
import Tooltip from 'af-components/Tooltip';
import Label from 'af-components/Label';

import Dropdown from 'af-fields/Dropdown';

import TimeIntervalDropdown from '../../../../Shared/TimeIntervalDropdown';
import type { TimeAllocationEntryFormModel } from '../formModel';
import styles from '../styles.module.scss';
import { TimeAllocationEntryAction } from '../TimeSheetEditModalBody';

interface OwnProps {
	index: number;
	readonly: boolean;
	subJobs: SubjobVM[];
	entry: TimeAllocationEntryFormModel;
	change: (fieldName: string, value) => void;
	removeEntry: (index: number) => void;
	changeEntries: (action: TimeAllocationEntryAction, entryIndex: Nullable<number>) => void;
}

interface TimeSheetEntryTypeOption {
	id: TimeSheetEntryWorkType;
	label?: string;
}

const timeSheetEntryTypeOptions: TimeSheetEntryTypeOption[] = Object.keys(TimeSheetEntryWorkType)
	.map((_currType: TimeSheetEntryWorkType) => ({ id: _currType, label: TimeSheetEntryWorkTypeLabel[_currType] }));

type Props = OwnProps & WrappedFieldProps;

const TimeAllocationEntry: React.FC<Props> = (props: Props) => {

	const {
		index,
		removeEntry,
		readonly,
		input,
		change,
		entry,
		subJobs,
		changeEntries,
	} = props;

	const [icon, setIcon] = React.useState<Nullable<TimeSheetEntryWorkType>>(entry.workType);

	React.useEffect(() => {
		setIcon(entry.workType);
	}, [entry]);

	const workTypeIcon = React.useMemo(() => icon ? TimeSheetWorkTypeDisplayInfo[icon] : null, [icon]);

	const workTypeName = React.useMemo(() => `${input.name}.workType`, [input.name]);
	const durationName = React.useMemo(() => `${input.name}.duration`, [input.name]);
	const workRequestName = React.useMemo(() => `${input.name}.allocatedWorkRequestId`, [input.name]);

	const onFieldChange = React.useCallback((fieldName: string) => {
		return (value) => {
			if (fieldName === workRequestName) {
				value = value.id;
			}
			change(fieldName, value);
			changeEntries(TimeAllocationEntryAction.FIELD_CHANGED, index);
		};
	}, [change, changeEntries, index, workRequestName]);

	const onWorkTypeChange = React.useCallback((entryWorkType: Nullable<TimeSheetEntryTypeOption>) => {
		if (entryWorkType?.id) {
			setIcon(entryWorkType.id);
		}
	}, []);

	const onDeleteEntry = React.useCallback(() => {
		removeEntry(index);
	}, [removeEntry, index]);

	const renderWorkRequestOption = React.useCallback((option: SubjobVM) => {
		const labelText = option.isProject ? 'P' : 'S';
		const classname = option.isProject ? '' : 'subjob';
		return (
			<>
				<Label
					className={classname}
					text={labelText}
				/>
				{option.jobCode}
			</>);
	}, []);

	const selectedWorkTypeValue = React.useMemo(() => timeSheetEntryTypeOptions.find((_e) => _e.id === entry.workType)?.label, [entry.workType]);
	const timeValue = React.useMemo(() => TimeUtils.minutesToHoursAndMinutes(entry.duration), [entry.duration]);
	const allocatedJobCode = React.useMemo(
		() => subJobs.find((_e) => _e.id === entry.allocatedWorkRequestId)?.jobCode,
		[entry.allocatedWorkRequestId, subJobs]
	);

	if (readonly) {
		return (
			<div className={styles['time-allocations__time-allocation-list__row']} key={`time-allocation-${entry.virtualId}`}>
				<Row>
					<Col sm={1}>
						{workTypeIcon && <span className={`${styles['time-allocations__time-allocation-list__row__work-type-icon']} icon-${workTypeIcon.icon}`} />}
					</Col>
					<Col sm={5}>
						<LockedValue label="WorkType" value={selectedWorkTypeValue} />
					</Col>
					<Col md={5}>
						<LockedValue label="Time" value={timeValue} />
					</Col>
					<Col md={7}>
						<LockedValue label="Allocated to sub-job" value={allocatedJobCode} />
					</Col>
				</Row>
			</div>
		);
	}

	return (
		<div className={styles['time-allocations__time-allocation-list__row']} key={`time-allocation-${entry.virtualId}`}>
			<Row>
				<Col sm={1}>
					{workTypeIcon && <span className={`${styles['time-allocations__time-allocation-list__row__work-type-icon']} icon-${workTypeIcon.icon}`} />}
				</Col>
				<Col sm={5}>
					<Field
						className={styles['time-allocations__time-allocation-list__row__work-type-icon__input-field']}
						component={Dropdown}
						id={workTypeName}
						isStandalone={true}
						labelKey="label"
						name={workTypeName}
						onValueChange={onWorkTypeChange}
						options={timeSheetEntryTypeOptions}
						placeholder="Work Type"
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col sm={5}>
					<TimeIntervalDropdown
						fixed={false}
						name={durationName}
						onChange={onFieldChange(durationName)}
					/>
				</Col>
				<Col className={styles['time-allocations__time-allocation-list__row__text-allocate-to']} sm={3}>
					<span > allocate to </span>
				</Col>
				<Col sm={9}>
					<Field
						component={Dropdown}
						id={workRequestName}
						labelKey="jobCode"
						name={workRequestName}
						onChange={onFieldChange(workRequestName)}
						options={subJobs}
						renderMenuItem={renderWorkRequestOption}
						renderSelected={renderWorkRequestOption}
						value={entry.allocatedWorkRequestId}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col sm={1}>
					<div className={styles['time-allocations__time-allocation-list__row__delete-button']}>
						<Tooltip message="Delete">
							<span className="icon-delete" onClick={onDeleteEntry}></span>
						</Tooltip>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default TimeAllocationEntry;
