import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import type { RootState } from 'af-reducers';

import * as divisionActions from 'af-actions/division';
import * as employeeActions from 'af-actions/employee';
import * as jobStatusActions from 'af-actions/jobStatus';
import * as locationActions from 'af-actions/location';
import * as saleActions from 'af-actions/sale';

import SalesSection from './Sections/SalesSection';
import JobSummarySection from './Sections/JobSummarySection';
import JobDetailSection from './Sections/JobDetailSection';
import RequierementsSection from './Sections/RequierementsSection';
import ContactsSection from './Sections/Contacts';
import ScheduleInfo from './Sections/ScheduleInfo';
import DeliverableSection from './Sections/DeliverableSection';
import WorkLocationSection from './Sections/WorkLocation';
import SurveyDataSection from './Sections/SurveyDataSection';
import SummaryOfWorkSection from './Sections/SummaryOfWorkSection';
import DisposalSection from './Sections/DisposalSection';
import AttachmentsSection from './Sections/AttachementsSection';
import TrucksAndEquipmentSection from './Sections/TrucksAndEquipment';

import styles from '../styles.module.scss';
import CustomerSignatureAndBillingCodes from './Sections/CustomerSignatureAndBillingCodes';
import ProjectSection from './Sections/ProjectSection';
import type { SubjobFM } from '../formModel';

type OwnProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	workRequestId?: number;
	formName: string;
	isProject: boolean;
	projectId: Nullable<number>;
	redirectOnSubmit: boolean;
	orgAlias: string;
	subjobs: SubjobFM[];
	availableSubjobs: SubjobFM[];
	onTurnToProjectChange: (value: boolean) => void;
	handleSubjobSelect: (option: Nullable<SubjobFM>) => void;
	setSubjobSearchText: (text: string) => void;
	goToJobForm: () => void;
	selectedSubjob: Nullable<SubjobFM>;
	openAddSubjobConfirmationModal: () => void;
	turnToProjectDisabled: boolean;
	disableTurnToProject: () => void;
	subjobSearchText: string;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const DetailsFormTab: React.FC<Props> = (props) => {
	const {
		findSales,
		findJobStatuses,
		findAllProjectManagers,
		findAllLocations,
		findAllDivisions,
		findAllSuperintendentsAndProjectManagers,
		change,
		workRequestId,
		formName,
		hasContactPermission,
		onlyDeliverableAllowed,
		isProject,
		projectId,
		redirectOnSubmit,
		orgAlias,
		subjobs,
		availableSubjobs,
		onTurnToProjectChange,
		handleSubjobSelect,
		setSubjobSearchText,
		goToJobForm,
		selectedSubjob,
		openAddSubjobConfirmationModal,
		turnToProjectDisabled,
		disableTurnToProject,
		subjobSearchText,
	} = props;

	const salesRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (salesRef.current) {
			salesRef.current.scrollIntoView({ block: 'end' });
		}
	}, []);

	return (
		<div className={styles['job-form__tab-content']} >
			<SalesSection findSales={findSales} isDisabled={onlyDeliverableAllowed} salesRef={salesRef} />
			<JobSummarySection
				findAllDivisions={findAllDivisions}
				findAllLocations={findAllLocations}
				findAllProjectManagers={findAllProjectManagers}
				findJobStatuses={findJobStatuses}
				isDisabled={onlyDeliverableAllowed}
			/>
			<JobDetailSection isDisabled={onlyDeliverableAllowed} />
			<RequierementsSection isDisabled={onlyDeliverableAllowed} />
			<ScheduleInfo change={change} formName={formName} isDisabled={onlyDeliverableAllowed} />
			{hasContactPermission && <ContactsSection formName={formName} isDisabled={onlyDeliverableAllowed} />}
			<DeliverableSection change={change} formName={formName} />
			<WorkLocationSection formName={formName} isDisabled={onlyDeliverableAllowed} />
			<SummaryOfWorkSection isDisabled={onlyDeliverableAllowed} />
			<SurveyDataSection findAllSuperintendentsAndProjectManagers={findAllSuperintendentsAndProjectManagers} isDisabled={onlyDeliverableAllowed} />
			<TrucksAndEquipmentSection />
			<DisposalSection change={change} formName={formName} isDisabled={onlyDeliverableAllowed} />
			<ProjectSection
				availableSubjobs={availableSubjobs}
				disableTurnToProject={disableTurnToProject}
				formName={formName}
				goToJobForm={goToJobForm}
				handleSubjobSelect={handleSubjobSelect}
				isProject={isProject}
				onTurnToProjectChange={onTurnToProjectChange}
				openAddSubjobConfirmationModal={openAddSubjobConfirmationModal}
				orgAlias={orgAlias}
				projectId={projectId}
				redirectOnSubmit={redirectOnSubmit}
				selectedSubjob={selectedSubjob}
				setSubjobSearchText={setSubjobSearchText}
				subjobs={subjobs}
				subjobSearchText={subjobSearchText}
				turnToProjectDisabled={turnToProjectDisabled}
				workRequestId={workRequestId}
			/>
			<CustomerSignatureAndBillingCodes change={change} formName={formName} isDisabled={onlyDeliverableAllowed} />
			<AttachmentsSection change={change} workRequestId={workRequestId} />
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasCreatePermissions = isAllowed(PagePermissions.COMPANY.JOBS.CREATE, companyData.permissions, companyData.isCompanyAdmin, userData.role);
	const hasEditPermissions = isAllowed(PagePermissions.COMPANY.JOBS.EDIT, companyData.permissions, companyData.isCompanyAdmin, userData.role);
	const hasContactPermission = isAllowed(PagePermissions.COMPANY.CONTACTS, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	return {
		onlyDeliverableAllowed: hasEditPermissions && !hasCreatePermissions,
		hasContactPermission,
	};
}

function mapDispatchToProps() {

	return {
		findSales: saleActions.findAllForForm,
		findJobStatuses: jobStatusActions.findList,
		findAllProjectManagers: employeeActions.findAllProjectManagers,
		findAllLocations: locationActions.findList,
		findAllDivisions: divisionActions.findAllForCompany,
		findAllSuperintendentsAndProjectManagers: employeeActions.findAllSuperintendentsAndProjectManagers,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(DetailsFormTab);
