import * as React from 'react';
import { Link } from 'react-router-dom';

import { AdditionalColors, ColorPalette, ExtendedColorPalette } from 'acceligent-shared/enums/color';
import type UserPermission from 'acceligent-shared/enums/userPermission';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import SkillsColorGrid from 'af-components/SkillsColorGrid';
import LabelWithColor from 'af-components/LabelWithColor';
import SegmentLabel from 'af-components/SegmentLabel';

import { isAllowed } from 'ab-utils/auth.util';
import * as ColorUtil from 'ab-utils/color.util';

import CLIENT from 'af-routes/client';

import MultiAssignmentsBadge from '../MultiAssignmentsBadge';
import DownEmployeeBadge from '../DownEmployeeBadge';
import EmployeeDraggableBadgesContainer from '../EmployeeDraggable/BadgesContainer';
import NightShiftBadge from '../NightShiftBadge';

interface OwnProps {
	orgAlias: string;
	companyName: string;
	isAdmin: boolean;
	accountPermissions: string[];
	userPermission: UserPermission;
}

type Props = OwnProps;

interface State {
	isNotificationStatusHovered: boolean;
	notificationStatusColor: ExtendedColorPalette;
	isNameHovered: boolean;
	isSkillGridHovered: boolean;
	isSiHovered: boolean;
	isPmHovered: boolean;
	isSiPmHovered: boolean;
	isCDLAHovered: boolean;
	isCDLBHovered: boolean;
	isNoCDLHovered: boolean;
	isPerDiemHovered: boolean;
	isDownEmployeeHovered: boolean;
	isMultiAssignmentsHovered: boolean;
	isNightShiftHovered: boolean;
	isWageRateHovered: boolean;
	isTemporaryHovered: boolean;
}

class EmployeeCardLegend extends React.PureComponent<Props, State> {

	state: State = {
		isNotificationStatusHovered: false,
		notificationStatusColor: ColorPalette.GREEN,
		isNameHovered: false,
		isSkillGridHovered: false,
		isSiHovered: false,
		isPmHovered: false,
		isSiPmHovered: false,
		isCDLAHovered: false,
		isCDLBHovered: false,
		isNoCDLHovered: false,
		isPerDiemHovered: false,
		isDownEmployeeHovered: false,
		isMultiAssignmentsHovered: false,
		isNightShiftHovered: false,
		isWageRateHovered: false,
		isTemporaryHovered: false,
	};

	onNotificationStatusHover = (isHovered: boolean, color: ExtendedColorPalette) => {
		this.setState(() => ({ isNotificationStatusHovered: isHovered, notificationStatusColor: color || ColorPalette.GREEN }));
	};

	renderNotificationStatus = () => {
		const { isNotificationStatusHovered, notificationStatusColor } = this.state;

		let className = 'icon-notification_status';
		className = isNotificationStatusHovered ? `${className} legend-modal__hovered-partial` : className;
		className = `${className} ${ColorUtil.getColorTextClass(notificationStatusColor || ColorPalette.GREEN)}`;

		return (
			<>
				<div className="icon-notification_status icon-notification_status--border" />
				<div className={className} />
			</>
		);
	};

	onNameHover = (isHovered: boolean) => {
		this.setState(() => ({ isNameHovered: isHovered }));
	};

	renderName = () => {
		const { isNameHovered } = this.state;

		let className = 'legend-modal__resource-card-label legend-modal__resource-card-label--employee';
		className = isNameHovered ? `${className} legend-modal__hovered-partial` : className;

		return (
			<span className={className}>
				J Doe
			</span>
		);
	};

	onWageRateHover = (isHovered: boolean) => {
		this.setState(() => ({ isWageRateHovered: isHovered }));
	};

	renderWageRate = () => {
		const { isWageRateHovered } = this.state;

		let className = 'legend-modal__resource-card-label';
		className = isWageRateHovered ? `${className} legend-modal__hovered-partial` : className;

		return (
			<span className={className}>
				Mechanic
			</span>
		);
	};

	onSkillGridHover = (isHovered: boolean) => {
		this.setState(() => ({ isSkillGridHovered: isHovered }));
	};

	onSiHover = (isHovered: boolean) => {
		this.setState(() => ({ isSiHovered: isHovered }));
	};

	onSiPmHover = (isHovered: boolean) => {
		this.setState(() => ({ isSiPmHovered: isHovered }));
	};

	onPmHover = (isHovered: boolean) => {
		this.setState(() => ({ isPmHovered: isHovered }));
	};

	renderSkillGridCard = () => {
		const {
			isSkillGridHovered,
			isCDLAHovered,
			isCDLBHovered,
			isNoCDLHovered,
			isSiHovered,
			isPmHovered,
			isSiPmHovered,
		} = this.state;

		const isHighlighted = isSkillGridHovered || isCDLAHovered || isSiHovered || isSiPmHovered || isPmHovered;

		const props = isCDLAHovered || isCDLBHovered || isNoCDLHovered
			? {
				enableCDLIndicator: true,
				skills: !!isCDLAHovered ? [{ color: ExtendedColorPalette.BLACK }] : [],
			}
			: { showAll: true };

		return (
			<span className={isHighlighted ? 'legend-modal__hovered-partial' : undefined}>
				{isSiHovered &&
					<span className="icon-helmet_s">
						<span className="path1" />
						<span className="path2" />
						<span className="path3" />
						<span className="path4" />
						<span className="path5" />
					</span>
				}
				{isPmHovered &&
					<span className="icon-helmet_pm">
						<span className="path1" />
						<span className="path2" />
						<span className="path3" />
						<span className="path4" />
						<span className="path5" />
					</span>
				}
				{isSiPmHovered &&
					<span className="icon-helmet_si_pm">
						<span className="path1" />
						<span className="path2" />
						<span className="path3" />
						<span className="path4" />
						<span className="path5" />
						<span className="path6" />
					</span>
				}
				{!isPmHovered && !isSiPmHovered && !isSiHovered && <SkillsColorGrid {...props} forceCdlIndicator={isCDLAHovered} />}
			</span>
		);
	};

	renderSkillGridPlaceholder = () => {
		const { isSkillGridHovered } = this.state;

		return (
			<span className={isSkillGridHovered ? 'legend-modal__hovered-partial' : undefined}>
				<SkillsColorGrid showAll={true} />
			</span>
		);
	};

	onCDLAHover = (isHovered: boolean) => {
		this.setState(() => ({ isCDLAHovered: isHovered }));
	};

	onCDLBHover = (isHovered: boolean) => {
		this.setState(() => ({ isCDLBHovered: isHovered }));
	};

	onNoCDLHover = (isHovered: boolean) => {
		this.setState(() => ({ isNoCDLHovered: isHovered }));
	};

	onPerDiemHover = (isHovered: boolean) => {
		this.setState(() => ({ isPerDiemHovered: isHovered }));
	};

	onDownEmployeeHover = (isHovered: boolean) => {
		this.setState(() => ({ isDownEmployeeHovered: isHovered }));
	};

	renderPerDiem = () => {
		const { isPerDiemHovered } = this.state;

		let className = 'legend-modal__per-diem';
		className = isPerDiemHovered ? `${className} legend-modal__hovered-partial` : className;

		return (
			<span className={className} />
		);
	};

	onMultiAssignmentsHover = (isHovered: boolean) => this.setState(() => ({ isMultiAssignmentsHovered: isHovered }));

	onNightShiftHover = (isHovered: boolean) => this.setState(() => ({ isNightShiftHovered: isHovered }));

	onTemporaryHover = (isHovered: boolean) => this.setState(() => ({ isTemporaryHovered: isHovered }));

	renderTemporaryEmployee = () => {
		const { isTemporaryHovered } = this.state;

		return (
			<span className={isTemporaryHovered ? 'legend-modal__hovered-partial' : ''}>
				<span className="icon-temp_labor" />
				{this.renderName()}
			</span>
		);
	};

	renderBadges = () => {
		const {
			isMultiAssignmentsHovered,
			isNotificationStatusHovered,
			isNameHovered,
			isNightShiftHovered,
			isSkillGridHovered,
			isCDLAHovered,
			isCDLBHovered,
			isPerDiemHovered,
			isDownEmployeeHovered,
			isSiHovered,
			isPmHovered,
			isSiPmHovered,
			isWageRateHovered,
			isTemporaryHovered,
		} = this.state;

		if (isDownEmployeeHovered) {
			return <DownEmployeeBadge />;
		}

		const isAnythingHovered =
			isNotificationStatusHovered
			|| isMultiAssignmentsHovered
			|| isNameHovered
			|| isNightShiftHovered
			|| isSkillGridHovered
			|| isCDLAHovered
			|| isCDLBHovered
			|| isPerDiemHovered
			|| isDownEmployeeHovered
			|| isSiHovered
			|| isPmHovered
			|| isSiPmHovered
			|| isWageRateHovered
			|| isTemporaryHovered;

		return (
			<EmployeeDraggableBadgesContainer>
				<MultiAssignmentsBadge count={2} disableAutoPositioning isOpaque={isAnythingHovered && !isMultiAssignmentsHovered} />
				<NightShiftBadge isOpaque={isAnythingHovered && !isNightShiftHovered} />
			</EmployeeDraggableBadgesContainer>
		);
	};

	render() {
		const {
			orgAlias,
			companyName,
			accountPermissions,
			isAdmin,
			userPermission,
		} = this.props;
		const {
			isNotificationStatusHovered,
			isNameHovered,
			isNightShiftHovered,
			isSkillGridHovered,
			isCDLAHovered,
			isCDLBHovered,
			isNoCDLHovered,
			isPerDiemHovered,
			isDownEmployeeHovered,
			isMultiAssignmentsHovered,
			isSiHovered,
			isPmHovered,
			isSiPmHovered,
			isWageRateHovered,
			isTemporaryHovered,
		} = this.state;

		const isAnythingHoveredCard =
			isNotificationStatusHovered
			|| isNameHovered
			|| isNightShiftHovered
			|| isSkillGridHovered
			|| isCDLAHovered
			|| isCDLBHovered
			|| isPerDiemHovered
			|| isDownEmployeeHovered
			|| isMultiAssignmentsHovered
			|| isSiHovered
			|| isPmHovered
			|| isSiPmHovered
			|| isWageRateHovered
			|| isTemporaryHovered;

		const isAnythingHoveredPlaceholder =
			isNotificationStatusHovered
			|| isNameHovered
			|| isNightShiftHovered
			|| isSkillGridHovered
			|| isNoCDLHovered
			|| isCDLAHovered
			|| isCDLBHovered
			|| isPerDiemHovered
			|| isDownEmployeeHovered
			|| isMultiAssignmentsHovered
			|| isSiHovered
			|| isPmHovered
			|| isSiPmHovered
			|| isWageRateHovered
			|| isTemporaryHovered;

		let resourceCardClass = 'legend-modal__resource-card legend-modal__resource-card--employee';
		resourceCardClass = isCDLAHovered || isCDLBHovered ? `${resourceCardClass} legend-modal__resource-card--with-cdl` : resourceCardClass;
		resourceCardClass = isNoCDLHovered ? `${resourceCardClass} legend-modal__hovered-partial` : resourceCardClass;

		const resourcePlaceholderCardClass = 'legend-modal__resource-card legend-modal__resource-card--placeholder';
		const temporaryEmployeeCardClass = 'legend-modal__resource-card';

		const isAllowedToChangeLabor = isAllowed(PagePermissions.COMPANY.RESOURCES.EMPLOYEE, accountPermissions, isAdmin, userPermission);
		const isAllowedToChangeOffice = isAllowed(PagePermissions.COMPANY.RESOURCES.LOCATION, accountPermissions, isAdmin, userPermission);
		const isAllowedToChangeNotifications = isAllowed(PagePermissions.COMPANY.SETTINGS.NOTIFICATIONS, accountPermissions, isAdmin, userPermission);
		const isAllowedToChangeSbSettings = isAllowed(PagePermissions.COMPANY.SETTINGS.SCHEDULE_BOARD, accountPermissions, isAdmin, userPermission);
		const isAllowedToChangeSkills = isAllowed(PagePermissions.COMPANY.RESOURCES.SKILL, accountPermissions, isAdmin, userPermission);
		const isAllowedToChangeWageRate = isAllowed(PagePermissions.COMPANY.RESOURCES.WAGE_RATE, accountPermissions, isAdmin, userPermission);

		return (
			<div className="legend-modal__segment">
				<SegmentLabel label="Employee Card" />
				<div className="legend-modal__segment-content">
					<div className="legend-modal__resource-card-container">
						<div className={resourceCardClass}>
							{isAnythingHoveredCard && <div className="legend-modal__resource-card-curtain" />}
							{this.renderNotificationStatus()}
							{this.renderName()}
							{this.renderSkillGridCard()}
							{this.renderPerDiem()}
							{this.renderBadges()}
						</div>
						<div className={resourcePlaceholderCardClass}>
							{isAnythingHoveredPlaceholder && <div className="legend-modal__resource-card-curtain" />}
							{this.renderWageRate()}
							{this.renderSkillGridPlaceholder()}
						</div>
						<div className={temporaryEmployeeCardClass}>
							{isAnythingHoveredCard && <div className="legend-modal__resource-card-curtain" />}
							{this.renderNotificationStatus()}
							{this.renderTemporaryEmployee()}
						</div>
					</div>
					<div className="legend-modal__description-container">
						<div
							onMouseEnter={this.onNameHover.bind(this, true)}
							onMouseLeave={this.onNameHover.bind(this, false)}
						>
							<div className="legend-modal__description">
								Employee's name
								{isAllowedToChangeLabor &&
									<div className="legend-modal__description legend-modal__description--details">
										Labor
										<Link
											className="btn--control"
											to={CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST(orgAlias, companyName)}
										>
											<span className="icon-settings" />
										</Link>
									</div>
								}
							</div>
							<div className="legend-modal__description">
								Office
								{isAllowedToChangeOffice &&
									<div className="legend-modal__description legend-modal__description--details">
										Offices
										<Link
											className="btn--control"
											to={CLIENT.COMPANY.RESOURCES.LOCATION.LIST(orgAlias, companyName)}
										>
											<span className="icon-settings" />
										</Link>
									</div>
								}
							</div>
							<div className="legend-modal__description legend-modal__description--additional">
								Indicated by the color of text used for Employee's name
							</div>
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onNotificationStatusHover.bind(this, true, null)}
							onMouseLeave={this.onNotificationStatusHover.bind(this, false, null)}
						>
							Notification status
							{isAllowedToChangeNotifications &&
								<div className="legend-modal__description legend-modal__description--details">
									Notifications
									<Link
										className="btn--control"
										to={CLIENT.COMPANY.SETTINGS.NOTIFICATION(orgAlias, companyName)}
									>
										<span className="icon-settings" />
									</Link>
								</div>
							}
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onNotificationStatusHover.bind(this, true, ColorPalette.GREEN)}
							onMouseLeave={this.onNotificationStatusHover.bind(this, false, null)}
						>
							<LabelWithColor color={ColorPalette.GREEN} text="Read" />
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onNotificationStatusHover.bind(this, true, ColorPalette.ORANGE)}
							onMouseLeave={this.onNotificationStatusHover.bind(this, false, null)}
						>
							<LabelWithColor color={ColorPalette.ORANGE} text="Delivered" />
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onNotificationStatusHover.bind(this, true, ColorPalette.RED)}
							onMouseLeave={this.onNotificationStatusHover.bind(this, false, null)}
						>
							<LabelWithColor color={ColorPalette.RED} text="Failed" />
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onNotificationStatusHover.bind(this, true, AdditionalColors.GREY)}
							onMouseLeave={this.onNotificationStatusHover.bind(this, false, null)}
						>
							<LabelWithColor color={null} text="Sent in previous revision" />
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onSkillGridHover.bind(this, true)}
							onMouseLeave={this.onSkillGridHover.bind(this, false)}
						>
							Skills
							{isAllowedToChangeSbSettings &&
								<div className="legend-modal__description legend-modal__description--details">
									Visibility
									<Link
										className="btn--control"
										to={CLIENT.COMPANY.SETTINGS.SCHEDULE_BOARD(orgAlias, companyName)}
									>
										<span className="icon-settings" />
									</Link>
								</div>
							}
							{isAllowedToChangeSkills &&
								<div className="legend-modal__description legend-modal__description--details">
									Skills
									<Link
										className="btn--control"
										to={CLIENT.COMPANY.RESOURCES.SKILL.LIST(orgAlias, companyName)}
									>
										<span className="icon-settings" />
									</Link>
								</div>
							}
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onSiPmHover.bind(this, true)}
							onMouseLeave={this.onSiPmHover.bind(this, false)}
						>
							Type of employee
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onSiHover.bind(this, true)}
							onMouseLeave={this.onSiHover.bind(this, false)}
						>
							<span className="icon-helmet_s">
								<span className="path1" />
								<span className="path2" />
								<span className="path3" />
								<span className="path4" />
								<span className="path5" />
							</span>
							Superintendent
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onPmHover.bind(this, true)}
							onMouseLeave={this.onPmHover.bind(this, false)}
						>
							<span className="icon-helmet_pm">
								<span className="path1" />
								<span className="path2" />
								<span className="path3" />
								<span className="path4" />
								<span className="path5" />
							</span>
							Project Manager
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onSiPmHover.bind(this, true)}
							onMouseLeave={this.onSiPmHover.bind(this, false)}
						>
							<span className="icon-helmet_si_pm">
								<span className="path1" />
								<span className="path2" />
								<span className="path3" />
								<span className="path4" />
								<span className="path5" />
								<span className="path6" />
							</span>
							Superintendent and Project Manager
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onNoCDLHover.bind(this, true)}
							onMouseLeave={this.onNoCDLHover.bind(this, false)}
						>
							CDL status
							{isAllowedToChangeSbSettings &&
								<div className="legend-modal__description legend-modal__description--details">
									Visibility
									<Link
										className="btn--control"
										to={CLIENT.COMPANY.SETTINGS.SCHEDULE_BOARD(orgAlias, companyName)}
									>
										<span className="icon-settings" />
									</Link>
								</div>
							}
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onNoCDLHover.bind(this, true)}
							onMouseLeave={this.onNoCDLHover.bind(this, false)}
						>
							<LabelWithColor color={ColorPalette.ORANGE} text="No CDL" />
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onCDLAHover.bind(this, true)}
							onMouseLeave={this.onCDLAHover.bind(this, false)}
						>
							<LabelWithColor color={AdditionalColors.BLACK} text="CDL A" />
						</div>
						<div
							className="legend-modal__description legend-modal__description--additional"
							onMouseEnter={this.onCDLBHover.bind(this, true)}
							onMouseLeave={this.onCDLBHover.bind(this, false)}
						>
							<LabelWithColor color={null} text="CDL B" />
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onPerDiemHover.bind(this, true)}
							onMouseLeave={this.onPerDiemHover.bind(this, false)}
						>
							Per diem
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onDownEmployeeHover.bind(this, true)}
							onMouseLeave={this.onDownEmployeeHover.bind(this, false)}
						>
							Unavailability
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onMultiAssignmentsHover.bind(this, true)}
							onMouseLeave={this.onMultiAssignmentsHover.bind(this, false)}
						>
							Daily assignments count
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onNightShiftHover.bind(this, true)}
							onMouseLeave={this.onNightShiftHover.bind(this, false)}
						>
							Night Shift
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onWageRateHover.bind(this, true)}
							onMouseLeave={this.onWageRateHover.bind(this, false)}
						>
							Wage Rate
							{isAllowedToChangeWageRate &&
								<div className="legend-modal__description legend-modal__description--details">
									Wage Rates
									<Link
										className="btn--control"
										to={CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST(orgAlias, companyName)}
									>
										<span className="icon-settings" />
									</Link>
								</div>
							}
						</div>
						<div
							className="legend-modal__description"
							onMouseEnter={this.onTemporaryHover.bind(this, true)}
							onMouseLeave={this.onTemporaryHover.bind(this, false)}
						>
							Temporary Employee
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EmployeeCardLegend;
