import * as Err from 'restify-errors';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import InvoiceBillingLookupType from 'acceligent-shared/enums/invoiceBillingLookupType';
import InvoiceStatus from 'acceligent-shared/enums/invoiceStatus';
import type FileType from 'acceligent-shared/enums/fileType';
import type ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import { type PhoneTypes, EmailTypes, EmailTypesArray } from 'acceligent-shared/enums/contactMethodType';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import * as TimeUtils from 'acceligent-shared/utils/time';
import * as UserUtils from 'acceligent-shared/utils/user';
import * as BlobStorageUtil from 'acceligent-shared/utils/blobStorage';
import { filterContactMethod } from 'acceligent-shared/utils/contact';

import type ContactLookupBase from 'ab-domain/models/contactLookup/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type InvoiceBase from 'ab-domain/models/invoice/base';
import type InstallmentBase from 'ab-domain/models/installment/base';

import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';
import type AttachmentBase from 'ab-domain/models/attachment/base';
import type AccountBase from 'ab-domain/models/account/base';

import * as BlobStorageUtilLocal from 'ab-utils/blobStorage.util'; // TODO: move everything to shared

class ContactMethodVM {
	id: number;
	contactId: number;
	type: PhoneTypes | EmailTypes;
	value: string;

	constructor(contactMethod: ContactMethodBase) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
	}

	static toList(contactMethods: ContactMethodBase[], type: typeof EmailTypes | typeof PhoneTypes): ContactMethodVM[] {
		return contactMethods
			.filter((_method) => _method && Object.keys(type).includes(_method.type))
			.map((_method) => new ContactMethodVM(_method));
	}
}

class ContactVM {
	id: number;
	contactId: number;
	title: Nullable<string>;
	fullName: string;
	companyName: Nullable<string>;
	emails: ContactMethodVM[];
	contactEmailIds: number[];

	constructor(_contactLookup: ContactLookupBase) {
		const _contact = _contactLookup?.contact;

		this.id = _contactLookup.id;
		this.contactId = _contact.id;
		this.title = _contact?.title;
		this.fullName = _contact?.fullName;
		this.companyName = _contact?.companyName;

		this.emails = ContactMethodVM.toList(_contact?.contactMethods ?? [], EmailTypes);

		this.contactEmailIds = filterContactMethod(_contactLookup.contactLookupMethods, EmailTypesArray);
	}

	static bulkConstructor = (_contactLookups: ContactLookupBase[]) => _contactLookups.map((__contactLookup) => new ContactVM(__contactLookup));
}

type BillingEmail = {
	type: InvoiceBillingLookupType.EMAIL_ONLY;
	email: string;
};

type BillingContact = {
	contact: ContactVM;
	type: InvoiceBillingLookupType.CONTACT;
};

type InvoiceBillingContactVM = { id: number; } & (BillingEmail | BillingContact);

export class W_Accounting_FindInvoicesTable_VM extends TableContent<W_Accounting_FindInvoicesTable_VM_Row>{
	constructor(invoices: InvoiceBase[], pages: number, totalCount: number) {
		super(
			W_Accounting_FindInvoicesTable_VM_Row.bulkConstructor(invoices),
			pages,
			totalCount
		);
	}
}

class W_Accounting_FindInvoicesTable_VM_Row {
	id: number;
	workRequestId: number;
	jobCode: string;
	invoiceCode: string;
	status: InvoiceStatusDisplay;
	totalAmount: number;
	paidAmount: number;
	outstandingDebt: number;
	retainedAmount: number;
	percentagePaid: number;
	dateCreated: string;
	invoicingDate: Nullable<string>;
	dueDate: string;
	note: Nullable<string>;
	billingContacts: InvoiceBillingContactVM[];
	sendReminderOnInvoice: boolean;
	excludeFromAutomaticReminders: boolean;
	installments: W_Accounting_FindInvoicesTable_VM_Installment[];
	lastInstallmentDate: Nullable<Date>;
	attachments: W_Accounting_FindInvoicesTable_VM_Attachment[];
	uploadedAttachmentIds: number[];

	constructor(invoice: InvoiceBase) {
		const _totalAmount = parseFloat(invoice.totalAmount);
		const _paidAmount = W_Accounting_FindInvoicesTable_VM_Row._resolvePaidAmount(invoice.installments);

		this.id = invoice.id;
		this.invoiceCode = invoice.invoiceCode;

		if (invoice.workRequest?.jobCode) {
			this.jobCode = invoice.workRequest?.jobCode;
		} else {
			throw new Err.NotFoundError('Job code and jobCustomName not found');
		}

		this.workRequestId = invoice.workRequestId;
		this.status = W_Accounting_FindInvoicesTable_VM_Row._resolveStatusForDisplay(invoice.status, _totalAmount, _paidAmount);
		this.totalAmount = _totalAmount;
		this.paidAmount = _paidAmount;
		this.outstandingDebt = W_Accounting_FindInvoicesTable_VM_Row._resolveOutstandingDebt(_totalAmount, _paidAmount);
		this.retainedAmount = invoice.retainedAmount ? parseFloat(invoice.retainedAmount) : 0;
		this.percentagePaid = +(Math.min(_paidAmount / _totalAmount * 100, 100)).toFixed(2);
		this.dateCreated = invoice.dateCreated;
		this.invoicingDate = invoice.invoicingDate;
		this.dueDate = invoice.dueDate;
		this.note = invoice.note;
		this.sendReminderOnInvoice = invoice.sendReminderOnInvoice;
		this.excludeFromAutomaticReminders = invoice.excludeFromAutomaticReminders;
		this.billingContacts = invoice.invoiceBillingLookups?.map((_lookup) => {
			switch (_lookup.type) {
				case InvoiceBillingLookupType.EMAIL_ONLY: {
					return {
						id: _lookup.id,
						type: InvoiceBillingLookupType.EMAIL_ONLY,
						email: _lookup.email!,
					};
				}
				case InvoiceBillingLookupType.CONTACT: {
					return {
						id: _lookup.id,
						type: InvoiceBillingLookupType.CONTACT,
						contact: new ContactVM(_lookup.contactLookup!),
					};
				}
			}
		}) ?? [];
		this.installments = W_Accounting_FindInvoicesTable_VM_Installment.bulkConstructor(invoice.installments);
		this.lastInstallmentDate = W_Accounting_FindInvoicesTable_VM_Row._resolveLastInstallmentDate(invoice.installments);
		this.attachments = invoice.invoiceAttachmentLookups ?
			W_Accounting_FindInvoicesTable_VM_Attachment.bulkConstructor(invoice.invoiceAttachmentLookups.map((_lookup) => _lookup.attachment))
			: [];
		this.uploadedAttachmentIds = invoice.invoiceAttachmentLookups?.map((_lookup) => _lookup.attachmentId) ?? [];
	}

	static bulkConstructor = (invoices: InvoiceBase[]) => invoices.map((_in) => new W_Accounting_FindInvoicesTable_VM_Row(_in));

	private static _resolvePaidAmount(installments: InstallmentBase[]) {
		if (!installments) {
			return 0;
		}
		return installments.reduce((_sum, _inst) => {
			return _sum + parseFloat(_inst.amount);
		}, 0);
	}

	private static _resolveOutstandingDebt(totalAmount: number, paidAmount: number) {
		if (paidAmount >= totalAmount) {
			return 0;
		}
		return totalAmount - paidAmount;
	}

	private static _resolveStatusForDisplay(invoiceStatus: InvoiceStatus, totalAmount: number, paidByInstallments: number) {
		if (paidByInstallments === 0) {
			if (invoiceStatus === InvoiceStatus.DRAFT) {
				return InvoiceStatusDisplay.DRAFT;
			}
			return InvoiceStatusDisplay.INVOICED;
		}

		if (paidByInstallments < totalAmount) {
			return InvoiceStatusDisplay.PARTIALLY_PAID;
		} else if (paidByInstallments === totalAmount) {
			return InvoiceStatusDisplay.PAID;
		} else {
			return InvoiceStatusDisplay.OVERPAID;
		}
	}

	private static _resolveLastInstallmentDate(installments: InstallmentBase[]) {
		if (!installments) {
			return null;
		}
		const installmentDates = installments.map((_i) => TimeUtils.toUtcDate(_i.datePaid, TimeFormat.DB_DATE_ONLY));
		return new Date(Math.max.apply(null, installmentDates));
	}
}

class W_Accounting_FindInvoicesTable_VM_Installment {
	id: number;
	invoiceId: number;
	amount: string;
	/** YYYY-MM-DD */
	datePaid: string;
	note: Nullable<string>;
	createdAt: Date;

	constructor(installment: InstallmentBase) {
		this.id = installment.id;
		this.invoiceId = installment.invoiceId;
		this.amount = installment.amount;
		this.datePaid = installment.datePaid;
		this.note = installment.note;
		this.createdAt = installment.createdAt;
	}

	static bulkConstructor = (installments: InstallmentBase[]) => installments.map((_in) => new W_Accounting_FindInvoicesTable_VM_Installment(_in));
}

class W_Accounting_FindInvoicesTable_VM_UserInfo {
	accountId?: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;

	constructor(account: AccountBase) {
		this.accountId = account.id ?? undefined;
		this.userId = account.user.id;
		this.firstName = account.user.firstName;
		this.lastName = account.user.lastName;
		this.fullName = UserUtils.getUserName(account.user);
	}
}

class W_Accounting_FindInvoicesTable_VM_Attachment {
	id: number;
	size: number;
	name: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	lastModifiedAt: Date;
	uploadedBy: Nullable<W_Accounting_FindInvoicesTable_VM_UserInfo>;

	constructor(attachment: AttachmentBase) {
		this.id = attachment.id;
		this.size = attachment.size;
		this.name = attachment.name;
		this.type = attachment.type;
		this.status = attachment.status;
		this.storageName = attachment.storageName;
		this.storageContainer = attachment.storageContainer;
		this.lastModifiedAt = attachment.createdAt;

		const directories = BlobStorageUtil.parseDirectoryPath(this.storageContainer);

		this.originalSrc = BlobStorageUtilLocal.generatePresignedGetUrl(directories, this.storageName);

		const attUser = attachment.uploadedBy?.user;
		const uploadedObj = attachment.uploadedBy ? new W_Accounting_FindInvoicesTable_VM_UserInfo(attachment.uploadedBy) : null;

		this.uploadedBy = attUser ? uploadedObj : null;
	}

	private static _constructorMap = (attachment: AttachmentBase) => new W_Accounting_FindInvoicesTable_VM_Attachment(attachment);

	static bulkConstructor = (attachments: AttachmentBase[]) => attachments?.map(W_Accounting_FindInvoicesTable_VM_Attachment._constructorMap) ?? [];
}
