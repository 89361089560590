import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import FieldReportAccessRoleEnum from 'acceligent-shared/enums/fieldReportAccessRole';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import * as FieldReportActions from 'af-actions/fieldReport';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as WorkRequestActions from 'af-actions/workRequests';

import type { RootState } from 'af-reducers';

import type WorkOrderForReportsTableVM from 'ab-viewModels/workOrder/workOrderForReportsTable.viewModel';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type TableComponent from 'af-components/Table6/Table';
import type { TabProps, Column, CellInfo, ItemBlueprint, TableFinalState, RowInfo, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import AddressCell from 'af-components/Table6/Cells/AddressCell';
import ComparableDateCell from 'af-components/Table6/Cells/ComparableDateCell';
import DateFilter from 'af-components/DateFilter';
import LabelWithColor from 'af-components/LabelWithColor';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import DateCell from 'af-components/Table6/Cells/DateCell';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import Dropdown from 'af-components/Controls/Dropdown';
import type ScrollToLoad from 'af-components/ScrollToLoad';
import Tooltip from 'af-components/Tooltip';
import LoadingOverlay from 'af-components/LoadingOverlay';
import type { ActionSuccessModalProps } from 'af-components/Modals/ActionSuccessModal';
import ActionSuccessModal from 'af-components/Modals/ActionSuccessModal';
import type { ContactActionModalProps, ContactActionModalSubmitActionCallbackData } from 'af-components/Modals/ContactActionModal';
import ContactActionModal from 'af-components/Modals/ContactActionModal';
import ResourceTooltipList from 'af-components/Table6/Cells/ResourceCell/ResourceTooltipList';
import LinkCell from 'af-components/Table6/Cells/LinkCell';

import TableNameEnum from 'ab-enums/tableName.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import type { UserTypeFilterOption } from 'ab-enums/userTypeFilter.enum';
import type UserTypeFilter from 'ab-enums/userTypeFilter.enum';
import { getUserTypeFilterOptions } from 'ab-enums/userTypeFilter.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import * as SettingsKeys from 'af-constants/settingsKeys';
import CLIENT from 'af-constants/routes/client';

import * as SettingsUtils from 'af-utils/settings.util';
import { downloadCSV } from 'af-utils/csv.utils';
import { useToggle } from 'af-utils/react.util';
import { debounce } from 'af-utils/actions.util';

import { bemElement } from 'ab-utils/bem.util';
import { isAllowed } from 'ab-utils/auth.util';
import { phoneNormalizer } from 'ab-utils/reduxForms.util';
import * as ColorUtils from 'ab-utils/color.util';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';
import { useTaskRecovery } from 'af-root/hooks/useTaskRecovery';

import { withTaskRecovery } from 'af-root/context/taskRecoveryContext';

import DisplayReviewStatusLabel from '../../Shared/DisplayReviewStatusLabelWithTooltip';
import type { MultipleReportsActionModalPropsWithoutClose, MultipleReportsActionModalSubmitActionCallbackData } from './MultipleReportsActionModal';
import MultipleReportsActionModal from './MultipleReportsActionModal';
import MultipleJobReportsActionModal from './MultipleJobReportsActionModal';

const ALLOWED_RETRIES = 4;

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	expandedWorkOrderId: number;
	textFilter: string;
	assignmentsFilterId: UserTypeFilter;
}

const connector = connect(mapStateToProps, mapDispatchToProps());

type Props = SettingProps & ConnectedProps<typeof connector>;

type ActionSuccessModalState = Omit<ActionSuccessModalProps, 'closeModal'>;
type ContactActionModalState = Omit<ContactActionModalProps, 'closeModal'>;
type MultipleReportsActionModalState = MultipleReportsActionModalPropsWithoutClose;

const INITIAL_ACTION_SUCCESS_MODAL_STATE: ActionSuccessModalState = {
	showModal: false,
	modalTitle: '',
	descriptionTitle: null,
	descriptionMessage: null,
};

const INITIAL_CONTACT_ACTION_MODAL_STATE: ContactActionModalState = {
	showModal: false,
	title: '',
	description: null,
	action: () => console.warn('Cannot call action for uninitialized modal'), // eslint-disable-line no-console
	actionLabel: '',
};

const SEND_MULTIPLE_REPORTS_TO_CUSTOMER_DESCRIPTION = (
	<>
		Select time range and Reports you wish to send to the Customer.<br />
		When sending in bulk, Customer will download all reports as a single file.
	</>
);

const INITIAL_MULTIPLE_REPORTS_ACTION_MODAL_STATE: MultipleReportsActionModalState = {
	workRequestId: null,
	workOrderDueDate: null,
	showModal: false,
	title: '',
	jobTitle: '',
	customerCompany: '',
	description: SEND_MULTIPLE_REPORTS_TO_CUSTOMER_DESCRIPTION,
	onSubmit: () => console.warn('Cannot call action for uninitialized modal'), // eslint-disable-line no-console
};

const EXPORT_TO_FILE_LABEL = (
	<>
		<b>Exporting to file.</b><br />
		<br />
		Larger files may take several minutes. Do not close or refresh this tab.<br />
		You can continue using Acceligent in a separate tab while download is in progress.
	</>
);

const SEND_MULTIPLE_REPORTS_CUSTOMER_SIGNATURE_REQUEST_DESCRIPTION = (
	<>
		Select time range and Reports you wish to send to the Customer for signature.
	</>
);

const EXPORT_MULTIPLE_TO_CSV_DESCRIPTION = (
	<>
		Select time range and Reports you wish to download.<br />
		All reports will be downloaded as a single sheet.
	</>
);

const EXPORT_MULTIPLE_COMPLETE_REPORTS_DESCRIPTION = (
	<>
		Select time range and Reports you wish to download.<br />
		All reports will be downloaded as a single file.
	</>
);

const ActionSuccessModalFormatters = {
	arraySeparator: (index?: number, array?: string[]) => !!array?.length && ((index ?? 0) < (array.length - 1)) ? ', ' : '',
	emailFormatter: (email: string, index?: number, array?: string[]) =>
		<><b key={email}>{email}</b>{ActionSuccessModalFormatters.arraySeparator(index, array)}</>,
	phoneFormatter: (phone: string, index?: number, array?: string[]) =>
		<><b key={phone}>{phoneNormalizer(phone, '')}</b>{ActionSuccessModalFormatters.arraySeparator(index, array)}</>,
};

const WorkOrdersReportsListFullTable = (props: Props) => {
	const {
		expandedWorkOrderId,
		hasPermissionToViewAll,
		companyData,
		isAccountingOrAdmin,
		isManagement,
		findReportsCSVData,
		findOrCreatePublicReportLink,
		findAllForReportsTable,
		findOrCreateJobReportsBulkGroupCode,
		sendCustomerSignatureRequestForReports,
		downloadJobReportsPdf,
		sendReportsForCustomer,
		findOrCreateCompleteReportBulkGroupCode,
		downloadPublicReportsPdf,
		downloadCompleteReportsPdf,
	} = props;

	const navigate = useNavigate();
	const location = useLocation();
	const orgAlias = location.state.orgAlias;

	const [startDate, setStartDate] = React.useState(props.startDate);
	const [endDate, setEndDate] = React.useState(props.endDate);
	const [period, setPeriod] = React.useState(props.period);
	const [textFilter, setTextFilter] = React.useState(props.textFilter);
	const {
		value: showDownloadingModal,
		setToTrue: openDownloadingModal,
		setToFalse: closeDownloadingModal,
	} = useToggle(false);
	const {
		value: showMultipleJobReportModal,
		setToTrue: openMultipleJobReportModal,
		setToFalse: closeMultipleJobReportModal,
	} = useToggle(false);
	const [assignmentFilterOptions] = React.useState(getUserTypeFilterOptions(props.companyData, props.userData));
	const [assignmentFilter, setAssignmentFilter] = React.useState(
		assignmentFilterOptions.find((_fo) => _fo.id === props.assignmentsFilterId) ?? assignmentFilterOptions[0]
	);

	const [actionSuccessModalState, setActionSuccessModalState] = React.useState(INITIAL_ACTION_SUCCESS_MODAL_STATE);
	const [contactActionModalState, setContactActionModalState] = React.useState(INITIAL_CONTACT_ACTION_MODAL_STATE);
	const [
		multipleReportsActionModalState,
		setMultipleReportsActionModalState,
	] = React.useState<MultipleReportsActionModalState>(INITIAL_MULTIPLE_REPORTS_ACTION_MODAL_STATE);
	const [tableRef, setTableRef] = React.useState<Nullable<TableComponent<WorkOrderForReportsTableVM>>>(null);
	const [listRef, setListRef] = React.useState<Nullable<ScrollToLoad<WorkOrderForReportsTableVM>>>(null);

	const notificationSnackbar = useNotificationSnackbar();
	const taskRecovery = useTaskRecovery();

	const resetActionSuccessModalState = React.useCallback(() => {
		setActionSuccessModalState(INITIAL_ACTION_SUCCESS_MODAL_STATE);
	}, []);

	const resetContactActionModalState = React.useCallback(() => {
		setContactActionModalState(INITIAL_CONTACT_ACTION_MODAL_STATE);
	}, []);

	const resetMultipleReportsActionModalState = React.useCallback(() => {
		setMultipleReportsActionModalState(INITIAL_MULTIPLE_REPORTS_ACTION_MODAL_STATE);
	}, []);

	const onSubmitMultipleJobReports = React.useCallback(async (ids: number[], from: Date, to: Date) => {
		closeMultipleJobReportModal();

		const msg = notificationSnackbar.info('Started preparing for download');
		const bulkGroupCode = await findOrCreateJobReportsBulkGroupCode(
			ids,
			TimeUtils.formatDate(from, TimeFormatEnum.DB_DATE_ONLY),
			TimeUtils.formatDate(to, TimeFormatEnum.DB_DATE_ONLY)
		);

		if (!!bulkGroupCode) {
			const fileName = `${TimeUtils.formatDate(from, TimeFormatEnum.DATE_ONLY)}_${TimeUtils.formatDate(to, TimeFormatEnum.DATE_ONLY)}_report_${TimeUtils.formatDate(new Date(), TimeFormatEnum.DATE_ONLY_SHORT_YEAR)}`;
			notificationSnackbar.removeNotificationSnackbar(msg);
			await downloadJobReportsPdf(bulkGroupCode, fileName, notificationSnackbar, taskRecovery, ALLOWED_RETRIES);
		}
	}, [closeMultipleJobReportModal, downloadJobReportsPdf, findOrCreateJobReportsBulkGroupCode, notificationSnackbar, taskRecovery]);

	const onTableMount = React.useCallback((table: TableComponent<WorkOrderForReportsTableVM>, list: ScrollToLoad<WorkOrderForReportsTableVM>) => {
		setTableRef(table);
		setListRef(list);
	}, []);

	const refresh = React.useCallback(() => {
		tableRef?.refreshTable();
		listRef?.refreshList();
	}, [tableRef, listRef]);

	const isRowHighlighted = (row: WorkOrderForReportsTableVM): boolean => {
		return row.id === expandedWorkOrderId;
	};

	const changeFilterText = React.useCallback((filterText: string) => {
		setTextFilter(filterText);
		SettingsUtils.setItem(SettingsKeys.FIELD_REPORTS_TEXT_FILTER(), filterText, BrowserStorageEnum.SESSION_STORAGE);
		debounce(refresh, 300);
	}, [refresh]);

	const setExpandedId = React.useCallback((workOrderId: Nullable<number>) => {
		if (workOrderId !== null) {
			SettingsUtils.setExpandedWorkOrderId(workOrderId.toString());
		}
	}, []);

	const onScrollIntoNode = React.useCallback(() => {
		setExpandedId(null);
	}, [setExpandedId]);

	const asyncOpenMultipleJobReportModal = React.useCallback(async () => openMultipleJobReportModal(), [openMultipleJobReportModal]);

	const getRowUrlForOnClick = React.useCallback((original: WorkOrderForReportsTableVM): string => {

		if (original?.id && companyData?.name) {
			return CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(original.id.toString(), orgAlias, companyData.name);
		}
		return location.pathname;
	}, [companyData.name, location.pathname, orgAlias]);

	const onRowClick = React.useCallback(({ original }: CellInfo<WorkOrderForReportsTableVM>) => {
		if (original?.id) {
			navigate(getRowUrlForOnClick(original));
			setExpandedId(original.id);
		}
	}, [getRowUrlForOnClick, navigate, setExpandedId]);

	const fetchData = React.useCallback(async (tableRequestModel: TableQuery) => {
		return await findAllForReportsTable(tableRequestModel, startDate, endDate, assignmentFilter.id);
	}, [assignmentFilter.id, endDate, findAllForReportsTable, startDate]);

	const getReportRowClassName = (state: TableFinalState<WorkOrderForReportsTableVM>, rowInfo: RowInfo<WorkOrderForReportsTableVM>) => {
		return rowInfo.original.status === WorkOrderStatus.CANCELED
			? 'field-report-orders-table__row--canceled'
			: '';
	};

	const contactActionModalDataToActionSuccessDescription = (data: ContactActionModalSubmitActionCallbackData) => {
		const { emails, phones } = data;

		return (
			<>
				{!!emails.length &&
					<>
						the email{emails.length === 1 ? '' : 's'}: {emails.map(ActionSuccessModalFormatters.emailFormatter)}
					</>
				}
				{(!!emails.length && !!phones.length) && <>, and to </>}
				{!!phones.length &&
					<>
						the phone number{phones.length === 1 ? '' : 's'}: {phones.map(ActionSuccessModalFormatters.phoneFormatter)}
					</>
				}
			</>
		);
	};

	const setMultipleReportsActionModalStateVisibility = React.useCallback((showModal: boolean) => {
		setMultipleReportsActionModalState({ ...multipleReportsActionModalState, showModal });
	}, [multipleReportsActionModalState]);

	const exportToCustomerPDF = React.useCallback(async (original: WorkOrderForReportsTableVM) => {
		const msg = notificationSnackbar.info('Started preparing for download');
		const publicLink = await findOrCreatePublicReportLink([original.id]);
		if (!!publicLink && companyData?.name) {
			notificationSnackbar.removeNotificationSnackbar(msg);
			await downloadPublicReportsPdf(publicLink, `${original.code}_report`, notificationSnackbar, taskRecovery, ALLOWED_RETRIES);
		}
	}, [companyData?.name, downloadPublicReportsPdf, findOrCreatePublicReportLink, notificationSnackbar, taskRecovery]);

	const exportToCompletePDF = React.useCallback(async (original: WorkOrderForReportsTableVM) => {
		const msg = notificationSnackbar.info('Started preparing for download');
		const bulkGroupCode = await findOrCreateCompleteReportBulkGroupCode([original.id]);
		if (!!bulkGroupCode) {
			const fileName = `${original.code}_report_${TimeUtils.formatDate(new Date(), TimeFormatEnum.DATE_ONLY_SHORT_YEAR)}`;
			notificationSnackbar.removeNotificationSnackbar(msg);
			await downloadCompleteReportsPdf(bulkGroupCode, fileName, notificationSnackbar, taskRecovery, ALLOWED_RETRIES);
		}
	}, [downloadCompleteReportsPdf, findOrCreateCompleteReportBulkGroupCode, notificationSnackbar, taskRecovery]);

	const exportToCSV = React.useCallback(async (original: WorkOrderForReportsTableVM) => {
		openDownloadingModal();
		const data = await findReportsCSVData([original.id]);
		const fileName = `${original.code}_report_${TimeUtils.formatDate(new Date(), TimeFormatEnum.DATE_ONLY_SHORT_YEAR)}.csv`;
		downloadCSV([data!.headerRow, ...data!.dataRows], fileName);
		closeDownloadingModal();
	}, [closeDownloadingModal, findReportsCSVData, openDownloadingModal]);

	const openExportMultipleCompleteReports = React.useCallback((original: WorkOrderForReportsTableVM) => {
		const title = 'Export Complete Reports';
		const { jobCode, jobTitle, customerCompany, workRequestId, dueDate } = original;

		setMultipleReportsActionModalState({
			showModal: true,
			title: title,
			jobTitle: jobTitle,
			customerCompany: customerCompany,
			description: EXPORT_MULTIPLE_COMPLETE_REPORTS_DESCRIPTION,
			workRequestId: workRequestId,
			workOrderDueDate: dueDate,
			onSubmit: async ({ selectedWorkOrderIds }: MultipleReportsActionModalSubmitActionCallbackData) => {
				// NOTE: maintain SendMultipleReportsModalState for back action
				setMultipleReportsActionModalStateVisibility(false);
				const msg = notificationSnackbar.info('Started preparing for download');
				const bulkGroupCode = await findOrCreateCompleteReportBulkGroupCode(selectedWorkOrderIds);

				if (!!bulkGroupCode) {
					const fileName = `${jobCode}_report_${TimeUtils.formatDate(new Date(), TimeFormatEnum.DATE_ONLY_SHORT_YEAR)}`;
					notificationSnackbar.removeNotificationSnackbar(msg);
					await downloadCompleteReportsPdf(bulkGroupCode, fileName, notificationSnackbar, taskRecovery, ALLOWED_RETRIES);
				}
				resetMultipleReportsActionModalState();
			},
		});
	}, [setMultipleReportsActionModalStateVisibility,
		notificationSnackbar,
		findOrCreateCompleteReportBulkGroupCode,
		resetMultipleReportsActionModalState,
		downloadCompleteReportsPdf,
		taskRecovery,
	]);

	const openExportMultipleToCSV = React.useCallback((original: WorkOrderForReportsTableVM) => {
		const title = 'Export Reports to CSV';
		const { jobCode, jobTitle, customerCompany, workRequestId, dueDate } = original;

		setMultipleReportsActionModalState({
			showModal: true,
			title: title,
			jobTitle: jobTitle,
			customerCompany: customerCompany,
			description: EXPORT_MULTIPLE_TO_CSV_DESCRIPTION,
			workRequestId: workRequestId,
			workOrderDueDate: dueDate,
			onSubmit: async ({ selectedWorkOrderIds }: MultipleReportsActionModalSubmitActionCallbackData) => {
				// NOTE: maintain SendMultipleReportsModalState for back action
				setMultipleReportsActionModalStateVisibility(false);
				openDownloadingModal();

				const data = await findReportsCSVData(selectedWorkOrderIds);
				const fileName = `${jobCode}_report_${TimeUtils.formatDate(new Date(), TimeFormatEnum.DATE_ONLY_SHORT_YEAR)}.csv`;
				downloadCSV([data!.headerRow, ...data!.dataRows], fileName);
				resetMultipleReportsActionModalState();
				closeDownloadingModal();
			},
		});
	}, [closeDownloadingModal, findReportsCSVData, openDownloadingModal, resetMultipleReportsActionModalState, setMultipleReportsActionModalStateVisibility]);

	const openSendReportCustomerSignatureRequestModal = React.useCallback((original: WorkOrderForReportsTableVM) => {
		const title = 'Send Report and Request Customer Signature';
		const { code, id } = original;
		setContactActionModalState({
			showModal: true,
			title: title,
			description: (<span>You are about to send Report <b>{code}</b> for Customer to sign.</span>),
			action: async (data: ContactActionModalSubmitActionCallbackData, formName: string) => {
				await sendCustomerSignatureRequestForReports({ ...data, workOrderIds: [id] }, formName);
				resetContactActionModalState();
				setActionSuccessModalState({
					showModal: true,
					modalTitle: title,
					descriptionTitle: (<span>Signature Requested</span>),
					descriptionMessage: (
						<span>
							<>Report {code} has been successfully sent to </>
							{contactActionModalDataToActionSuccessDescription(data)}
							<>, and Customer signature has been requested.</>
						</span>
					),
				});
			},
			actionLabel: 'Send and Request Signature',
		});
	}, [resetContactActionModalState, sendCustomerSignatureRequestForReports]);

	const openSendMultipleReportsCustomerSignatureRequestModal = React.useCallback((original: WorkOrderForReportsTableVM) => {
		const title = 'Send and Request Signature';
		const { jobCode, jobTitle, customerCompany, workRequestId, dueDate } = original;
		setMultipleReportsActionModalState({
			showModal: true,
			title: title,
			jobTitle: jobTitle,
			customerCompany: customerCompany,
			workRequestId: workRequestId,
			workOrderDueDate: dueDate,
			description: SEND_MULTIPLE_REPORTS_CUSTOMER_SIGNATURE_REQUEST_DESCRIPTION,
			onSubmit: ({ selectedWorkOrderIds }: MultipleReportsActionModalSubmitActionCallbackData) => {
				// NOTE: maintain SendMultipleReportsModalState for back action
				setMultipleReportsActionModalStateVisibility(false);
				setContactActionModalState({
					showModal: true,
					title: title,
					description: (
						<span>You are about to send <b>{selectedWorkOrderIds.length}</b> Reports for <b>{jobCode}</b> to Customer.</span>
					),
					action: async (data: ContactActionModalSubmitActionCallbackData, formName: string) => {
						await sendCustomerSignatureRequestForReports({ ...data, workOrderIds: selectedWorkOrderIds, jobCode }, formName);
						resetMultipleReportsActionModalState();
						resetContactActionModalState();
						setActionSuccessModalState({
							showModal: true,
							modalTitle: title,
							descriptionTitle: (<span>Signature Requested</span>),
							descriptionMessage: (
								<span>
									<><b>{selectedWorkOrderIds.length}</b> Reports for {jobCode} has been successfully sent to </>
									{contactActionModalDataToActionSuccessDescription(data)}
									<>, and Customer signature has been requested.</>
								</span>
							),
						});
					},
					goBack: () => {
						resetContactActionModalState();
						setMultipleReportsActionModalStateVisibility(true);
					},
					actionLabel: 'Send',
				});
			},
		});
	}, [
		resetContactActionModalState,
		resetMultipleReportsActionModalState,
		sendCustomerSignatureRequestForReports,
		setMultipleReportsActionModalStateVisibility,
	]);

	const openSendReportToCustomerModal = React.useCallback((original: WorkOrderForReportsTableVM) => {
		const title = 'Send Report to Customer';
		const { code, id } = original;

		setContactActionModalState({
			showModal: true,
			title: title,
			description: (<span>You are about to send Report <b>{code}</b> to Customer.</span>),
			action: async (data: ContactActionModalSubmitActionCallbackData, formName: string) => {
				await sendReportsForCustomer({ ...data, workOrderIds: [id] }, formName);
				resetContactActionModalState();
				setActionSuccessModalState({
					showModal: true,
					modalTitle: title,
					descriptionTitle: (<span>Report Sent</span>),
					descriptionMessage: (
						<span>
							<>Report {code} has been successfully sent to </>
							{contactActionModalDataToActionSuccessDescription(data)}
							<>.</>
						</span>
					),
				});
			},
			actionLabel: 'Send',
		});
	}, [resetContactActionModalState, sendReportsForCustomer]);

	const openSendMultipleReportsToCustomer = React.useCallback((original: WorkOrderForReportsTableVM) => {
		const title = 'Send Reports to Customer';
		const { jobCode, jobTitle, customerCompany, workRequestId, dueDate } = original;

		setMultipleReportsActionModalState({
			showModal: true,
			title: title,
			jobTitle: jobTitle,
			customerCompany: customerCompany,
			description: SEND_MULTIPLE_REPORTS_TO_CUSTOMER_DESCRIPTION,
			workRequestId: workRequestId,
			workOrderDueDate: dueDate,
			onSubmit: ({ selectedWorkOrderIds }: MultipleReportsActionModalSubmitActionCallbackData) => {
				// NOTE: maintain SendMultipleReportsModalState for back action
				setMultipleReportsActionModalStateVisibility(false);
				setContactActionModalState({
					showModal: true,
					title: title,
					description: (
						<span>You are about to send <b>{selectedWorkOrderIds.length}</b> Reports for <b>{jobCode}</b> to Customer.</span>
					),
					action: async (data: ContactActionModalSubmitActionCallbackData, formName: string) => {
						await sendReportsForCustomer({ ...data, workOrderIds: selectedWorkOrderIds }, formName);
						resetMultipleReportsActionModalState();
						resetContactActionModalState();
						setActionSuccessModalState({
							showModal: true,
							modalTitle: title,
							descriptionTitle: (<span>Reports Sent</span>),
							descriptionMessage: (
								<span>
									<><b>{selectedWorkOrderIds.length}</b> Reports for {jobCode} has been successfully sent to </>
									{contactActionModalDataToActionSuccessDescription(data)}
									<>.</>
								</span>
							),
						});
					},
					goBack: () => {
						resetContactActionModalState();
						setMultipleReportsActionModalStateVisibility(true);
					},
					actionLabel: 'Send',
				});
			},
		});
	}, [resetContactActionModalState, resetMultipleReportsActionModalState, sendReportsForCustomer, setMultipleReportsActionModalStateVisibility]);

	const goToSignLocally = React.useCallback(async (original: WorkOrderForReportsTableVM) => {

		const publicLink = await findOrCreatePublicReportLink([original.id]);

		if (!publicLink) {
			throw new Error('Public link not provided');
		}
		window.open(CLIENT.COMPANY.FIELD_REPORT.PUBLIC_REPORT(publicLink, orgAlias, companyData?.name), '_blank')?.focus();
	}, [companyData?.name, findOrCreatePublicReportLink, orgAlias]);

	const hideExportToCSV = React.useCallback((original: WorkOrderForReportsTableVM) => {
		return !props.isAccountingOrAdmin || original.status === WorkOrderStatus.CANCELED;
	}, [props.isAccountingOrAdmin]);

	const rowActions: ItemBlueprint<WorkOrderForReportsTableVM>[] = React.useMemo(() => [
		{
			label: 'Export to PDF',
			isCascade: true,
			hide: (original: WorkOrderForReportsTableVM) => original.status === WorkOrderStatus.CANCELED,
			items: [
				{
					label: 'Export for Customer',
					action: exportToCustomerPDF,
					shouldRefresh: false,
				},
				{
					label: 'Export Complete Report',
					action: exportToCompletePDF,
					shouldRefresh: false,
				},
				{
					label: 'Export Bulk Complete Report',
					action: openExportMultipleCompleteReports,
					shouldRefresh: false,
				},
			],
		},
		{
			label: 'Export to CSV',
			isCascade: true,
			hide: hideExportToCSV,
			items: [
				{
					label: 'Export Report Data',
					action: exportToCSV,
					shouldRefresh: false,
				},
				{
					label: 'Export Bulk Report Data',
					action: openExportMultipleToCSV,
					shouldRefresh: false,
				},
			],
		},
		{
			label: 'Send to Customer',
			isCascade: true,
			hide: (original: WorkOrderForReportsTableVM) => original.status === WorkOrderStatus.CANCELED,
			items: [
				{
					label: (original: WorkOrderForReportsTableVM) => `Send Signature Request for ${original.code}`,
					action: openSendReportCustomerSignatureRequestModal,
					shouldRefresh: false,
				},
				{
					label: (original: WorkOrderForReportsTableVM) => `Send signature request for reports of ${original.jobCode}`,
					action: openSendMultipleReportsCustomerSignatureRequestModal,
					shouldRefresh: false,
				},
				{ isDivider: true },
				{
					label: (original: WorkOrderForReportsTableVM) => `Send ${original.code} Report`,
					action: openSendReportToCustomerModal,
					shouldRefresh: false,
				},
				{
					label: (original: WorkOrderForReportsTableVM) => `Send Reports for ${original.jobCode}`,
					action: openSendMultipleReportsToCustomer,
					shouldRefresh: false,
				},
			],
		},
		{
			label: 'Add Customer Signature Locally',
			action: goToSignLocally,
			hide: (original: WorkOrderForReportsTableVM) => original.status === WorkOrderStatus.CANCELED,
			shouldRefresh: false,
		},
	], [exportToCSV,
		exportToCompletePDF,
		exportToCustomerPDF,
		goToSignLocally,
		hideExportToCSV,
		openExportMultipleCompleteReports,
		openExportMultipleToCSV,
		openSendMultipleReportsCustomerSignatureRequestModal,
		openSendMultipleReportsToCustomer,
		openSendReportCustomerSignatureRequestModal,
		openSendReportToCustomerModal,
	]);

	const renderStatusMenuItem = React.useCallback((optionItem: UserTypeFilterOption) => !optionItem ? <></> : <div>{optionItem.name}</div>, []);

	const changePeriod = React.useCallback((newPeriod: TimePeriodRecurrence, selected: Date) => {
		let from: Date = selected, to: Date = selected;

		switch (newPeriod) {
			case TimePeriodRecurrence.MONTHLY:
				from = TimeUtils.positionDate(from, 'start', 'month');
				to = TimeUtils.positionDate(to, 'end', 'month');
				break;
			case TimePeriodRecurrence.WEEKLY:
				from = TimeUtils.positionDate(from, 'start', 'week');
				to = TimeUtils.positionDate(to, 'end', 'week');
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				break;
		}

		setPeriod(newPeriod);
		setStartDate(from);
		setEndDate(to);
		SettingsUtils.setWorkOrderReportsPeriod(newPeriod);
		SettingsUtils.setWorkOrderReportsStartDate(from);
		SettingsUtils.setWorkOrderReportsEndDate(to);
		refresh();
	}, [refresh]);

	const filterByDate = React.useCallback((from: Date, to: Date) => {
		setStartDate(from);
		setEndDate(to);
		SettingsUtils.setWorkOrderReportsStartDate(from);
		SettingsUtils.setWorkOrderReportsEndDate(to);
		refresh();
	}, [refresh]);

	const changeAssignmentsFilter = React.useCallback((filter: UserTypeFilterOption) => {
		setAssignmentFilter(filter);
		SettingsUtils.setItem(SettingsKeys.FIELD_REPORTS_ASSIGNMENTS_ID(), filter.id, BrowserStorageEnum.LOCAL_STORAGE);
		refresh();
	}, [refresh]);

	const renderFilter = React.useCallback(() => {

		return (
			<div className="table-filter field-report-orders-table__filters">
				<div className={bemElement('table-filter', 'parameter', ['no-margin-top', 'margin-right'])}>
					<Dropdown<UserTypeFilterOption>
						className="field-report-orders-table__filter-button"
						defaultValue={assignmentFilter}
						id="field-reports-orders-user-type-filter"
						labelKey="name"
						onValueChange={changeAssignmentsFilter}
						options={assignmentFilterOptions}
						renderMenuItem={renderStatusMenuItem}
						valueKey="id"
						withBorder={false}
					/>
					<RectangleButton
						action={undefined}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-filter" />}
					/>
				</div>
				<DateFilter
					changePeriod={changePeriod}
					endDate={endDate}
					onChange={filterByDate}
					period={period}
					startDate={startDate}
				/>
			</div>
		);
	}, [assignmentFilter, assignmentFilterOptions, changeAssignmentsFilter, changePeriod, endDate, filterByDate, period, renderStatusMenuItem, startDate]);

	const columns: Column<WorkOrderForReportsTableVM>[] = React.useMemo(() => [
		{
			Header: 'Work Order',
			accessor: 'workOrderCode',
			Cell: ({ original }: CellInfo<WorkOrderForReportsTableVM>) => (
				original
					? (
						<LinkCell
							label={(
								<Tooltip
									className="field-report-orders-table__popover"
									message={
										<ResourceTooltipList
											workOrderEmployees={original.workOrderEmployees}
											workOrderEquipment={original.workOrderEquipment}
											workOrderTemporaryEmployees={original.workOrderTemporaryEmployees}
										/>
									}
								>
									<div>
										<div>{original.code}</div>
										{original.status === WorkOrderStatus.CANCELED && <div className="field-report-orders-table__canceled-code">Canceled</div>}
									</div>
								</Tooltip>
							)}
							url={getRowUrlForOnClick(original)}
						/>
					)
					: <EmptyCell />
			),
		},
		{
			Header: 'Review Status',
			accessor: 'calculatedReportDisplayStatus',
			Cell: ({ original }: CellInfo<WorkOrderForReportsTableVM>) => {

				if (original.status === WorkOrderStatus.CANCELED) {
					return <EmptyCell />;
				}

				let role = original.fieldReportAccessRole;
				if (isManagement) {
					role = FieldReportAccessRoleEnum.MANAGEMENT;
				}
				if (isAccountingOrAdmin) {
					role = FieldReportAccessRoleEnum.ACCOUNTING;
				}

				return (
					<DisplayReviewStatusLabel
						accountId={companyData?.accountId ?? null}
						assignedTo={original.assignedTo}
						calculatedReportDisplayStatus={original.calculatedReportDisplayStatus}
						fieldReportAccessRole={role}
						isCondensedView={false}
						lastUpdatedAt={original.lastUpdatedAt}
						reviewLevel={original.reviewLevel}
						reviewStatus={original.reviewStatus}
						timeSheets={original.timeSheets}
					/>
				);
			},
		},
		{
			Header: 'Date of Work',
			accessor: 'dueDate',
			Cell: ({ original }: CellInfo<WorkOrderForReportsTableVM>) => (
				<ComparableDateCell date={original.dueDate} dateSourceFormat={TimeFormatEnum.DB_DATE_ONLY} format={TimeFormatEnum.DATE_ONLY} />
			),
		},
		{
			Header: 'Last Approved At',
			accessor: 'lastApprovedAt',
			Cell: ({ original }: CellInfo<WorkOrderForReportsTableVM>) => (
				original.lastApprovedAt
					? (
						<DateCell
							date={original.lastApprovedAt}
							dateSourceFormat={TimeFormatEnum.ISO_DATETIME}
							details={original.lastApprovedBy}
							format={TimeFormatEnum.DATE_ONLY}
							isCustomerService={!!original.lastApprovedAt && !original.lastApprovedBy}
							isLeftAligned={true}
							withTime={true}
						/>
					)
					: <EmptyCell />
			),
		},
		{
			Header: 'Customer',
			accessor: 'customerCompany',
			className: 'text-strong',
			Cell: ({ original }: CellInfo<WorkOrderForReportsTableVM>) => original.customerCompany,
		},
		{
			Header: 'Job Title',
			accessor: 'title',
			Cell: ({ original }: CellInfo<WorkOrderForReportsTableVM>) => {
				return (
					<div>
						<div>{original.jobTitle}</div>
						{original.jobStatus &&
							<div className={`cell-additional-info ${ColorUtils.getColorTextClass(original.jobStatusColor)}`}>
								{original.jobStatus}
							</div>
						}
					</div>
				);
			},
		},
		{
			Header: 'Superintendent',
			accessor: 'superintendent',
			Cell: ({ original }) => original.superintendent,
		},
		{
			Header: 'Project Manager',
			accessor: 'projectManager',
			Cell: ({ original }) => original.projectManager ?? <EmptyCell />,
		},
		{
			Header: 'Crew Type',
			accessor: 'crewName',
			Cell: ({ original }: CellInfo<WorkOrderForReportsTableVM>) => <LabelWithColor color={original.crewTypeColor} text={original.crewTypeName} />,
		},
		{
			accessor: 'address',
			Header: 'Address',
			sortable: false,
			Cell: ({ original }: CellInfo<WorkOrderForReportsTableVM>) => original.address ? <AddressCell address={original.address} /> : <EmptyCell />,
		},
	], [companyData?.accountId, getRowUrlForOnClick, isAccountingOrAdmin, isManagement]);

	const tabs = React.useMemo((): TabProps<WorkOrderForReportsTableVM>[] => {
		const buttons: ButtonData[] = [];

		if (process.env.FTD_DISABLE_FR_BULK_EXPORT !== 'true') {
			buttons.push({
				type: TableButtonType.LINK,
				hasPermission: hasPermissionToViewAll,
				label: 'Bulk Export as PDF',
				icon: 'download',
				onClick: asyncOpenMultipleJobReportModal,
			});
		}

		const tabProps: TabProps<WorkOrderForReportsTableVM> = {
			label: 'Work Orders',
			buttons,
			columns,
			onRowClick: onRowClick,
			hasSearchInput: true,
			searchLabel: 'Work Orders',
			additionalFilter: renderFilter,
			fetch: fetchData,
			rowActions: rowActions,
			getRowClassName: getReportRowClassName,
		};

		return [tabProps];
	}, [asyncOpenMultipleJobReportModal, columns, fetchData, hasPermissionToViewAll, onRowClick, renderFilter, rowActions]);

	return (
		<>
			<Table
				getRowHighlighted={expandedWorkOrderId ? isRowHighlighted : undefined}
				onFilterTextChange={changeFilterText}
				onMount={onTableMount}
				onScrollIntoNode={onScrollIntoNode}
				tableName={TableNameEnum.WORK_ORDER_FIELD_REPORT_LIST}
				tabs={tabs}
				textFilter={textFilter}
			/>
			<ActionSuccessModal
				{...actionSuccessModalState}
				closeModal={resetActionSuccessModalState}
			/>
			<ContactActionModal
				{...contactActionModalState}
				closeModal={resetContactActionModalState}
			/>
			<MultipleReportsActionModal
				{...multipleReportsActionModalState}
				closeModal={resetMultipleReportsActionModalState}
			/>
			<MultipleJobReportsActionModal
				closeModal={closeMultipleJobReportModal}
				initialEndDate={endDate}
				initialStartDate={startDate}
				onSubmit={onSubmitMultipleJobReports}
				showModal={showMultipleJobReportModal}
			/>
			<LoadingOverlay
				label={EXPORT_TO_FILE_LABEL}
				show={showDownloadingModal}
			/>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;

	if (!companyData || !userData) {
		throw new Error('User not logged in');
	}

	const isAccountingOrAdmin = isAllowed(
		PagePermissions.COMPANY.FIELD_REPORT.MANAGE.ACCOUNTING,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	const isManagement = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.MANAGEMENT, companyData.permissions, companyData.isCompanyAdmin, userData.role);
	const hasPermissionToEdit = isAccountingOrAdmin
		?? isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FILL, companyData.permissions, companyData.isCompanyAdmin, userData.role);
	const hasPermissionToViewAll = isAccountingOrAdmin
		?? isAllowed(PagePermissions.COMPANY.FIELD_REPORT.VIEW_ALL, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	return {
		userData: userData,
		companyData: companyData,
		isAccountingOrAdmin,
		isManagement,
		hasPermissionToEdit,
		hasPermissionToViewAll,
	};
}

function mapDispatchToProps() {
	return {
		findAllForReportsTable: FieldReportActions.findAllForReportsTable,
		findReportsCSVData: WorkOrderActions.findReportsCSVData,
		findOrCreateCompleteReportBulkGroupCode: WorkOrderActions.findOrCreateCompleteGroupCode,
		downloadCompleteReportsPdf: WorkOrderActions.downloadCompleteReportPDF,
		downloadPublicReportsPdf: WorkOrderActions.downloadPublicReportPDF,
		findOrCreatePublicReportLink: WorkOrderActions.findOrCreatePublicGroupCode,
		sendCustomerSignatureRequestForReports: WorkOrderActions.sendCustomerSignatureRequestForReports,
		sendReportsForCustomer: WorkOrderActions.sendReportsForCustomer,
		downloadOld: WorkOrderActions.downloadCustomerWorkOrderReportPdf,
		findOrCreateJobReportsBulkGroupCode: WorkRequestActions.findOrCreateJobReportsBulkGroupCode,
		downloadJobReportsPdf: WorkOrderActions.downloadCompleteReportPDF,
	};
}

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormatEnum.DATE_ONLY);
};

const enhance = compose<React.ComponentClass>(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.EXPANDED_WORK_ORDER_ID(),
			mappedName: 'expandedWorkOrderId',
			normalize: (value: string) => +value,
			defaultValue: null,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.FIELD_REPORTS_TEXT_FILTER(),
			mappedName: 'textFilter',
			defaultValue: null,
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
		{
			key: SettingsKeys.FIELD_REPORTS_ASSIGNMENTS_ID(),
			mappedName: 'assignmentsFilterId',
			defaultValue: null,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector,
	withTaskRecovery
);

export default enhance(WorkOrdersReportsListFullTable);
