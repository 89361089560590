import * as React from 'react';
import type { Table } from '@tanstack/react-table';

import type TableSettingsRequestModel from 'ab-requestModels/tableSettings.requestModel';

import type { DropdownOptionType } from 'af-components/Controls/Dropdown';
import Dropdown from 'af-components/Controls/Dropdown';

import styles from './styles.module.scss';

interface RowsPerPageDropdown extends DropdownOptionType {
	label: number;
}

interface Props<T> {
	table: Table<T>;
	updateTableSettings: (settings: TableSettingsRequestModel) => Promise<void>;
	tableSettings: Nullable<TableSettingsRequestModel>;
	pageSize?: number;
	pageCount: number;
	defaultPageNumber?: number;
	savePageNumber: (pageNumber: number) => void;
}

const PAGE_SIZES = [5, 10, 20, 25, 50, 100, 500];

function PaginationFooter<T,>(props: React.PropsWithChildren<Props<T>>) {
	const { table, tableSettings, updateTableSettings, pageSize, pageCount, savePageNumber, defaultPageNumber } = props;

	const onPageChange = React.useCallback((e) => {
		const page = Number(e.target.value) - 1;
		table.setPageIndex(page);
	}, [table]);

	const onPreviousClick = React.useCallback(() => table.previousPage(), [table]);

	const onNextClick = React.useCallback(() => table.nextPage(), [table]);

	const pageSizeOptions: RowsPerPageDropdown[] = React.useMemo(() => PAGE_SIZES.map((_ps) => {
		return {
			disabled: false,
			onClick: async () => {
				if (!tableSettings) return;
				table.setPageSize(Number(_ps));
				updateTableSettings({ ...tableSettings, pageSize: Number(_ps) });
			},
			label: _ps,
		};
	}), [table, tableSettings, updateTableSettings]);

	const selectedOption = React.useMemo(() => pageSizeOptions.find((_pgo) => _pgo.label === pageSize), [pageSize, pageSizeOptions]);

	React.useEffect(() => {
		if (defaultPageNumber) {
			table.setPageIndex(defaultPageNumber - 1);
		}
		// We don't want anything here
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		savePageNumber(table.getState().pagination.pageIndex + 1);
		// We don't want table and savePageNumber here
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [table.getState().pagination.pageIndex]);

	return (
		<div className={styles['pagination-footer']}>
			Rows per page
			<Dropdown
				className={styles['pagination-footer__dropdown']}
				defaultValue={selectedOption}
				labelKey="label"
				options={pageSizeOptions}
				withBorder={true}
				withCaret={true}
			/>
			Page
			<input
				onChange={onPageChange}
				type="number"
				value={table.getState().pagination.pageIndex + 1}
			/>
			of {isNaN(pageCount) ? 0 : pageCount}
			<button
				className={!table.getCanPreviousPage() ? styles.disabled : undefined}
				disabled={!table.getCanPreviousPage()}
				onClick={onPreviousClick}
			>
				<span className="icon-left" />
			</button>
			<button
				className={!table.getCanNextPage() ? styles.disabled : undefined}
				disabled={!table.getCanNextPage()}
				onClick={onNextClick}
			>
				<span className="icon-right" />
			</button>
		</div>
	);
}

export default PaginationFooter;
