import PurchaseOrderStatus from 'acceligent-shared/enums/purchaseOrderStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { getUserName } from 'acceligent-shared/utils/user';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import PurchaseOrderItemCompletion from 'acceligent-shared/enums/purchaseOrderItemCompletion';

import type PurchaseOrdersTableBase from 'ab-domain/views/purchaseOrdersTable/base';

const CSV_HEADER = [
	'PO Number',
	'Date Created',
	'Date Submitted',
	'Vendor',
	'Location',
	'Total Cost ($)',
	'Equipment Code',
	'Job Number',
	'Status',
];

export class PurchaseOrderTableVM {
	id: number;
	status: PurchaseOrderStatus;
	orderNumber: string;
	/** MM-DD-YYYY */
	dateCreated: string;
	/** MM-DD-YYYY */
	dateSubmitted: Nullable<string>;
	vendorName: string;
	equipmentCode: Nullable<string>;
	jobCode: Nullable<string>;
	itemCategory: Nullable<string>;
	locationName: Nullable<string>;
	totalCost: number;
	requestedBy: string;
	submittedBy: Nullable<string>;
	itemsReceived: PurchaseOrderItemCompletion;
	itemsInvoiced: PurchaseOrderItemCompletion;

	constructor(po: PurchaseOrdersTableBase) {
		this.id = po.id;
		this.status = po.status;
		this.orderNumber = po.orderNumber;
		this.dateCreated = TimeUtils.formatDate(po.createdAt, TimeFormat.DATE_ONLY);
		this.dateSubmitted = po.dateSubmitted ? TimeUtils.formatDate(po.dateSubmitted, TimeFormat.DATE_ONLY) : null;
		this.vendorName = po.vendor.name;
		this.equipmentCode = po.equipment ? po.equipment.code : null;
		this.jobCode = po.workRequest ? po.workRequest.jobCode : null;
		this.itemCategory = po.itemCategory?.name ?? null;
		this.locationName = po.location?.nickname ?? null;
		this.totalCost = po.totalCost;
		this.requestedBy = getUserName(po.requestedBy?.user);
		this.submittedBy = po.status !== PurchaseOrderStatus.UNFINISHED ? getUserName(po.submittedBy?.user) : null;
		this.itemsReceived = po.purchaseOrderItems.every((poi) => poi.itemsReceived === PurchaseOrderItemCompletion.ALL)
			? PurchaseOrderItemCompletion.ALL
			: po.purchaseOrderItems.every((poi) => poi.itemsReceived === PurchaseOrderItemCompletion.NONE)
				? PurchaseOrderItemCompletion.NONE
				: PurchaseOrderItemCompletion.PARTIALLY;
		this.itemsInvoiced = po.purchaseOrderItems.every((poi) => poi.itemsInvoiced === PurchaseOrderItemCompletion.ALL)
			? PurchaseOrderItemCompletion.ALL
			: po.purchaseOrderItems.every((poi) => poi.itemsInvoiced === PurchaseOrderItemCompletion.NONE)
				? PurchaseOrderItemCompletion.NONE
				: PurchaseOrderItemCompletion.PARTIALLY;
	}

	private static _constructorMap = (po: PurchaseOrdersTableBase) => new PurchaseOrderTableVM(po);

	static bulkConstructor = (pos: PurchaseOrdersTableBase[]) => pos.map(this._constructorMap);

	static toCSVData(viewModels: PurchaseOrderTableVM[]): string[][] {
		const header: string[] = [...CSV_HEADER];

		const rows: string[][] = viewModels.map((_entry: PurchaseOrderTableVM) => {
			const _row: string[] = [
				`${_entry.orderNumber}`,
				`${_entry.dateCreated}`,
				`${_entry.dateSubmitted}`,
				_entry.vendorName,
				_entry.locationName ?? 'N/A',
				`${_entry.totalCost}`,
				_entry.equipmentCode ?? 'N/A',
				_entry.jobCode ?? 'N/A',
				_entry.status,
			];
			return _row;
		});

		return [header, ...rows];
	}
}
