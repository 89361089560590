import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { StaticContext } from 'react-router';

import type { ReportTypeRM } from 'ab-requestModels/reportType/reportType.requestModel';

import type ReportTypeLocationVM from 'ab-viewModels/reportType/locationState.viewModel';
import { ReportTypeVM } from 'ab-viewModels/reportType/reportType.viewModel';

import type ReportBlockMapVM from 'ab-viewModels/reportBlock/reportBlockMap.viewModel';

import * as ReportTypeActions from 'af-actions/reportType';
import * as ReportBlockActions from 'af-actions/reportBlock';

import CLIENT from 'af-constants/routes/client';

import Breadcrumbs from 'af-components/Breadcrumbs';

import type { RootState } from 'af-reducers';

import ReportTypeForm from '../CustomTypeForm';

interface LocationState {
	orgAlias: string;
	reportType: ReportTypeLocationVM;
}

type OwnProps = CustomRouteComponentProps<void, StaticContext, LocationState>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const CreateReportType: React.FC<Props> = (props: Props) => {
	const { location: { state: { orgAlias } }, create, history, companyName, fetchingBlocks, fetchReportBlocks } = props;

	const [blocks, setBlocks] = React.useState<ReportBlockMapVM>({ blockMap: {}, fieldMap: {} });
	const [reportType] = React.useState<ReportTypeVM>(ReportTypeVM.fromLocationStateVM(props.location.state.reportType));

	React.useEffect(() => {
		async function getBlocks() {
			const _blocks = await fetchReportBlocks();
			setBlocks(_blocks);
		}
		getBlocks();
	}, [fetchReportBlocks]);

	const onCreate = React.useCallback((form: ReportTypeRM) => {
		create(form);
		history.push(CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName));
	}, [companyName, create, history, orgAlias]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={
					[
						{ label: 'Report Type', url: CLIENT.COMPANY.SETTINGS.REPORT.TABLE(orgAlias, companyName) },
						{ label: 'Create' },
					]
				}
			/>
			<ReportTypeForm
				loading={fetchingBlocks}
				onSubmit={onCreate}
				reportBlockFieldsMap={blocks.fieldMap}
				reportBlocksMap={blocks.blockMap}
				reportType={reportType}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { isFetching } = state.http;
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		fetchingBlocks: isFetching,
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		fetchReportBlocks: ReportBlockActions.findList,
		create: ReportTypeActions.createCustom,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector
);

export default enhance(CreateReportType);
