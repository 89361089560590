import type WorkOrderUpsertRM from 'acceligent-shared/dtos/mobile/request/workOrder/workOrderUpsert';

import { daysBetween } from 'acceligent-shared/utils/time';
import { isValidEmail } from 'acceligent-shared/utils/email';

import type CreateTemporaryEmployeeAssignmentRM from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';
import { shouldCreateEmployee } from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';
import type { WorkOrderMetricsItem } from 'ab-requestModels/scheduleBoardMetrics.requestModel';
import type ScheduleBoardMetricsRequestModel from 'ab-requestModels/scheduleBoardMetrics.requestModel';
import type * as SignatureRequestModel from 'ab-requestModels/signature.requestModel';
import type { WorkOrderForm } from 'ab-requestModels/workOrder.requestModel';
import type WorkOrderSendReportsForCustomerRM from 'ab-requestModels/workOrder/sendReportsForCustomer.requestModel';
import type WorkOrderSendCustomerSignatureRequestForReportsRM from 'ab-requestModels/workOrder/sendCustomerSignatureRequestForReports.requestModel';
import type SubmitWorkOrderForReviewRM from 'ab-requestModels/workOrder/submitForReview.requestModel';

import type { WorkOrderViewModel } from 'ab-viewModels/workOrder.viewModel';
import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import { MAX_WORK_ORDER_REVENUE, MAX_WORK_ORDER_MAN_HOUR_AVERAGE } from 'ab-common/constants/value';

import { isValidAddressRequestObject } from 'ab-validators/address.validator';

import { isValidNumber, isValidPositiveInteger } from 'ab-utils/validation.util';
import { isValidPhoneNumber } from 'ab-utils/phone.util';

// private functions

const _isValidWorkOrderMetrics = (values: WorkOrderMetricsItem) => {
	const errors: ValidationErrors = {};

	if (values?.revenue && !isValidNumber(values.revenue)) {
		errors.revenue = 'Revenue must be a number';
	} else if (values?.revenue && +values.revenue > MAX_WORK_ORDER_REVENUE) {
		errors.revenue = 'Revenue value is too high';
	}

	if (values?.manHourAverage && !isValidNumber(values.manHourAverage)) {
		errors.manHourAverage = 'Man Hour Average must be a number';
	} else if (values?.manHourAverage && +values.manHourAverage > MAX_WORK_ORDER_MAN_HOUR_AVERAGE) {
		errors.manHourAverage = 'Man Hour Average value is too high';
	}

	if (Object.keys(errors).length === 0) {
		return null;
	}
	return errors;
};

// public consts

export const InvalidWorkOrderModalReasons = {
	delayReason: 'Delay Reason',
	supervisorId: 'Supervisor',
	crewTypeId: 'Crew Type',
	addresses: 'Address',
	workOrderTasks: 'Task',
};

// public functions

interface WorkOrderCopyForm {
	/** YYYY-MM-DD */
	startDate: string;
	/** YYYY-MM-DD */
	endDate: string;
	intervalSelection: boolean;
}
export const isValidWorkOrderCopyForm = (values: WorkOrderCopyForm) => {
	const errors: ValidationErrors = {};

	if (values.intervalSelection) {
		if (!values.startDate) {
			errors.startDate = 'Start Date is required.';
		}
		if (!values.endDate) {
			errors.endDate = 'End Date is required.';
		}

		const daysDifference = daysBetween(values.startDate, values.endDate);
		if (daysDifference === null || daysDifference < 0) {
			errors._error = 'Start date need to be before end date.';
		}
	} else if (!values.startDate) {
		errors.startDate = 'Copy Date is required.';
	}

	return {
		isValid: Object.keys(errors).length === 0,
		errors,
	};
};

export const isValidWorkOrder = (values: WorkOrderForm | WorkOrderViewModel) => {
	const errors: ValidationErrors = {};

	if (values.isFirst && values.isLate && !values.delayReason) {
		errors.delayReason = 'Delay reason is required.';
	}

	if (!values.supervisorId) {
		errors.supervisorId = 'Superintendent is required';
	}

	if (!values.dueDate) {
		errors.dueDate = 'Date of work is required';
	}

	if (!values.jobId) {
		errors.jobId = 'Job is required';
	}

	if (!values.job?.isInternal && !values.crewTypeId) {
		errors.crewTypeId = 'Crew type is required';
	}

	if (values.job?.isInternal && !(values.customCrewType?.trim())) {
		errors.customCrewType = 'Crew type is required';
	}

	if (!values?.shift?.id) {
		errors.shift = 'Shift is required';
	}

	if (values.addresses?.length) {
		const _addresses: ValidationErrors[] = [];
		values.addresses.forEach((_adr, _index) => {
			const _addressErrors = isValidAddressRequestObject(_adr, 'Work Order address ');
			if (!_addressErrors.isValid) {
				_addresses[_index] = _addressErrors.errors;
			}
		});
		if (_addresses.length > 0) {
			errors.addresses = _addresses;
		}
	}

	return {
		isValid: Object.keys(errors).length === 0,
		errors,
	};
};

export const isValidScheduleBoardWorkOrder = (values: ScheduleBoardWorkOrderViewModel) => {
	const errors: ValidationErrors = {};

	if (!values.supervisorId) {
		errors.supervisorId = 'Superintendent is required';
	}

	if (!values.dueDate) {
		errors.dueDate = 'Date of work is required';
	}

	if (!values.jobCode) {
		errors.jobId = 'Job is required';
	}

	if (!values.isInternal && !values.crewTypeId) {
		errors.crewTypeId = 'Crew type is required';
	}

	if (values.isInternal && !(values.customCrewType?.trim())) {
		errors.customCrewType = 'Crew type is required';
	}

	if (!values.shift) {
		errors.shift = 'Shift is required';
	}

	return {
		isValid: Object.keys(errors).length === 0,
		errors,
	};
};

export const areValidWorkOrdersMetrics = (values: ScheduleBoardMetricsRequestModel) => {
	const errors: ValidationErrors = {};

	const _woErrors: ValidationErrors[] = [];
	const workOrders = values?.workOrders ?? [];
	workOrders.forEach((_wo, _index) => {
		if (!!_wo) {
			const _err = _isValidWorkOrderMetrics(_wo);
			if (!!_err) {
				_woErrors[_index] = _err;
			}
		}
	});

	const isValid = _woErrors.length === 0;
	if (!isValid) {
		errors.workOrders = _woErrors;
		const columnsWithError = _woErrors.reduce((_acc: number[], _message: ValidationErrors, _index: number) => {
			if (_message) {
				_acc.push(_index + 1);
			}
			return _acc;
		}, []);
		errors._error = columnsWithError.join(', ');
	}

	return {
		isValid,
		errors,
	};
};

export const isValidMobileWorkOrderCreate = (form: WorkOrderUpsertRM) => {
	const errors: ValidationErrors = {};

	if (!isValidPositiveInteger(form.supervisorId)) {
		errors.supervisorId = 'Superintendent is required';
	}

	if (!isValidPositiveInteger(form.shift?.id) && !form.shift?.name) {
		errors.shift = 'Shift is required';
	}

	if (form.siteContact && form.siteContact.contactEmailIds.length === 0 && form.siteContact.contactPhoneIds.length === 0) {
		errors.siteContact = 'Provide at least one site contact method';
	}

	if (Object.keys(errors).length === 0) {
		return null;
	}

	return {
		errors,
		isValid: false,
	};
};

export const isValidMobileWorkOrderEdit = (form: WorkOrderUpsertRM) => {
	const errors: ValidationErrors = {};

	if (!isValidPositiveInteger(form.id)) {
		errors.id = 'Work order id is required';
	}

	if (Object.keys(errors).length === 0) {
		return null;
	}
	return {
		errors,
		isValid: false,
	};
};

export function isValidDigitalSignatureRequest(values: SubmitWorkOrderForReviewRM) {
	const errors: ValidationErrors = {};

	if (!Object.keys(values).length) {
		return { isValid: true };
	}

	if (!values.signatureImage && !values.digitalSignatureId) {
		errors.signatureImage = 'Signature is required';
	}

	if (values.signatureImage && values.digitalSignatureId) {
		errors.signatureImage = 'Submit for review requires either manual or digital signature.';
	}

	if (!values.signedAt) {
		errors.signedAt = 'Signed time is required';
	}

	const isValid = Object.keys(errors).length === 0;
	return {
		isValid,
		errors: { context: { ...errors } },
	};
}

export function isValidSignatureRequest(values: SignatureRequestModel.SignatureRequestModel) {
	const errors: ValidationErrors = {};

	if (!Object.keys(values).length) {
		return { isValid: true };
	}

	if (!values.signatureImage) {
		errors.signatureImage = 'Signature is required';
	}

	if (!values.signedAt) {
		errors.signedAt = 'Signed time is required';
	}

	const isValid = Object.keys(errors).length === 0;
	return {
		isValid,
		errors: { context: { ...errors } },
	};
}

export function isValidCreateTemporaryEmployeeAssignmentRequest(values: CreateTemporaryEmployeeAssignmentRM) {
	const errors: ValidationErrors = {};

	if (values.index !== undefined) {
		if (!isValidNumber(values.index) || values.index < 0) {
			errors.index = 'Index must be a valid positive integer';
		}
	}

	if (shouldCreateEmployee(values)) {
		const temporaryEmployee: ValidationErrors = {};
		if (!values.temporaryEmployee.firstName) {
			temporaryEmployee.firstName = 'Missing temporary employee first name';
		}

		if (!values.temporaryEmployee.lastName) {
			temporaryEmployee.lastName = 'Missing temporary employee last name';
		}

		if (!values.temporaryEmployee.agencyId) {
			temporaryEmployee.agencyId = 'Missing temporary employee agency';
		}
		if (!!Object.keys(temporaryEmployee).length) {
			errors.temporaryEmployee = temporaryEmployee;
		}
	} else {
		if (!values.temporaryEmployeeId) {
			errors.temporaryEmployeeId = 'Missing temporary employee id';
		}
	}

	const isValid = Object.keys(errors).length === 0;
	return {
		isValid,
		errors: { context: { ...errors } },
	};
}

export function isValidReportSignatureRequest(values: WorkOrderSendCustomerSignatureRequestForReportsRM) {
	const errors: ValidationErrors = {};

	for (const _email of (values.emails ?? [])) {
		if (!isValidEmail(_email)) {
			errors.email = `Email ${_email} is not valid`;
		}
	}

	for (const _phone of (values.phones ?? [])) {
		if (!isValidPhoneNumber(_phone)) {
			errors.phones = `Phone number ${_phone} is not valid`;
		}
	}

	const isValid = Object.keys(errors).length === 0;
	return {
		isValid,
		errors: { context: { ...errors } },
	};
}

export function isValidSendReportsRequest(values: WorkOrderSendReportsForCustomerRM) {
	const errors: ValidationErrors = {};

	for (const _email of (values.emails ?? [])) {
		if (!isValidEmail(_email)) {
			errors.email = `Email ${_email} is not valid`;
		}
	}

	for (const _phone of (values.phones ?? [])) {
		if (!isValidPhoneNumber(_phone)) {
			errors.phones = `Phone number ${_phone} is not valid`;
		}
	}

	const isValid = Object.keys(errors).length === 0;
	return {
		isValid,
		errors: { context: { ...errors } },
	};
}
