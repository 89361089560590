import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as sentry from '@sentry/react';
import { pdfjs } from 'react-pdf';
import { Router } from 'react-router-dom';
import { CompatRouter, useLocation, useNavigate } from 'react-router-dom-v5-compat';

import history from 'af-root/store/history';

import { LOCALSTORAGE_MULTIPLE_SIGNINS } from 'af-constants/localstorage';

import type { SignInData } from 'ab-viewModels/user.viewModel';

import socketConnectionHoc from 'af-components/socketConnectionInitHoc';

import App from 'af-root/App';
import AppStore from 'af-root/store';

import { getCurrentOrgAlias, setCompanyNameInPath } from 'af-utils/window.util';
import NR from 'af-utils/newRelic.util';

import { NavigationContext } from './context/navigationContext';

// Direct CSS imports from node_modules
import 'react-table-6/react-table.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'cropperjs/dist/cropper.min.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'acceligent-assets/assets/icons/style.css';

if (process.env.NODE_ENV !== 'development') {
	pdfjs.GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.min.mjs';
} else {
	pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
}

// you can add sessionStorage.setItem('enableWhyDidYouUpdate', true) start the logs
if (process.env.NODE_ENV === 'development' && sessionStorage.getItem('enableWhyDidYouUpdate')) {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const { whyDidYouUpdate } = require('why-did-you-update');
	whyDidYouUpdate(React);
}

window.onstorage = (event: StorageEvent) => {
	if (!event.key) {	// if storage clear
		window.location.reload();
		return;
	}
	if (event.key === LOCALSTORAGE_MULTIPLE_SIGNINS) {
		const oldMultipleSignIns: Nullable<SignInData[]> = event.oldValue ? JSON.parse(event.oldValue) : null;
		const newMultipleSignIns: Nullable<SignInData[]> = event.newValue ? JSON.parse(event.newValue) : null;

		if (!oldMultipleSignIns || !newMultipleSignIns) {
			return;
		}
		const orgAlias = getCurrentOrgAlias();
		const isSignInForOrg = (_signIn: SignInData) => _signIn.orgAlias === orgAlias;

		const oldCurrentOrganizationSignIn = oldMultipleSignIns.find(isSignInForOrg);
		const newCurrentOrganizationSignIn = newMultipleSignIns.find(isSignInForOrg);

		if (!oldCurrentOrganizationSignIn || !newCurrentOrganizationSignIn) {
			return;
		}

		if (oldCurrentOrganizationSignIn.companyId !== newCurrentOrganizationSignIn.companyId) {
			if (newCurrentOrganizationSignIn.companyName) {
				setCompanyNameInPath(orgAlias!, newCurrentOrganizationSignIn.companyName);
			} else {
				window.location.reload();
			}
		}
	}
};

/**
 * Custom provider for redux store and navigation context. Needed because router v6 uses hooks which can only be accessed inside React components.
 */
const CustomProvider = ({ children }: React.PropsWithChildren) => {

	const navigate = useNavigate();
	const [isInitialized, setIsInitialized] = React.useState(false);

	const location = useLocation();
	const navigationData = React.useContext(NavigationContext);

	React.useEffect(() => {
		AppStore.initStore(navigate);
		setIsInitialized(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		navigationData.from = navigationData.to;
		navigationData.to = location.pathname;
		navigationData.pathChangedSideEffects =
			navigationData.
				pathChangedSideEffects.
				filter((sideEffect) => !sideEffect(navigationData.to, navigationData.from));
	}, [location, navigationData]);

	if (!isInitialized) {
		return null;
	}

	return (
		<NavigationContext.Provider value={navigationData}>
			<Provider store={AppStore.getStore()}>
				{children}
			</Provider>
		</NavigationContext.Provider>
	);
};

/**
 * Router needs to be top level component so the router API is available to all components.
 */
const render = (AppComponent): void => {
	const AppComponentWithSocket = socketConnectionHoc(AppComponent);
	ReactDOM.render(
		<Router history={history}>
			<CompatRouter>
				<CustomProvider>
					<AppComponentWithSocket />
				</CustomProvider>
			</CompatRouter>
		</Router>
		,
		document.getElementById('root') as HTMLElement
	);
};

if (process.env.SENTRY_FE_URL) {
	sentry.init({
		release: process.env.npm_package_version,
		dsn: process.env.SENTRY_FE_URL,
		environment: process.env.ENVIRONMENT,
		integrations: [
			new sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			}),
			new sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

NR.init();

render(App);

if (module.hot) {
	module.hot.accept('./App', () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		render(require('./App').default);
	});
}
