import type { ColorPalette, ExtendedColorValue } from 'acceligent-shared/enums/color';
import type WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import type WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';

import type { SearchableModel } from 'ab-common/dataStructures/scheduleBoardQuery';

import { getShortAddress } from 'acceligent-shared/utils/address';
import * as TimeUtils from 'acceligent-shared/utils/time';
import * as UserUtils from 'acceligent-shared/utils/user';
import * as TimeOptionUtils from 'acceligent-shared/utils/timeOption';

import type AddressBase from 'ab-domain/models/address/base';
import type WorkOrderBase from 'ab-domain/models/workOrder/base';
import type EmployeeBase from 'ab-domain/models/employee/base';
import type CrewTypeBase from 'ab-domain/models/crewType/base';

import { NIGHT_SHIFT } from 'ab-enums/shift.enum';
import { stateAbbreviation } from 'ab-enums/states.enum';

import * as TextUtil from 'ab-utils/text.util';
import * as CodeUtils from 'ab-utils/codes.util';
import { getId } from 'ab-utils/array.util';
import { getStreetAddress } from 'ab-utils/formatting.util';

import type { ScheduleBoardQueryMatchingFunction } from 'ab-common/dataStructures/scheduleBoardQuery';

import type ValidationState from 'ab-enums/validationState.enum';

import { UNKNOWN_LOCATION_NICKNAME } from 'ab-common/constants/value';

import BasicCrewType from 'ab-common/dataStructures/basicCrewType';

class CrewTypeViewModel extends BasicCrewType {
	id: Nullable<number>;

	constructor(crewType: Nullable<CrewTypeBase>) {
		super(crewType?.name ?? null, crewType?.color ?? null);
		if (!crewType) {
			// UNDEFINED_CREW_TYPE
			this.id = null;
			return;
		}
		this.id = crewType.id;
	}

	/**
	 * Used for crew types of internal jobs
	 * @param crewTypeName usually `WorkOrder.customCrewType`
	 */
	static internal(crewTypeName: Nullable<string>): CrewTypeViewModel {
		return {
			...BasicCrewType.internal(crewTypeName),
			id: null,
		};
	}
}

class AddressViewModel {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	stateAbbreviation: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	shortAddress: string;
	latitude: number;
	longitude: number;

	constructor(address: AddressBase) {
		this.street = getStreetAddress(address);
		this.streetNumber = address.streetNumber;
		this.route = address.route;
		this.suite = address.suite ?? null;
		this.city = address.locality;
		this.state = address.aa1;
		this.stateAbbreviation = address.aa1 ? stateAbbreviation[address.aa1] : null;
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.zip = address.postalCode;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode ?? null;
		this.country = address.country;
		this.shortAddress = getShortAddress(address);
		this.longitude = address.longitude;
		this.latitude = address.latitude;
	}
}

export class EmployeeVM {
	id: number;
	formattedCode: string;
	firstName: string;
	lastName: string;
	fullName: string;
	accountId: number;
	locationNickname: string | undefined;
	locationColor: ColorPalette | undefined;

	constructor(employee: EmployeeBase) {
		this.id = employee.id;
		this.formattedCode = employee.account.user.uniqueId;
		this.firstName = employee.account.user.firstName;
		this.lastName = employee.account.user.lastName;
		this.fullName = UserUtils.getUserName(employee.account.user);
		this.accountId = employee.accountId;
		this.locationNickname = employee.account.location?.nickname;
		this.locationColor = employee.account.location?.color;
	}
}

export interface BlankWorkOrder {
	isBlank: boolean;
}

export interface PlaceholderWorkOrder {
	isPlaceholder: boolean;
}

export default class ScheduleBoardWorkOrderViewModel implements SearchableModel {
	static matches: ScheduleBoardQueryMatchingFunction<ScheduleBoardWorkOrderViewModel> = TextUtil.matches;

	id: number;
	code: string;
	/** Without date portion of code - for Schedule Board purposes */
	codeStripped: string;
	/** Visual index, starting from 1 */
	index: number;
	supervisorId: Nullable<number>;
	projectManagerId: Nullable<number>;
	crewTypeId: Nullable<number>;
	crewTypeName: Nullable<string>;
	crewTypeColor: Nullable<ExtendedColorValue>;
	customCrewType: Nullable<string>;
	isInternal: boolean;
	supervisor: Nullable<EmployeeVM>;
	projectManager: Nullable<EmployeeVM>;
	workOrderResourceLookups: number[];
	title: string;
	customer: Nullable<string>;
	workLocations: AddressViewModel[];
	address: string;
	officeNickname: string;
	officeColor: Nullable<ColorPalette>;
	companyAddress: string;
	timeToStart: Nullable<number>;
	timeToEnd: Nullable<number>;
	workStart: string;
	workEnd: string;
	jobCode: string;
	jobStatus: WorkRequestStatus;
	workOrderNotes: Nullable<string>;
	hseRequirementNotes: string;
	scheduleNote: string;
	locked: boolean;
	approved: boolean;
	isFirst: boolean;
	isLate: boolean;
	validationState: ValidationState;
	status: WorkOrderStatus;
	dueDate: string;
	revision: string;
	isDisabled: boolean;
	isFiltered: boolean;
	isMatched?: boolean;
	shift: string;
	isNightShift: boolean;
	office: string;
	division: string;
	isBlank: boolean;
	revenue: Nullable<string>;
	manHourAverage: Nullable<string>;
	jobHours: Nullable<number>;
	shopHours: Nullable<number>;
	travelHours: Nullable<number>;
	workDescription: Nullable<string>;
	hasAttachments?: boolean;
	excludeFromNotify: boolean;
	excludeFromDeliverables: boolean;
	isPaused: boolean;
	pauseReason: Nullable<string>;
	projectJobCode: Nullable<string>;
	isUnion: boolean;
	isPrevailingWage: boolean;
	jobStatusColor: Nullable<ColorPalette>;
	jobStatusName: Nullable<string>;
	excludeTempLaborFromNotify: boolean;

	_desc: string;

	constructor(workOrder: WorkOrderBase, locks?: ScheduleBoardWorkOrdersLocks) {
		if (!workOrder.workRequest.title) {
			throw new Error('Work Order title not defined');
		}

		if (workOrder.index === null) {
			throw new Error('Work Order doesn\'t have an index');
		}

		this.id = workOrder.id;
		this.code = CodeUtils.workOrderCode(workOrder, workOrder.workRequest);
		this.codeStripped = CodeUtils.workOrderCodeStripped(workOrder, workOrder.workRequest);
		this.title = workOrder.workRequest.title;
		this.index = workOrder.index;
		this.timeToStart = workOrder.timeToStart;
		this.timeToEnd = workOrder.timeToEnd;
		this.workStart = TimeOptionUtils.format(workOrder.timeToStart);
		this.workEnd = TimeOptionUtils.format(workOrder.timeToEnd);

		const crewType = workOrder.isInternal
			? CrewTypeViewModel.internal(workOrder.customCrewType)
			: new CrewTypeViewModel(workOrder.crewType);
		this.crewTypeId = crewType.id;
		this.crewTypeName = crewType.name;
		this.crewTypeColor = crewType.color;
		this.customCrewType = CrewTypeViewModel.internal(workOrder.customCrewType).name;

		this.isInternal = workOrder.isInternal;
		this.jobStatus = workOrder.workRequest?.status;

		this.supervisorId = workOrder.supervisorId;
		this.supervisor = workOrder.supervisor && new EmployeeVM(workOrder.supervisor);

		this.projectManagerId = workOrder.projectManagerId;
		this.projectManager = workOrder.projectManager && new EmployeeVM(workOrder.projectManager);
		this.isBlank = false;

		this.workOrderNotes = workOrder.notes;

		this.workOrderResourceLookups = (workOrder.workOrderResourceLookups ?? []).map(getId);

		this.revision = CodeUtils.revisionCode(workOrder.revision) || undefined;
		this.isDisabled = false;
		this.isFiltered = false;

		this.customer = workOrder.workRequest?.customerCompanyName ?? workOrder.workRequest?.customerContact?.contact?.companyName ?? null;

		const _travelLocation = workOrder.workRequest?.travelLocation ?? {} as AddressBase;
		this.address = new AddressViewModel(_travelLocation).shortAddress;

		this.officeNickname = workOrder.workRequest?.office?.nickname ?? UNKNOWN_LOCATION_NICKNAME;
		this.officeColor = workOrder.workRequest?.office?.color ?? null;

		this.jobCode = CodeUtils.workRequestCode(workOrder.workRequest.year, workOrder.workRequest?.code);
		this.locked = !!locks?.[workOrder.id] ?? false;

		this.isFirst = false; // FIXME:
		// TODO: AP-2095, uncomment this if delay modal is needed in future
		// this.isLate = workOrder.dueDate > workOrder.workRequest.startDate;

		this.status = workOrder.status;
		this.dueDate = TimeUtils.formatDate(workOrder.dueDate);
		this.shift = workOrder.shift?.name;
		this.isNightShift = workOrder.shiftId === NIGHT_SHIFT.id;

		this.revenue = workOrder.revenue ?? null;
		this.manHourAverage = workOrder.manHourAverage ?? null;
		this.jobHours = workOrder.jobHours ?? null;
		this.shopHours = workOrder.shopHours ?? null;
		this.travelHours = workOrder.travelHours ?? null;
		this.workDescription = workOrder.workDescription ?? null;
		this.isPaused = workOrder.isPaused;

		const searchableLiterals = [this.codeStripped, this.crewTypeName, this.customer, this.companyAddress, this.title];
		if (this.supervisor) {
			const supervisorName = `${this.supervisor.firstName} ${this.supervisor.lastName}`;
			const supervisorNameShort = `${this.supervisor.firstName[0]} ${this.supervisor.lastName}`;
			const supervisorNameInverse = `${this.supervisor.lastName} ${this.supervisor.firstName}`;
			searchableLiterals.push(supervisorName, supervisorNameShort, supervisorNameInverse);
		} else {
			searchableLiterals.push('N/A');
		}
		const workTime = (this.workStart && this.workEnd) ? `${this.workStart} - ${this.workEnd}` : '';
		searchableLiterals.push(workTime);

		this._desc = searchableLiterals.join(' ');

		if (workOrder.workOrderDirectories) {
			this.hasAttachments = workOrder.workOrderDirectories.some((_woDir) => !!_woDir.directory.attachmentDirectories?.length);
		}
		this.isMatched = false;
		this.excludeFromNotify = workOrder.excludeFromNotify;
		this.excludeFromDeliverables = workOrder.excludeFromDeliverables;

		this.isPaused = workOrder.isPaused;
		this.pauseReason = workOrder.pauseReason;
		this.projectJobCode = workOrder.projectJobCode();

		this.isUnion = workOrder.workRequest.isUnion;
		this.isPrevailingWage = workOrder.workRequest.isPrevailingWage;
		this.jobStatusColor = workOrder.workRequest.jobStatus?.color ?? null;
		this.jobStatusName = workOrder.workRequest.jobStatus?.name ?? null;

		this.excludeTempLaborFromNotify = workOrder.excludeTempLaborFromNotify;
	}
}

export type ScheduleBoardWorkOrdersViewModel = { [workOrderCode: string]: ScheduleBoardWorkOrderViewModel; };

export type ScheduleBoardWorkOrdersLocks = { [workOrderId: string]: true; };

export type ScheduleBoardColumnPlaceHolder = ScheduleBoardWorkOrderViewModel | PlaceholderWorkOrder | BlankWorkOrder;
