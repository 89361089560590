import * as React from 'react';
import { connect } from 'react-redux';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import type { RootState } from 'af-reducers';

import EmployeesDroppable from 'af-root/scenes/Company/ScheduleBoard/DailyView/EmployeesDroppable';

import StatusIcon from 'af-components/StatusIcon';

import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import { AVAILABLE_EMPLOYEE_STATUS } from 'ab-common/constants/employee';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import type { EmployeeModalProps } from '../../../../Shared/ModalProps';

interface OwnProps extends EmployeeModalProps {
	dueDate: string;
	hasReason?: boolean;
	available: boolean;
	groupTitle: Nullable<string>;
	groupId: number;
	isDeleted: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	openNotifyModal?: (groupId: number) => void;
}

interface StateProps {
	employeeIds: number[];
	hasArrow: boolean;
	employeesNotified: boolean;
	isShowNotifyAvailableEmployeesDisabled: boolean;
	disabled: boolean;
}

type Props = OwnProps & StateProps;

class EmployeeGroup extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		employeeIds: [],
		groupTitle: AVAILABLE_EMPLOYEE_STATUS,
		available: true,
		hasReason: false,
	};

	openNotificationModal = () => {
		const { groupId, openNotifyModal } = this.props;
		openNotifyModal?.(groupId);
	};

	render() {
		const {
			employeeIds,
			dueDate,
			isDeleted,
			hasArrow,
			available,
			groupTitle,
			groupId,
			hasReason,
			isShowNotifyAvailableEmployeesDisabled,
			employeesNotified,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			hasPermissionsToSendNotifications,
			disabled,
			setEmployeeModalData,
			setEmployeeModalVisibility,
		} = this.props;

		if (isDeleted && employeeIds.length === 0) {
			return null;
		}

		const notificationsAvailable = available
			&& !isShowNotifyAvailableEmployeesDisabled
			&& hasPermissionsToSendNotifications;

		return (
			<div className="schedule-board-toolbar-employees-group" key={groupId}>
				<div className="schedule-board-arrow-container">
					<div className={`schedule-board-arrow ${hasArrow ? '--visible' : '--invisible'}`} />
				</div>
				<div className="group-title">
					{
						available ?
							<img src="/images/elements/ic_state_complete.svg" /> :
							<img src="/images/elements/ic_state_incomplete.svg" />
					}
					<span>{groupTitle}</span>
					{
						notificationsAvailable &&
						<span className="notification-status">
							<StatusIcon
								firstRevision={false}
								isSmall={true}
								notificationsEnabled={!isShowNotifyAvailableEmployeesDisabled}
								notificationStatus={employeesNotified ? NotificationStatusEnum.OPENED : NotificationStatusEnum.DELIVERED}
								onClick={this.openNotificationModal}
								status={WorkOrderStatus.PUBLISHED}
							/>
						</span>
					}
				</div>
				<hr />
				<EmployeesDroppable
					available={available}
					disabled={disabled || isDeleted}
					draggablePrefix={ScheduleBoardContext.TOOLBAR}
					droppableId={ScheduleBoardUtil.generateDroppableId(ScheduleBoardContext.TOOLBAR, ScheduleBoardProperty.EMPLOYEE, dueDate, `${groupId}`, undefined, available)}
					dueDate={dueDate}
					employeeIds={employeeIds}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					hasReason={hasReason}
					isDragAndDropDisabled={isDragAndDropDisabled}
					isToolbar={true}
					setEmployeeModalData={setEmployeeModalData}
					setEmployeeModalVisibility={setEmployeeModalVisibility}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate, available, groupId } = ownProps;
	const { employees, workOrdersByDateDictionary } = state.scheduleBoard;
	const { company } = state.company;

	const availability = ScheduleBoardUtil.getAvailabilityLabel(available);
	const employeeIds = workOrdersByDateDictionary?.[dueDate]?.toolbarEmployees?.[availability]?.[groupId]?.filter(
		(_empId) => employees?.[_empId]?.account?.assignableToWorkOrder
	) ?? [];
	const { availableNotificationStatuses } = workOrdersByDateDictionary?.[dueDate];

	const hasArrow = employeeIds.some((_id) => employees?.[_id]?.isMatched);

	const employeesNotified = !employeeIds.some((_id) => {
		const emailStatus = availableNotificationStatuses?.[_id]?.emailStatus !== NotificationStatusEnum.OPENED;
		const smsStatus = availableNotificationStatuses?.[_id]?.smsStatus !== NotificationStatusEnum.OPENED;
		return emailStatus && smsStatus;
	});

	return {
		disabled: !state.scheduleBoard.draggedEmployeeId,
		hasArrow,
		employeeIds: employeeIds.filter((_empId) => employees?.[_empId]?.showOnScheduleBoard ?? false),
		employeesNotified,
		isShowNotifyAvailableEmployeesDisabled: !company?.notification?.isEnabled ?? false,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(EmployeeGroup);
