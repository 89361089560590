import * as React from 'react';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import type { ResourcesGroupItem } from 'ab-viewModels/scheduleBoardToolbarResources.viewModel';

import EmployeeGroup from './EmployeeGroup';

import type { EmployeeModalProps } from '../../../Shared/ModalProps';

interface OwnProps extends EmployeeModalProps {
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
}

interface StateProps {
	employeeStatuses?: ResourcesGroupItem[];
}

type Props = OwnProps & StateProps;

class UnavailableEmployees extends React.Component<Props> {
	static defaultProps: Partial<Props> = {
		employeeStatuses: [],
	};

	render() {
		const {
			dueDate,
			employeeStatuses,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			setEmployeeModalData,
			setEmployeeModalVisibility,
		} = this.props;

		if (!employeeStatuses?.length) {
			return null;
		}

		return (
			<div className="schedule-board-toolbar-employees-row">
				<label>Labor Unavailable</label>
				<div className="schedule-board-toolbar-employees-wrapper">
					<div className="schedule-board-toolbar-employees">
						{
							employeeStatuses.map(({ id: _empStatusId, title: _empStatus, isDeleted }) => (
								<EmployeeGroup
									available={false}
									dueDate={dueDate}
									groupId={_empStatusId}
									groupTitle={_empStatus}
									hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
									hasPermissionsToSendNotifications={false}
									hasReason={true}
									isDeleted={isDeleted}
									isDragAndDropDisabled={isDragAndDropDisabled}
									key={_empStatusId}
									setEmployeeModalData={setEmployeeModalData}
									setEmployeeModalVisibility={setEmployeeModalVisibility}
								/>
							))
						}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	return {
		employeeStatuses: state?.scheduleBoard?.toolbarEmployeeGroupTitles?.unavailable,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(UnavailableEmployees);
