import * as React from 'react';

import * as ArrayUtils from 'acceligent-shared/utils/array';

import RepeatableBlockType from 'acceligent-shared/enums/repeatableBlockType';

import type { PCFRBlockVM, PCFRBlockFieldVM, PCRBlockFieldVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import RepeatingFieldReportBlock from './RepeatingFieldReportBlock';
import FieldReportBlockField from './FieldReportBlockField';
import RepeatingFieldReportBlockField from './RepeatingFieldReportBlockField';

interface OwnProps {
	fieldReportBlock: PCFRBlockVM;
}

type Props = OwnProps;

const FieldReportReportBlock: React.FC<Props> = (props: Props) => {
	const { fieldReportBlock } = props;
	const { fieldReportBlockFields, reportBlock } = fieldReportBlock;

	const repeatingCount = React.useMemo(() => (reportBlock.isRepeating
		? (fieldReportBlockFields?.[0]?.value?.length ?? 0)
		: 0
	), [fieldReportBlockFields, reportBlock.isRepeating]);

	const repeatingBlockIndexes = React.useMemo(() => ArrayUtils.range(0, repeatingCount), [repeatingCount]);

	const headerFields = React.useMemo(() => fieldReportBlockFields.map((_field) => _field.reportBlockField), [fieldReportBlockFields]);

	const tableType = React.useMemo(() => (reportBlock.repeatableBlockType === RepeatableBlockType.INLINE_TABLE
		? 'inline'
		: 'appendix')
	, [reportBlock.repeatableBlockType]);

	const className = React.useMemo(() => (reportBlock.repeatableBlockType === RepeatableBlockType.DEFAULT
		? 'public-report__segment__row'
		: `public-report__segment__${tableType}-table`)
	, [reportBlock.repeatableBlockType, tableType]);

	// i.e. is Inline or Appendix table
	const isTableWithHeaderRow = React.useMemo(() => !!reportBlock.repeatableBlockType && reportBlock.repeatableBlockType !== RepeatableBlockType.DEFAULT
		, [reportBlock.repeatableBlockType]);

	const _renderField = (_field: PCFRBlockFieldVM) => <FieldReportBlockField fieldReportField={_field} key={_field.id} />;

	const _renderRepeatingField = React.useCallback((_index: number) => (
		<RepeatingFieldReportBlock
			className={className}
			fieldReportBlock={fieldReportBlock}
			index={_index}
			inverse={isTableWithHeaderRow}
			key={_index}
		/>
	), [className, fieldReportBlock, isTableWithHeaderRow]);

	const _renderHeaderField = React.useCallback((_field: PCRBlockFieldVM, index: number) =>
		(
			<>
				<RepeatingFieldReportBlockField
					index={0}
					inverse={isTableWithHeaderRow}
					key={index}
					renderLabel={true}
					renderValue={false}
					repeatableBlockType={reportBlock.repeatableBlockType}
					reportBlockField={_field}
				/>
			</>
		)
	, [isTableWithHeaderRow, reportBlock.repeatableBlockType]);

	if (!reportBlock.isRepeating) {
		return (
			<div className="public-report__segment__row">
				{fieldReportBlockFields.map(_renderField)}
			</div>
		);
	}

	return (
		<>
			{
				isTableWithHeaderRow &&

				<div className={`${className} ${className}__header`}>
					{
						headerFields?.map(_renderHeaderField)
					}
				</div>
			}
			{repeatingBlockIndexes.map(_renderRepeatingField)}
		</>
	);

};

export default React.memo(FieldReportReportBlock);
