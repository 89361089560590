import type { Dispatch, AnyAction } from 'redux';

import type ToolState from 'acceligent-shared/enums/toolState';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type ToolTableViewModel from 'ab-viewModels/tool/table.viewModel';
import type OptionVM from 'ab-viewModels/tool/option.viewModel';
import type ToolVM from 'ab-viewModels/tool/edit.viewModel';
import type ListVM from 'ab-viewModels/tool/list.viewModel';
import type ToolTrackingRecordVM from 'ab-viewModels/toolTrackingRecord/table.viewModel';

import type UpsertToolRM from 'ab-requestModels/tool/upsert.requestModel';
import type ChangeLocationRM from 'ab-requestModels/tool/changeLocation.requestModel';

import type { GetRootState } from 'af-reducers';

export function create(form: UpsertToolRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.RESOURCES.TOOL.CREATE, form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(id: number, data: UpsertToolRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.RESOURCES.TOOL.FIND_BY_ID(id), data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ToolVM>(API.V1.RESOURCES.TOOL.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findTakenSerialList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ListVM[]>(API.V1.RESOURCES.TOOL.FIND_SERIAL_NUMBER_LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteTool(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.TOOL.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<ToolTableViewModel>>(API.V1.RESOURCES.TOOL.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<OptionVM[]>(API.V1.RESOURCES.TOOL.FIND_OPTION_LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function fetchToolHistoryTable(tableRequestModel: TableQuery, toolId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<ToolTrackingRecordVM>>(API.V1.RESOURCES.TOOL.HISTORY(toolId, data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function changeLocation(form: ChangeLocationRM, toolId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.put<void>(API.V1.RESOURCES.TOOL.CHANGE_LOCATION(toolId), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function changeToolState(toolId: number, state: ToolState) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.put<void>(API.V1.RESOURCES.TOOL.CHANGE_STATE(toolId), { state });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
