import * as React from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';
import type NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import type { RootState } from 'af-reducers';

import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';
import type * as ScheduleBoardResourceLookupVM from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardResourceLookup.viewModel';

import type { SelectedWorkOrderModel } from 'af-models/scheduleBoard.models';

import Checkbox from 'af-components/Controls/Checkbox';

import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';
import ScheduleBoardViewEnum from 'ab-enums/scheduleBoardView.enum';

import { RESOURCE_PLACEHOLDER } from 'ab-common/constants/scheduleBoard';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import CardInputFields from './CardInputFields';
import CardData from './CardData';

import type SharedModalProps from '../../Shared/ModalProps';

interface OwnProps extends SharedModalProps {
	index: number;
	/** `MM-DD-YYYY` */
	dueDate: string;
	workOrderCode: string;
	forceUnlockOrder?: (workOrderId: string) => void;
	showMetrics?: boolean;
	maxResourceItems?: number;
	ignorePlaceholders?: boolean;
	areDailyMetricsLocked?: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	isCalculationsView?: boolean;
	/** prop not used if `readOnly = true` */
	selectOrder?: (workOrder: SelectedWorkOrderModel) => void;
	/** prop not used if `readOnly = true` */
	deselectOrder?: (workOrder: SelectedWorkOrderModel) => void;
	/** prop not used if `readOnly = true` */
	addBlankWorkOrder?: (dueDate: string, index: number) => Promise<void>;
	/** prop not used if `isCalculationsView = true` */
	lastOpenedOrderCode?: string;
}

interface StateProps {
	columnNumber: number;
	copiedWorkOrderCode: Nullable<string>;
	hasCardArrow: boolean;
	isActive: boolean;
	isCardDisabled: boolean;
	isCardFiltered: boolean;
	isCardHighlighted: boolean;
	isCardLocked: boolean;
	isInternal: boolean;
	isMultiSelectModeActive: boolean;
	isOrderSelected: boolean;
	notificationStatus: Nullable<NotificationStatusEnum>;
	searchableId: string | undefined;
	workOrderId: Nullable<number>;
	isCancelled: boolean;
}

type Props = OwnProps & StateProps;

class Card extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		isDragAndDropDisabled: false,
		hasPermissionsToEditScheduleBoard: true,
		isCalculationsView: false,
		maxResourceItems: 0,
		showMetrics: false,
		areDailyMetricsLocked: false,
	};

	forceUnlockOrder = () => {
		const { forceUnlockOrder, workOrderId } = this.props;
		if (!forceUnlockOrder || !workOrderId) {
			// This should never happen
			console.error('[FORCE_UNLOCK_WORK_ORDER ERROR]: Missing unlock action or Work Order id on target');
			return;
		}
		forceUnlockOrder(`${workOrderId}`);
	};

	addBlankColumn = async () => {
		const { addBlankWorkOrder, dueDate, index, hasPermissionsToEditScheduleBoard } = this.props;
		if (!hasPermissionsToEditScheduleBoard) {
			return;
		}
		await addBlankWorkOrder?.(dueDate, index);
	};

	onCheck = () => {
		const { workOrderId, workOrderCode, selectOrder, deselectOrder, isOrderSelected, hasPermissionsToEditScheduleBoard, isCancelled } = this.props;
		if (!hasPermissionsToEditScheduleBoard) {
			return;
		}
		const action = isOrderSelected ? deselectOrder : selectOrder;
		action?.({ id: workOrderId!, code: workOrderCode, isCancelled });
	};

	render() {
		const {
			areDailyMetricsLocked,
			columnNumber,
			copiedWorkOrderCode,
			dueDate,
			hasCardArrow,
			hasPermissionsToEditScheduleBoard,
			ignorePlaceholders,
			index,
			isActive,
			isCalculationsView,
			isCardDisabled,
			isCardFiltered,
			isCardHighlighted,
			isCardLocked,
			isDragAndDropDisabled,
			isInternal,
			isMultiSelectModeActive,
			isOrderSelected,
			lastOpenedOrderCode,
			maxResourceItems,
			notificationStatus,
			searchableId,
			showMetrics,
			workOrderCode,
			setWorkOrderModalId,
			setWorkOrderNoteModalData,
			setEmployeeModalData,
			setEquipmentModalData,
			setTemporaryEmployeeModalData,
			setEmployeeModalVisibility,
			setEquipmentModalVisibility,
			currentWorkOrderModalId,
		} = this.props;

		const isCardSelected = isMultiSelectModeActive && isOrderSelected;

		let className = 'schedule-board-card';
		className += isCardDisabled ? ' disabled' : '';
		className += isCardHighlighted ? ' highlighted' : '';
		className += isActive ? ' highlighted-active' : '';
		className += isCardFiltered ? ' filtered' : '';
		const classNameWithSelected = `${className} ${isCardSelected ? ' selected' : ''}`;
		const cardWrapperClassName = `schedule-board-card-wrapper columns-${columnNumber} ${isInternal ? 'internal' : ''}`;

		if (showMetrics) {
			return (
				<div className={cardWrapperClassName}>
					<div className="schedule-board-arrow-index-container">
						<div className="schedule-board-card-index pointer">{index + 1}</div>
					</div>
					<div className={classNameWithSelected} >
						<CardData
							currentWorkOrderModalId={currentWorkOrderModalId}
							dueDate={dueDate}
							hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
							ignorePlaceholders={ignorePlaceholders}
							isCalculationsView={isCalculationsView}
							isCardDisabled={isCardDisabled}
							isDragAndDropDisabled={isDragAndDropDisabled}
							isDragging={false}
							lastOpenedOrderCode={lastOpenedOrderCode}
							maxResourceItems={maxResourceItems}
							notificationStatus={notificationStatus}
							setEmployeeModalData={setEmployeeModalData}
							setEmployeeModalVisibility={setEmployeeModalVisibility}
							setEquipmentModalData={setEquipmentModalData}
							setEquipmentModalVisibility={setEquipmentModalVisibility}
							setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
							setWorkOrderModalId={setWorkOrderModalId}
							setWorkOrderNoteModalData={setWorkOrderNoteModalData}
							workOrderCode={workOrderCode}
						/>
						{!isInternal && <CardInputFields fieldPrefix={`workOrders[${index}]`} isDisabled={areDailyMetricsLocked} />}
					</div>
				</div>
			);
		}

		return (
			<div className={cardWrapperClassName}>
				<div className="schedule-board-arrow-index-container">
					{
						hasCardArrow ?
							<div className="schedule-board-arrow --visible" /> :
							<div className="schedule-board-card-index pointer" onClick={this.addBlankColumn}>{index + 1}</div>
					}
				</div>
				<Draggable
					draggableId={ScheduleBoardUtil.generateDroppableId(ScheduleBoardContext.BOARD, ScheduleBoardProperty.WORK_ORDER, dueDate, workOrderCode)}
					index={index}
					isDragDisabled={isCardDisabled || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard}
				>
					{(provided, snapshot) => {
						return (
							<>
								<div
									className={classNameWithSelected}
									id={searchableId}
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									onDoubleClick={isCardLocked ? this.forceUnlockOrder : undefined}
								>
									{isMultiSelectModeActive &&
										<Checkbox
											extraClass="schedule-board-card-checkbox"
											handleChange={this.onCheck}
											isChecked={isOrderSelected}
										/>
									}
									<CardData
										currentWorkOrderModalId={currentWorkOrderModalId}
										dueDate={dueDate}
										hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
										ignorePlaceholders={ignorePlaceholders}
										isCalculationsView={isCalculationsView}
										isCardDisabled={isCardDisabled}
										isDragAndDropDisabled={isDragAndDropDisabled}
										isDragging={snapshot.isDragging}
										lastOpenedOrderCode={lastOpenedOrderCode}
										maxResourceItems={maxResourceItems}
										notificationStatus={notificationStatus}
										setEmployeeModalData={setEmployeeModalData}
										setEmployeeModalVisibility={setEmployeeModalVisibility}
										setEquipmentModalData={setEquipmentModalData}
										setEquipmentModalVisibility={setEquipmentModalVisibility}
										setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
										setWorkOrderModalId={setWorkOrderModalId}
										setWorkOrderNoteModalData={setWorkOrderNoteModalData}
										workOrderCode={workOrderCode}
									/>
								</div>
								{copiedWorkOrderCode && copiedWorkOrderCode === workOrderCode && (
									<div className={`${className} --copy-placeholder ${!snapshot.isDragging ? 'display-none' : ''}`}>
										<CardData
											currentWorkOrderModalId={currentWorkOrderModalId}
											dueDate={dueDate}
											hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
											ignorePlaceholders={ignorePlaceholders}
											isCalculationsView={isCalculationsView}
											isCardDisabled={isCardDisabled}
											isCopyPlaceholder={true}
											isDragAndDropDisabled={isDragAndDropDisabled}
											isDragging={snapshot.isDragging}
											lastOpenedOrderCode={lastOpenedOrderCode}
											maxResourceItems={maxResourceItems}
											notificationStatus={notificationStatus}
											setEmployeeModalData={setEmployeeModalData}
											setEmployeeModalVisibility={setEmployeeModalVisibility}
											setEquipmentModalData={setEquipmentModalData}
											setEquipmentModalVisibility={setEquipmentModalVisibility}
											setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
											setWorkOrderModalId={setWorkOrderModalId}
											setWorkOrderNoteModalData={setWorkOrderNoteModalData}
											workOrderCode={workOrderCode}
										/>
									</div>
								)}
							</>
						);
					}}
				</Draggable>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate, workOrderCode } = ownProps;
	const {
		activeSearchItemIndex,
		copiedWorkOrderCode,
		employees,
		equipment,
		temporaryEmployees,
		isMultiSelectModeActive,
		scheduleBoardView,
		searchResultItems,
		selectedWorkOrders,
		weeklyViewSelectMultiple,
		workOrdersByDateDictionary,
	} = state.scheduleBoard;
	const workOrdersOnDateDict: ScheduleBoardOnDateViewModel = workOrdersByDateDictionary[dueDate];
	const workOrder: ScheduleBoardWorkOrderViewModel = workOrdersOnDateDict?.workOrders?.[workOrderCode];

	const columnNumber: number = scheduleBoardView === ScheduleBoardViewEnum.DAILY_VIEW
		? workOrdersOnDateDict?.columnNumbersDict[workOrderCode]
		: ScheduleBoardUtil.getColumnNumberForWorkOrder(workOrder);

	if (!workOrder) {
		return {
			columnNumber,
			copiedWorkOrderCode,
			hasCardArrow: false,
			isActive: false,
			isCardDisabled: false,
			isCardFiltered: false,
			isCardHighlighted: false,
			isCardLocked: false,
			isInternal: false,
			isMultiSelectModeActive: isMultiSelectModeActive || !!weeklyViewSelectMultiple[dueDate],
			isOrderSelected: false,
			notificationStatus: ScheduleBoardUtil.getGlobalParticipantNotificationStatus([], {}, false),
			searchableId: undefined,
			workOrderId: null,
			isCancelled: false,
		};
	}

	const { assignedCanceledNotificationStatuses = {}, assignedPublishedNotificationStatuses = {} } = workOrdersOnDateDict ?? {};
	const employeesDict = employees ?? {};
	const equipmentDict = equipment ?? {};
	const tempEmployeesDict = temporaryEmployees ?? {};
	const isWorkOrderCanceled: boolean = workOrder.status === WorkOrderStatusEnum.CANCELED;

	const searchableId = workOrder.id ? ScheduleBoardUtil.generateWorkOrderSearchItemId(workOrder.id.toString()) : undefined;

	const notificationStatusMap = isWorkOrderCanceled ? assignedCanceledNotificationStatuses : assignedPublishedNotificationStatuses;
	const workOrderResourceLookupsDict = isWorkOrderCanceled
		? workOrdersOnDateDict?.canceledWorkOrderResourceLookups
		: workOrdersOnDateDict?.workOrderResourceLookups;

	const workOrderResourceLookups: ScheduleBoardResourceLookupVM.Single[] = [];
	let anyResourceMatched = false;
	if (!!workOrderResourceLookupsDict && !!workOrder.workOrderResourceLookups) {
		for (const _resourceId of workOrder.workOrderResourceLookups) {
			if (_resourceId === RESOURCE_PLACEHOLDER) {
				continue;
			}
			const _resourceLookup = workOrderResourceLookupsDict[_resourceId];
			if (!_resourceLookup) {
				continue;
			}
			workOrderResourceLookups.push(_resourceLookup);
			const _isResourceMatched = (!!_resourceLookup.employeeId && !!employeesDict[_resourceLookup.employeeId]?.isMatched)
				|| (!!_resourceLookup.equipmentId && !!equipmentDict[_resourceLookup.equipmentId]?.isMatched)
				|| (!!_resourceLookup.temporaryEmployeeId && !!tempEmployeesDict[_resourceLookup.temporaryEmployeeId]?.isMatched);
			anyResourceMatched = anyResourceMatched || _isResourceMatched;
		}
	}

	return {
		columnNumber,
		copiedWorkOrderCode,
		hasCardArrow: !!workOrder.isMatched || anyResourceMatched,
		isActive: searchResultItems[activeSearchItemIndex] === searchableId,
		isCardDisabled: workOrder.locked || workOrder.isDisabled,
		isCardFiltered: workOrder.isFiltered,
		isCardHighlighted: !!workOrder.isMatched,
		isCardLocked: workOrder.locked,
		isInternal: workOrder.isInternal,
		isMultiSelectModeActive: isMultiSelectModeActive || !!weeklyViewSelectMultiple[dueDate],
		isOrderSelected: selectedWorkOrders.some(({ id }) => id === workOrder.id) || !!(weeklyViewSelectMultiple[dueDate]?.[workOrder.id]),
		notificationStatus: ScheduleBoardUtil.getGlobalParticipantNotificationStatus(
			workOrderResourceLookups,
			notificationStatusMap,
			workOrder.excludeFromNotify
		),
		searchableId,
		workOrderId: workOrder.id,
		isCancelled: workOrder.status === WorkOrderStatusEnum.CANCELED,
	};
}

export default connect<StateProps, OwnProps>(mapStateToProps)(Card);
