import * as React from 'react';

import type WorkRequestPreviewDetailsVM from 'ab-viewModels/workRequest/workRequestPreviewDetails.viewModel';
import type JobPreviewViewModel from 'ab-viewModels/jobPreview.viewModel';

import AdditionalInfo from 'af-root/scenes/Company/Jobs/Preview/AdditionalInfo';

import { getStreetAddress } from 'ab-utils/formatting.util';

import JobSummarySection from './Sections/JobSummarySection';
import SalesSection from './Sections/SalesSection';
import JobDetailSection from './Sections/JobDetailSection';
import RequirementsSection from './Sections/RequirementsSection';
import ScheduleInfoSection from './Sections/ScheduleInfoSection';
import ContactsSection from './Sections/ContactsSection';
import DeliverablesSection from './Sections/DeliverablesSection';
import SummaryOfWorkSection from './Sections/SummaryOfWorkSection';
import SurveyDataSection from './Sections/SurveyDataSection';
import DisposalSection from './Sections/DisposalSection';
import WorkLocationSection from './Sections/WorkLocationSection';
import TrucksAndEquipmentSection from './Sections/TrucksAndEquipment/TrucksAndEquipmentSection';
import SubjobsSection from './Sections/SubjobsSection';
import BillingCodesSection from './Sections/BillingCodesSection';

import styles from '../styles.module.scss';

type WorkRequestProps = {
	workRequest: WorkRequestPreviewDetailsVM;
	orgAlias: string;
	isJobPreview: false;
};

interface JobProps {
	workRequest: WorkRequestPreviewDetailsVM;
	orgAlias: string;
	isJobPreview: true;
	hasPermissionToManageBillingCodes: boolean;
	billingCodes: JobPreviewViewModel['billingCodes'];
	allowCustomerSignature: JobPreviewViewModel['allowCustomerSignature'];
	companyName: string;
}

type Props = WorkRequestProps | JobProps;

const WorkRequestPreviewTab: React.FC<Props> = (props) => {
	const { workRequest, orgAlias, isJobPreview } = props;

	return (
		<div className={styles['work-request-preview__tab-content']}>
			<SalesSection estimateTotal={workRequest.estimateTotal} salesId={workRequest.salesId} salesLeadName={workRequest.salesLeadName ?? null} />
			<JobSummarySection
				customerCompanyName={workRequest.customerCompanyName}
				customerReferencePO={workRequest.customerReferencePO}
				divisionName={workRequest.divisionName ?? null}
				isEmergencyJob={workRequest.isEmergencyJob}
				isInternal={workRequest.isInternal}
				isJobPreview={isJobPreview}
				jobCode={workRequest.jobCode}
				officeNickname={workRequest.officeNickname ?? null}
				priority={workRequest.priority}
				projectManagerFullName={workRequest.projectManagerName ?? null}
				statusName={workRequest.status?.name ?? null}
				title={workRequest.title}
			/>
			<JobDetailSection
				contractorReference={workRequest.contractorReference}
				createdFromWorkRequestId={workRequest.createdFromWorkRequestId}
				createdFromWorkRequestJobCode={workRequest.createdFromWorkRequestJobCode}
				generalContractor={workRequest.generalContractor}
				isPrevailingWage={workRequest.isPrevailingWage}
				isUnion={workRequest.isUnion}
				jurisdiction={workRequest.jurisdiction}
				orgAlias={orgAlias}
				ownerReference={workRequest.ownerReference}
				projectOwner={workRequest.projectOwner}
				taxExempt={workRequest.taxExempt}
			/>
			<RequirementsSection
				requestBondRequirementsNote={workRequest.requestBondRequirementsNote}
				requestHSERequirementsNote={workRequest.requestHSERequirementsNote}
				requestInsuranceRequirementsNote={workRequest.requestInsuranceRequirementsNote}
				requestLiquidatedDamagesNote={workRequest.requestLiquidatedDamagesNote}
				requestMBEOrWBERequirementsNote={workRequest.requestMBEOrWBERequirementsNote}
				retainage={workRequest.retainage}
			/>
			<ScheduleInfoSection
				guaranteedCompletionDate={workRequest.guaranteedCompletionDate}
				guaranteedDaysFromStart={workRequest.guaranteedDaysFromStart}
				startDate={workRequest.startDate}
				targetCompletionDate={workRequest.targetCompletionDate}
				targetDaysFromStart={workRequest.targetDaysFromStart}
				targetStartDate={workRequest.targetStartDate}
			/>
			<ContactsSection
				additionalContacts={workRequest.additionalContacts}
				billingContact={workRequest.billingContact}
				billTo={workRequest.billTo}
				billToAddress={workRequest.billToAddress}
				billToCityStateZip={workRequest.billToCityStateZip}
				billToContactName={workRequest.billToContactName}
				billToEmailAddress={workRequest.billToEmail}
				billToFaxNumber={workRequest.billToFaxNumber}
				billToPhoneNumber={workRequest.billToPhoneNumber}
				customerContact={workRequest.customerContact}
				siteContact={workRequest.siteContact}
			/>
			<DeliverablesSection
				deliverableAssignee={workRequest.deliverableAssigneeFullName ?? null}
				deliverableCodeStandard={workRequest.deliverableCodeStandardName ?? null}
				deliverableDeliveryMethod={workRequest.deliverableDeliveryMethodName ?? null}
				deliverableDeliveryTimeline={workRequest.deliverableDeliveryTimelineName ?? null}
				deliverableFileFormat={workRequest.deliverableFileFormatName ?? null}
				deliverableNotes={workRequest.deliverableNotes}
				deliverableSoftware={workRequest.deliverableSoftwareName ?? null}
				isDeliverable={workRequest.isDeliverable}
			/>
			<WorkLocationSection
				city={workRequest.workLocation?.locality ?? null}
				state={workRequest.workLocation?.aa1 ?? null}
				streetAddress={workRequest.workLocation ? getStreetAddress(workRequest.workLocation) : null}
				suite={workRequest.workLocation?.suite ?? null}
				zip={workRequest.workLocation?.postalCode ?? null}
			/>
			<SummaryOfWorkSection summaryOfWork={workRequest.summaryOfWork} />
			<SurveyDataSection
				informationsProvidedByCustomersBy={workRequest.informationsProvidedByCustomersBy}
				manholeDepths={workRequest.manholeDepths}
				manholeFrameDiameters={workRequest.manholeFrameDiameters}
				pipeSizes={workRequest.pipeSizes}
				pipeTypes={workRequest.pipeTypes}
				subcontractorInformation={workRequest.subcontractorInformation}
				subcontractorNeeded={workRequest.subcontractorNeeded}
				surveyDate={workRequest.surveyDate}
				surveyedBy={workRequest.surveyedBy ?? null}
				surveyNeeded={workRequest.surveyNeeded}
				waterAvailable={workRequest.waterAvailable}
				waterSupplyInformation={workRequest.waterSupplyInformation}
			/>
			<TrucksAndEquipmentSection workRequest={workRequest} />
			<DisposalSection
				ableToCleanAndWashEquipmentAtCustomerLocation={workRequest.ableToCleanAndWashEquipmentAtCustomerLocation}
				cleanAndWashLocation={workRequest.cleanAndWashLocation}
				disposalContact={workRequest.disposalContact}
				disposalInstructions={workRequest.disposalInstructions}
				disposalMethod={workRequest.disposalMethod}
				expectedWasteQuantity={workRequest.expectedWasteQuantity}
				isWasteGeneratedLiquid={workRequest.isWasteGeneratedLiquid}
				isWasteGeneratedSludge={workRequest.isWasteGeneratedSludge}
				isWasteGeneratedSolid={workRequest.isWasteGeneratedSolid}
				isWasteHazardous={workRequest.isWasteHazardous}
				isWasteManifest={workRequest.isWasteManifest}
				wasteDescription={workRequest.wasteDescription}
			/>
			{isJobPreview &&
				<>
					<AdditionalInfo
						allowCustomerSignature={props.allowCustomerSignature}
					/>
					<BillingCodesSection
						workRequestId={workRequest.id}
					/>
					{
						workRequest.isProject &&
						<SubjobsSection
							orgAlias={orgAlias}
							workRequestId={workRequest.id}
						/>
					}
				</>
			}
		</div>
	);
};

export default WorkRequestPreviewTab;
