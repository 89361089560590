import * as ReportBlockEnums from 'acceligent-shared/enums/reportBlockField';
import ReportBlockType, { TotalBlockType } from 'acceligent-shared/enums/reportBlockType';
import ReportTypeBlockType from 'acceligent-shared/enums/reportTypeBlockType';

import { CustomBlocks } from 'ab-enums/reportTypeBuilder.enum';

import type { ReportTypeBlockFormModel, ReportBlockFormModel } from 'af-root/scenes/Company/Settings/Reports/Shared/formModel';
import { nanoid } from 'nanoid';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

// Private

export const InitialTotalBlockReportBlockValues = (virtualId: string) => ({ type: ReportBlockType.CALCULATED, name: 'Report Total', virtualId }) as ReportBlockFormModel;

type CustomBlockContext = {
	name: string;
	uniqueId: string;
	id?: number;
	virtualId: string;
	isPrimary?: boolean;
};

type CustomBlockBuilderMethod = {
	[T in keyof typeof CustomBlocks.CustomBlock]: (context: CustomBlockContext) => {
		reportTypeBlock: ReportTypeBlockFormModel;
		reportBlock: ReportBlockFormModel;
	};
};

// Public

export const DEFAULT_FIELD_LIST_ID = 'default-field-list-id';
export const BLOCK_FIELD_LIST_ID = 'block-field-list-id';

export const PREDEFINED_INITIAL_VALUES = {
	[ReportBlockEnums.Type.IMMUTABLE_IMAGE]: {
		fieldType: ReportBlockEnums.Type.IMMUTABLE_IMAGE,
		valueType: ReportBlockEnums.ValueType.OBJECT,
		dimension: ReportBlockEnums.Dimension.LARGEST,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.IMAGE]: {
		fieldType: ReportBlockEnums.Type.IMAGE,
		valueType: ReportBlockEnums.ValueType.OBJECT,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.ID]: {
		fieldType: ReportBlockEnums.Type.ID,
		valueType: ReportBlockEnums.ValueType.TEXT,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: true,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.TEXTUAL_ATTRIBUTE]: {
		fieldType: ReportBlockEnums.Type.TEXTUAL_ATTRIBUTE,
		valueType: ReportBlockEnums.ValueType.TEXT,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: true,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.INFORMATION]: {
		fieldType: ReportBlockEnums.Type.INFORMATION,
		valueType: ReportBlockEnums.ValueType.TEXT,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.NUMERIC_ATTRIBUTE]: {
		fieldType: ReportBlockEnums.Type.NUMERIC_ATTRIBUTE,
		valueType: ReportBlockEnums.ValueType.NUMBER,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: true,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.ADDRESS]: {
		fieldType: ReportBlockEnums.Type.ADDRESS,
		valueType: ReportBlockEnums.ValueType.OBJECT,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.BOOLEAN]: {
		fieldType: ReportBlockEnums.Type.BOOLEAN,
		valueType: ReportBlockEnums.ValueType.BOOLEAN,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.DROPDOWN]: {
		fieldType: ReportBlockEnums.Type.DROPDOWN,
		valueType: ReportBlockEnums.ValueType.TEXT,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.TIME]: {
		fieldType: ReportBlockEnums.Type.TIME,
		valueType: ReportBlockEnums.ValueType.TEXT,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.CALCULATED]: {
		fieldType: ReportBlockEnums.Type.CALCULATED,
		valueType: ReportBlockEnums.ValueType.CALCULATED,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.SIGNATURE]: {
		fieldType: ReportBlockEnums.Type.SIGNATURE,
		valueType: ReportBlockEnums.ValueType.OBJECT,
		dimension: ReportBlockEnums.Dimension.LARGE,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.IMMUTABLE_TEXT]: {
		fieldType: ReportBlockEnums.Type.IMMUTABLE_TEXT,
		valueType: ReportBlockEnums.ValueType.TEXT,
		dimension: ReportBlockEnums.Dimension.LARGEST,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
	[ReportBlockEnums.Type.LINE_BREAK]: {
		fieldType: ReportBlockEnums.Type.LINE_BREAK,
		valueType: ReportBlockEnums.ValueType.LINE_BREAK,
		dimension: ReportBlockEnums.Dimension.LARGEST,
		isKeyParameter: false,
		isVisibleToCustomer: true,
	},
};

export const INITIAL_TOTAL_BLOCK_VALUES: { [T in keyof typeof TotalBlockType]: (
	virtualId: string,
	reportBlockVirtualId: string
) => ReportTypeBlockFormModel } = {
	[TotalBlockType.UPPER]: (virtualId: string, reportBlockVirtualId: string) => ({
		isPrimary: true,
		virtualId,
		reportBlockVirtualId,
		type: ReportTypeBlockType.TOTAL,
	} as ReportTypeBlockFormModel),
	[TotalBlockType.LOWER]: (virtualId: string, reportBlockVirtualId: string) => ({
		isPrimary: false,
		virtualId,
		reportBlockVirtualId,
		type: ReportTypeBlockType.TOTAL,
	} as ReportTypeBlockFormModel),
};

export const CustomBlockInitialValues: CustomBlockBuilderMethod = {
	[CustomBlocks.CustomBlock.CALCULATED]: (context: CustomBlockContext) => ({
		reportBlock: {
			id: context.id,
			virtualId: context.virtualId,
			name: context.name,
			uniqueId: context.uniqueId,
			hasCompletionStatus: false,
			isMain: false,
			isRepeating: false,
			reportBlockFieldIds: [],
			completionFieldVirtualId: null,
			type: CustomBlocks.CustomBlockReportBlockType[CustomBlocks.CustomBlock.CALCULATED],
		},
		reportTypeBlock: {
			reportBlockId: null,
			reportBlockVirtualId: context.virtualId,
			reportTypeId: null,
			virtualId: nanoid(UNIQUE_ID_SIZE),
			type: ReportTypeBlockType.BLOCK,
			isPrimary: context.isPrimary ?? false,
		},
	}),
};
