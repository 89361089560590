import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { Resize, SortingRule, FinalState } from 'react-table-6';
import type ReactTable from 'react-table-6';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type { RootState } from 'af-reducers';

import * as TableSettingsActions from 'af-actions/tableSettings';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type TableSettingsRequestModel from 'ab-requestModels/tableSettings.requestModel';

import { RESIZE_DELAY, FETCH_DELAY } from 'ab-common/constants/value';

import TabNavigation from 'af-components/TabNavigation';
import InfiniteScroll from 'af-components/ScrollToLoad';

import { SEARCH_DELAY } from 'ab-common/constants/value';

import { bemBlock } from 'ab-utils/bem.util';

import { debounce } from 'af-utils/actions.util';
import { getDefaultTable6Settings, getTableRequestModel, generateTabName, areColumnNamesEqual, updateTableSettingsColumns } from 'af-utils/table.utils';

import ActionHeader from './ActionHeader';
import ScrollToLoadItem from './ScrollToLoadItem';
import TableSettingsModal from './Settings/TableSettingsModal';
import TableComponent from './Table';
import { getActiveTabStorageKey } from './helpers';
import TableTypeEnum, { getTableType } from './tableType.enum';
import type { ExpandedDefinition, TabsDefinition, TabData, TabProps, RowInfo } from './types';

// ts-unused-exports:disable-next-line
export * from './types';

type RefreshTable = (resetTableState?: boolean) => void;

export type TableRef<ParentOwnProps = never> = React.RefObject<React.Component<ParentOwnProps> & { refreshTable: RefreshTable; }>;
export type LegacyTableRef<TableVM> = React.LegacyRef<React.Component<OwnProps<TableVM>, { current: { refreshTable: RefreshTable; }; }>>;

export interface OwnProps<T> {
	keepResizing?: boolean;
	keepSort?: boolean;
	tabs: TabProps<T>[];
	tableName: string;
	hideTabs?: boolean;
	autoHeight?: boolean;
	/** ignored if tabs are shown or `autoHeight` is `true` */
	clearTableClassname?: boolean;
	SubComponent?: (rowInfo: RowInfo<T>) => React.ReactNode;
	onMount?: (table: Nullable<TableComponent<T>>, list?: InfiniteScroll<T>) => void;
	/** required to be sent separately from tabs in order to trigger re-renders */
	expanded?: ExpandedDefinition;
	dynamicColumns?: boolean;
	getRowExpanded?: (row: T, index: number) => boolean;
	getRowHighlighted?: (row: T) => boolean;
	getRowState?: (row: T, index: number) => { expanded: boolean; highlighted: boolean; };
	onScrollIntoNode?: () => void;
	onActiveTabChange?: (tabIndex: number) => void;
	onFilterTextChange?: (filterText: string) => void;
	textFilter?: string;
	exportAsZip?: boolean;
}

interface State<T> {
	tableType: TableTypeEnum;
	activeTab: Nullable<number>;
	table: TableContent<T>;
	isLoaded: boolean;
	tabsData: TabsDefinition;
	page: number;
	filterText: string;
	tableSettings: Nullable<TableSettingsRequestModel>;
	isTableSettingsLoaded: boolean;
	showTableSettingsModal: boolean;
	expanded: ExpandedDefinition | undefined;
}

type Props<T> = OwnProps<T> & ConnectedProps<typeof connector>;

const getInitialTableState = <T,>() => ({
	table: { rows: [] as T[], pages: 0, totalCount: 0 },
	isLoaded: false,
	filterText: '',
});

function getDefaultTabsData<T>(props: Props<T>) {
	const activeTabJSON = sessionStorage.getItem(getActiveTabStorageKey(props.tableName));
	const activeTab = activeTabJSON ? JSON.parse(activeTabJSON).activeTab : 0;

	return props.tabs.reduce((_acc: TabsDefinition, _tab: TabProps<T>, _index: number) => {
		_acc[_index] = ({
			label: _tab.label,
			isActive: _index === activeTab,
		});
		return _acc;
	}, {});
}

/** @deprecated */
class Table6<T = Metadata> extends React.PureComponent<Props<T>, State<T>> {

	static defaultProps: Partial<Props<Metadata>> = {
		hideTabs: false,
		autoHeight: false,
		clearTableClassname: false,
		exportAsZip: false,
	};

	state: State<T> = {
		tableType: getTableType(window.innerWidth),
		activeTab: null,
		tabsData: getDefaultTabsData(this.props),
		...getInitialTableState<T>(),
		page: 0,
		tableSettings: null,
		isTableSettingsLoaded: false,
		showTableSettingsModal: false,
		expanded: undefined,
	};

	private _table: Nullable<ReactTable<T> & { clearSelection: () => void; }> = null;
	private _list: Nullable<InfiniteScroll<T>> = null;

	static getDerivedStateFromProps<T2>(props: Props<T2>, state: State<T2>): Partial<State<T2>> {
		const { getRowExpanded, expanded } = props;
		const { table } = state;

		if (getRowExpanded) {
			const expandedState = table?.rows?.reduce((_acc, _row, _index) => {
				if (getRowExpanded(_row, _index)) {
					_acc[_index] = true;
				}
				return _acc;
			}, {} as ExpandedDefinition) ?? {};
			return { expanded: { ...expandedState, ...expanded } };
		}
		return { expanded };
	}

	async componentDidMount() {
		const { tableName, tabs, textFilter } = this.props;
		const activeTabJSON = sessionStorage.getItem(getActiveTabStorageKey(tableName));
		let activeTabIndex = ((activeTabJSON) ? JSON.parse(activeTabJSON) : {}).activeTab || 0;

		if (activeTabIndex >= tabs.length) {
			activeTabIndex = 0;
		}
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		sessionStorage.setItem(getActiveTabStorageKey(tableName), JSON.stringify({ activeTab: activeTabIndex }));

		await this.initTableSettings(this.props, activeTabIndex);

		if (textFilter) {
			this.setState(() => ({ filterText: textFilter }));
		}
		this.refreshTable(true);
	}

	componentDidUpdate(prevProp: Props<T>, prevState: State<T>) {
		const { onActiveTabChange } = this.props;
		const { activeTab } = this.state;

		if (onActiveTabChange && activeTab !== null && activeTab !== prevState.activeTab) {
			onActiveTabChange(activeTab);
		}
	}

	async initTableSettings(props: Props<T>, activeTabIndex: number) {
		const {
			findTableSettings,
			updateTableSettings,
			tableName,
			accountId,
			tabs,
		} = props;
		const { columns, label } = tabs[activeTabIndex];
		const tabName = generateTabName(tableName, label);
		const defaultTableSettings = getDefaultTable6Settings(tabName, accountId, columns);

		if (accountId) {
			let tableSettings = await findTableSettings(tabName);

			if (!tableSettings) {
				tableSettings = defaultTableSettings;
				await updateTableSettings(tableSettings);
			} else if (!areColumnNamesEqual(defaultTableSettings, tableSettings)) {
				// table component has been updates since last settings
				tableSettings = updateTableSettingsColumns(tableSettings, defaultTableSettings.columnSettings);
				await updateTableSettings(tableSettings);
			}

			this.setState(
				() => ({
					tableSettings: tableSettings ?? null,
					isTableSettingsLoaded: true,
					activeTab: activeTabIndex,
					isLoaded: false,
				}),
				() => {
					this.refreshTabsData();
					this.resetTable();	// will set isLoaded back to true
				});
		} else {
			this.setState(
				() => ({
					isTableSettingsLoaded: true,
					tableSettings: defaultTableSettings,
					activeTab: activeTabIndex,
				}),
				() => {
					this.refreshTabsData();
					this.resetTable();	// will set isLoaded back to true
				}
			);
		}
	}

	onReactTableMount = (table: ReactTable<T> & { clearSelection: () => void; }) => {
		this._table = table;
	};

	onScrollToLoadMount = (list: InfiniteScroll<T>) => {
		const { onMount } = this.props;
		this._list = list;
		onMount?.(null, list);
	};

	onTableMount = (table: TableComponent<T>) => {
		const { onMount } = this.props;

		if (onMount) {
			onMount(table);
		}
	};

	openTableSettings = () => this.setState(() => ({ showTableSettingsModal: true }));

	closeTableSettings = () => this.setState(() => ({ showTableSettingsModal: false }));

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		const updatedTableType = getTableType(window.innerWidth);
		const { tableType } = this.state;

		if (tableType !== updatedTableType) {
			if (updatedTableType !== TableTypeEnum.SCROLLABLE) {
				this.setState(() => ({ tableType: updatedTableType }));
			} else {
				this.refreshStateForScrollingTable();
			}
		}
	};

	refreshStateForScrollingTable = () => this.setState((state) => ({
		tableType: TableTypeEnum.SCROLLABLE,
		...getInitialTableState(),
		filterText: state.filterText,
	}));

	getActiveTabProps = (): Nullable<TabProps<T>> => {
		const { tabs } = this.props;
		const { activeTab } = this.state;
		return activeTab !== null ? tabs[activeTab] : null;
	};

	setActiveTab = async (index: number) => {
		const { tableName } = this.props;

		sessionStorage.setItem(getActiveTabStorageKey(tableName), JSON.stringify({ activeTab: index }));

		this.setState(
			(state) => {
				return ({
					activeTab: index,
					isLoaded: false,
					tableSettings: state.tableSettings ? { ...state.tableSettings, sort: [] } : null,
				});
			},
			() => {
				this.resetTable();	// will set isLoaded back to true
				if (this._list) {
					this._list.refreshList();
				}
			}
		);
		await this.initTableSettings(this.props, index);
	};

	refreshTabsData = () => {
		const { tabs } = this.props;
		const { activeTab } = this.state;

		const newTabsData = tabs.reduce((_acc: TabsDefinition, _tab: TabProps<T>, _index: number) => {
			_acc[_index] = {
				label: _tab.label,
				isActive: _index === activeTab,
			};
			return _acc;
		}, {} as TabsDefinition);

		this.setState(() => ({ tabsData: newTabsData }));
	};

	refreshTable: RefreshTable = (resetTableState: boolean = false) => {
		if (this._table) {
			this.fetchForTable(this._table.state as unknown as Readonly<FinalState<T>>, resetTableState);
		} else if (this._list) {
			this._list.refreshList();
		}
	};

	fetchForTable = async (tableState: FinalState<T>, resetTableState: boolean = false, resetLoadingState: boolean = true) => {
		const activeTab = this.getActiveTabProps();
		if (!activeTab) {
			throw new Error('Tab data not found!');
		}
		const { filterText } = this.state;
		const tableRequestModel = getTableRequestModel(tableState, activeTab.getSortBy, filterText);
		this.fetchDataDebounced(tableRequestModel, resetTableState, resetLoadingState);
	};

	fetchForInfiniteScroll = async (limit: number, page: number, isRefresh: boolean) => {
		const tableRequestModel: TableQuery = {
			pageSize: limit,
			page,
			sortBy: [],
		};
		this.fetchDataDebounced(tableRequestModel, isRefresh);
	};

	fetchData = async (tableRequestModel: TableQuery, resetTableState: boolean = false, resetLoadingState: boolean = true) => {
		const _activeTabProps = this.getActiveTabProps();
		if (!_activeTabProps) {
			throw new Error('Active tab props not found');
		}
		const { fetch } = _activeTabProps;
		const { tableType } = this.state;
		this.setState(() => ({ isLoaded: !resetLoadingState }), async () => {
			this.refreshTabsData();
			const _table = await fetch(tableRequestModel);

			if (!_table) {
				return;
			}

			// When we're working with table element, we want to only display what we fetch
			// When working with infinity scroll, we want to concat results as we get them
			if ((tableType !== TableTypeEnum.SCROLLABLE || resetTableState) && _table) {
				this.setState(() => ({ table: _table, isLoaded: true }));
			} else {
				// Rows can be null when loading, need to make sure concat goes through
				this.setState((state: State<T>) => ({ table: { ..._table, rows: (state.table.rows || []).concat(_table.rows) }, isLoaded: true }));
			}
		});
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	fetchDataDebounced = debounce(this.fetchData, FETCH_DELAY);

	resetTable = (cb?: () => void) => this.setState(() => ({ ...getInitialTableState() }), cb);

	filterByTextCallback = async (filterText: string) => {
		const { tableType } = this.state;
		const _activeTabProps = this.getActiveTabProps();
		if (!_activeTabProps) {
			throw new Error('Active tab props not found');
		}
		const { onFilterByText, getSortBy } = _activeTabProps;

		if (onFilterByText) {
			onFilterByText(filterText);
		}

		if (tableType === TableTypeEnum.SCROLLABLE) {
			this.refreshStateForScrollingTable();
			const tableRequestModel = new TableQuery({
				page: 0,
				filterByText: filterText,
				pageSize: undefined,
				sortBy: undefined,
			});
			await this.fetchData(tableRequestModel, true);
		}

		if (this._table) {
			const tableRequestModel = getTableRequestModel(this._table.state as unknown as Readonly<FinalState<T>>, getSortBy, filterText);
			await this.fetchData(tableRequestModel, true);
		}
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	filterByTextCallbackDebounced = debounce(this.filterByTextCallback, SEARCH_DELAY);

	filterByText = (filterText: string) => {
		const { onFilterTextChange } = this.props;
		this.setState(() => ({ filterText }), () => this.filterByTextCallbackDebounced(filterText));
		if (onFilterTextChange) {
			onFilterTextChange(filterText);
		}
	};

	onResizedChanged = (columns: Resize[]) => {
		this.setState(
			(state: State<T>) => {
				const columnSettings = [...state?.tableSettings?.columnSettings ?? []];
				columns.forEach((_column) => {
					const index = state?.tableSettings?.columnSettings?.findIndex((_col) => _col.name === _column.id) ?? 0;
					if (index >= 0) {
						columnSettings[index].width = _column.value;
					}
				});
				return { tableSettings: state.tableSettings ? { ...state.tableSettings, columnSettings } : null };
			},
			async () => {
				const { updateTableSettings, accountId } = this.props;
				if (accountId && this.state.tableSettings) {
					await updateTableSettings(this.state.tableSettings);
				}
			}
		);
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	onResizedChangedDebounced = debounce(this.onResizedChanged, RESIZE_DELAY);

	onSortChanged = (sort: SortingRule[]): void => {
		this.setState(
			(state: State<T>) => ({ tableSettings: state.tableSettings ? { ...state.tableSettings, sort } : null }),
			() => {
				const { updateTableSettings, accountId } = this.props;
				if (accountId && this.state.tableSettings) {
					updateTableSettings(this.state.tableSettings);
				}
			}
		);
	};

	onPageSizeChange = (pageSize: number) => {
		this.setState(
			(state: State<T>) => ({ tableSettings: state.tableSettings ? { ...state.tableSettings, pageSize } : null }),
			() => {
				const { updateTableSettings, accountId } = this.props;
				if (accountId && this.state.tableSettings) {
					updateTableSettings(this.state.tableSettings);
				}
			}
		);
	};

	saveTableSettings = async (tableSettings: TableSettingsRequestModel) => {
		const { accountId, updateTableSettings } = this.props;
		if (accountId) {
			this.setState(
				() => ({ tableSettings }),
				() => this.state.tableSettings && updateTableSettings(this.state.tableSettings)
			);
		}
	};

	renderTabs = () => {
		const { tabsData } = this.state;

		let activeIndex: Nullable<number> = null;

		const tabs = Object.keys(tabsData).map((_tabIndex: string) => {
			const _tab: Nullable<TabData> = tabsData[_tabIndex];

			if (!_tab) {
				throw new Error('Tab data not found');
			}

			if (_tab.isActive) {
				activeIndex = +_tabIndex;
			}

			return { label: _tab.label, id: +_tabIndex };
		});

		return (
			<TabNavigation
				active={activeIndex}
				onClick={this.setActiveTab}
				tabs={tabs}
			/>
		);
	};

	renderActionHeader = () => {
		const { exportAsZip } = this.props;
		const { tableType, filterText } = this.state;
		const activeTab = this.getActiveTabProps();
		if (!activeTab) {
			throw new Error('Tab data not found!');
		}
		const {
			hideActionHeader,
			hasSearchInput,
			additionalFilter,
			searchLabel,
			buttons,
		} = activeTab;

		if (hideActionHeader) {
			return null;
		}

		return (
			<ActionHeader
				additionalFilter={additionalFilter}
				buttons={buttons}
				exportAsZip={exportAsZip}
				filterText={filterText}
				hasSearchInput={!!hasSearchInput}
				onFilterTextChange={this.filterByText}
				searchLabel={searchLabel ?? ''}
				tableType={tableType}
			/>
		);
	};

	renderTable = () => {
		const { keepResizing, keepSort, SubComponent, accountId, getRowHighlighted, onScrollIntoNode, dynamicColumns } = this.props;
		const { table, isLoaded, tabsData, tableSettings, expanded } = this.state;

		return (
			<TableComponent<T>
				accountId={accountId}
				dynamicColumns={dynamicColumns}
				expanded={expanded}
				getActiveTabProps={this.getActiveTabProps}
				getRowHighlighted={getRowHighlighted}
				isLoaded={isLoaded}
				keepResizing={keepResizing}
				keepSort={keepSort}
				onMount={this.onReactTableMount}
				onPageSizeChange={this.onPageSizeChange}
				onResizedChanged={this.onResizedChangedDebounced}
				onScrollIntoNode={onScrollIntoNode}
				onSortChanged={this.onSortChanged}
				openTableSettingsModal={this.openTableSettings}
				parentFetch={this.fetchForTable}
				ref={this.onTableMount}
				SubComponent={SubComponent}
				table={table}
				tableSettings={tableSettings}
				tabsData={tabsData}
			/>
		);
	};

	renderScrollToLoadItem = (_item: T, _index: number) => {
		const activeTab = this.getActiveTabProps();
		if (!activeTab) {
			throw new Error('Tab data not found!');
		}
		const { columns, onRowClick, rowActions } = activeTab;
		const { SubComponent } = this.props;

		if (!this._list) {
			return null;
		}

		return (
			<ScrollToLoadItem<T>
				columns={columns}
				index={_index}
				item={_item}
				key={_index}
				onRowClick={onRowClick}
				refreshList={this._list.refreshList}
				rowActions={rowActions}
				SubComponent={SubComponent}
			/>
		);
	};

	renderScrollToLoad = () => {
		const { table: { rows, totalCount } } = this.state;

		return (
			<InfiniteScroll
				data={rows}
				fetch={this.fetchForInfiniteScroll}
				ref={this.onScrollToLoadMount}
				renderItem={this.renderScrollToLoadItem}
				totalCount={totalCount}
			/>
		);
	};

	render() {
		const { clearTableClassname, hideTabs, tableName, accountId, tabs, autoHeight } = this.props;

		const { tableType, activeTab, tableSettings, showTableSettingsModal, isTableSettingsLoaded } = this.state;

		if (activeTab === null || !isTableSettingsLoaded) {
			return null;
		}

		const currentTab = this.getActiveTabProps();

		if (!currentTab) {
			throw new Error('Tab data not found!');
		}

		const { columns, label, renderBulkActionHeader } = currentTab;

		const tabName = generateTabName(tableName, label);

		const shouldDisplayTabs = !hideTabs && tabs?.length > 1;
		const notTableContainerClass = clearTableClassname && !shouldDisplayTabs && !autoHeight;

		let tableContainerClassName = notTableContainerClass ? '' : bemBlock('table-container', { 'tabbed': shouldDisplayTabs, 'auto-height': !!autoHeight });
		tableContainerClassName = tableType === TableTypeEnum.SCROLLABLE ? `${tableContainerClassName} scroll-to-load-table-container` : tableContainerClassName;

		return (
			<div className={tableContainerClassName}>
				{shouldDisplayTabs && this.renderTabs()}
				{this.renderActionHeader()}
				{renderBulkActionHeader?.()}
				{tableType !== TableTypeEnum.SCROLLABLE ? this.renderTable() : this.renderScrollToLoad()}
				{isTableSettingsLoaded && showTableSettingsModal &&
					<TableSettingsModal<T>
						accountId={accountId}
						closeTableSettings={this.closeTableSettings}
						columns={columns}
						onSave={this.saveTableSettings}
						show={showTableSettingsModal}
						tableName={tabName}
						tableSettings={tableSettings}
					/>
				}
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;

	// Null in case of platform admin in platform admin part of the platform (managing organizations etc)
	return {
		accountId: companyData?.accountId ?? null,
	};
}

function mapDispatchToProps() {
	return {
		findTableSettings: TableSettingsActions.findTableSettings,
		updateTableSettings: TableSettingsActions.updateTableSettings,
	};
}
export type TypedTable<T> = React.ComponentClass<OwnProps<T>>;

const connector = connect(
	mapStateToProps,
	mapDispatchToProps(),
	null,
	{ forwardRef: true }
);

export default connector(Table6) as unknown as TypedTable<unknown>;
