import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import type { RootState } from 'af-reducers';

import * as EquipmentActions from 'af-actions/equipment';

import type { EquipmentTableViewModel } from 'ab-viewModels/equipmentTable.viewModel';
import { EquipmentListViewModel } from 'ab-viewModels/equipmentList.viewModel';

import CLIENT from 'af-constants/routes/client';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import type { TabProps, Column, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import ImageTag from 'af-components/Image';
import LabelWithColor from 'af-components/LabelWithColor';
import SkillsCell from 'af-components/Table6/Cells/SkillsCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import StatusLabel from 'af-components/StatusLabel';

import { downloadCSV } from 'af-utils/csv.utils';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

const IMAGE_COLUMN_WIDTH = 100;
const TYPE_COLUMN_WIDTH = 80;
const UPDATED_COLUMN_WIDTH = 200;

const DELETE_CONFIRMATION_BODY = (
	<>
		If deleted, the equipment will no longer be assignable to new Work Orders.
		<br />
		Work Orders already using it will not be affected.
		<br />
		This action cannot be undone.
	</>
);

const EquipmentList = (props: Props) => {
	const {
		history,
		location: { state: { orgAlias } },
		companyName,
		findAllForCompanyList,
		deleteEquipmentFromTable,
		findAllForCompanyTable,
		bulkDeleteEquipmentFromTable,
	} = props;

	const COLUMNS: Column<EquipmentTableViewModel>[] = [
		{
			accessor: 'imageUrl',
			sortable: false,
			width: IMAGE_COLUMN_WIDTH,
			className: 'rt-thumbnail',
			Cell: ({ original }) => (
				<ImageTag
					fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
					minSize={BlobStorageImageSizeContainer.SIZE_50X50}
					src={original.imageUrl}
					tryOriginal={true}
					tryRoot={true}
				/>
			),
		},
		{
			Header: 'ID',
			accessor: 'code',
			Cell: ({ original }) => {
				const text = <>{original.code} {original.specification ? <span className="small-label">{original.specification}</span> : ''}</>;
				return <LabelWithColor color={original.equipmentCost?.category?.categoryColor ?? null} text={text} />;
			},
		},
		{
			Header: 'Type',
			minWidth: TYPE_COLUMN_WIDTH,
			accessor: 'equipmentCost.category.group.type',
			Cell: ({ original }) => original.equipmentCost.category?.group?.type?.[0],
		},
		{
			Header: 'Group',
			accessor: 'equipmentCost.category.group.name',
			Cell: ({ original }) => original.equipmentCost.category?.group?.name,
		},
		{
			Header: 'Category',
			accessor: 'equipmentCost.category.name',
			Cell: ({ original }) => original.equipmentCost.category?.name,
		},
		{
			Header: 'Subcategory',
			accessor: 'equipmentCost.subcategory',
			Cell: ({ original }) => original.equipmentCost.subcategory,
		},
		{
			Header: 'Skills',
			accessor: 'allSkills',
			sortable: false,
			Cell: ({ original }) => <SkillsCell skills={original.allSkills} />,
		},
		{
			Header: 'Home Office',
			accessor: 'location.nickname',
			Cell: ({ original }) => original.location?.nickname ?? <EmptyCell />,
		},
		{
			Header: 'Equipment Status',
			sortable: false,
			accessor: 'equipmentStatus.name',
			Cell: ({ original }) => <StatusLabel isAvailable={original.equipmentStatus?.available ?? true} label={original.equipmentStatus?.name ?? 'Available'} />,
		},
		{
			Header: 'Updated',
			width: UPDATED_COLUMN_WIDTH,
			headerClassName: 'align-right',
			accessor: 'updatedAt',
			Cell: ({ original }) => <LastUpdatedByCell updatedAt={original.updatedAt} updatedBy={original.updatedBy} />,
		},
	];

	const deleteEquipmentModalTitle = (original: EquipmentTableViewModel) => `Are you sure you want to delete this Equipment (${original.code})?`;
	const deleteEquipmentModalBody = () => DELETE_CONFIRMATION_BODY;
	const deleteEquipmentModalText = () => 'Delete Equipment';

	const deleteEquipment = async (original: EquipmentTableViewModel) => {
		await deleteEquipmentFromTable(original.id);
	};

	const editEquipment = async (original: EquipmentTableViewModel) => {

		history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT.EDIT(original.id.toString(), orgAlias, companyName));
	};

	const previewEquipment = async (original: EquipmentTableViewModel) => {
		history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT.PREVIEW(original.id.toString(), orgAlias, companyName));
	};

	const equipmentStatusHistory = (original: EquipmentTableViewModel) => {
		history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT.STATUS_HISTORY(original.id.toString(), orgAlias, companyName));
	};

	const onRowClick = ({ original }: { original: EquipmentTableViewModel; }) => {
		if (original.id) {
			previewEquipment(original);
		}
	};

	const onDownloadCSVClick = async () => {
		const listViewModel = await findAllForCompanyList();
		downloadCSV(EquipmentListViewModel.toCSVData(listViewModel), `${companyName}_equipment.csv`);
	};

	const tabs = (): TabProps<EquipmentTableViewModel>[] => {

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.LINK,
				hasPermission: true,
				label: 'CSV Import',
				icon: 'upload',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT.BULK_UPLOAD(orgAlias, companyName)),
			},
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: onDownloadCSVClick,
			},
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Equipment',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'Equipment',
				columns: COLUMNS,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Equipment',
				fetch: findAllForCompanyTable,
				bulkDelete: bulkDeleteEquipmentFromTable,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				buttons,
				onRowClick: onRowClick,
				rowActions: [
					{
						label: 'Preview',
						action: previewEquipment,
						shouldRefresh: false,
					},
					{
						label: 'Edit',
						action: editEquipment,
						shouldRefresh: false,
					},
					{
						label: 'Delete',
						action: deleteEquipment,
						hasModal: true,
						modalTitle: deleteEquipmentModalTitle,
						modalBody: deleteEquipmentModalBody,
						modalText: deleteEquipmentModalText,
						shouldRefresh: true,
					},
					{
						label: 'Status History',
						action: equipmentStatusHistory,
						shouldRefresh: false,
					},
				],
			},
		];
	};

	return (
		<Table
			tableName={TableNameEnum.EQUIPMENT}
			tabs={tabs()}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		deleteEquipmentFromTable: EquipmentActions.deleteEquipmentFromTable,
		findAllForCompanyTable: EquipmentActions.findAllForCompanyTable,
		bulkDeleteEquipmentFromTable: EquipmentActions.bulkDeleteEquipmentFromTable,
		findAllForCompanyList: EquipmentActions.findAllForCompanyList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	withRouter,
	connector
);

export default enhance(EquipmentList);
