import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';
import { Button, Row, Col } from 'react-bootstrap';
import { nanoid } from 'nanoid';

import * as ReportBlockFieldEnums from 'acceligent-shared/enums/reportBlockField';
import type OperationType from 'acceligent-shared/enums/operation';
import { OperationName } from 'acceligent-shared/enums/operation';
import type UnitEnum from 'acceligent-shared/enums/quantityUnit';
import { QuantityUnitLabel, CompoundUnitEnum } from 'acceligent-shared/enums/quantityUnit';

import { REPORT_BLOCK_TOOLTIP_MAX_LENGTH } from 'acceligent-shared/constants/value';

import { deepClone } from 'ab-utils/object.util';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';
import Checkbox from 'af-fields/Checkbox';
import Textarea from 'af-fields/Textarea';

import { REPORT_BLOCK_FIELD } from 'af-constants/reduxForms';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import type { RootState } from 'af-reducers';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

import DescriptiveTextBlock from './DescriptiveTextBlock';
import type { DropdownListFieldArrayOwnProps } from './DropdownListField';
import DropdownListField from './DropdownListField';
import ImageUploadField from './ImageUploadField';

import { validateReportBlockField as validate } from '../validation';

import { PREDEFINED_INITIAL_VALUES } from '../../values';
import type { OwnProps as CalculatedFieldOptionsProps } from '../../../Shared/CalculatedFieldOption';
import CalculatedFieldOptions from '../../../Shared/CalculatedFieldOption';
import type { ReportBlockFieldFormModel, CalculatedFieldOptionFormModel } from '../../../Shared/formModel';

const DEFAULT_FORM_VALUE = {};

const DISABLED_WIDTH_DROPDOWN_FIELD_TYPES = {
	[ReportBlockFieldEnums.Type.ADDRESS]: true,
	[ReportBlockFieldEnums.Type.IMMUTABLE_IMAGE]: true,
};

interface DropdownOption<T> {
	id: T;
	label: string;
}

interface OwnProps {
	closeModal: () => void;
	fields: CalculatedFieldOptionFormModel[];
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; };
	firstInitialValues?: Partial<ReportBlockFieldFormModel> & { index?: number; };
	hideIdField: boolean;
	isPrimaryBlock: boolean;
	onImageUpload: (form: Partial<ReportBlockFieldFormModel>) => void;
	onSubmit: (form: ReportBlockFieldFormModel, fieldIndex: Nullable<number>) => void;
	showModal: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<ReportBlockFieldFormModel>;

const QUANTITY_UNIT_OPTIONS: DropdownOption<UnitEnum>[] = Object.keys(QuantityUnitLabel)
	.map((_unit: UnitEnum) => ({ id: _unit, label: QuantityUnitLabel[_unit] }));

const PRIMARY_FIELD_OPTIONS: DropdownOption<ReportBlockFieldEnums.Type>[] = Object.keys(ReportBlockFieldEnums.PrimaryTypeNamed)
	.reduce((_acc, _key: ReportBlockFieldEnums.Type) => {
		if (_key !== ReportBlockFieldEnums.Type.COMPLETION) {
			_acc.push({
				id: _key,
				label: ReportBlockFieldEnums.PrimaryTypeNamed[_key],
			});
		}
		return _acc;
	}, [] as DropdownOption<ReportBlockFieldEnums.Type>[]);

const SECONDARY_FIELD_OPTIONS: DropdownOption<ReportBlockFieldEnums.Type>[] = Object.keys(ReportBlockFieldEnums.SecondaryTypeNamed)
	.reduce((_acc, _key: ReportBlockFieldEnums.Type) => {
		if (_key !== ReportBlockFieldEnums.Type.COMPLETION) {
			_acc.push({
				id: _key,
				label: ReportBlockFieldEnums.SecondaryTypeNamed[_key],
			});
		}
		return _acc;
	}, [] as DropdownOption<ReportBlockFieldEnums.Type>[]);

const OPERATION_TYPE_OPTIONS: DropdownOption<OperationType>[] = Object.keys(OperationName)
	.map((_key: OperationType) => ({
		id: _key,
		label: OperationName[_key],
	}));

const _getFieldOptions = (isPrimary: boolean): DropdownOption<ReportBlockFieldEnums.Type>[] => {
	return isPrimary ? PRIMARY_FIELD_OPTIONS : SECONDARY_FIELD_OPTIONS;
};

const _getDimensionOptions = (unit: Nullable<UnitEnum>): DropdownOption<ReportBlockFieldEnums.Dimension>[] => {
	return Object.keys(ReportBlockFieldEnums.DimensionNamed).reduce(
		(_acc: DropdownOption<ReportBlockFieldEnums.Dimension>[], _key: ReportBlockFieldEnums.Dimension) => {
			if (!unit || !CompoundUnitEnum[unit] || ![ReportBlockFieldEnums.Dimension.VERY_SMALL, ReportBlockFieldEnums.Dimension.ADJUSTABLE].includes(_key)) {
				_acc.push({ id: _key, label: ReportBlockFieldEnums.DimensionNamed[_key] });
			}
			return _acc;
		},
		[]
	);
};

const _blockBuilderFilter = (option: ReportBlockFieldFormModel) => option.fieldType !== ReportBlockFieldEnums.Type.CALCULATED;

const ReportBlockFieldModal: React.FC<Props> = (props: Props) => {
	const {
		closeModal,
		change,
		destroy,
		handleSubmit,
		showModal,
		invalid,
		submitting,
		firstInitialValues,
		formValues,
		initialize,
		isPrimaryBlock,
		hideIdField,
		fieldsByIdMap,
		fields,
		onSubmit,
		onImageUpload,
	} = props;

	const [showName, setShowName] = React.useState<boolean>(formValues.fieldType !== ReportBlockFieldEnums.Type.LINE_BREAK);
	const [showUnitDropdown, setShowUnitDropdown] = React.useState<boolean>(formValues.fieldType === ReportBlockFieldEnums.Type.NUMERIC_ATTRIBUTE);
	const [showWidthDropdown, setShowWidthDropdown] = React.useState<boolean>(
		formValues.fieldType !== ReportBlockFieldEnums.Type.IMAGE
		&& formValues.fieldType !== ReportBlockFieldEnums.Type.LINE_BREAK
		&& formValues.fieldType !== ReportBlockFieldEnums.Type.SIGNATURE
	);
	const [showOperationDropdown, setShowOperationDropdown] = React.useState<boolean>(formValues.fieldType === ReportBlockFieldEnums.Type.CALCULATED);
	const [showImageUpload, setShowImageUpload] = React.useState<boolean>(formValues.fieldType === ReportBlockFieldEnums.Type.IMMUTABLE_IMAGE);
	const [disabledWidthDropdown, setDisabledWidthDropdown] = React.useState<boolean>(false);
	const [fieldList] = React.useState<DropdownOption<ReportBlockFieldEnums.Type>[]>(
		_getFieldOptions(isPrimaryBlock && !hideIdField)
	);
	const [showOptions, setShowOptions] = React.useState<boolean>(formValues.fieldType === ReportBlockFieldEnums.Type.DROPDOWN);
	const [showCalculatedOptions, setShowCalculatedOptions] = React.useState<boolean>(!!formValues.operationType);
	const [showDescriptiveTextBlock, setShowDescriptiveTextBlock] = React.useState<boolean>(formValues.fieldType === ReportBlockFieldEnums.Type.IMMUTABLE_TEXT);
	const [tooltipDisabled, setTooltipDisabled] = React.useState<boolean>(
		formValues.fieldType === ReportBlockFieldEnums.Type.SIGNATURE
		|| formValues.fieldType === ReportBlockFieldEnums.Type.LINE_BREAK
	);
	const [isVisibleToCustomerDisabled, setIsVisibleToCustomerDisabled] = React.useState<boolean>(
		formValues.fieldType !== ReportBlockFieldEnums.Type.LINE_BREAK
	);

	React.useEffect(() => {
		if (showModal) {
			initialize(firstInitialValues ?? { dimension: ReportBlockFieldEnums.Dimension.LARGE });
		} else {
			destroy();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal]);

	React.useEffect(() => {
		setShowName(formValues.fieldType !== ReportBlockFieldEnums.Type.LINE_BREAK);
		setShowUnitDropdown(formValues.fieldType === ReportBlockFieldEnums.Type.NUMERIC_ATTRIBUTE);
		setShowWidthDropdown(formValues.fieldType !== ReportBlockFieldEnums.Type.IMAGE
			&& formValues.fieldType !== ReportBlockFieldEnums.Type.LINE_BREAK
			&& formValues.fieldType !== ReportBlockFieldEnums.Type.SIGNATURE);
		setDisabledWidthDropdown(!!DISABLED_WIDTH_DROPDOWN_FIELD_TYPES[formValues.fieldType]
			|| formValues.dimension === ReportBlockFieldEnums.Dimension.ADJUSTABLE);
		setShowOptions(formValues.fieldType === ReportBlockFieldEnums.Type.DROPDOWN);
		setShowCalculatedOptions(!!formValues.operationType);
		setShowOperationDropdown(formValues.fieldType === ReportBlockFieldEnums.Type.CALCULATED);
		setShowImageUpload(formValues.fieldType === ReportBlockFieldEnums.Type.IMMUTABLE_IMAGE);
		setTooltipDisabled(formValues.fieldType === ReportBlockFieldEnums.Type.SIGNATURE
			|| formValues.fieldType === ReportBlockFieldEnums.Type.LINE_BREAK);
		setShowDescriptiveTextBlock(formValues.fieldType === ReportBlockFieldEnums.Type.IMMUTABLE_TEXT);
		setIsVisibleToCustomerDisabled(formValues.fieldType === ReportBlockFieldEnums.Type.LINE_BREAK);
	}, [formValues.fieldType, formValues]);

	const getValueType = (type: ReportBlockFieldEnums.Type) => {
		switch (type) {
			case ReportBlockFieldEnums.Type.ID:
			case ReportBlockFieldEnums.Type.TEXTUAL_ATTRIBUTE:
			case ReportBlockFieldEnums.Type.INFORMATION:
			case ReportBlockFieldEnums.Type.IMAGE:
			case ReportBlockFieldEnums.Type.DROPDOWN:
				return ReportBlockFieldEnums.ValueType.TEXT;
			case ReportBlockFieldEnums.Type.NUMERIC_ATTRIBUTE:
				return ReportBlockFieldEnums.ValueType.NUMBER;
			case ReportBlockFieldEnums.Type.ADDRESS:
				return ReportBlockFieldEnums.ValueType.OBJECT;
			case ReportBlockFieldEnums.Type.BOOLEAN:
				return ReportBlockFieldEnums.ValueType.BOOLEAN;
		}
	};

	const onCreate = (form: ReportBlockFieldFormModel) => {
		if (!form.virtualId) {
			form.virtualId = nanoid(UNIQUE_ID_SIZE);
		}
		if (!form.unit) {
			form.unit = null;
		}
		// we can't use JSON.parse(JSON.stringify(form)) since it does not copy Files among other stuff
		const _form = deepClone(form);

		// in case of line break we need to give unique name for draggable field
		if (!form.name) {
			_form.name = `LINE_BREAK#${new Date().getTime()}`;
		}

		_form.valueType = form.valueType ?? getValueType(form.fieldType);
		onSubmit(_form, firstInitialValues?.index ?? null);
		closeModal();
	};

	const onTypeChange = React.useCallback((value: DropdownOption<ReportBlockFieldEnums.Type>) => {
		change('valueType', PREDEFINED_INITIAL_VALUES[value.id].valueType);
		change('dimension', PREDEFINED_INITIAL_VALUES[value.id].dimension);
		// isKeyParameter is not set in the form, but is always set to certain value depending on the field type
		change('isKeyParameter', PREDEFINED_INITIAL_VALUES[value.id].isKeyParameter);
		change('unit', null);
		change('options', null);
		change('calculatedFieldOptions', null);
		change('operationType', null);
	}, [change]);

	const onUnitChange = React.useCallback((value: DropdownOption<UnitEnum>) => {
		if (CompoundUnitEnum[value.id] && formValues.dimension === ReportBlockFieldEnums.Dimension.VERY_SMALL) {
			change('dimension', ReportBlockFieldEnums.Dimension.LARGE);
		}
	}, [change, formValues]);

	const onOperationChange = React.useCallback(() => {
		change('calculatedFieldOptions', null);
		change('unit', null);
	}, [change]);

	const filterUnits = React.useCallback((option: DropdownOption<UnitEnum>, searchText: string) => {
		return option.label.toLowerCase().includes(searchText);
	}, []);

	const clearUnit = React.useCallback(() => {
		change('unit', null);
	}, [change]);

	const handleImageUpload = React.useCallback(() => {
		const imageUrl = onImageUpload(formValues);
		change('defaultValue', imageUrl);
	}, [onImageUpload, change, formValues]);

	const renderFieldType = () => {
		if (showOperationDropdown) {
			return renderOperationDropdown();
		} else if (showUnitDropdown) {
			return renderUnitDropdown();
		}
		return renderFieldTypeStandalone();
	};

	const renderFieldTypeDropdown = React.useCallback(() => {
		return (
			<Field
				component={Dropdown}
				fixed={true}
				id="isMain"
				label="Field Type *"
				labelKey="label"
				name="fieldType"
				onValueChange={onTypeChange}
				options={fieldList}
				valueKey="id"
				withCaret={true}
			/>
		);
	}, [fieldList, onTypeChange]);

	const renderFieldTypeStandalone = React.useCallback(() => (
		<Col sm={24}>
			{renderFieldTypeDropdown()}
		</Col>
	), [renderFieldTypeDropdown]);

	const renderUnitDropdown = React.useCallback(() => {
		return (
			<>
				<Col sm={12}>
					{renderFieldTypeDropdown()}
				</Col>
				<Col sm={12}>
					<Field
						component={Dropdown}
						filterable={true}
						filterBy={filterUnits}
						fixed={true}
						id="unit"
						label="Unit"
						labelKey="label"
						name="unit"
						onClear={clearUnit}
						onValueChange={onUnitChange}
						options={QUANTITY_UNIT_OPTIONS}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
			</>
		);
	}, [renderFieldTypeDropdown, filterUnits, clearUnit, onUnitChange]);

	const renderOperationDropdown = React.useCallback(() => {
		return (
			<>
				<Col sm={12}>
					{renderFieldTypeDropdown()}
				</Col>
				<Col sm={12}>
					<Field
						component={Dropdown}
						fixed={true}
						id="operationType"
						label="Operator *"
						labelKey="label"
						name="operationType"
						onValueChange={onOperationChange}
						options={OPERATION_TYPE_OPTIONS}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
			</>
		);
	}, [onOperationChange, renderFieldTypeDropdown]);

	const renderImageUpload = React.useCallback(() => {
		return (
			<Field
				component={ImageUploadField}
				name="defaultValue"
				onImageUpload={handleImageUpload}
			/>
		);
	}, [handleImageUpload]);

	const renderDescriptiveTextBlock = React.useCallback(() => {
		return (
			<Field
				component={DescriptiveTextBlock}
				name="defaultValue"
			/>
		);
	}, []);

	const renderCalculatedOptions = React.useCallback(() => {
		return (
			<FieldArray<CalculatedFieldOptionsProps>
				additionalFilter={_blockBuilderFilter}
				change={change}
				component={CalculatedFieldOptions}
				fieldsByIdMap={fieldsByIdMap}
				formValues={formValues}
				grouped={false}
				name="calculatedFieldOptions"
				options={fields}
			/>
		);
	}, [change, fieldsByIdMap, formValues, fields]);

	const renderDropdownOptions = React.useCallback(() => {
		return (
			<FieldArray<DropdownListFieldArrayOwnProps>
				allowCustomDropdownListValue={formValues.allowCustomDropdownListValue ?? false}
				component={DropdownListField}
				name="options"
			/>
		);
	}, [formValues.allowCustomDropdownListValue]);

	const renderOptions = React.useCallback(() => {
		if (showOptions) {
			return renderDropdownOptions();
		} else if (showCalculatedOptions) {
			return renderCalculatedOptions();
		} else if (showImageUpload) {
			return renderImageUpload();
		} else if (showDescriptiveTextBlock) {
			return renderDescriptiveTextBlock();
		} else {
			return null;
		}
	}, [
		showOptions,
		showCalculatedOptions,
		showImageUpload,
		showDescriptiveTextBlock,
		renderCalculatedOptions,
		renderDropdownOptions,
		renderImageUpload,
		renderDescriptiveTextBlock,
	]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={`${firstInitialValues?.id ? 'Edit' : 'Add New'} Field`}
			/>
			<CustomModal.Body>
				<Row className="row--non-padded">
					<Col sm={12}>
						{
							showName &&
							<Field
								component={Input}
								id="name"
								label="Name *"
								name="name"
								placeholder="Enter Field Name"
								type="text"
							/>
						}
					</Col>
					<Col sm={12}>
						{showWidthDropdown &&
							<Field
								component={Dropdown}
								disabled={disabledWidthDropdown}
								dropdownClassName="report-block-form-field-modal__dimension-dropdown"
								fixed={true}
								id="dimension"
								label="Field Dimension *"
								labelKey="label"
								name="dimension"
								options={_getDimensionOptions(formValues.unit)}
								valueKey="id"
								withCaret={true}
							/>
						}
					</Col>
				</Row>
				<Row className="row--non-padded">
					{renderFieldType()}
				</Row>
				<div className="report-block-form-field-modal__grouped">
					{renderOptions()}
					<Row className="row--non-padded">
						<Field
							component={Checkbox}
							id="isVisibleToCustomer"
							inline={true}
							isDisabled={isVisibleToCustomerDisabled}
							isStandalone={true}
							label={ReportBlockFieldEnums.ReportBlockFieldFlags.IS_VISIBLE_TO_CUSTOMER}
							name="isVisibleToCustomer"
							tooltipMessage={ReportBlockFieldEnums.ReportBlockFieldFlagHint.IS_VISIBLE_TO_CUSTOMER}
						/>
					</Row>
					<Row className="row--non-padded">
						<Field
							component={Checkbox}
							id="hasTooltip"
							inline={true}
							isDisabled={tooltipDisabled}
							isStandalone={true}
							label={ReportBlockFieldEnums.ReportBlockFieldFlags.HAS_CUSTOM_INFORMATION_TOOLTIP}
							name="hasTooltip"
							tooltipMessage={ReportBlockFieldEnums.ReportBlockFieldFlagHint.HAS_CUSTOM_INFORMATION_TOOLTIP}
						/>
					</Row>
					{formValues.hasTooltip &&
						<Field
							component={Textarea}
							maxCharacters={REPORT_BLOCK_TOOLTIP_MAX_LENGTH}
							name="tooltipText"
							placeholder="Tooltip Message"
							rows={3}
							showMaxCharactersLabel={true}
						/>
					}
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={invalid}
					label="Save"
					onClick={handleSubmit(onCreate)}
					reduxFormSubmitting={submitting}
					submitKey={REPORT_BLOCK_FIELD}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

const getForm = getFormValues(REPORT_BLOCK_FIELD);

function mapStateToProps(state: RootState) {
	return {
		formValues: (getForm(state) ?? DEFAULT_FORM_VALUE) as ReportBlockFieldFormModel,
	};
}

const connector = connect(mapStateToProps, null, null, {
	areStatesEqual: (nextState, prevState) => nextState.form[REPORT_BLOCK_FIELD] === prevState.form[REPORT_BLOCK_FIELD],
});

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector,
	reduxForm({
		form: REPORT_BLOCK_FIELD,
		validate,
	})
);

export default enhance(ReportBlockFieldModal);
