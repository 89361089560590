import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { RevisionInformationGroupsEnum } from 'acceligent-shared/enums/revisionInformationGroups';
import { AdditionalColors, ColorHex, ColorPalette } from 'acceligent-shared/enums/color';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { getUserName } from 'acceligent-shared/utils/user';
import { revisionCode } from 'acceligent-shared/utils/codes';
import * as TimeUtils from 'acceligent-shared/utils/time';

import ConfirmationModal from 'af-components/ConfirmationModal';
import DisabledOnAction from 'af-components/DisabledOnAction';
import ActionButton from 'af-components/DisabledOnAction/ActionButton';
import LoadingIndicator from 'af-components/LoadingIndicator';
import CustomModal from 'af-components/CustomModal';
import LabelWithColor from 'af-components/LabelWithColor';

import { generateGoogleMapLink } from 'ab-utils/location.util';
import { formatPhoneNumber } from 'ab-utils/phone.util';
import { toValueString } from 'ab-utils/timeOption.util';
import type { CrewAndDateDifferences, Differences, GeneralDifferences, JobDifferences, LocationsDifferences, Notes_1_Differences, Notes_2_Differences, ResourcesDifferences } from 'ab-utils/workOrder.util';

import * as WorkOrderActions from 'af-actions/workOrder';

import styles from './styles.module.scss';

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	approve: (revertOnOutdated: boolean) => Promise<void>;
	submit?: (revertOnOutdated: boolean) => Promise<void>;
	workOrderOutdatedDifferences: Nullable<Differences>;
	isSubmit: boolean;
	revision: number;
	revisionCreatedAt: Date | undefined;
	woUpdatedAt: Date;
	woCode: string;
	workOrderId: number;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const OUTDATED_INFO_CATEGORIES_ORDERED = [
	RevisionInformationGroupsEnum.JOB,
	RevisionInformationGroupsEnum.CREW_AND_DATE,
	RevisionInformationGroupsEnum.GENERAL,
	RevisionInformationGroupsEnum.LOCATIONS,
	RevisionInformationGroupsEnum.RESOURCES,
	RevisionInformationGroupsEnum.NOTES_1,
	RevisionInformationGroupsEnum.NOTES_2,
];

const WO_NOT_CHANGED_AFTER_REPUBLISH_MODAL_BODY = (
	<>
		Work Order has been republished and changes might have occurred on Field Report.
		<br />
		Do you want to review before approving?
	</>
);

const OutdatedChangesModal: React.FC<Props> = (props) => {

	const {
		approve,
		closeModal,
		showModal,
		workOrderOutdatedDifferences,
		submit,
		isSubmit,
		publish,
		revision,
		revisionCreatedAt,
		woUpdatedAt,
		woCode,
		workOrderId,
	} = props;

	const [showWONotChangedAfterRepublishModal, setWONotChangedAfterRepublishModal] = React.useState(false);

	const toggleShowWONotChangedAfterRepublishModal = React.useCallback(() => {
		setWONotChangedAfterRepublishModal(!showWONotChangedAfterRepublishModal);
	}, [showWONotChangedAfterRepublishModal]);

	const submitOrApprove = React.useCallback(async () => {
		if (showWONotChangedAfterRepublishModal) {
			toggleShowWONotChangedAfterRepublishModal();
		}
		if (isSubmit && submit) {
			await submit(false);
		} else {
			await approve(false);
		}
	}, [approve, isSubmit, showWONotChangedAfterRepublishModal, submit, toggleShowWONotChangedAfterRepublishModal]);

	const onRollback = React.useCallback(async () => {
		closeModal?.();
		if (isSubmit && submit) {
			await submit(true);
		} else {
			await approve(true);
		}
	}, [approve, closeModal, isSubmit, submit]);

	const onRepublish = React.useCallback(async () => {
		closeModal?.();
		await publish(workOrderId);
		toggleShowWONotChangedAfterRepublishModal();
	}, [closeModal, publish, toggleShowWONotChangedAfterRepublishModal, workOrderId]);

	const renderLabel = (name: string, color: Nullable<ColorPalette>, isEquipment: boolean, isTemp: boolean, info?: Nullable<string>) => {
		const defaultColor = isEquipment ? ColorPalette.RED : AdditionalColors.GREY;
		return (
			<span
				className={`${styles.label} ${isEquipment ? styles.dynamicBackgroundColor : styles.dynamicTextColor}`}
				style={{ '--dynamic-color': color ? ColorHex[color] : defaultColor } as React.CSSProperties}
			>
				{
					isTemp &&
					<span className={'icon-temp_labor'}></span>
				}
				{name}{info ? ` ${info}` : ''}
			</span>
		);
	};

	const renderNotes1 = (diffs: Notes_1_Differences, isCurrent: boolean) => {
		const note = isCurrent ? diffs.notes?.workOrder : diffs.notes?.workOrderRevision;
		const jobNote = isCurrent ? diffs.jobNotes?.workOrder : diffs.jobNotes?.workOrderRevision;
		const scopeOfWorkNote = isCurrent ? diffs.scopeOfWork?.workOrder : diffs.scopeOfWork?.workOrderRevision;

		return (
			<>
				{
					diffs.notes?.workOrder !== diffs.notes?.workOrderRevision &&
					<>
						<div className={styles['notes-field-name']}>WORK ORDER NOTES</div>
						<div className={styles['notes-field']}>{note ?? '-'}</div>
					</>
				}
				{
					diffs.jobNotes?.workOrder !== diffs.jobNotes?.workOrderRevision &&
					<>
						<div className={styles['notes-field-name']}>JOB NOTES</div>
						<div className={styles['notes-field']}>{jobNote ?? '-'}</div>
					</>
				}
				{
					diffs.scopeOfWork?.workOrder !== diffs.scopeOfWork?.workOrderRevision &&
					<>
						<div className={styles['notes-field-name']}>SCOPE OF WORK NOTES</div>
						<div className={styles['notes-field']}>{scopeOfWorkNote ?? '-'}</div>
					</>
				}
			</>
		);
	};

	const renderNotes2 = (diffs: Notes_2_Differences, isCurrent: boolean) => {
		const revenue = isCurrent ? diffs.revenue?.workOrder : diffs.revenue?.workOrderRevision;
		const manHourAverage = isCurrent ? diffs.manHourAverage?.workOrder : diffs.manHourAverage?.workOrderRevision;
		const jobHours = isCurrent ? diffs.jobHours?.workOrder : diffs.jobHours?.workOrderRevision;
		const shopHours = isCurrent ? diffs.shopHours?.workOrder : diffs.shopHours?.workOrderRevision;
		const travelHours = isCurrent ? diffs.travelHours?.workOrder : diffs.travelHours?.workOrderRevision;
		const workDescription = isCurrent ? diffs.workDescription?.workOrder : diffs.workDescription?.workOrderRevision;

		return (
			<>
				<div className={styles['group-name']}>NOTES</div>
				{diffs.revenue?.workOrder !== diffs.revenue?.workOrderRevision && (
					<>
						<div className={styles['field-name']}>Revenue</div>
						{revenue ?? '-'}
					</>
				)}
				{diffs.manHourAverage?.workOrder !== diffs.manHourAverage?.workOrderRevision && (
					<>
						<div className={styles['field-name']}>Man Hour Average</div>
						{manHourAverage ?? '-'}
					</>
				)}
				{diffs.jobHours?.workOrder !== diffs.jobHours?.workOrderRevision && (
					<>
						<div className={styles['field-name']}>Job Hours</div>
						{jobHours ?? '-'}
					</>
				)}
				{diffs.shopHours?.workOrder !== diffs.shopHours?.workOrderRevision && (
					<>
						<div className={styles['field-name']}>Shop Hours</div>
						{shopHours ?? '-'}
					</>
				)}
				{diffs.travelHours?.workOrder !== diffs.travelHours?.workOrderRevision && (
					<>
						<div className={styles['field-name']}>Travel Hours</div>
						{travelHours ?? '-'}
					</>
				)}
				{diffs.workDescription?.workOrder !== diffs.workDescription?.workOrderRevision && (
					<>
						<div className={styles['field-name']}>Work Description</div>
						{workDescription ?? '-'}
					</>
				)}
			</>
		);
	};

	const renderJob = (diffs: JobDifferences, isCurrent: boolean) => {
		const title = isCurrent ? diffs.title.workOrder : diffs.title.workOrderRevision;
		const jobCode = isCurrent ? diffs.jobCode.workOrder : diffs.jobCode.workOrderRevision;

		const location = isCurrent ? diffs.location.workOrder : diffs.location.workOrderRevision;

		const office = isCurrent ? diffs.office.workOrder : diffs.office.workOrderRevision;

		const customer = isCurrent ? diffs.customerCompanyName.workOrder : diffs.customerCompanyName.workOrderRevision;
		return (
			<>
				{
					title &&
					<>
						<div className={styles['field-name']}>Job Title/Job ID</div>
						<div className={styles.field}>{title}</div>
						{
							jobCode &&
							<div className={styles.field}>{jobCode}</div>
						}
					</>
				}
				{
					location &&
					<>
						<div className={styles['field-name']}>Location</div>
						<div className={styles.field}>{location}</div>
					</>
				}
				{
					office &&
					<>
						<div className={styles['field-name']}>Office</div>
						<div className={styles.field}>{office.nickname}</div>
					</>
				}
				{
					customer &&
					<>
						<div className={styles['field-name']}>Customer</div>
						<div className={styles.field}>{customer}</div>
					</>
				}
			</>
		);
	};

	const renderCrewAndDate = (diffs: CrewAndDateDifferences, isCurrent: boolean) => {
		const color = isCurrent ? diffs.crewTypeColor?.workOrder : diffs.crewTypeColor?.workOrderRevision;
		const name = isCurrent ? diffs.crewTypeName?.workOrder : diffs.crewTypeName?.workOrderRevision;
		const superintendend = isCurrent ? diffs.supervisor?.workOrder : diffs.supervisor?.workOrderRevision;
		return (
			<>
				{
					color && name &&
					<>
						<div className={styles['field-name']}>Crew Type</div>
						<LabelWithColor className="bold" color={color as string} text={name as string} />
					</>
				}
				{
					superintendend &&
					<>
						<div className={styles['field-name']}>Superintendent</div>
						<div className={styles.field}>{getUserName(superintendend.account.user)}</div>
						{
							superintendend.account.user.phoneNumber &&
							<div className={styles.field}>
								{formatPhoneNumber(superintendend.account.user.phoneNumber, superintendend.account.user.countryCode)}
							</div>
						}
					</>
				}
			</>
		);
	};

	const renderGeneral = (diffs: GeneralDifferences, isCurrent: boolean) => {
		const shift = isCurrent ? diffs.shiftName?.workOrder : diffs.shiftName?.workOrderRevision;
		const timeStart = isCurrent ? diffs.timeToStart?.workOrder : diffs.timeToStart?.workOrderRevision;
		const timeEnd = isCurrent ? diffs.timeToEnd?.workOrder : diffs.timeToEnd?.workOrderRevision;
		const projectManager = isCurrent ? diffs.projectManager?.workOrder : diffs.projectManager?.workOrderRevision;
		return (
			<>
				<div className={styles['group-name']}>GENERAL</div>
				{
					(diffs.projectManager?.workOrder ?? diffs.projectManager?.workOrderRevision) &&
					<>
						<div className={styles['field-name']}>Project Manager</div>
						<div className={styles.field}>{projectManager ? getUserName(projectManager.account.user) : '-'}</div>
					</>

				}
				{
					shift &&
					<>
						<div className={styles['field-name']}>Shift</div>
						<div className={styles.field}>{shift}</div>
					</>
				}
				{
					timeStart && // if there is time to start, time to end will be there as well
					<>
						<div className={styles['field-name']}>Work Time Start - End</div>
						<div className={styles.field}>{toValueString(timeStart as number)} - {toValueString(timeEnd as number)}</div>
					</>
				}
			</>
		);
	};

	const renderLocations = (diffs: LocationsDifferences, isCurrent: boolean) => {
		const locations = isCurrent ? diffs.addresses?.workOrder : diffs.addresses?.workOrderRevision;
		const siteContact = isCurrent ? diffs.siteContact?.workOrder : diffs.siteContact?.workOrderRevision;

		if (locations?.length || siteContact) {
			return (
				<>
					<div className={styles['group-name']}>WORK LOCATIONS</div>
					{locations?.map((l, index) => {
						const { latitude: lat, longitude: long } = 'address' in l ? l.address : l;
						const mapLink = generateGoogleMapLink(lat, long);
						const addressLine = 'address' in l ? l.address.street : `${l.streetAddress}, ${l.locality}, ${l.aa1} ${l.postalCode}, ${l.country}`;
						return (
							<div className={styles.field} key={index}>
								{addressLine}<br />
								{mapLink ? (
									<a className={styles['map-link']} href={mapLink}>View on Google Maps</a>
								) : (
									<span>Google Maps link is not available</span>
								)}
							</div>
						);
					})}
					{
						locations?.length === 0 &&
						<>-</>
					}
					{siteContact && (
						'contact' in siteContact
							? `${siteContact.contact.fullName}, ${siteContact.contact.companyName}`
							: `${siteContact.name}, ${siteContact.companyName}`
					)}
				</>
			);
		}
	};

	const renderResources = (diffs: ResourcesDifferences, isCurrent: boolean) => {
		const employees = isCurrent
			? diffs.employees?.workOrder?.map((e) => e.employee)
			: diffs.employees?.workOrderRevision;

		const temporaryEmployees = isCurrent
			? diffs.temporaryEmployees?.workOrder?.map((te) => te.temporaryEmployee)
			: diffs.temporaryEmployees?.workOrderRevision;

		const equipment = isCurrent
			? diffs.equipment?.workOrder?.map((e) => e.equipment)
			: diffs.equipment?.workOrderRevision;

		if (employees || temporaryEmployees || equipment) {
			return (
				<>
					<div className={styles['group-name']}>LABOR & EQUIPMENT</div>
					{employees?.map((e) => (
						<>
							{renderLabel(getUserName(e.account.user, true), e.account.location?.color ?? null, false, false, e.wageRate.type)}
							<br />
						</>
					))}
					{temporaryEmployees?.map((te) => (
						<>
							{renderLabel(getUserName(te.account.user, true), te.agency?.color, false, true)}
							<br />
						</>
					))}
					{equipment?.map((e) => (
						<>
							{renderLabel(e.code, e.location?.color ?? null, true, false, e.specification)}
							<br />
						</>
					))}
				</>
			);
		}
	};

	const renderGroupInfo = (isCurrent: boolean) => {
		const renderedGroups: JSX.Element[] = [];

		for (const group of OUTDATED_INFO_CATEGORIES_ORDERED) {
			const diffs = workOrderOutdatedDifferences?.[group];
			if (diffs) {
				let renderedGroup: Nullable<JSX.Element>;
				switch (group) {
					case RevisionInformationGroupsEnum.JOB:
						renderedGroup = renderJob(diffs as JobDifferences, isCurrent);
						break;
					case RevisionInformationGroupsEnum.CREW_AND_DATE:
						renderedGroup = renderCrewAndDate(diffs as CrewAndDateDifferences, isCurrent);
						break;
					case RevisionInformationGroupsEnum.GENERAL:
						renderedGroup = renderGeneral(diffs as GeneralDifferences, isCurrent);
						break;
					case RevisionInformationGroupsEnum.RESOURCES:
						renderedGroup = renderResources(diffs as ResourcesDifferences, isCurrent) ?? null;
						break;
					case RevisionInformationGroupsEnum.LOCATIONS:
						renderedGroup = renderLocations(diffs as LocationsDifferences, isCurrent) ?? null;
						break;
					case RevisionInformationGroupsEnum.NOTES_1:
						renderedGroup = renderNotes1(diffs as Notes_1_Differences, isCurrent);
						break;
					case RevisionInformationGroupsEnum.NOTES_2:
						renderedGroup = renderNotes2(diffs as Notes_2_Differences, isCurrent);
						break;
				}
				if (renderedGroup) {
					renderedGroups.push(<div className={`${styles['info-block']} ${isCurrent ? styles.current : styles.revision}`} key={group}>{renderedGroup}</div>);
				}
			}
		}

		return renderedGroups;
	};

	return (
		<>
			<CustomModal
				closeModal={closeModal}
				modalStyle="danger"
				showModal={showModal}
				size="xl"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Work Order is currently outdated"
				/>
				<CustomModal.Body>
					<>
						You are attempting to {isSubmit ? 'submit to review' : 'approve'} this report which will lock the <b>{woCode}</b> Work Order from having further changes being made.
						<br />
						The Work Order is currently in <b>OUTDATED</b> state.
						<br />
						Do you wish to publish those changes or rollback to last published version?
						<br />
						<div className={styles['main-container']}>
							<div className={styles['info-container']}>
								<div className={styles['info-header']}>
									<div className={styles['group-name']}>LATEST PUBLISHED REVISION</div>
									<div className={styles['group-info']}>
										<span className={`icon-check ${styles.check}`}></span>
										<div>
											REV. {revisionCode(revision)} - {
												TimeUtils.formatDate(revisionCreatedAt?.toString(), TimeFormat.FULL_DATE_SLASHES)
											}
										</div>
									</div>
								</div>
								<div className={styles.info}>
									{renderGroupInfo(false)}
								</div>

							</div>
							<div className={styles['info-container']}>
								<div className={styles['info-header']}>
									<div className={styles['group-name']}>CURRENT WORK ORDER</div>
									<div className={styles['group-info']}>
										<span className={`icon-clock ${styles.clock}`}></span>
										<div>
											Unpublished - {TimeUtils.formatDate(woUpdatedAt?.toString(), TimeFormat.FULL_DATE_SLASHES, TimeFormat.ISO_DATETIME)}
										</div>
									</div>
								</div>
								<div className={styles.info}>
									{renderGroupInfo(true)}
								</div>

							</div>
						</div>
					</>
				</CustomModal.Body>
				<CustomModal.Footer>
					<>
						<Button
							onClick={closeModal}
							variant="info"
						>
							Cancel
						</Button>
						<DisabledOnAction
							activeElement={<span>Rollback And Approve</span>}
							component={ActionButton}
							disabledElement={<LoadingIndicator />}
							inactiveElement={<LoadingIndicator />}
							onClick={onRollback}
							variant={'danger'}
						/>
						<DisabledOnAction
							activeElement={<span>Republish And Approve</span>}
							component={ActionButton}
							disabledElement={<LoadingIndicator />}
							inactiveElement={<LoadingIndicator />}
							onClick={onRepublish}
							variant={'primary'}
						/>
					</>
				</CustomModal.Footer>
			</CustomModal>
			<ConfirmationModal
				body={WO_NOT_CHANGED_AFTER_REPUBLISH_MODAL_BODY}
				closeModal={toggleShowWONotChangedAfterRepublishModal}
				closeText="Yes"
				confirmAction={submitOrApprove}
				confirmText="No"
				modalStyle="warning"
				showModal={showWONotChangedAfterRepublishModal}
				title="Work Order is republished"
			/>
		</>
	);
};

function mapDispatchToProps() {
	return {
		publish: WorkOrderActions.publishWorkOrder,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(OutdatedChangesModal);
