import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import { TimeSheetInternalApprovalStatus } from 'acceligent-shared/enums/timeSheetApprovalStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { filterMap } from 'acceligent-shared/utils/array';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type JobPayrollTableOldBase from 'ab-domain/views/jobPayrollTableOld/base';

import { stateAbbreviation } from 'ab-enums/states.enum';

import { formatDecimalNumber } from 'ab-utils/formatting.util';

// #region Private:CSV

const _booleanFormatter = (value: boolean) => value ? 'Yes' : 'No';
const _dateOnlyFormatter = (value: string) => TimeUtils.formatDate(value, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

type ParentRowCSVKeys = keyof JobPayrollTableRowVM & (
	| 'calculatedJobCode'
	| 'jobTitle'
	| 'divisionName'
	| 'allCrewIsInternal'
);
type ChildRowCSVKeys = keyof JobPayrollChildTableRowVM & (
	| 'localDateValue'
	| 'workOrderShift'
	| 'workOrderWeeklyCode'
	| 'isWorkOrderCanceled'
	| 'crewName'
	| 'userUniqueId'
	| 'userFullName'
	| 'accountLocationAddressState'
	| 'isTimeSheetSigned'
	| 'isTimeSheetApproved'
	| 'timeSheetNote'
	| 'fieldWorkClassificationCode'
	| 'equipmentIdCodes'
	| 'jobHours'
	| 'totalJobHours'
	| 'breakHours'
	| 'totalBreakHours'
	| 'shopHours'
	| 'totalShopHours'
	| 'travelHours'
	| 'totalTravelHours'
	| 'totalHours'
	| 'totalHoursPerWO'
);
/** Complex keys require dynamic getters/formatters to be created in runtime */
type ComplexValueCSVKeys = 'reportUrl';

type CSVKey = ParentRowCSVKeys | ChildRowCSVKeys | ComplexValueCSVKeys;

interface SharedCSVMetadataProps {
	label: string;
	index: number;
}
interface ParentRowCSVMetadataProps<TKey extends ParentRowCSVKeys> extends SharedCSVMetadataProps {
	isInChildTable: false;
	formatter?: (value: JobPayrollTableRowVM[TKey]) => string;
	fallback?: string;
	isComplexValue?: false;
}
interface ChildRowCSVMetadataProps<TKey extends ChildRowCSVKeys> extends SharedCSVMetadataProps {
	isInChildTable: true;
	formatter?: (value: JobPayrollChildTableRowVM[TKey]) => string;
	fallback?: string;
	isComplexValue?: false;
}
interface ComplexValueCSVMetadataProps extends SharedCSVMetadataProps {
	isComplexValue: true;
}

/** Value used ONLY inside `CSV_METADATA_LOOKUP` to initialize `index` values */
let CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX = -1;

const CSV_METADATA_LOOKUP: Readonly<(
	& { [ParentKey in ParentRowCSVKeys]: Readonly<ParentRowCSVMetadataProps<ParentKey>>; }
	& { [ChildKey in ChildRowCSVKeys]: Readonly<ChildRowCSVMetadataProps<ChildKey>>; }
	& { [ComplexKey in ComplexValueCSVKeys]: Readonly<ComplexValueCSVMetadataProps> }
)> = {
	localDateValue: { isInChildTable: true, label: 'Date', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, formatter: _dateOnlyFormatter },
	calculatedJobCode: { isInChildTable: false, label: 'Job Id', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	workOrderShift: { isInChildTable: true, label: 'Work Order', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	workOrderWeeklyCode: { isInChildTable: true, label: 'Work Order Weekly', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	userUniqueId: { isInChildTable: true, label: 'Employee ID', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	userFullName: { isInChildTable: true, label: 'Employee Name', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	divisionName: { isInChildTable: false, label: 'Division', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	accountLocationAddressState: { isInChildTable: true, label: 'Home State', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	fieldWorkClassificationCode: { isInChildTable: true, label: 'Classification Code', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, fallback: '[MISSING]' },
	equipmentIdCodes: { isInChildTable: true, label: 'Equipment', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	timeSheetNote: { isInChildTable: true, label: 'Time Card Note', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	jobHours: { isInChildTable: true, label: 'Job Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	shopHours: { isInChildTable: true, label: 'Shop Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	travelHours: { isInChildTable: true, label: 'Travel Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	breakHours: { isInChildTable: true, label: 'Break Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalJobHours: { isInChildTable: true, label: 'Total Job Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalShopHours: { isInChildTable: true, label: 'Total Shop Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalTravelHours: { isInChildTable: true, label: 'Total Travel Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalBreakHours: { isInChildTable: true, label: 'Total Break Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalHours: { isInChildTable: true, label: 'Total Hours', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	totalHoursPerWO: { isInChildTable: true, label: 'Total Hours/WO', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	jobTitle: { isInChildTable: false, label: 'Job Title', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	allCrewIsInternal: { isInChildTable: false, label: 'Job Is Internal', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	crewName: { isInChildTable: true, label: 'Crew', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
	isTimeSheetSigned: { isInChildTable: true, label: 'Time Is Signed', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, formatter: _booleanFormatter },
	isTimeSheetApproved: { isInChildTable: true, label: 'Time Is Approved', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, formatter: _booleanFormatter },
	isWorkOrderCanceled: { isInChildTable: true, label: 'Work Order Is Cancelled', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX, formatter: _booleanFormatter },
	reportUrl: { isComplexValue: true, label: 'Report URL', index: ++CSV_METADATA_LOOKUP_INITIALIZE_LAST_INDEX },
};

type _CSVMetadataLookup = { [TKey in CSVKey]: (typeof CSV_METADATA_LOOKUP)[TKey] & { key: TKey; } };
type CSVMetadata = _CSVMetadataLookup[CSVKey];
type SimpleValueCSVMetadata = Omit<_CSVMetadataLookup, ComplexValueCSVKeys>[Exclude<CSVKey, ComplexValueCSVKeys>];

const CSV_METADATA_LIST = Object.keys(CSV_METADATA_LOOKUP)
	.map((_key): CSVMetadata => ({ ...CSV_METADATA_LOOKUP[_key], key: _key }))
	.sort((_e1, _e2) => _e1.index - _e2.index);

const CSV_HEADER_ROW = CSV_METADATA_LIST.map((_metadata) => _metadata.label);

const BETA_COLUMN_KEYS = [
	'totalJobHours',
	'totalBreakHours',
	'totalShopHours',
	'totalTravelHours',
	'totalHours',
	'totalHoursPerWO',
];

const BETA_COLUMN_LABELS = filterMap(CSV_METADATA_LIST, (value) => BETA_COLUMN_KEYS.includes(value.key), (value) => value.label);

type ComplexValueGetter = (parentRow: JobPayrollTableRowVM, childRow?: JobPayrollChildTableRowVM) => string;

// #endregion Private:CSV

export type JobPayrollTableCSVComplexValueGetterLookup = Record<ComplexValueCSVKeys, ComplexValueGetter>;

export class JobPayrollChildTableRowVM {
	// Computed time range columns:

	/** `YYYY-MM-DD`, time date value based on notification settings timezone */
	localDateValue: string;

	// WorkOrder columns:

	workOrderId: number;
	/** `YYYY-MM-DD` */
	workOrderDueDate: string;
	isWorkOrderCanceled: boolean;

	/** `customCrewType` of WO if internal, otherwise `CrewType.name` */
	crewName: string;

	// WorkOrder x Computed time range columns:

	/**
	 * `WorkOrder['code']` (the middle part of the `calculated_work_order_code` string)
	 * plus a shift, i.e. day indicator (see implementation)
	 *
	 * @see {@link https://acceligent.atlassian.net/browse/AP-6711 AP-6711} for some special cases of how shits should be assigned.
	 */
	workOrderShift: string;
	workOrderWeeklyCode: string;

	// Account columns:

	userId: number;
	userUniqueId: string;
	userFullName: string;

	accountId: number;
	accountLocationAddressState: Nullable<string>;

	// TimeSheet & TimeSplit columns:

	timeSheetId: number;

	isTimeSheetSigned: boolean;
	isTimeSheetApproved: boolean;
	timeSheetNote: Nullable<string>;

	/** `timeSplitFWCCCode` ?? `timeSplitsWithZeroTimeFWCCCodesText` ?? null */
	fieldWorkClassificationCode: Nullable<string>;
	/** `timeSplitEquipmentIdCodesText` ?? `timeSplitsWithZeroTimeEquipmentIdCodesText` ?? null */
	equipmentIdCodes: Nullable<string>;

	/** `#.##` */
	jobHours: string;
	totalJobHours: string;
	/** `#.##` */
	breakHours: string;
	totalBreakHours: string;
	/** `#.##` */
	shopHours: string;
	totalShopHours: string;
	/** `#.##` */
	travelHours: string;
	totalTravelHours: string;
	/** `#.##` */
	totalHours: string;
	totalHoursPerWO: string;

	/** list of all possible shift indicators, currently: none (`''`), `'N'` or `'NN'` */
	private static readonly SHIFTS = ['', 'N', 'NN'];
	private static readonly LAST_WEEK_SHIFT = 'LW';
	private static readonly NEXT_WEEK_SHIFT = 'FW';
	/** shift indicator for unexpected cases, should never happen */
	private static readonly SHIFT_OUT_OF_RANGE = 'ERR';

	private constructor(dbRow: JobPayrollTableOldBase, showTotalEmployee?: boolean, showTotalWO?: boolean) {
		this.localDateValue = dbRow.localDateValue;

		this.workOrderId = dbRow.workOrderId;
		this.workOrderDueDate = dbRow.workOrderDueDate;
		this.isWorkOrderCanceled = dbRow.workOrderStatus === WorkOrderStatus.CANCELED;
		this.crewName = dbRow.crewName;

		const workOrderCodeShort = dbRow.workOrderCode < 10 ? `0${dbRow.workOrderCode}` : dbRow.workOrderCode.toString();
		const shiftIndicator = JobPayrollChildTableRowVM.SHIFTS[dbRow.localDateInWorkOrderIndex] ?? JobPayrollChildTableRowVM.SHIFT_OUT_OF_RANGE;
		const nextWeekIndicator = dbRow.localWeekInWorkOrderIndex > 0 && dbRow.isLocalDateOutOfRange ? JobPayrollChildTableRowVM.NEXT_WEEK_SHIFT : '';
		const lastWeekIndicator = dbRow.localWeekInWorkOrderIndex > 0 && !nextWeekIndicator ? JobPayrollChildTableRowVM.LAST_WEEK_SHIFT : '';
		this.workOrderShift = `${workOrderCodeShort}${shiftIndicator}${lastWeekIndicator}${nextWeekIndicator}`;

		const workOrderWeeklyCodeShort = dbRow.workOrderWeeklyCode < 10 ? `0${dbRow.workOrderWeeklyCode}` : dbRow.workOrderWeeklyCode?.toString();
		this.workOrderWeeklyCode = `${workOrderWeeklyCodeShort}${shiftIndicator}${lastWeekIndicator}${nextWeekIndicator}`;

		this.userId = dbRow.userId;
		this.userUniqueId = dbRow.userUniqueId;
		this.userFullName = dbRow.userFullName;
		this.accountId = dbRow.accountId;
		this.accountLocationAddressState = stateAbbreviation[dbRow.accountLocationAddressAa1] ?? null;

		this.timeSheetId = dbRow.timeSheetId;
		this.isTimeSheetSigned = !!dbRow.timeSheetSignatureId;
		this.isTimeSheetApproved = dbRow.timeSheetApprovalStatus === TimeSheetInternalApprovalStatus.APPROVED;
		this.timeSheetNote = dbRow.localDateInWorkOrderIndex === 0 ? dbRow.timeSheetNote : null;
		this.fieldWorkClassificationCode = dbRow.timeSplitFWCCCode ?? dbRow.timeSplitsWithZeroTimeFWCCCodesText ?? null;
		this.equipmentIdCodes = dbRow.timeSplitEquipmentIdCodesText ?? dbRow.timeSplitsWithZeroTimeEquipmentIdCodesText ?? null;

		this.jobHours = formatDecimalNumber(dbRow.jobTime / 60);
		this.totalJobHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalJobTime / 60) : '';
		this.breakHours = formatDecimalNumber(dbRow.breakTime / 60);
		this.totalBreakHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalBreakTime / 60) : '';
		this.shopHours = formatDecimalNumber(dbRow.shopTime / 60);
		this.totalShopHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalShopTime / 60) : '';
		this.travelHours = formatDecimalNumber(dbRow.travelTime / 60);
		this.totalTravelHours = showTotalEmployee ? formatDecimalNumber(dbRow.totalTravelTime / 60) : '';

		this.totalHours = formatDecimalNumber(dbRow.totalHours / 60);
		this.totalHoursPerWO = showTotalWO ? formatDecimalNumber(dbRow.totalHoursPerWO / 60) : '';
	}

	static bulkConstructor = (dbRowsForJob: JobPayrollTableOldBase[]) => {
		const childTableRows: JobPayrollChildTableRowVM[] = [];
		for (let i = 0; i < dbRowsForJob.length; i++) {
			const dbRow = dbRowsForJob[i];
			childTableRows.push(new JobPayrollChildTableRowVM(
				dbRow,
				dbRow.userId !== dbRowsForJob[i + 1]?.userId || dbRow.workOrderId !== dbRowsForJob[i + 1]?.workOrderId,
				dbRow.workOrderId !== dbRowsForJob[i + 1]?.workOrderId
			));
		}
		return childTableRows;
	};
}

export class JobPayrollTableRowVM {
	jobId: number;
	calculatedJobCode: string;
	jobTitle: string;

	divisionName: string;

	allCrewIsInternal: boolean;

	childTable: TableContent<JobPayrollChildTableRowVM>;

	private constructor(dbRowsForJob: JobPayrollTableOldBase[]) {
		const firstRow = dbRowsForJob[0];

		this.jobId = firstRow.jobId;
		this.calculatedJobCode = firstRow.calculatedJobCode;
		this.jobTitle = firstRow.jobTitle;
		this.divisionName = firstRow.divisionName ?? '';
		this.allCrewIsInternal = firstRow.crewIsInternal;

		const childTableRows = JobPayrollChildTableRowVM.bulkConstructor(dbRowsForJob);
		this.childTable = new TableContent(childTableRows, 1, childTableRows.length);
	}

	/** NOTE: `dbRows` is assumed to be ordered by (job, workOrder, user, date), as described in {@link JobPayrollTableOldBase} */
	static bulkConstructor(dbRows: JobPayrollTableOldBase[]): JobPayrollTableRowVM[] {
		if (!dbRows?.length) {
			return [];
		}
		const result: JobPayrollTableRowVM[] = [];

		const firstRow = dbRows[0];
		let currentJobId: number = firstRow.jobId;
		let currentUserId: number = firstRow.userId;
		let currentWorkOrderId: number = firstRow.workOrderId;
		/** All rows for one (workOrder, user) grouping, i.e. all originating data belongs to the same TimeSheet */
		let dbRowsForCurrentWorkOrderUser: JobPayrollTableOldBase[] = [firstRow];
		/** All rows for one job, but with each (workOrder, user) grouping having normalized time values */
		let dbRowsForCurrentJobNormalized: JobPayrollTableOldBase[] = [];

		for (const _dbRow of dbRows) {
			if (_dbRow === firstRow) {
				// Already assigned all values for first row
				continue;
			}
			if (currentUserId !== _dbRow.userId || currentWorkOrderId !== _dbRow.workOrderId) {
				dbRowsForCurrentJobNormalized.push(...JobPayrollTableRowVM._normalizeTimeValues(dbRowsForCurrentWorkOrderUser));

				// Reset (workOrder, user) grouping:
				currentUserId = _dbRow.userId;
				currentWorkOrderId = _dbRow.workOrderId;
				dbRowsForCurrentWorkOrderUser = [];
			}
			if (currentJobId !== _dbRow.jobId) {
				const dbRowsForCurrentJobNormalizedInRange = dbRowsForCurrentJobNormalized.filter(JobPayrollTableRowVM._isNotOutOfDateRange);
				if (!!dbRowsForCurrentJobNormalizedInRange.length) {
					result.push(new JobPayrollTableRowVM(dbRowsForCurrentJobNormalizedInRange));
				}

				// Reset job:
				currentJobId = _dbRow.jobId;
				dbRowsForCurrentJobNormalized = [];
			}

			dbRowsForCurrentWorkOrderUser.push(_dbRow);
		}
		// Loop logic for final grouping:
		dbRowsForCurrentJobNormalized.push(...JobPayrollTableRowVM._normalizeTimeValues(dbRowsForCurrentWorkOrderUser));
		const dbRowsForCurrentJobNormalizedInRange = dbRowsForCurrentJobNormalized.filter(JobPayrollTableRowVM._isNotOutOfDateRange);
		if (!!dbRowsForCurrentJobNormalizedInRange.length) {
			result.push(new JobPayrollTableRowVM(dbRowsForCurrentJobNormalizedInRange));
		}

		return result;
	}

	/**
	 * Returns `true` if row is in queried Payroll Report date range and should be included in the final result.
	 * Includes rows in date range or if it overflows into the following week (FW suffix)
	 *
	 * **DO NOT** exclude rows before calculations between rows (such as {@link _normalizeTimeValues})
	 * to ensure same results for same data between differing date ranges.
	 */
	private static _isNotOutOfDateRange = (row: JobPayrollTableOldBase) => !row.isLocalDateOutOfRange || row.shouldDisplay;

	/**
	 * Adjust time values in a group so that all are non-negative while maintaining the same sum in a time category.
	 *
	 * The values are adjusted in reverse order - last rows adjusted first,
	 * and so on until the total sum is adjusted and no negative values left.
	 * This is because the rows are assumed do be ordered by date (ASC order),
	 * and `get_job_payroll_table` is implemented to do offsets at the final row of that grouping.
	 *
	 * @param dbRows row group that is assumed to belong to the same (workOrder, user), i.e. the same Time Sheet
	 * @returns row group with normalized time values in the same order as `dbRows`
	 */
	private static _normalizeTimeValues(dbRows: JobPayrollTableOldBase[]): JobPayrollTableOldBase[] {
		if (!dbRows?.length) {
			return [];
		}
		const { userId, workOrderId } = dbRows[0];
		/** value `<= 0` */
		let jobTimeOffset = 0;
		/** value `<= 0` */
		let breakTimeOffset = 0;
		/** value `<= 0` */
		let shopTimeOffset = 0;
		/** value `<= 0` */
		let travelTimeOffset = 0;

		for (const _row of dbRows) {
			if (_row.userId !== userId || _row.workOrderId !== workOrderId) {
				throw new Error('Cannot normalize values of a different Time Sheet');
			}
			jobTimeOffset += Math.min(_row.jobTime, 0);
			breakTimeOffset += Math.min(_row.breakTime, 0);
			shopTimeOffset += Math.min(_row.shopTime, 0);
			travelTimeOffset += Math.min(_row.travelTime, 0);
		}
		if ((jobTimeOffset + breakTimeOffset + shopTimeOffset + travelTimeOffset) === 0) {
			// Nothing to offset
			return dbRows;
		}

		const reverseResult: JobPayrollTableOldBase[] = [];

		for (const _row of [...dbRows].reverse()) {
			let { jobTime, breakTime, shopTime, travelTime } = _row;

			[jobTime, jobTimeOffset] = JobPayrollTableRowVM._normalizeTimeValue(jobTime, jobTimeOffset);
			[breakTime, breakTimeOffset] = JobPayrollTableRowVM._normalizeTimeValue(breakTime, breakTimeOffset);
			[shopTime, shopTimeOffset] = JobPayrollTableRowVM._normalizeTimeValue(shopTime, shopTimeOffset);
			[travelTime, travelTimeOffset] = JobPayrollTableRowVM._normalizeTimeValue(travelTime, travelTimeOffset);

			reverseResult.push({ ..._row, jobTime, breakTime, shopTime, travelTime });
		}
		if ((jobTimeOffset + breakTimeOffset + shopTimeOffset + travelTimeOffset) !== 0) {
			throw new Error('Unable to fully offset row group, please check your data set');
		}
		return reverseResult.reverse();
	}

	/**
	 * @param currentValue single time value
	 * @param currentOffset total offset for related time value, always `<= 0`
	 * @returns updated value (always `>= 0`) and offset (always `<= 0`)
	 */
	private static _normalizeTimeValue(currentValue: number, currentOffset: number): [newValue: number, newOffset: number] {
		if (currentOffset === 0) {
			return [currentValue, 0];
		}
		if (currentValue <= 0) {
			return [0, currentOffset];
		}
		const newValue = Math.max(currentValue + currentOffset, 0);
		const newOffset = Math.min(currentOffset + (currentValue - newValue), 0);

		return [newValue, newOffset];
	}

	private static _toCSVCell(
		metadata: CSVMetadata,
		parentRow: JobPayrollTableRowVM,
		childRow: JobPayrollChildTableRowVM,
		complexValueGetters: JobPayrollTableCSVComplexValueGetterLookup
	): string {
		if (metadata.isComplexValue) {
			return complexValueGetters[metadata.key](parentRow, childRow);
		}
		const simpleValueMetadata = metadata as SimpleValueCSVMetadata;

		const input = simpleValueMetadata.isInChildTable ? childRow[simpleValueMetadata.key] : parentRow[simpleValueMetadata.key as ParentRowCSVKeys];
		const formatter: Nullable<(value: typeof input) => string> = 'formatter' in simpleValueMetadata ? simpleValueMetadata.formatter ?? null : null; // required to infer typing

		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
		return (formatter ? formatter(input) : `${input || ''}`) || simpleValueMetadata.fallback || '';
	}

	static toCSVData(jobRows: JobPayrollTableRowVM[], complexValueGetters: JobPayrollTableCSVComplexValueGetterLookup, showTotalColumns = false): string[][] {
		const rows: string[][] = [showTotalColumns ? CSV_HEADER_ROW : CSV_HEADER_ROW.filter((value) => !BETA_COLUMN_LABELS.includes(value))];

		for (const _parentRow of jobRows) {
			for (const _childRow of _parentRow.childTable.rows ?? []) {
				const _currentRow: string[] = [];

				for (const _metadata of CSV_METADATA_LIST) {
					if (!showTotalColumns && BETA_COLUMN_LABELS.includes(_metadata.label)) {
						continue;
					}
					_currentRow.push(JobPayrollTableRowVM._toCSVCell(_metadata, _parentRow, _childRow, complexValueGetters));
				}
				rows.push(_currentRow);
			}
		}
		return rows;
	}
}
