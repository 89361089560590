import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import { CompoundUnitEnum, ConstrainedUnitEnum } from 'acceligent-shared/enums/quantityUnit';
import { ExtendedColorPalette } from 'acceligent-shared/enums/color';

import { StringifiedCompoundUnit } from 'acceligent-shared/utils/unit';
import { getUserName } from 'acceligent-shared/utils/user';

import type * as CustomerSignatureRequestModel from 'ab-requestModels/signature.requestModel';

import { bemElement } from 'ab-utils/bem.util';

import { FIELD_PREFIX } from 'af-utils/reportTypeBuilder.util';

import Input from 'af-fields/Input';
import CompoundInput from 'af-fields/CompoundInput';
import CompoundText from 'af-fields/CompoundText';
import ConstrainedInput from 'af-fields/ConstrainedInput';
import Checkbox from 'af-fields/Checkbox';
import Textarea from 'af-fields/Textarea';
import Text from 'af-fields/Text';
import TimePicker from 'af-fields/TimePicker';

import type { RootState } from 'af-reducers';

import { INPUT_FIELD_MAX_CHARACTERS } from 'af-constants/values';

import DropdownField from './DropdownListField';
import ImageField from './ImageField';
import SignatureField from './SignatureField';

import type { ReportBlockFieldFormModel } from '../formModel';
import SignatureModal from '../../ReportType/Modals/SignatureModal';

interface OwnProps {
	field: ReportBlockFieldFormModel;
	formField: string;
	highlightVisibleToCustomer?: boolean;
	id?: string | number;
	isHighlighted?: boolean;
	onCalculatedFieldChange?: (id: string) => void;
	onFieldValueChange?: (id: string, value: string, index: number | undefined) => void;
	repeatIndex?: number;
	formValues?: Record<string, string>[];
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const ReportField: React.FC<Props> = (props: Props) => {
	const {
		field,
		formField,
		fullName,
		highlightVisibleToCustomer = false,
		isHighlighted,
		onCalculatedFieldChange,
		onFieldValueChange,
		repeatIndex,
		formValues,
		id,
		field: {
			allowCustomDropdownListValue,
			calculatedFieldOptions,
			defaultValue,
			descriptiveTextColor,
			dimension,
			fieldType,
			hasTooltip,
			isDescriptiveTextBold,
			isVisibleToCustomer,
			name,
			options,
			tooltipText,
			unit,
			valueType,
			virtualId,
			icon,
		},
	} = props;

	const [fieldName] = React.useState<string>(`${formField}.${FIELD_PREFIX}#${field.virtualId}`);
	const [showSignatureModal, setShowSignatureModal] = React.useState<boolean>(false);
	const [signatureValue, setSignatureValue] = React.useState<Nullable<{ signedAt: string; signature: string; fullName: string; }>>(null);

	// If Calculated field, calculate values on mount
	React.useEffect(() => {
		if (fieldType === ReportBlockFieldEnum.Type.CALCULATED && calculatedFieldOptions) {
			onCalculatedFieldChange?.(virtualId);
		}
	}, [virtualId, fieldType, calculatedFieldOptions, onCalculatedFieldChange]);

	const onChange = React.useCallback((value: string) => {
		onFieldValueChange?.(virtualId, value, repeatIndex);
	}, [onFieldValueChange, virtualId, repeatIndex]);

	const onCompoundChange = React.useCallback((elementUnit: string, value: number) => {
		if (!unit) {
			throw new Error('Unit not defined');
		}

		const compoundValue = formValues?.[fieldName] ?? {};
		compoundValue[elementUnit] = value;
		onFieldValueChange?.(virtualId, StringifiedCompoundUnit[unit](compoundValue), repeatIndex);
	}, [onFieldValueChange, unit, fieldName, formValues, virtualId, repeatIndex]);

	const onConstrainedChange = React.useCallback((value: string) => {
		onFieldValueChange?.(virtualId, value, repeatIndex);
	}, [onFieldValueChange, virtualId, repeatIndex]);

	const openSignatureModal = React.useCallback(() => {
		setShowSignatureModal(true);
	}, []);

	const closeSignatureModal = React.useCallback(() => {
		setShowSignatureModal(false);
	}, []);

	const onSetSignatureValue = React.useCallback(async (signature: CustomerSignatureRequestModel.SignatureForm) => {
		setSignatureValue({ signedAt: signature.signedAt, signature: signature.signatureImage, fullName: signature.fullName });
	}, []);

	const renderImageField = () => {
		const highlightedRed = highlightVisibleToCustomer && isVisibleToCustomer;
		return (
			<ImageField
				fieldName={name}
				highlightedRed={!!highlightedRed}
				label={name}
				tooltipMessage={hasTooltip ? tooltipText : undefined}
				value={defaultValue}
			/>
		);

	};

	const renderBooleanField = () => {
		const highlightedBlue = isHighlighted;
		const extraClass = bemElement('report-block', 'boolean-field', { 'highlighted-blue': !!highlightedBlue });

		return (
			<Field
				component={Checkbox}
				extraClass={extraClass}
				inline={true}
				isStandalone={true}
				label={name}
				labelId={id}
				name={fieldName}
				onValueChange={onChange}
				tooltipMessage={hasTooltip ? tooltipText : null}
			/>
		);

	};

	const renderAddressField = () => {
		const highlightedRed = highlightVisibleToCustomer && isVisibleToCustomer;
		const className = bemElement('report-block', 'input-field', { 'highlighted-red': !!highlightedRed });
		const iconClassName = `icon-location_pin ${bemElement('report-block', 'input-field__icon-location_pin')}`;

		return (
			<Field
				addonAfter={<span className={iconClassName} />}
				className={className}
				component={Input}
				label={name}
				name={fieldName}
				placeholder={name}
				tooltipMessage={hasTooltip ? tooltipText : null}
				type="text"
			/>
		);
	};

	const renderTextAreaField = () => {
		const highlightedRed = highlightVisibleToCustomer && isVisibleToCustomer;
		const highlightedBlue = isHighlighted;
		const className = bemElement('report-block', 'textarea-field', { 'highlighted-red': !!highlightedRed, 'highlighted-blue': !!highlightedBlue });

		return (
			<Field
				className={className}
				component={Textarea}
				dynamicRows={true}
				label={name}
				maxCharacters={INPUT_FIELD_MAX_CHARACTERS}
				name={fieldName}
				placeholder={name}
				rows={1}
				tooltipMessage={hasTooltip ? tooltipText : null}
			/>
		);
	};

	const renderCompoundField = () => {
		const highlightedRed = highlightVisibleToCustomer && isVisibleToCustomer;
		const highlightedBlue = isHighlighted;
		const className = bemElement('report-block', 'input-field', { 'highlighted-red': !!highlightedRed, 'highlighted-blue': !!highlightedBlue });

		return (
			<CompoundInput
				className={className}
				id={id?.toString()}
				label={name}
				name={fieldName}
				onValueChange={onCompoundChange}
				tooltipMessage={hasTooltip ? tooltipText : null}
				unit={unit as CompoundUnitEnum}
			/>
		);
	};

	const renderConstrainedField = () => {
		const highlightedRed = highlightVisibleToCustomer && isVisibleToCustomer;
		const className = bemElement('report-block', 'input-field', { 'highlighted-red': !!highlightedRed });

		return (
			<ConstrainedInput
				className={className}
				label={name}
				name={fieldName}
				onValueChange={onConstrainedChange}
				tooltipMessage={hasTooltip ? tooltipText : null}
				unit={unit as ConstrainedUnitEnum}
			/>
		);
	};

	const renderPlainField = () => {
		const highlightedRed = highlightVisibleToCustomer && isVisibleToCustomer;
		const type = valueType === ReportBlockFieldEnum.ValueType.TEXT ? 'text' : 'number';
		const highlightedBlue = isHighlighted;
		const className = bemElement('report-block', 'input-field', { 'highlighted-red': !!highlightedRed, 'highlighted-blue': !!highlightedBlue });
		const groupClassName = bemElement('report-block', 'input-field');

		return (
			<Field
				addonAfter={unit}
				className={className}
				component={Input}
				id={id}
				inputGroupClassName={groupClassName}
				label={name}
				name={fieldName}
				onValueChange={onChange}
				placeholder={name}
				tooltipMessage={hasTooltip ? tooltipText : null}
				type={type}
			/>
		);
	};

	const renderDropdownField = () => {
		const highlightedRed = highlightVisibleToCustomer && isVisibleToCustomer;
		const highlightedBlue = isHighlighted;
		const className = bemElement('report-block', 'textarea-field', { 'highlighted-red': !!highlightedRed, 'highlighted-blue': !!highlightedBlue });

		return (
			<DropdownField
				allowCustomValue={!!allowCustomDropdownListValue}
				className={className}
				fieldName={fieldName}
				id={id}
				label={name}
				name={name}
				options={options ?? []}
				tooltipMessage={hasTooltip ? tooltipText ?? undefined : undefined}
			/>
		);
	};

	const renderTimeField = () => {
		const className = bemElement('report-block', 'time-picker-field', {
			'highlighted-red': !!highlightVisibleToCustomer && isVisibleToCustomer,
		});

		return (
			<Field
				className={className}
				component={TimePicker}
				fieldName={fieldName}
				label={name}
				name={name}
				options={options}
				tooltipMessage={hasTooltip ? tooltipText : null}
			/>
		);
	};

	const renderCalculatedField = () => {
		const highlightedBlue = isHighlighted;

		const className = bemElement('report-block', 'calculated-field', {
			'highlighted-blue': !!highlightedBlue,
		});

		if (unit && CompoundUnitEnum[unit]) {
			return (
				<Field
					component={CompoundText}
					defaultValue="None"
					id={id}
					label={name}
					name={fieldName}
					tooltipMessage={hasTooltip && tooltipText}
					unit={unit}
					valueClassName={className}
				/>
			);
		}

		return (
			<div className={className}>
				<Field
					component={Text}
					id={id}
					label={name}
					name={fieldName}
					tooltipMessage={hasTooltip ? tooltipText : null}
					valueSuffix={unit}
				/>
			</div>
		);
	};

	const renderSignatureField = () => {
		return (
			<SignatureField
				fullName={fullName}
				name={name}
				openSignatureModal={openSignatureModal}
				signatureValue={signatureValue}
			/>
		);
	};

	const renderImmutableTextField = () => {
		const isBlackText = descriptiveTextColor === ExtendedColorPalette.BLACK;
		const className = bemElement('report-block', 'descriptive-text', { 'black-text': isBlackText, 'bold-text': isDescriptiveTextBold });

		return (
			<div className={className}>
				{defaultValue}
			</div>
		);
	};

	const renderInputField = () => {
		if (fieldType === ReportBlockFieldEnum.Type.INFORMATION) {
			return renderTextAreaField();
		}
		if (fieldType === ReportBlockFieldEnum.Type.DROPDOWN) {
			return renderDropdownField();
		}
		if (unit && CompoundUnitEnum[unit]) {
			return renderCompoundField();
		}
		if (unit && ConstrainedUnitEnum[unit]) {
			return renderConstrainedField();
		}
		return renderPlainField();
	};

	const renderField = () => {
		switch (fieldType) {
			case ReportBlockFieldEnum.Type.IMAGE:
			case ReportBlockFieldEnum.Type.IMMUTABLE_IMAGE:
				return renderImageField();
			case ReportBlockFieldEnum.Type.BOOLEAN:
				return renderBooleanField();
			case ReportBlockFieldEnum.Type.ADDRESS:
				return renderAddressField();
			case ReportBlockFieldEnum.Type.TIME:
				return renderTimeField();
			case ReportBlockFieldEnum.Type.CALCULATED:
				return renderCalculatedField();
			case ReportBlockFieldEnum.Type.SIGNATURE:
				return renderSignatureField();
			case ReportBlockFieldEnum.Type.IMMUTABLE_TEXT:
				return renderImmutableTextField();
			case ReportBlockFieldEnum.Type.LINE_BREAK:
				return <br />;
			case ReportBlockFieldEnum.Type.COMPLETION:
				return null;
			default:
				return renderInputField();
		}
	};

	const className = bemElement('report-block', 'array-elements__element', [dimension.toLowerCase(), icon ? 'with-icon' : '']);

	return (
		<>
			<div className={className}>
				{icon && <span className={`report-block_with_icon icon-${icon}`} />}
				<div>{renderField()}</div>
			</div>
			<SignatureModal
				closeModal={closeSignatureModal}
				onSubmit={onSetSignatureValue}
				showModal={showSignatureModal}
			/>
		</>

	);
};

function mapStateToProps(state: RootState) {
	const { userData } = state.user;

	return {
		fullName: getUserName(userData),
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector
);

export default enhance(ReportField);
