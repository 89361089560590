import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { InjectedFormProps} from 'redux-form';
import { Field } from 'redux-form';
import { Row, Col, Form } from 'react-bootstrap';

import type UpsertTemporaryEmployeeRM from 'ab-requestModels/temporaryEmployee.requestModel';

import type AgencyVM from 'ab-viewModels/agency/agency.viewModel';

import { phoneNormalizer } from 'ab-utils/reduxForms.util';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';

import LabelWithColor from 'af-components/LabelWithColor';

import CLIENT from 'af-constants/routes/client';

import SubmitButton from 'af-components/SubmitButton';

interface OwnProps {
	agencies: Nullable<AgencyVM[]>;
	companyName: string;
	onSubmit: (form: UpsertTemporaryEmployeeRM) => Promise<void>;
}

type FormOwnProps = OwnProps & InjectedFormProps<UpsertTemporaryEmployeeRM>;

interface PathParams {
	temporaryEmployeeId: string;
}

type Props = FormOwnProps & (CustomRouteComponentProps | CustomRouteComponentProps<PathParams>);

const FILTER_AGENCY_BY = ['name', 'email', 'phone', 'website'];

const renderAgency = (option: AgencyVM) => <LabelWithColor color={option.color} text={<strong>{option.name}</strong>} />;

const TemporaryEmployeeForm: React.FC<Props> = (props: Props) => {
	const {
		agencies,
		companyName,
		onSubmit,
		invalid,
		submitting,
		handleSubmit,
		location: { state: { orgAlias } },
	} = props;

	return (
		<Form>
			<div className="form-box-highlighted-input">
				<Row className="row--padded-top">
					<Col sm={8}>
						<Field
							component={Input}
							label="First Name *"
							name="firstName"
							placeholder="Enter First Name *"
							type="text"
						/>
					</Col>
					<Col sm={8}>
						<Field
							component={Input}
							label="Last Name *"
							name="lastName"
							placeholder="Enter Last Name *"
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={8}>
						<Field
							component={Input}
							label="Email"
							name="email"
							placeholder="Email"
							type="text"
						/>
					</Col>
					<Col sm={8}>
						<Field
							component={Input}
							format={phoneNormalizer}
							label="Phone Number"
							name="phoneNumber"
							normalize={phoneNormalizer}
							placeholder="Phone Number"
							type="text"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={8}>
						<Field
							component={Dropdown}
							filterable={true}
							filterBy={FILTER_AGENCY_BY}
							label="Agency"
							name="agencyId"
							options={agencies}
							placeholder="Select Agency"
							renderMenuItem={renderAgency}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
			</div>
			<div className="form-box__after">
				<Link
					className="btn btn-info"
					to={CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.LIST(orgAlias, companyName)}
				>
					Cancel
				</Link>
				<SubmitButton
					disabled={invalid}
					onClick={handleSubmit(onSubmit)}
					reduxFormSubmitting={submitting}
					variant="primary"
					variantDisabled="info"
				/>
			</div>
		</Form>
	);
};

export default TemporaryEmployeeForm;
