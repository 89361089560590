import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import API from 'af-constants/routes/api';

import { LOADING_LOCATIONS } from 'af-constants/reduxForms';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import type LocationRequestModel from 'ab-requestModels/location.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { LocationTableRowVM } from 'ab-viewModels/location/tableRow.viewModel';
import type { LocationViewModel } from 'ab-viewModels/location/location.viewModel';
import type LocationOptionVM from 'ab-viewModels/location/option.viewModel';
import type LocationDepartmentVM from 'ab-viewModels/location/locationDepartment.viewModel';
import type ToolOptionLocationVM from 'ab-viewModels/location/locationToolOption';

import type { GetRootState } from 'af-reducers';

export function create(form: LocationRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }): Promise<boolean | undefined> => {

		const action = async () => {
			await http.post(API.V1.RESOURCES.LOCATION.CREATE(), { ...form });
			return true;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(form: LocationRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			if (!form.id) {
				throw new Error('Location ID not provided');
			}

			await http.put(API.V1.RESOURCES.LOCATION.EDIT(form.id.toString()), { ...form });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<LocationViewModel>(API.V1.RESOURCES.LOCATION.FIND_BY_ID(id));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteLocationFromTable(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.LOCATION.DELETE(id.toString()));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkDeleteLocationFromTable(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.RESOURCES.LOCATION.BULK_DELETE, { locationIds: ids });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<LocationOptionVM[]>(API.V1.RESOURCES.LOCATION.LIST, { submitting: LOADING_LOCATIONS });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findForToolOrderLocationOptions() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ToolOptionLocationVM[]>(API.V1.RESOURCES.LOCATION.TOOL_LOCATION_OPTIONS);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findDepartmentList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<LocationDepartmentVM[]>(API.V1.RESOURCES.LOCATION.DEPARTMENT_LIST, { submitting: LOADING_LOCATIONS });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function saveSortedIndexes(form: LocationOptionVM[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.RESOURCES.LOCATION.EDIT_LIST, form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<LocationTableRowVM>>(API.V1.RESOURCES.LOCATION.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
