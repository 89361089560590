import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import * as ReportBlockFieldEnum from 'acceligent-shared/enums/reportBlockField';
import { CompoundUnitEnum } from 'acceligent-shared/enums/quantityUnit';
import { ExtendedColorPalette } from 'acceligent-shared/enums/color';
import { ReportBlockFieldIconName } from 'acceligent-shared/enums/reportBlockFieldIcon';

import { getUserName } from 'acceligent-shared/utils/user';
import type { BlockValueType, RepeatableBlockValueType, SignatureFieldVM, ValueType } from 'acceligent-shared/utils/fieldReport';

import type * as CustomerSignatureRequestModel from 'ab-requestModels/signature.requestModel';

import type { RootState } from 'af-reducers';

import * as FieldReportActions from 'af-actions/fieldReport';

import { debounce } from 'af-utils/actions.util';

import { bemElement } from 'ab-utils/bem.util';

import { FIELD_REPORT } from 'af-constants/reduxForms';
import { INPUT_FIELD_MAX_CHARACTERS } from 'af-constants/values';

import AddressField from './Address';
import BooleanField from './Boolean';
import DropdownField from './Dropdown';
import CalculatedField from './Calculated';
import CompoundField from './Compound';
import ImageField from './Image';
import InputField from './Input';
import SignatureField from './Signature';
import TextAreaField from './TextArea';
import TimeInputField from './Time';
import type { RequestQueue } from '../../../helpers';

interface SharedProps {
	fieldReportId: number;
	fieldReportTypeId: number;
	fieldId: string;
	blockId: string;
	disabled: boolean;
	fieldName: string;
	focusedBlockId: Nullable<number>;
	setFieldToFocus: (ref: HTMLDivElement) => void;
	removeFocusedField: () => void;
}

interface PreviewProps extends SharedProps {
	isPreview: true;
	onFocus?: () => void;
	change?: (fieldName: string, value: BlockValueType) => void;
}

interface EditableProps extends SharedProps {
	isPreview: false;
	onFocus?: () => void;
	change: (fieldName: string, value: BlockValueType) => void;
}

type OwnProps = PreviewProps | EditableProps;

type QueueProps = {
	requestQueue?: RequestQueue;
};

type Props = OwnProps & QueueProps & ConnectedProps<typeof connector>;

interface State {
	showSignatureModal: boolean;
	signatureValue: Nullable<{ signedAt: string; signature: string; fullName: string; }>;
	isBlurableChanged: boolean;
}

class Field extends React.PureComponent<Props> {

	state: State = {
		showSignatureModal: false,
		signatureValue: null,
		isBlurableChanged: false,
	};

	saveValue = (newValue: BlockValueType) => {
		const {
			fieldName,
			fieldId,
			fieldReportBlockField,
			updateFieldValue,
			fieldReportTypeId,
			fieldReportId,
			blockId,
			requestQueue,
		} = this.props;
		const { value } = fieldReportBlockField;
		const isArray = Array.isArray(value);

		if (requestQueue) {
			const data = {
				fieldId,
				value: newValue,
				index: isArray ? +fieldName.split('field-')[1].split(/\[(.*?)\]/g)[1] : null,
				blockId,
			};

			requestQueue.add(async () => {
				await updateFieldValue(fieldReportId, fieldReportTypeId, data);
				this.syncRequiredFieldFilledValue(fieldId, newValue);
			});
		}
	};

	syncRequiredFieldFilledValue = (fieldId: string, newValue: ValueType) => {
		const { fieldReport, syncFieldReportTypes } = this.props;

		const field = fieldReport?.fieldReportBlockFieldMap[fieldId];
		const block = fieldReport?.fieldReportBlockMap[field?.blockId ?? -1];
		const fieldReportType = fieldReport?.typeMap[block?.fieldReportTypeId ?? -1];
		if (!field || !block || !fieldReportType) {
			return;
		}
		const fieldDefinition = fieldReport?.fieldMap[field?.reportBlockFieldId];

		if (!fieldDefinition?.isRequired) {
			return;
		}

		const hasValue = !(
			newValue === null
			|| newValue === ''
			|| (Array.isArray(newValue) && (newValue as RepeatableBlockValueType).length === 0)
			|| (Array.isArray(newValue) && (newValue as RepeatableBlockValueType).length === 1 && newValue[1] === null)
			|| (Array.isArray(newValue) && (newValue as RepeatableBlockValueType).length === 1 && newValue[1] === '')
			|| (fieldDefinition.fieldType === ReportBlockFieldEnum.Type.SIGNATURE && (newValue as SignatureFieldVM).id === -1)
		);

		const currentCount = fieldReport.typeMap[fieldReportType.id].numberOfFilledRequiredFields;
		const maxCount = fieldReport.typeMap[fieldReportType.id].numberOfRequiredFields;
		const newCountValue = hasValue ? Math.min(currentCount + 1, maxCount) : Math.max(0, (currentCount - 1));
		const newFieldReportMap = {
			...fieldReport.typeMap,
			[fieldReportType.id]: {
				...fieldReport.typeMap[fieldReportType.id],
				numberOfFilledRequiredFields: Math.max(0, newCountValue),
			},
		};
		// this need rethinking. There is possibility that one change will trigger this sync twice.
		// at the moment when this was implemented, only one field was required, so I didn't go into too much
		syncFieldReportTypes({
			fieldReport: {
				...fieldReport,
				typeMap: newFieldReportMap,
			},
		});
	};

	changeAndSave = (newValue: BlockValueType) => {
		const {
			fieldName,
			change,
		} = this.props;
		change?.(fieldName, newValue);
		this.saveValue(newValue);
	};

	autosave = () => {
		const { formValue } = this.props;
		this.saveValue(formValue ?? '');
	};

	autosaveBlurable = () => {
		if (this.state.isBlurableChanged) {
			const { formValue } = this.props;
			this.saveValue(formValue ?? '');
			this.clearBlurableChanged();
		}
	};

	// eslint-disable-next-line @typescript-eslint/member-ordering
	autosaveDebounced = debounce(this.autosave, 300);

	focusOnChange = () => {
		this.onFieldFocus();
		this.autosaveDebounced();
	};

	openSignatureModal = () => {
		this.setState(() => ({ showSignatureModal: true }));
	};

	closeSignatureModal = () => {
		this.setState(() => ({ showSignatureModal: false }));
	};

	setSignatureValue = async (signature: CustomerSignatureRequestModel.SignatureForm) => {
		this.setState(() => ({ signatureValue: { signedAt: signature.signedAt, signature: signature.signatureImage, fullName: signature.fullName } }));
	};

	setBlurableChanged = () => {
		this.setState(() => ({ isBlurableChanged: true }));
	};

	clearBlurableChanged = () => {
		this.setState(() => ({ isBlurableChanged: false }));
	};

	onDeleteSignature = async (signature: SignatureFieldVM) => {
		const { deleteSignature, fieldReportId } = this.props;
		const form = {
			signatureId: signature.id,
		};
		await deleteSignature(form, fieldReportId);
		const newValue = {
			id: -1,
			signedAt: '',
			imageUrl: '',
			name: '',
		};
		this.changeAndSave(newValue);
	};

	onFieldFocus = () => {
		const { onFocus, focusedBlockId, removeFocusedField } = this.props;
		onFocus?.();

		if (focusedBlockId) {
			removeFocusedField();
		}
	};

	renderImageField = () => {
		const {
			disabled,
			isPreview,
			reportBlockField: { name, tooltipText, hasTooltip, fieldType },
			fieldName,
			fieldId,
			fieldReportId,
			fieldReportTypeId,
			blockId,
		} = this.props;

		return (
			<ImageField
				blockId={+blockId}
				disabled={disabled || isPreview}
				fieldId={+fieldId}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				formName={fieldName}
				isEditable={!disabled && !isPreview && fieldType !== ReportBlockFieldEnum.Type.IMMUTABLE_IMAGE}
				name={name}
				onFocus={this.onFieldFocus}
				onSave={this.changeAndSave}
				tooltipMessage={hasTooltip ? tooltipText : null}
			/>
		);
	};

	renderBooleanField = () => {
		const { disabled, isPreview, reportBlockField: { name, tooltipText, hasTooltip }, fieldName } = this.props;

		return (
			<BooleanField
				disabled={disabled}
				formName={fieldName}
				isPreview={isPreview}
				name={name}
				onValueChange={this.focusOnChange}
				tooltipMessage={hasTooltip ? tooltipText : null}
			/>
		);
	};

	renderAddressField = () => {
		const { disabled, isPreview, reportBlockField: { name, tooltipText, hasTooltip }, fieldName, change } = this.props;

		return (
			<AddressField
				change={change}
				disabled={disabled}
				formName={fieldName}
				isPreview={isPreview}
				name={name}
				onFocus={this.onFieldFocus}
				onValueChange={this.autosaveDebounced}
				tooltipMessage={hasTooltip ? tooltipText : null}
			/>
		);
	};

	renderSignatureField = () => {
		const {
			disabled,
			isPreview,
			reportBlockField: { name },
			formValue,
			fieldReportId,
			fieldName,
		} = this.props;

		return (
			<SignatureField
				deleteSignature={this.onDeleteSignature}
				disabled={disabled}
				fieldReportId={fieldReportId}
				formName={fieldName}
				isPreview={isPreview}
				name={name}
				onFocus={this.onFieldFocus}
				onValueChange={this.changeAndSave}
				value={formValue as SignatureFieldVM}
			/>
		);
	};

	renderTextAreaField = () => {
		const { disabled, isPreview, reportBlockField: { name, tooltipText, hasTooltip, textLimit }, fieldName } = this.props;

		return (
			<TextAreaField
				disabled={disabled}
				formName={fieldName}
				isPreview={isPreview}
				maxCharacters={textLimit ?? INPUT_FIELD_MAX_CHARACTERS}
				name={name}
				onBlur={this.autosaveBlurable}
				onChange={this.setBlurableChanged}
				onFocus={this.onFieldFocus}
				tooltipMessage={hasTooltip ? tooltipText : null}
			/>
		);
	};

	renderCompoundField = () => {
		const { disabled, isPreview, reportBlockField: { unit, name, tooltipText, hasTooltip }, fieldName } = this.props;

		return (
			<CompoundField
				disabled={disabled}
				formName={fieldName}
				isPreview={isPreview}
				name={name}
				onFocus={this.onFieldFocus}
				onValueChange={this.autosaveDebounced}
				tooltipMessage={hasTooltip ? tooltipText : null}
				unit={unit as CompoundUnitEnum}
			/>
		);
	};

	renderTimeInputField = () => {
		const { disabled, isPreview, reportBlockField: { name, tooltipText, hasTooltip }, fieldName } = this.props;

		return (
			<TimeInputField
				disabled={disabled}
				formName={fieldName}
				isPreview={isPreview}
				name={name}
				onFocus={this.onFieldFocus}
				onValueChange={this.autosaveDebounced}
				tooltipMessage={hasTooltip ? tooltipText : null}
			/>
		);
	};

	renderPlainField = () => {
		const { disabled, isPreview, reportBlockField, fieldName } = this.props;
		const { name, unit, valueType, tooltipText, hasTooltip, fieldType } = reportBlockField;

		return (
			<InputField
				disabled={disabled}
				formName={fieldName}
				hasTooltip={hasTooltip}
				isPreview={isPreview || fieldType === 'IMMUTABLE_TEXT'}
				name={name}
				onBlur={this.autosaveBlurable}
				onChange={this.setBlurableChanged}
				onFocus={this.onFieldFocus}
				tooltipMessage={tooltipText}
				unit={unit}
				valueType={valueType}
			/>
		);
	};

	renderDropdownField = () => {
		const { disabled, reportBlockField, fieldName, formValue, change, isPreview } = this.props;
		const { name, options, allowCustomDropdownListValue, hasTooltip, tooltipText } = reportBlockField;

		return (
			<DropdownField
				allowCustomValue={allowCustomDropdownListValue}
				change={change}
				disabled={disabled}
				formName={fieldName}
				initialValue={formValue as string}
				isPreview={isPreview}
				name={name}
				onFocus={this.onFieldFocus}
				onValueChange={this.autosaveDebounced}
				options={options ?? []}
				tooltipMessage={hasTooltip ? tooltipText : null}
			/>
		);
	};

	renderCalculatedField = () => {
		const { reportBlockField: { name, tooltipText, hasTooltip, unit }, fieldName } = this.props;

		return (
			<CalculatedField
				formName={fieldName}
				name={name}
				tooltipMessage={hasTooltip ? tooltipText : null}
				unit={unit}
			/>
		);
	};

	renderImmutableTextField = () => {
		const {
			reportBlockField: {
				defaultValue,
				isDescriptiveTextBold,
				descriptiveTextColor,
			},
		} = this.props;

		const blackText = descriptiveTextColor === ExtendedColorPalette.BLACK;

		const className = bemElement('report-block', 'descriptive-text', { 'black-text': blackText, 'bold-text': isDescriptiveTextBold });

		return (
			<div className={className}>
				{defaultValue}
			</div>
		);
	};

	renderField = () => {
		const { reportBlockField: { fieldType, unit } } = this.props;

		switch (fieldType) {
			case ReportBlockFieldEnum.Type.TIME:
				return this.renderTimeInputField();
			case ReportBlockFieldEnum.Type.IMAGE:
			case ReportBlockFieldEnum.Type.IMMUTABLE_IMAGE:
				return this.renderImageField();
			case ReportBlockFieldEnum.Type.BOOLEAN:
				return this.renderBooleanField();
			case ReportBlockFieldEnum.Type.ADDRESS:
				return this.renderAddressField();
			case ReportBlockFieldEnum.Type.CALCULATED:
				return this.renderCalculatedField();
			case ReportBlockFieldEnum.Type.SIGNATURE:
				return this.renderSignatureField();
			case ReportBlockFieldEnum.Type.IMMUTABLE_TEXT:
				return this.renderImmutableTextField();
			case ReportBlockFieldEnum.Type.INFORMATION:
				return this.renderTextAreaField();
			case ReportBlockFieldEnum.Type.DROPDOWN:
				return this.renderDropdownField();
			case ReportBlockFieldEnum.Type.LINE_BREAK:
				return <br />;
		}

		if (unit && CompoundUnitEnum[unit]) {
			return this.renderCompoundField();
		}

		return this.renderPlainField();
	};

	render() {
		const { fieldReportBlockField, reportBlockField, focusedBlockId, setFieldToFocus } = this.props;
		if (!fieldReportBlockField) {
			return null;
		}

		const { dimension, iconName } = reportBlockField;
		const isFocused = focusedBlockId === +fieldReportBlockField.id;

		const className = bemElement('field-report-block', 'field', {
			[dimension.toLowerCase()]: true,
			'with-icon': !!iconName,
			focused: isFocused,
		});

		return (
			<div className={className} ref={isFocused ? setFieldToFocus : undefined}>
				{iconName && <span className={`field-report-block__field__icon icon-${ReportBlockFieldIconName[iconName]}`} />}
				{this.renderField()}
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { fieldReport: { fieldReport }, user: { userData } } = state;
	if (!userData) {
		throw new Error('User not logged in');
	}

	const { fieldId, fieldName } = ownProps;
	const fieldReportBlockField = fieldReport?.fieldReportBlockFieldMap?.[fieldId];
	if (!fieldReportBlockField) {
		throw new Error('fieldReportBlockField not found');
	}

	return {
		fieldReport: state.fieldReport.fieldReport,
		fieldReportBlockField,
		reportBlockField: fieldReport?.fieldMap?.[fieldReportBlockField?.reportBlockFieldId],
		formValue: formValueSelector(FIELD_REPORT)(state, fieldName),
		fullName: getUserName(userData),
	};
}

function mapDispatchToProps() {
	return {
		updateFieldValue: FieldReportActions.editFieldReportValue,
		deleteSignature: FieldReportActions.deleteBlockFieldSignature,
		syncFieldReportTypes: FieldReportActions.syncFieldReportTypes,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Field);
