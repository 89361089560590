import type { AnyAction, Dispatch } from 'redux';

import API from 'af-constants/routes/api';

import type { GetRootState } from 'af-reducers';

import { errorHandler } from 'af-utils/actions.util';
import { http } from 'af-utils/http.util';

import type WorkSummaryDetailsAssignBillingCodeRM from 'ab-requestModels/workSummaryDetails/assignBillingCode.requestModel';
import type WorkSummaryDetailsBillingStatusesRM from 'ab-requestModels/workSummaryDetails/editBillable.requestModel';
import type WorkSummaryDetailsAssignGroupNamesRM from 'ab-requestModels/workSummaryDetails/assignGroupName.requestModel';
import type BulkWorkSummaryToNonBillableRM from 'ab-requestModels/workSummaryDetails/bulkWorkSummaryToNonBillable.requestModel';
import type { AllocatedWorkSummaryDetailsVM } from 'ab-viewModels/workSummaryDetails/allocatedWorkSummaryDetails.viewModels';
import type WorkSummaryDetailsReallocateToSubJobRM from 'ab-requestModels/workSummaryDetails/reallocateToSubjob.requestModel';
import type WorkSummaryDetailsReallocateAllToSubJobRM from 'ab-requestModels/workSummaryDetails/reallocateAllToSubjob.requestModel';

export function updateBillingStatuses(workSummaryDetailsRM: WorkSummaryDetailsBillingStatusesRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(API.V1.WORK_SUMMARY_DETAILS.UPDATE_BILLING_STATUSES, workSummaryDetailsRM);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkUpdateBillingStatuses(data: BulkWorkSummaryToNonBillableRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(API.V1.WORK_SUMMARY_DETAILS.BULK_NON_BILLABLE, data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateGroupNames(workSummaryDetailsAssignGroupNameRM: WorkSummaryDetailsAssignGroupNamesRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(API.V1.WORK_SUMMARY_DETAILS.ASSIGN_GROUP_NAMES, workSummaryDetailsAssignGroupNameRM);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function assignBillingCode(workSummaryWithBillingCode: WorkSummaryDetailsAssignBillingCodeRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(API.V1.WORK_SUMMARY_DETAILS.ASSIGN_BILLING_CODE, workSummaryWithBillingCode);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllocatedWorkSummaryDetails(jobId: number, workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<AllocatedWorkSummaryDetailsVM[]>(API.V1.WORK_SUMMARY_DETAILS.FIND_ALLOCATED_WORK_SUMMARY_DETAILS(jobId, workOrderId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reallocateToSubJob(data: WorkSummaryDetailsReallocateToSubJobRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(API.V1.WORK_SUMMARY_DETAILS.REALLOCATE_TO_SUB_JOB, data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reallocateAllBillableToSubJob(data: WorkSummaryDetailsReallocateAllToSubJobRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(API.V1.WORK_SUMMARY_DETAILS.REALLOCATE_ALL_BILLABLE_TO_SUB_JOB, data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reallocateAllNonBillableToSubJob(data: WorkSummaryDetailsReallocateAllToSubJobRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(API.V1.WORK_SUMMARY_DETAILS.REALLOCATE_ALL_NON_BILLABLE_TO_SUB_JOB, data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
