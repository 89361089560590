import type AccountPermissionTemplate from 'acceligent-shared/enums/accountPermissionTemplate';
import type { ColorPalette } from 'acceligent-shared/enums/color';
import type { EmailTypes, PhoneTypes } from 'acceligent-shared/enums/contactMethodType';
import type CountryCode from 'acceligent-shared/enums/countryCode';
import type DeliverableDataType from 'acceligent-shared/enums/deliverableDataType';
import type { DirectoryIcon } from 'acceligent-shared/enums/directoryIcon';
import type FileType from 'acceligent-shared/enums/fileType';
import type Priority from 'acceligent-shared/enums/priority';
import type QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import type ResourceStatus from 'acceligent-shared/enums/resourceStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import type WorkRequestDisposalMethod from 'acceligent-shared/enums/workRequestDisposalMethod';
import type WorkRequestJobHazardAssessmentStatus from 'acceligent-shared/enums/workRequestJobHazardAssessmentStatus';
import type WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';

import { isNullOrUndefined } from 'acceligent-shared/utils/extensions';
import * as TimeUtils from 'acceligent-shared/utils/time';
import { isValidTextInput } from 'acceligent-shared/utils/text';
import { EMAIL_REGEX } from 'acceligent-shared/utils/email';

import { toRawPhoneNumber } from 'ab-utils/phone.util';

import { MAX_BILLING_CODE_UNIT_PRICE_VALUE, MAX_WR_TRUCK_EQUIPMENT_ADDITIONAL_FIELDS } from 'ab-common/constants/value';

import type * as JobUpsertRM from 'ab-requestModels/workRequest/jobUpsert.requestModel';

import type JobUpsertVM from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import { getId } from 'ab-utils/array.util';

class AccountFM {
	id: number;
	firstName: string;
	lastName: string;
}

class DeliverableDataFM {
	id: number;
	name: string;
	type: DeliverableDataType;
}

class EmployeeFM {
	id: number;
	firstName: string;
	lastName: string;
	fullName: string;
}

class ContactMethodFM {
	id: number;
	contactId: number;
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode: Nullable<CountryCode>;
	extension: Nullable<string>;
}

class ContactAddressFM {
	id: number;
	contactId: number;
	addressId: number;
	address: AddressFM;
}

class ContactFM {
	id: number;
	workOrderContactId: number;
	role: Nullable<string>;
	fullName: string;
	companyName: Nullable<string>;

	addresses: ContactAddressFM[];
	emails: ContactMethodFM[];
	phones: ContactMethodFM[];

	contactAddressIds: number[];
	contactEmailIds: number[];
	contactPhoneIds: number[];
}

class OfficeFM {
	id: number;
	nickname: string;
}

class DivisionFM {
	id: number;
	name: string;
}

class BillingCodeFM {
	id: number;
	lineItemNumber: number;
	customerNumber: Nullable<number>;
	customerId: string;
	ownerNumber: Nullable<string>;
	ownerId: Nullable<string>;
	unit: QuantityUnitType;
	unitPrice: string;
	bidQuantity: Nullable<string>;
	group: Nullable<string>;
	description: string;
}

class AddressFM {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
}

interface UserInfoFM {
	accountId?: Nullable<number>;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

class WorkRequestDirectoryFM {
	id: number;
	name: string;
	parentId: Nullable<number>;
	icon: Nullable<DirectoryIcon>;
	lastModifiedAt: Nullable<Date>;
	lastModifiedBy: Nullable<UserInfoFM>;
	attachmentIds: number[];
}

interface AttachmentFM {
	id: number;
	size: number;
	name: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	copyToWorkOrderFlag: boolean;
	lastModifiedAt: Date;
	uploadedBy: Nullable<UserInfoFM>;
}

interface SalesFM {
	id: number;
	fullName: string;
}

interface WorkRequestStatusFM {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;
}

interface EmployeeOptionFM {
	id: number;
	formattedCode: string;
	firstName: string;
	lastName: string;
	uniqueId: string;
	accountTemplate: AccountPermissionTemplate;
	fullName: string;
}

export class SubjobFM {
	id: number;
	jobCode: string;
	isInternal: boolean;
	title: Nullable<string>;
	customerCompany: Nullable<string>;
	customerFormatted: Nullable<string>;
	travelLocationShort: Nullable<string>;

	static fromJobUpsertFM = (job: JobUpsertFM): SubjobFM => {
		const customerCompany = job.customerCompanyName ?? job.customerContact?.companyName ?? null;
		return {
			id: job.id,
			jobCode: job.jobCode,
			isInternal: job.isInternal,
			customerCompany,
			customerFormatted: job.customerContact?.fullName ? `${job.customerContact.fullName}${customerCompany ? `, ${customerCompany}` : ''}` : null,
			title: job.title,
			travelLocationShort: job.travelLocationShort,
		};
	};
}

export type AdditionalTrucksEquipmentField = {
	name: string;
	isChecked: boolean;
};

class JobUpsertFM {
	id: number; // Necessary when creating job through WO page
	jobCode: string;
	title: Nullable<string>;
	salesId: Nullable<string>;
	salesLead: Nullable<SalesFM>;
	salesLeadId: Nullable<number>;
	customerCompanyName: Nullable<string>;
	workLocation: Nullable<AddressFM>;
	projectManager: Nullable<EmployeeFM>;
	projectManagerId: Nullable<number>;
	officeId: Nullable<number>;
	office: Nullable<OfficeFM>;
	divisionId: Nullable<number>;
	division: Nullable<DivisionFM>;
	isInternal: boolean;
	jobNotes: Nullable<string>;
	estimateTotal: number;
	status: WorkRequestStatus;
	priority: Priority;

	customerReferencePO: Nullable<string>;
	isEmergencyJob: boolean;
	jobStatusId: Nullable<number>;
	jobStatus: Nullable<WorkRequestStatusFM>;
	requestBondRequirementsNote: Nullable<string>;
	requestHSERequirementsNote: Nullable<string>;
	requestInsuranceRequirementsNote: Nullable<string>;
	requestJobNote: Nullable<string>;
	requestLiquidatedDamagesNote: Nullable<string>;
	requestMBEOrWBERequirementsNote: Nullable<string>;
	retainage: Nullable<string>;
	targetDaysFromStart: Nullable<number>;
	targetStartDate: Nullable<string>;

	projectOwner: Nullable<string>;
	ownerReference: Nullable<string>;
	taxExempt: boolean;
	generalContractor: Nullable<string>;
	contractorReference: Nullable<string>;
	isUnion: boolean;
	isPrevailingWage: boolean;
	jurisdiction: Nullable<string>;

	surveyNeeded: boolean;
	surveyedById: Nullable<number>;
	surveyedBy: Nullable<EmployeeOptionFM>;
	surveyDate: Nullable<string>;
	waterAvailable: boolean;
	waterSupplyInformation: Nullable<string>;
	subcontractorNeeded: Nullable<string>;
	subcontractorInformation: Nullable<string>;
	pipeSizes: Nullable<string>;
	pipeTypes: Nullable<string>;
	manholeDepths: Nullable<string>;
	manholeFrameDiameters: Nullable<string>;
	informationsProvidedByCustomersBy: Nullable<string>;

	jc80gpm: boolean;
	sjc80gpm: boolean;
	jcRecycler80: boolean;
	jjc120gpm: boolean;
	jjc160gpm: boolean;
	jcHiRail: boolean;
	jetter: boolean;
	harbenJetter: boolean;
	hjJetter: boolean;
	tvTruck: boolean;
	tvLateralTk: boolean;
	tvGroutTk: boolean;
	tvLatGroutTk: boolean;
	tvSonarTk: boolean;
	tvPolaris: boolean;
	tvLCRTk: boolean;
	tvLateralReinstator: boolean;
	tvMTHTk: boolean;
	tvLaserTk: boolean;
	minicam: boolean;
	vacTk: boolean;
	hivacTk: boolean;
	hiRailvacTk: boolean;
	tanker: boolean;
	wb: boolean;
	hwBoiler: boolean;
	pressureWash: boolean;
	airComp: boolean;
	clamTk: boolean;
	bucketMach: boolean;
	mhRehabTk: boolean;
	microtrax: boolean;
	smallEasementMachine: boolean;
	bigEasementMachine: boolean;
	arrowBoard: boolean;
	supportTk: boolean;
	rollOffTk: boolean;
	pump: boolean;
	pigLaunch: boolean;
	stoveMach: boolean;
	powerRodder: boolean;
	reeferTruck: boolean;
	lateralReinstatorTrailer: boolean;
	steamCureTruck: boolean;
	airTesting: boolean;
	trackMachine: boolean;
	additionalTruckEquipmentFields: AdditionalTrucksEquipmentField[];
	numberOfEmployees: number;

	customerContact: Nullable<ContactFM>;
	siteContact: Nullable<ContactFM>;
	billingContact: Nullable<ContactFM>;
	billTo: Nullable<string>;
	billToAddress: Nullable<string>;
	billToCityStateZip: Nullable<string>;
	billToPhoneNumber: Nullable<string>;
	billToFaxNumber: Nullable<string>;
	billToContactName: Nullable<string>;
	billToEmail: Nullable<string>;
	additionalContacts: Nullable<ContactFM[]>;
	travelLocationShort: Nullable<string>;

	isDeliverable: boolean;
	deliverableAssigneeId: Nullable<number>;
	deliverableAssignee: Nullable<AccountFM>;
	deliverableSoftwareId: Nullable<number>;
	deliverableSoftware: Nullable<DeliverableDataFM>;
	deliverableFileFormatId: Nullable<number>;
	deliverableFileFormat: Nullable<DeliverableDataFM>;
	deliverableCodeStandardId: Nullable<number>;
	deliverableCodeStandard: Nullable<DeliverableDataFM>;
	deliverableDeliveryMethodId: Nullable<number>;
	deliverableDeliveryMethod: Nullable<DeliverableDataFM>;
	deliverableDeliveryTimelineId: Nullable<number>;
	deliverableDeliveryTimeline: Nullable<DeliverableDataFM>;
	deliverableNotes: Nullable<string>;

	allowCustomerSignature: boolean;

	startDate: Nullable<string>;
	guaranteedCompletionDate: Nullable<string>;
	targetCompletionDate: Nullable<string>;
	targetedDaysFromStart: Nullable<number>;
	guaranteedDaysFromStart: Nullable<number>;

	// Disposal
	ableToCleanAndWashEquipmentAtCustomerLocation: boolean;
	cleanAndWashLocation: Nullable<string>;
	isWasteGeneratedSolid: boolean;
	isWasteGeneratedSludge: boolean;
	isWasteGeneratedLiquid: boolean;
	isWasteHazardous: boolean;
	isWasteManifest: boolean;
	wasteDescription: Nullable<string>;
	expectedWasteQuantity: Nullable<number>;
	disposalMethod: Nullable<WorkRequestDisposalMethod>;
	disposalInstructions: Nullable<string>;
	disposalContact: Nullable<ContactFM>;

	billingCodes: BillingCodeFM[];

	// Job Hazard Summary
	hazardSummary: Nullable<string>;
	permitsRequired: Nullable<string[]>;

	// Safety
	environmentalExposure: boolean;
	environmentalExposureDescription: Nullable<string>;
	respiratoryProtection: boolean;
	respiratoryProtectionDescription: Nullable<string>;
	overheadHazards: boolean;
	overheadHazardsDescription: Nullable<string>;
	heightWork: boolean;
	heightWorkDescription: Nullable<string>;
	mechanicalHazards: boolean;
	mechanicalHazardsDescription: Nullable<string>;
	electricalHazards: boolean;
	electricalHazardsDescription: Nullable<string>;
	hazardousMaterials: boolean;
	hazardousMaterialsDescription: Nullable<string>;
	dangerousAnimalsOrPlants: boolean;
	dangerousAnimalsOrPlantsDescription: Nullable<string>;

	// Environmental Protection
	waterOrWetlands: boolean;
	waterOrWetlandsDescription: Nullable<string>;
	stormDrain: boolean;
	stormDrainDescription: Nullable<string>;
	bypassPumping: boolean;
	bypassPumpingDescription: Nullable<string>;
	sensitiveEnvironment: boolean;
	sensitiveEnvironmentDescription: Nullable<string>;

	// Public Safety
	vehicleTraffic: boolean;
	vehicleTrafficDescription: Nullable<string>;
	pedestrianCrossing: boolean;
	pedestrianCrossingDescription: Nullable<string>;

	// Status
	jobHazardAssessmentStatus: Nullable<WorkRequestJobHazardAssessmentStatus>;

	directories: WorkRequestDirectoryFM[];
	workRequestAttachments: AttachmentFM[];
	uploadedAttachmentIds?: number[];

	uploadingAttachments?: Record<string, true>;

	isProject: boolean;
	subjobs: SubjobFM[];
	projectId: Nullable<number>;

	constructor(vm: JobUpsertVM) {
		this.id = vm.id;
		this.jobCode = vm.jobCode;
		this.title = vm.title;
		this.salesId = vm.salesId;
		this.salesLead = vm.salesLead;
		this.salesLeadId = vm.salesLeadId;
		this.customerCompanyName = vm.customerCompanyName;
		this.workLocation = vm.workLocation;
		this.projectManager = vm.projectManager;
		this.projectManagerId = vm.projectManagerId;
		this.officeId = vm.officeId;
		this.office = vm.office;
		this.divisionId = vm.divisionId;
		this.division = vm.division;
		this.isInternal = vm.isInternal;
		this.jobNotes = vm.jobNotes;
		this.estimateTotal = vm.estimateTotal;
		this.status = vm.status;
		this.priority = vm.priority;
		this.customerReferencePO = vm.customerReferencePO;
		this.isEmergencyJob = vm.isEmergencyJob;
		this.jobStatusId = vm.jobStatusId;
		this.jobStatus = vm.jobStatus;
		this.requestBondRequirementsNote = vm.requestBondRequirementsNote;
		this.requestHSERequirementsNote = vm.requestHSERequirementsNote;
		this.requestInsuranceRequirementsNote = vm.requestInsuranceRequirementsNote;
		this.requestJobNote = vm.requestJobNote;
		this.requestLiquidatedDamagesNote = vm.requestLiquidatedDamagesNote;
		this.requestMBEOrWBERequirementsNote = vm.requestMBEOrWBERequirementsNote;
		this.retainage = vm.retainage;
		this.targetDaysFromStart = vm.targetDaysFromStart;
		this.targetStartDate = vm.targetStartDate;
		this.travelLocationShort = vm.travelLocationShort;
		this.projectOwner = vm.projectOwner;
		this.ownerReference = vm.ownerReference;
		this.taxExempt = vm.taxExempt;
		this.generalContractor = vm.generalContractor;
		this.contractorReference = vm.contractorReference;
		this.isUnion = vm.isUnion;
		this.isPrevailingWage = vm.isPrevailingWage;
		this.jurisdiction = vm.jurisdiction;
		this.surveyNeeded = vm.surveyNeeded;
		this.surveyedById = vm.surveyedById;
		this.surveyedBy = vm.surveyedBy;
		this.surveyDate = vm.surveyDate;
		this.waterAvailable = vm.waterAvailable;
		this.waterSupplyInformation = vm.waterSupplyInformation;
		this.subcontractorNeeded = vm.subcontractorNeeded;
		this.subcontractorInformation = vm.subcontractorInformation;
		this.pipeSizes = vm.pipeSizes;
		this.pipeTypes = vm.pipeTypes;
		this.manholeDepths = vm.manholeDepths;
		this.manholeFrameDiameters = vm.manholeFrameDiameters;
		this.informationsProvidedByCustomersBy = vm.informationsProvidedByCustomersBy;
		this.jc80gpm = vm.jc80gpm;
		this.sjc80gpm = vm.sjc80gpm;
		this.jcRecycler80 = vm.jcRecycler80;
		this.jjc120gpm = vm.jjc120gpm;
		this.jjc160gpm = vm.jjc160gpm;
		this.jcHiRail = vm.jcHiRail;
		this.jetter = vm.jetter;
		this.harbenJetter = vm.harbenJetter;
		this.hjJetter = vm.hjJetter;
		this.tvTruck = vm.tvTruck;
		this.tvLateralTk = vm.tvLateralTk;
		this.tvGroutTk = vm.tvGroutTk;
		this.tvLatGroutTk = vm.tvLatGroutTk;
		this.tvSonarTk = vm.tvSonarTk;
		this.tvPolaris = vm.tvPolaris;
		this.tvLCRTk = vm.tvLCRTk;
		this.tvLateralReinstator = vm.tvLateralReinstator;
		this.tvMTHTk = vm.tvMTHTk;
		this.tvLaserTk = vm.tvLaserTk;
		this.minicam = vm.minicam;
		this.vacTk = vm.vacTk;
		this.hivacTk = vm.hivacTk;
		this.hiRailvacTk = vm.hiRailvacTk;
		this.tanker = vm.tanker;
		this.wb = vm.wb;
		this.hwBoiler = vm.hwBoiler;
		this.pressureWash = vm.pressureWash;
		this.airComp = vm.airComp;
		this.clamTk = vm.clamTk;
		this.bucketMach = vm.bucketMach;
		this.mhRehabTk = vm.mhRehabTk;
		this.microtrax = vm.microtrax;
		this.smallEasementMachine = vm.smallEasementMachine;
		this.bigEasementMachine = vm.bigEasementMachine;
		this.arrowBoard = vm.arrowBoard;
		this.supportTk = vm.supportTk;
		this.rollOffTk = vm.rollOffTk;
		this.pump = vm.pump;
		this.pigLaunch = vm.pigLaunch;
		this.stoveMach = vm.stoveMach;
		this.powerRodder = vm.powerRodder;
		this.reeferTruck = vm.reeferTruck;
		this.lateralReinstatorTrailer = vm.lateralReinstatorTrailer;
		this.steamCureTruck = vm.steamCureTruck;
		this.airTesting = vm.airTesting;
		this.trackMachine = vm.trackMachine;
		this.additionalTruckEquipmentFields = vm.additionalTruckEquipmentFields;
		this.numberOfEmployees = vm.numberOfEmployees;
		this.customerContact = vm.customerContact;
		this.siteContact = vm.siteContact;
		this.billingContact = vm.billingContact;
		this.billTo = vm.billTo;
		this.billToAddress = vm.billToAddress;
		this.billToCityStateZip = vm.billToCityStateZip;
		this.billToPhoneNumber = vm.billToPhoneNumber;
		this.billToFaxNumber = vm.billToFaxNumber;
		this.billToContactName = vm.billToContactName;
		this.billToEmail = vm.billToEmail;
		this.additionalContacts = vm.additionalContacts;
		this.isDeliverable = vm.isDeliverable;
		this.deliverableAssigneeId = vm.deliverableAssigneeId;
		this.deliverableAssignee = vm.deliverableAssignee;
		this.deliverableSoftwareId = vm.deliverableSoftwareId;
		this.deliverableSoftware = vm.deliverableSoftware;
		this.deliverableFileFormatId = vm.deliverableFileFormatId;
		this.deliverableFileFormat = vm.deliverableFileFormat;
		this.deliverableCodeStandardId = vm.deliverableCodeStandardId;
		this.deliverableCodeStandard = vm.deliverableCodeStandard;
		this.deliverableDeliveryMethodId = vm.deliverableDeliveryMethodId;
		this.deliverableDeliveryMethod = vm.deliverableDeliveryMethod;
		this.deliverableDeliveryTimelineId = vm.deliverableDeliveryTimelineId;
		this.deliverableDeliveryTimeline = vm.deliverableDeliveryTimeline;
		this.deliverableNotes = vm.deliverableNotes;
		this.allowCustomerSignature = vm.allowCustomerSignature;
		this.startDate = vm.startDate;
		this.guaranteedCompletionDate = vm.guaranteedCompletionDate;
		this.targetCompletionDate = vm.targetCompletionDate;
		this.targetedDaysFromStart = vm.targetedDaysFromStart;
		this.guaranteedDaysFromStart = vm.guaranteedDaysFromStart;
		this.ableToCleanAndWashEquipmentAtCustomerLocation = vm.ableToCleanAndWashEquipmentAtCustomerLocation;
		this.cleanAndWashLocation = vm.cleanAndWashLocation;
		this.isWasteGeneratedSolid = vm.isWasteGeneratedSolid;
		this.isWasteGeneratedSludge = vm.isWasteGeneratedSludge;
		this.isWasteGeneratedLiquid = vm.isWasteGeneratedLiquid;
		this.isWasteHazardous = vm.isWasteHazardous;
		this.isWasteManifest = vm.isWasteManifest;
		this.wasteDescription = vm.wasteDescription;
		this.expectedWasteQuantity = vm.expectedWasteQuantity;
		this.disposalMethod = vm.disposalMethod;
		this.disposalInstructions = vm.disposalInstructions;
		this.disposalContact = vm.disposalContact;
		this.billingCodes = vm.billingCodes;
		this.hazardSummary = vm.hazardSummary;
		this.permitsRequired = vm.permitsRequired;
		this.environmentalExposure = vm.environmentalExposure;
		this.environmentalExposureDescription = vm.environmentalExposureDescription;
		this.respiratoryProtection = vm.respiratoryProtection;
		this.respiratoryProtectionDescription = vm.respiratoryProtectionDescription;
		this.overheadHazards = vm.overheadHazards;
		this.overheadHazardsDescription = vm.overheadHazardsDescription;
		this.heightWork = vm.heightWork;
		this.heightWorkDescription = vm.heightWorkDescription;
		this.mechanicalHazards = vm.mechanicalHazards;
		this.mechanicalHazardsDescription = vm.mechanicalHazardsDescription;
		this.electricalHazards = vm.electricalHazards;
		this.electricalHazardsDescription = vm.electricalHazardsDescription;
		this.hazardousMaterials = vm.hazardousMaterials;
		this.hazardousMaterialsDescription = vm.hazardousMaterialsDescription;
		this.dangerousAnimalsOrPlants = vm.dangerousAnimalsOrPlants;
		this.dangerousAnimalsOrPlantsDescription = vm.dangerousAnimalsOrPlantsDescription;
		this.waterOrWetlands = vm.waterOrWetlands;
		this.waterOrWetlandsDescription = vm.waterOrWetlandsDescription;
		this.stormDrain = vm.stormDrain;
		this.stormDrainDescription = vm.stormDrainDescription;
		this.bypassPumping = vm.bypassPumping;
		this.bypassPumpingDescription = vm.bypassPumpingDescription;
		this.sensitiveEnvironment = vm.sensitiveEnvironment;
		this.sensitiveEnvironmentDescription = vm.sensitiveEnvironmentDescription;
		this.vehicleTraffic = vm.vehicleTraffic;
		this.vehicleTrafficDescription = vm.vehicleTrafficDescription;
		this.pedestrianCrossing = vm.pedestrianCrossing;
		this.pedestrianCrossingDescription = vm.pedestrianCrossingDescription;
		this.jobHazardAssessmentStatus = vm.jobHazardAssessmentStatus;
		this.directories = vm.directories;
		this.workRequestAttachments = vm.workRequestAttachments;
		this.isProject = vm.isProject;
		this.subjobs = vm.subjobs;
		this.projectId = vm.projectId;
	}

	static getAttributeName = (field: keyof JobUpsertFM) => field;

	static validateBillingCode = (
		billingCode: Required<JobUpsertFM>['billingCodes'][0],
		hasDuplicateLineItemNumber = false,
		hasDuplicateCustomerId = false
	) => {
		const errors: ValidationErrors = {};

		if (!billingCode.lineItemNumber) {
			errors.lineItemNumber = 'Line item number is required';
		} else if (hasDuplicateLineItemNumber) {
			errors.lineItemNumber = 'Duplicate line item number';
		}

		if (!isValidTextInput(billingCode.customerId)) {
			errors.customerId = 'Customer ID is required';
		} else if (hasDuplicateCustomerId) {
			errors.customerId = 'Duplicate customer ID';
		}

		if (!billingCode.unit) {
			errors.unit = 'Unit is required';
		}

		if (!billingCode.unitPrice) {
			errors.unitPrice = 'Unit price is required';
		} else if (+billingCode.unitPrice.split('.')[0] > MAX_BILLING_CODE_UNIT_PRICE_VALUE) {
			errors.unitPrice = `Unit Price value must be less than ${MAX_BILLING_CODE_UNIT_PRICE_VALUE}.`;
		} else if (+billingCode.unitPrice === 0) {
			errors.unitPrice = 'Unit price must be different from zero (0)';
		}

		if (!isValidTextInput(billingCode.description)) {
			errors.description = 'Description is required';
		}

		return errors;
	};

	static validate = (form: JobUpsertFM) => {
		const errors: ValidationErrors = {};

		if (!isValidTextInput(form?.jobCode)) {
			errors.jobCode = 'Job ID is required';
		}

		if (!isValidTextInput(form?.title)) {
			errors.title = 'Title is required';
		}

		if (!isValidTextInput(form?.customerCompanyName)) {
			errors.customerCompanyName = 'Customer company name is required';
		}

		if (!form?.officeId) {
			errors.officeId = 'Office is required';
		}

		if (form?.isDeliverable) {
			if (!form?.deliverableAssigneeId) {
				errors.deliverableAssigneeId = 'Assignee is required';
			}
		}

		if (!form.priority) {
			errors.priority = 'Priority is required';
		}

		if (form.billingCodes) {
			const lineItemNumberCounter: Record<number, number> = {};
			const customerIdCounter: Record<string, number> = {};

			for (const billingCode of form.billingCodes) {
				if (billingCode.lineItemNumber) {
					lineItemNumberCounter[billingCode.lineItemNumber] = (lineItemNumberCounter[billingCode.lineItemNumber] ?? 0) + 1;
				}
				if (billingCode.customerId) {
					customerIdCounter[billingCode.customerId] = (customerIdCounter[billingCode.customerId] ?? 0) + 1;
				}
			}

			errors.billingCodes = [];

			errors._error = undefined;

			for (let i = 0; i < form.billingCodes.length; i++) {
				const billingCode = form.billingCodes[i];

				const hasDuplicateLineItemNumber: boolean = lineItemNumberCounter[billingCode.lineItemNumber] > 1;
				const hasDuplicateCustomerId: boolean = customerIdCounter[billingCode.customerId] > 1;

				errors.billingCodes[i] = JobUpsertFM.validateBillingCode(billingCode, hasDuplicateLineItemNumber, hasDuplicateCustomerId);

				// we add billing codes to global error variable, since we might not render billing codes
				// and if form does not render fields for all of the errors,
				// then the form might be considered valid as per ReduxForm documentation
				if (Object.keys(errors.billingCodes[i]).length !== 0) {
					errors._error = 'billingCodes';
				}
			}
		}

		if (!!form.uploadingAttachments && Object.keys(form.uploadingAttachments).length) {
			errors.uploadingAttachments = 'Attachment upload in progress.';
		}

		if (form.billToEmail && !EMAIL_REGEX.test(form.billToEmail)) {
			errors.billToEmail = 'Invalid email address';
		}

		return errors;
	};

	static warn = (form: JobUpsertFM) => {
		const warnings: ValidationErrors = {};

		if (
			form?.workLocation &&
			(isNullOrUndefined(form.workLocation.latitude) || isNullOrUndefined(form.workLocation.longitude))
		) {
			warnings.workLocation = { street: 'Work location not recognized by Google Maps.' };
		}

		return warnings;
	};

	static normalizeNumberOfEmployees = (_value) => {
		if (+_value < 0) {
			return 0;
		}

		// Float doesnt make sense so just return floor
		if (!Number.isInteger(+_value)) {
			return Math.floor(_value);
		}

		return _value;
	};

	static toRM(form: Partial<JobUpsertFM>): Partial<JobUpsertRM.Default> {
		const rm: Partial<JobUpsertRM.Default> = {};

		for (const _key of Object.keys(form) as (keyof JobUpsertFM)[]) {
			switch (_key) {
				// Skip VM properties that we only need ids from or dont need at all in RM
				case 'office':
				case 'salesLead':
				case 'projectManager':
				case 'office':
				case 'division':
				case 'deliverableSoftware':
				case 'deliverableAssignee':
				case 'deliverableDeliveryMethod':
				case 'deliverableCodeStandard':
				case 'deliverableDeliveryTimeline':
				case 'deliverableFileFormat':
				case 'jobStatus':
				case 'guaranteedDaysFromStart':
				case 'targetDaysFromStart':
				case 'uploadingAttachments':
					break;

				case 'surveyDate':
				case 'startDate':
				case 'targetStartDate':
				case 'targetCompletionDate':
				case 'guaranteedCompletionDate':
					rm[_key] = form[_key] ? TimeUtils.formatDate(form[_key], TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY) : null;
					break;

				case 'workLocation': {
					// if location is deleted
					if (!form[_key]?.street) {
						rm[_key] = null;
						break;
					}

					rm[_key] = form[_key];
					break;
				}

				case 'billToPhoneNumber': {
					if (!!form[_key]) {
						rm[_key] = toRawPhoneNumber(form[_key]!);
					}
					break;
				}

				case 'additionalTruckEquipmentFields': {
					for (let i = 0; i < MAX_WR_TRUCK_EQUIPMENT_ADDITIONAL_FIELDS; i++) {
						rm[`additionalTruckEquipmentField${i + 1}Name`] = form[_key]?.[i]?.name ?? null;
						rm[`additionalTruckEquipmentField${i + 1}Checked`] = form[_key]?.[i]?.isChecked ?? false;
					}
					break;
				}

				case 'workRequestAttachments': {
					rm.workRequestAttachments = form.workRequestAttachments?.map((_wra) => ({
						attachmentId: _wra.id,
						copyToWorkOrder: _wra.copyToWorkOrderFlag,
					}));
					break;
				}

				case 'subjobs': {
					rm.subjobIds = form.subjobs?.map(getId);
					break;
				}

				default:
					rm[_key] = form[_key];
					break;
			}
		}

		return rm;
	}
}

export default JobUpsertFM;
