import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import type { RootState } from 'af-reducers';

import EmployeeGroup from './EmployeeGroup';
import NotifyEmployeesModal from '../NotifyEmployeesModal';

import type { ResourcesGroupItem } from 'ab-viewModels/scheduleBoardToolbarResources.viewModel';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { TOOLBAR_GROUP_DEFAULT_ID } from 'ab-common/constants/scheduleBoard';
import { AVAILABLE_EMPLOYEE_STATUS } from 'ab-common/constants/employee';

import type { EmployeeModalProps } from '../../../Shared/ModalProps';

interface OwnProps extends EmployeeModalProps {
	/** MM-DD-YYYY */
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	sendNotification: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
	scheduleAutoNotify: (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => void;
}

interface DispatchProps {
	openAvailableEmployeesNotificationModal: typeof ScheduleBoardActions.openAvailableEmployeesNotificationModal;
	closeAvailableEmployeesNotificationModal: typeof ScheduleBoardActions.closeAvailableEmployeesNotificationModal;
}

interface StateProps {
	employeeStatuses?: ResourcesGroupItem[];
	hasDefault?: boolean;
	autoNotifyAt?: number;
	autoNotifyUnassignedEmployees?: boolean;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class AvailableEmployees extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		employeeStatuses: [],
		hasDefault: false,
	};

	openNotifyModal = (groupId: number) => {
		const { openAvailableEmployeesNotificationModal, dueDate } = this.props;
		openAvailableEmployeesNotificationModal(groupId, dueDate);
	};

	closeNotifyModal = () => this.props.closeAvailableEmployeesNotificationModal();

	render() {
		const {
			dueDate,
			employeeStatuses,
			hasDefault,
			sendNotification,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			hasPermissionsToSendNotifications,
			autoNotifyAt,
			autoNotifyUnassignedEmployees,
			scheduleAutoNotify,
			setEmployeeModalData,
			setEmployeeModalVisibility,
		} = this.props;

		return (
			<div className="schedule-board-toolbar-employees-row">
				<label>Labor</label>
				<div className="schedule-board-toolbar-employees-wrapper">
					<div className="schedule-board-toolbar-employees">
						{
							!!employeeStatuses && employeeStatuses.length > 0 ?
								employeeStatuses.map(({ id: _empStatusId, title: _empStatus, isDeleted }) => (
									<EmployeeGroup
										available={true}
										dueDate={dueDate}
										groupId={_empStatusId}
										groupTitle={_empStatus}
										hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
										hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
										isDeleted={isDeleted}
										isDragAndDropDisabled={isDragAndDropDisabled}
										key={_empStatusId}
										openNotifyModal={this.openNotifyModal}
										setEmployeeModalData={setEmployeeModalData}
										setEmployeeModalVisibility={setEmployeeModalVisibility}
									/>
								)) :
								(
									hasDefault &&
									<EmployeeGroup
										available={true}
										dueDate={dueDate}
										groupId={TOOLBAR_GROUP_DEFAULT_ID}
										groupTitle={AVAILABLE_EMPLOYEE_STATUS}
										hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
										hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
										isDeleted={false}
										isDragAndDropDisabled={isDragAndDropDisabled}
										openNotifyModal={this.openNotifyModal}
										setEmployeeModalData={setEmployeeModalData}
										setEmployeeModalVisibility={setEmployeeModalVisibility}
									/>
								)
						}
					</div>
				</div>
				<NotifyEmployeesModal
					autoNotifyAt={autoNotifyAt ?? null}
					autoNotifyUnassignedEmployees={!!autoNotifyUnassignedEmployees}
					closeModal={this.closeNotifyModal}
					dueDate={dueDate}
					scheduleAutoNotify={scheduleAutoNotify}
					sendNotification={sendNotification}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate } = ownProps;
	const { company } = state.company;

	if (!state.scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return {};
	}
	const { toolbarEmployees } = state?.scheduleBoard?.workOrdersByDateDictionary?.[dueDate];
	const { toolbarEmployeeGroupTitles } = state?.scheduleBoard;

	const selectedDayOfWeek = TimeUtils.parseMoment(dueDate, TimeFormat.DATE_ONLY)?.day();
	const dayBefore = selectedDayOfWeek ? selectedDayOfWeek - 1 : 6;

	return {
		employeeStatuses: toolbarEmployeeGroupTitles?.available,
		hasDefault: !!toolbarEmployees.available[TOOLBAR_GROUP_DEFAULT_ID],
		autoNotifyAt: company?.notification?.notifyOnDay?.[dayBefore],
		autoNotifyUnassignedEmployees: company?.notification?.notifyUnassignedEmployees ?? false,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		openAvailableEmployeesNotificationModal: ScheduleBoardActions.openAvailableEmployeesNotificationModal,
		closeAvailableEmployeesNotificationModal: ScheduleBoardActions.closeAvailableEmployeesNotificationModal,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(AvailableEmployees);
