import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import type { Dispatch } from 'redux';
import { compose } from 'redux';
import type { InjectedFormProps} from 'redux-form';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import FormFieldArrayStatusEnum from 'acceligent-shared/enums/formFieldArrayStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { RootState } from 'af-reducers';

import * as WorkOrderActions from 'af-actions/workOrder';
import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { http } from 'af-utils/http.util';
import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';
import { isEmptyNumber } from 'af-utils/validation.util';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';
import ScheduleBoardPropertyEnum from 'ab-enums/scheduleBoardProperty.enum';

import SubmitButton from 'af-components/SubmitButton';
import ConfirmationModal from 'af-components/ConfirmationModal';

import CancelModal from '../Modals/Cancel';
import WorkOrderFM from '../formModel';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

interface OwnProps {
	workOrderId: number | undefined;
	status: WorkOrderStatus;
	hasJob: boolean;
	isCreate: boolean;
	isFetching: boolean;
	resetTimer: () => void;
	redirect: (checkIfFormIsDirty?: boolean) => void;
	isJobChanged: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<WorkOrderFM>;

interface State {
	showDeleteModal: boolean;
	showCancelModal: boolean;
	showUpdateFieldReportModal: boolean;
	publishAfterLookupCheck: boolean;
	publishing: boolean;
	canceling: boolean;
	saving: boolean;
	showChangeJobWarningModal: boolean;
	currentNextAction: Nullable<() => void>;
	updateExistingReports: boolean;
}
class WorkOrderFormActions extends React.PureComponent<Props, State> {
	state: State = {
		showCancelModal: false,
		showDeleteModal: false,
		showUpdateFieldReportModal: false,
		publishAfterLookupCheck: false,
		publishing: false,
		canceling: false,
		saving: false,
		showChangeJobWarningModal: false,
		currentNextAction: null,
		updateExistingReports: false,
	};

	static CHANGE_JOB_WARNING_HEADER = 'Job change detected';
	static CHANGE_JOB_WARNING_BODY =
		<>
			{'Changing the job will not remove current attachments, even if they belong to previous job.'}
			<br />
			{'This action will also add attachments from newly selected job.'}
		</>;

	openCancelModal = () => this.setState(({ showCancelModal: true }));
	closeCancelModal = () => this.setState(({ showCancelModal: false }));

	openDeleteModal = () => this.setState(({ showDeleteModal: true }));
	closeDeleteModal = () => this.setState(({ showDeleteModal: false }));

	openChangeJobWarningModal = () => {
		this.setState(() => ({ showChangeJobWarningModal: true }));
	};
	closeChangeJobWarningModal = () => {
		this.setState(() => ({ showChangeJobWarningModal: false }));
	};

	wasLookupUpdated = () => {
		const { formActions: { getFormData } } = this.props;
		const form = getFormData();
		return form?.workOrderReportTypeLookup?.find((_lookup) => _lookup.status !== FormFieldArrayStatusEnum.OLD);
	};

	checkFieldReportLookupAndSave = () => {
		const { status, isFinalized } = this.props;
		if (!isFinalized && (status === WorkOrderStatus.OUTDATED || status === WorkOrderStatus.PUBLISHED) && this.wasLookupUpdated()) {
			this.setState(() => ({ showUpdateFieldReportModal: true, publishAfterLookupCheck: false }));
		} else {
			this.saveWorkOrder();
		}
	};

	checkFieldReportLookupAndRepublish = () => {
		const { isFinalized } = this.props;
		if (!isFinalized && this.wasLookupUpdated()) {
			this.setState(() => ({ showUpdateFieldReportModal: true, publishAfterLookupCheck: true }));
		} else {
			this.publishWorkOrder();
		}
	};

	closeUpdateFieldReportModal = () => this.setState(({ showUpdateFieldReportModal: false, publishAfterLookupCheck: false }));

	continueWithFieldReportUpdate = () => {
		this.setState(
			() => ({ updateExistingReports: true }),
			this.handleFieldReportUpdate
		);
	};
	continueWithoutFieldReportUpdate = () => {
		this.setState(
			() => ({ updateExistingReports: false }),
			this.handleFieldReportUpdate
		);
	};

	handleFieldReportUpdate = () => {
		const { publishAfterLookupCheck } = this.state;
		if (publishAfterLookupCheck) {
			this.publishWorkOrder();
		} else {
			this.saveWorkOrder();
		}
		this.closeUpdateFieldReportModal();
	};

	publishWorkOrder = async () => {
		const { publish, redirect, workOrderId } = this.props;
		if (!workOrderId) {
			throw new Error('No WO to save');
		}

		this.setState(() => ({ publishing: true }), async () => {
			await this.save();
			await publish(workOrderId);
			this.setState(() => ({ publishing: false }));
			redirect(false);
		});
	};

	publish = () => {
		const { isJobChanged, isCreate } = this.props;

		if (isJobChanged && !isCreate) {
			this.setState(
				() => ({ currentNextAction: this.publishWorkOrder }),
				this.openChangeJobWarningModal
			);
		} else {
			this.publishWorkOrder();
		}

	};

	saveWorkOrder = () => {
		const { redirect } = this.props;
		this.setState(() => ({ saving: true }), async () => {
			await this.save();
			this.setState(() => ({ saving: false }));
			redirect(false);
		});
	};

	preSaveCheck = async () => {
		const { isJobChanged, isCreate } = this.props;

		if (isJobChanged && !isCreate) {
			this.setState(
				() => ({ currentNextAction: this.checkFieldReportLookupAndSave }),
				this.openChangeJobWarningModal
			);
		} else {
			this.checkFieldReportLookupAndSave();
		}
	};

	save = async () => {
		const {
			workOrderId,
			setPlaceholder,
			formActions: { getFormData },
			startSubmitting,
			stopSubmitting,
			create,
			update,
			save,
		} = this.props;
		const { updateExistingReports } = this.state;

		const form: Nullable<WorkOrderFM> = getFormData();
		if (!form) {
			return;
		}
		const rm = WorkOrderFM.toRequestModel(form);

		rm.updateExistingReports = updateExistingReports;

		if (!workOrderId) {
			const { dueDate, index } = rm;
			const code = ScheduleBoardUtil.generatePlaceholderDroppableId(dueDate, ScheduleBoardPropertyEnum.WORK_ORDER);
			setPlaceholder(TimeUtils.formatDate(dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY), code, !isEmptyNumber(index!) ? index! - 1 : undefined);
		}

		startSubmitting(WORK_ORDER_FORM); // used for loading indicator
		if (!workOrderId) {
			await create(rm);
		} else {
			await update(workOrderId, rm);
		}
		save();
		stopSubmitting(WORK_ORDER_FORM);
	};

	cancel = async () => {
		const { workOrderId, redirect, cancel, cancellationReason } = this.props;
		if (!workOrderId) {
			throw new Error('No WO to cancel');
		}

		this.setState(() => ({ canceling: true }), async () => {
			const date = TimeUtils.formatDate(new Date());
			await cancel(workOrderId, { cancellationReason, date });
			this.setState(() => ({ canceling: false }));
			this.closeCancelModal();
			redirect(false);
		});
	};

	delete = async () => {
		const { workOrderId, deleteWorkOrder } = this.props;
		if (!workOrderId) {
			throw new Error('No WO to delete');
		}

		await deleteWorkOrder(+workOrderId);
	};

	updateFieldReportModalBody = () => {
		return (
			<>
				Changes have been made to how report types should be used. Would You like to also update existing field reports for this work order?
				<br />
				<strong>IMPORTANT:</strong> Proceeding might result in irreversible loss of information
			</>
		);
	};

	updateFieldReportModalFooter = () => {
		return (
			<>
				<Button onClick={this.closeUpdateFieldReportModal} variant="info">
					Cancel
				</Button>
				<Button onClick={this.continueWithoutFieldReportUpdate} variant="primary">
					No
				</Button>
				<Button onClick={this.continueWithFieldReportUpdate} variant="danger">
					Yes
				</Button>
			</>
		);
	};

	renderButtons = () => {
		const {
			status,
			isSubmitting,
			invalid,
			isFetching,
			dirty,
			hasJob,
			isCreate,
		} = this.props;
		const { publishing, saving } = this.state;

		if (status === WorkOrderStatus.CANCELED) {
			return;
		}

		return (
			<div className="work-order-upsert__sticky-actions">
				{!isCreate && status === WorkOrderStatus.DRAFT &&
					<>
						<Button
							disabled={isSubmitting}
							onClick={this.openDeleteModal}
							variant="info"
						>
							Delete
						</Button>
						<SubmitButton
							disabled={isSubmitting || invalid || isFetching}
							label="Publish"
							onClick={this.publish}
							reduxFormSubmitting={publishing}
						/>
					</>
				}
				{(status === WorkOrderStatus.PUBLISHED || status === WorkOrderStatus.OUTDATED) &&
					<Button
						disabled={isSubmitting}
						onClick={this.openCancelModal}
						variant="danger"
					>
						Cancel Order
					</Button>
				}
				{(status === WorkOrderStatus.OUTDATED || (this.props.isJobChanged && !isCreate && status !== WorkOrderStatus.DRAFT)) &&
					<SubmitButton
						disabled={isSubmitting || invalid || isFetching}
						label="Republish"
						onClick={this.checkFieldReportLookupAndRepublish}
						reduxFormSubmitting={publishing}
					/>
				}
				{(!this.props.isJobChanged || isCreate || status === WorkOrderStatus.DRAFT) &&
					<SubmitButton
						className="save-work-order-btn"
						disabled={isSubmitting || isFetching || !dirty || invalid || !hasJob}
						label="Save"
						onClick={this.preSaveCheck}
						reduxFormSubmitting={saving}
						type="button"
					/>
				}
			</div>
		);
	};

	renderModals = () => {
		const { resetTimer, cancellationReason, code } = this.props;
		const { showCancelModal, showDeleteModal, canceling, showUpdateFieldReportModal, showChangeJobWarningModal, currentNextAction } = this.state;
		if (!code) {
			return null;
		}

		return (
			<>
				<CancelModal
					cancel={this.cancel}
					cancellationReason={cancellationReason}
					closeModal={this.closeCancelModal}
					code={code}
					resetTimer={resetTimer}
					showModal={showCancelModal}
					submitting={canceling}
				/>
				<ConfirmationModal
					body={
						<>
							Are you sure you want to delete this Work Order?
							<br />
							This action cannot be undone.
						</>
					}
					closeModal={this.closeDeleteModal}
					confirmAction={this.delete}
					confirmText="Delete Work Order"
					modalStyle="danger"
					showModal={showDeleteModal}
					title={`Delete Work Order (${code})?`}
				/>
				<ConfirmationModal
					body={this.updateFieldReportModalBody()}
					closeModal={this.closeDeleteModal}
					footer={this.updateFieldReportModalFooter()}
					modalStyle="danger"
					showModal={showUpdateFieldReportModal}
					size="md"
					title="Update Existing Field Reports"
				/>
				<ConfirmationModal
					body={WorkOrderFormActions.CHANGE_JOB_WARNING_BODY}
					closeModal={this.closeChangeJobWarningModal}
					closeText="Cancel"
					confirmAction={currentNextAction}
					confirmText="I understand"
					modalStyle="warning"
					showModal={showChangeJobWarningModal}
					title={WorkOrderFormActions.CHANGE_JOB_WARNING_HEADER}
				/>
			</>
		);
	};

	render() {
		return (
			<>
				{this.renderButtons()}
				{this.renderModals()}
			</>
		);
	}
}

const selector = formValueSelector(WORK_ORDER_FORM);

const formActions: { getFormData: () => Nullable<WorkOrderFM>; } = {
	getFormData: () => null,
};

function mapStateToProps(state: RootState) {
	const { workOrder, http: { submitting } } = state;

	formActions.getFormData = () => getFormValues(WORK_ORDER_FORM)(state) as WorkOrderFM;

	return {
		isSubmitting: !!submitting.length,
		code: workOrder.order?.code,
		isFinalized: workOrder.order?.isFinalized,
		cancellationReason: selector(state, 'cancellationReason'),
		formActions,
	};
}

function mapDispatchToProps() {
	return {
		publish: WorkOrderActions.publishWorkOrder,
		create: WorkOrderActions.create,
		update: WorkOrderActions.update,
		save: WorkOrderActions.saveWorkOrder,
		cancel: WorkOrderActions.cancelWorkOrder,
		deleteWorkOrder: WorkOrderActions.deleteWorkOrder,
		setPlaceholder: ScheduleBoardActions.setCopiedWorkOrderPlaceholder,
		startSubmitting: (formName: string) => (dispatch: Dispatch) => dispatch(http.startSubmitting(formName)),
		stopSubmitting: (formName: string) => (dispatch: Dispatch) => dispatch(http.endSubmitting(formName)),
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<WorkOrderFM>({ form: WORK_ORDER_FORM })
);

export default enhance(WorkOrderFormActions);
