import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import * as SettingsUtils from 'af-utils/settings.util';

import * as SettingsKeys from 'af-constants/settingsKeys';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import Breadcrumbs from 'af-components/Breadcrumbs';
import TabNavigation from 'af-components/TabNavigation';

import type { RootState } from 'af-reducers';

import TruckTable from '../Equipment/Table';
import ItemTable from '../Item/Table';
import ToolTable from '../Tool/List';

const BREADCRUMBS = [{ label: 'Equipment' }];

const TAB_IDS = {
	EQUIPMENT: 0,
	SMALL_TOOL: 1,
	ACCESSORY: 2,
};

const TABS = [
	{ id: TAB_IDS.EQUIPMENT, label: 'Equipment' },
	{ id: TAB_IDS.SMALL_TOOL, label: 'Small tools' },
	{ id: TAB_IDS.ACCESSORY, label: 'Accessories' },
];

interface SettingProps {
	tabId: number;
}

type Props = SettingProps & ConnectedProps<typeof connector>;

const EquipmentList: React.FC<Props> = (props) => {

	const {
		hasManageEquipmentPermission,
		hasViewSmallToolsPermission,
		hasViewAccessoriesPermission,
	} = props;

	const [activeTabId, setActiveTabId] = React.useState(props.tabId);
	const [disableTab] = React.useState(DisabledFeatures.Inventory);

	React.useEffect(() => {
		let defaultTab = TAB_IDS.EQUIPMENT;

		if (!hasManageEquipmentPermission) {
			defaultTab = TAB_IDS.SMALL_TOOL;

			if (!hasViewSmallToolsPermission) {
				defaultTab = TAB_IDS.ACCESSORY;
			}
		}
		setActiveTabId(defaultTab);
	}, [hasManageEquipmentPermission, hasViewSmallToolsPermission]);

	const tabs = React.useMemo(() => (TABS.map((tab) => {
		return {
			...tab,
			hide: tab.id === TAB_IDS.SMALL_TOOL && !hasViewSmallToolsPermission
				|| tab.id === TAB_IDS.ACCESSORY && !hasViewAccessoriesPermission
				|| tab.id === TAB_IDS.EQUIPMENT && !hasManageEquipmentPermission,
		};
	})), [hasManageEquipmentPermission, hasViewAccessoriesPermission, hasViewSmallToolsPermission]);

	const selectTab = React.useCallback((tabId: number) => {
		setActiveTabId(tabId);

		SettingsUtils.setItem(SettingsKeys.INVENTORY_TAB(), `${tabId}`, BrowserStorageEnum.LOCAL_STORAGE);
	}, []);

	const renderTab = React.useCallback(() => {
		switch (activeTabId) {
			case TAB_IDS.SMALL_TOOL:
				return <ToolTable />;
			case TAB_IDS.ACCESSORY:
				return <ItemTable />;
			case TAB_IDS.EQUIPMENT:
			default:
				return <TruckTable />;
		}
	}, [activeTabId]);

	return (
		<div className="form-segment">
			<Breadcrumbs items={BREADCRUMBS} />
			<div className="table-container table-container--tabbed">
				<div>
					{!disableTab && (
						<TabNavigation
							active={activeTabId}
							onClick={selectTab}
							tabs={tabs}
						/>
					)}
					{renderTab()}
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManageEquipmentPermission: boolean = isAllowed(
		PagePermissions.COMPANY.RESOURCES.EQUIPMENT.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasViewSmallToolsPermission: boolean = isAllowed(
		PagePermissions.COMPANY.RESOURCES.SMALL_TOOLS.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasViewAccessoriesPermission: boolean = isAllowed(
		PagePermissions.COMPANY.RESOURCES.ACCESSORIES.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasManageEquipmentPermission,
		hasViewSmallToolsPermission,
		hasViewAccessoriesPermission,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentClass>(
	connector,
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.INVENTORY_TAB(),
			mappedName: 'tabId',
			normalize: (item: string) => DisabledFeatures.Inventory ? TAB_IDS.EQUIPMENT : +item, // override value if tabs are disabled
			defaultValue: TAB_IDS.EQUIPMENT,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])));

export default enhance(EquipmentList);
