import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col } from 'react-bootstrap';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';

import * as ReportBlockFieldEnums from 'acceligent-shared/enums/reportBlockField';
import RepeatableBlockType from 'acceligent-shared/enums/repeatableBlockType';
import { CalculationsWithUnit } from 'acceligent-shared/enums/operation';
import OperandType from 'acceligent-shared/enums/operand';

import { omitObject } from 'acceligent-shared/utils/extensions';

import type { ReportBlockRM, ReportBlockFieldRM } from 'ab-requestModels/reportBlock/reportBlock.requestModel';

import { initDefaultCompletionFieldFMValues } from 'af-utils/reportTypeBuilder.util';

import type { OwnProps as ConfirmationModalProps } from 'af-components/ConfirmationModal';
import ConfirmationModal from 'af-components/ConfirmationModal';

import * as ReportBlockActions from 'af-actions/reportBlock';

import DisplayTypeEnum, { getDisplayType } from '../../Shared/displayType.enum';
import type { CalculatedFieldOptionFormModel} from '../../Shared/formModel';
import { ReportBlockFormModel, ReportBlockFieldFormModel, OPERATION_TYPE_FILTER } from '../../Shared/formModel';

import Loading from '../Edit/Loading';
import ReportBlockModal from '../Modals/ReportBlockModal';
import CreateReportBlockFieldModal from '../Modals/CreateReportBlockField';
import ReportBlockActionConfirmationBody from '../Modals/ReportBlockActionConfirmationBody';

import FieldList from './FieldList';
import BlockInfo from './BlockInfo';
import Preview from './Preview';
import Header from './Header';

import { DEFAULT_FIELD_LIST_ID, BLOCK_FIELD_LIST_ID, PREDEFINED_INITIAL_VALUES } from '../values';

const MAX_INLINE_TABLE_COLUMNSPAN = 8;
const MAX_APPENDIX_TABLE_COLUMNSPAN = 16;

const TABLE_COMPATIBLE_FIELD_LIST = Object.keys(ReportBlockFieldEnums.TableCompatibleFields);

const DISPLAY_TYPE_COL_SIZE = {
	[DisplayTypeEnum.DEFAULT]: { FIELD_LIST: 6, BLOCK_INFO: 8, PREVIEW: 10 },
	[DisplayTypeEnum.SMALL]: { FIELD_LIST: 0, BLOCK_INFO: 10, PREVIEW: 14 },
	[DisplayTypeEnum.EXTRA_SMALL]: { FIELD_LIST: 0, BLOCK_INFO: 24, PREVIEW: 24 },
};

type FieldReducerType = {
	/** Ordered array of field ids */
	fieldIds: string[];
	calculableFields: CalculatedFieldOptionFormModel[];
	completionFieldId: Nullable<string>;
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; };
};

type ConfirmationModalState = ConfirmationModalProps;

interface OwnProps {
	fields: Nullable<ReportBlockFieldFormModel[]>;
	reportBlock: ReportBlockFormModel;
	loading?: boolean;
	onSubmit: (fields: ReportBlockFieldRM[]) => void;
	onSaveAs?: (form: ReportBlockFormModel, fields: ReportBlockFieldRM[]) => void;
	onEditBlock: (block: ReportBlockRM, fields: ReportBlockFieldRM[]) => void;
	reportTypes?: string[];
}

type Props = OwnProps & ConnectedProps<typeof connector>;

// Checks whether a breaking change happened on field, if unit or field type changed
// Breaking change means that it possibly affects calculations it is a part of
const _hasBreakingChange = (oldField: ReportBlockFieldFormModel, newField: ReportBlockFieldFormModel) => {
	return oldField.unit !== newField.unit || oldField.fieldType !== newField.fieldType;
};

const _getInitialFieldState = (
	fieldIds: string[],
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; },
	fieldType: Nullable<ReportBlockFieldEnums.Type>,
	fieldId: Nullable<string>,
	index?: number
): Partial<ReportBlockFieldFormModel> & { index?: number; } => {
	let initialState: Partial<ReportBlockFieldFormModel> = fieldType && PREDEFINED_INITIAL_VALUES[ReportBlockFieldEnums.Type[fieldType]]
		? PREDEFINED_INITIAL_VALUES[ReportBlockFieldEnums.Type[fieldType]]
		: { isVisibleToCustomer: true };

	if (fieldId) {
		const field = fieldsByIdMap[fieldId];
		initialState = field;
	}

	const names = fieldIds.reduce<string[]>((_acc, _id) => {
		const field = fieldsByIdMap?.[_id];
		if (!field || field.virtualId === fieldId) {
			return _acc;
		}
		_acc.push(field.name);
		return _acc;
	}, []);

	initialState.names = names;

	return {
		...initialState,
		index,
	};
};

const _getIsIdFieldType = (fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; }) => {
	return (fieldId: string) => {
		const _field = fieldsByIdMap[fieldId];
		return _field
			? _isIdFieldType(_field)
			: false;
	};
};

const _isIdFieldType = (field: ReportBlockFieldFormModel) => field.fieldType === ReportBlockFieldEnums.Type.ID;
const _getFindCompletionField = (completionFieldId: string) => {
	return (option: CalculatedFieldOptionFormModel) => {
		return option.id === completionFieldId;
	};
};

const REMOVED_CALCULATION_OPERAND_TITLE = 'Updating field has removed it from calculations';
const _getRemovedCalculationModalBody = (fieldNames: string[]) => (
	<div>
		<div>Updating field unit has caused it to be removed from the following calculation fields:</div>
		<strong>{fieldNames.join(', ')}</strong>

		<div>Please check your calculated fields.</div>
	</div>
);

const _getConfirmationModalTitle = (name: string) => `Are you sure you want to update Report Block (${name})?`;
const _getConfirmationModalBody = (blockName: string, reportTypes: string[]) => {
	const message = `Update of Report Block (${blockName}) will also update the following Report Types and any related Billable Work, the changes will be included in all new Field Reports:`;

	return (
		<ReportBlockActionConfirmationBody
			message={message}
			reportTypes={reportTypes}
		/>
	);
};
const _getTitle = (showReportBlockModal: boolean, id: Nullable<number>) => {
	if (showReportBlockModal) {
		if (id) {
			return 'Edit Block Settings';
		}
		return 'New Report Block';
	} else {
		return 'Save Block As';
	}
};

const _initializeState = (fields: Nullable<ReportBlockFieldFormModel[]>, blockId: string) => {
	return (fields ?? []).reduce<FieldReducerType>((_acc, _field) => {
		const _id = _field.virtualId;

		const newField = { ..._field, blockId: _field.reportBlockVirtualId ?? blockId };

		if (_field.fieldType !== ReportBlockFieldEnums.Type.COMPLETION) {
			_acc.fieldIds.push(_id);
		} else {
			_acc.completionFieldId = _id;
		}

		_acc.calculableFields.push({
			id: _id,
			constant: null,
			type: OperandType.FIELD,
			index: _acc.calculableFields.length,
			isOperandBlockInPrimarySegment: null,
		});
		_acc.fieldsByIdMap[_id] = newField;

		return _acc;
	}, {
		fieldIds: [],
		calculableFields: [],
		completionFieldId: null,
		fieldsByIdMap: {},
	});
};

const ReportBlockForm: React.FC<Props> = (props) => {
	const {
		fields,
		reportBlock: {
			hasCompletionStatus,
			isMain,
			isRepeating,
			name,
		},
		reportBlock,
		loading,
		onSubmit,
		onSaveAs,
		onEditBlock,
		reportTypes,
		uploadImage,
	} = props;

	const [disabled, setDisabled] = React.useState<boolean>(true);
	const [displayType, setDisplayType] = React.useState<DisplayTypeEnum>(DisplayTypeEnum.DEFAULT);
	const [dropFieldType, setDropFieldType] = React.useState<Nullable<ReportBlockFieldEnums.Type>>(null);
	const [dropIndex, setDropIndex] = React.useState<Nullable<number>>(null);
	const [editFieldId, setEditFieldId] = React.useState<Nullable<string>>(null);
	/** Array of field ids except the completion field id */
	const [fieldIds, setFieldIds] = React.useState<FieldReducerType['fieldIds']>([]);
	const [calculableFields, setCalculableFields] = React.useState<FieldReducerType['calculableFields']>([]);
	const [fieldsByIdMap, setFieldsByIdMap] = React.useState<FieldReducerType['fieldsByIdMap']>({});
	const [completionFieldId, setCompletionFieldId] = React.useState<FieldReducerType['completionFieldId']>(null);
	const [hasIdField, setHasIdField] = React.useState<boolean>(fields?.some(_isIdFieldType) ?? false);
	const [isPreviewInFocus, setIsPreviewInFocus] = React.useState<boolean>(false);
	const [showPreviewToggle, setShowPreviewToggle] = React.useState<boolean>(false);
	const [showReportBlockFieldModal, setShowReportBlockFieldModal] = React.useState<boolean>(false);
	const [showReportBlockModal, setShowReportBlockModal] = React.useState<boolean>(false);
	const [showSaveAsReportBlockModal, setShowSaveAsReportBlockModal] = React.useState<boolean>(false);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [maxColumnspanBreached, setMaxColumnspanBreached] = React.useState<boolean>(false);
	const [hasIncompatibleFields, setHasIncompatibleFields] = React.useState<boolean>(false);
	const [confirmationModal, setConfirmationModal] = React.useState<Nullable<ConfirmationModalState>>(null);
	const [reportBlockByIdMap, setReportBlockByIdMap] = React.useState<{ [id: string]: ReportBlockFormModel; }>({});

	const updateWindowDimensions = React.useCallback(() => {
		const updatedDisplayType = getDisplayType(window.innerWidth);

		if (displayType !== updatedDisplayType) {
			setDisplayType(updatedDisplayType);
			setShowPreviewToggle(updatedDisplayType === DisplayTypeEnum.EXTRA_SMALL);
		}
	}, [displayType]);

	const fieldTableColumnspanReducer = React.useCallback((sum: number, _fieldId: string) => {
		const _field = fieldsByIdMap?.[_fieldId];
		if (!_field) {
			return sum;
		}
		switch (_field.dimension) {
			case ReportBlockFieldEnums.Dimension.VERY_SMALL:
			case ReportBlockFieldEnums.Dimension.SMALL:
				return sum += 1;
			case ReportBlockFieldEnums.Dimension.MEDIUM:
				return sum += 2;
			case ReportBlockFieldEnums.Dimension.LARGE:
				return sum += 3;
			default:
				return sum += 8;
		}
	}, [fieldsByIdMap]);

	// component did mount
	React.useEffect(() => {
		updateWindowDimensions();
		window.addEventListener('resize', updateWindowDimensions);

		return (() => {
			window.removeEventListener('resize', updateWindowDimensions);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setReportBlockByIdMap({ [reportBlock.virtualId]: reportBlock });
	}, [reportBlock]);

	React.useEffect(() => {
		// We can type assert since we checked with type guard
		const {
			calculableFields: newCalculableFields,
			completionFieldId: newCompletionFieldId,
			fieldIds: newFieldIds,
			fieldsByIdMap: newFieldsByIdMap,
		} = _initializeState(fields, reportBlock.virtualId);

		setCalculableFields(newCalculableFields);
		setCompletionFieldId(newCompletionFieldId);
		setFieldIds(newFieldIds);
		setFieldsByIdMap(newFieldsByIdMap);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields]); // don't extend dependency array

	React.useEffect(() => {
		const fieldColumnSpanSum = fieldIds.reduce(fieldTableColumnspanReducer, 0);
		const maxColumnspan = reportBlock.repeatableBlockType === RepeatableBlockType.INLINE_TABLE
			? MAX_INLINE_TABLE_COLUMNSPAN
			: MAX_APPENDIX_TABLE_COLUMNSPAN;

		const fieldTypes = fieldIds.reduce<ReportBlockFieldEnums.Type[]>((_acc, _id) => {
			const _field = fieldsByIdMap?.[_id];
			if (!_field) {
				return _acc;
			}
			_acc.push(_field.fieldType);
			return _acc;
		}, []);

		// check if max columnspan is breached
		// or if it has incompatible fields
		// only if its repeatable block that is not of default type
		if (reportBlock.isRepeating
			&& reportBlock.repeatableBlockType !== RepeatableBlockType.DEFAULT
		) {
			setMaxColumnspanBreached(fieldColumnSpanSum > maxColumnspan);
			setHasIncompatibleFields(!fieldTypes.every((_field) => TABLE_COMPATIBLE_FIELD_LIST.includes(_field)));
		} else {
			setMaxColumnspanBreached(false);
			setHasIncompatibleFields(false);
		}
	}, [fieldIds, fieldsByIdMap, fieldTableColumnspanReducer, reportBlock.isRepeating, reportBlock.repeatableBlockType]);

	const removeCompletionField = React.useCallback(() => {
		if (!completionFieldId) {
			return Object.values(fieldsByIdMap);
		}

		// Remove completion field from field map
		const newFieldsByIdMap = omitObject(fieldsByIdMap, [completionFieldId]);

		for (const _field of Object.values(fieldsByIdMap ?? {})) {
			if (_field.fieldType !== ReportBlockFieldEnums.Type.CALCULATED) {
				continue;
			}
			const optionIndex = _field.calculatedFieldOptions?.findIndex(_getFindCompletionField(completionFieldId)) ?? -1;
			if (optionIndex < 0) {
				continue;
			}

			const newOptionIds = _field.calculatedFieldOptions ? [..._field.calculatedFieldOptions] : [];
			newOptionIds.splice(optionIndex, 1);

			const _newField = { ..._field, calculatedFieldOptions: newOptionIds };

			newFieldsByIdMap[_newField.virtualId] = _newField;
		}

		setDisabled(false);
		setCalculableFields(fieldIds.map(
			(_id, _index) => ({ id: _id, constant: null, type: OperandType.FIELD, index: _index, isOperandBlockInPrimarySegment: null })
		));
		setCompletionFieldId(null);
		setHasIdField(fieldIds.some(_getIsIdFieldType(newFieldsByIdMap)));
		setFieldsByIdMap(newFieldsByIdMap);

		return Object.values(newFieldsByIdMap);
	}, [fieldIds, fieldsByIdMap, completionFieldId]);

	const addCompletionField = React.useCallback((form: ReportBlockFormModel) => {
		if (!form.completionFieldVirtualId) {
			throw new Error('Report block is missing both id and virtualId or form is missing completionFieldId');
		}

		// We can type assert since we checked with type guard
		const newCompletionField = initDefaultCompletionFieldFMValues(form.completionFieldVirtualId, reportBlock.virtualId);
		const newFieldsByIdMap = {
			...fieldsByIdMap,
			[form.completionFieldVirtualId]: newCompletionField,
		};

		const newCalculableFields: CalculatedFieldOptionFormModel[] = [...fieldIds, form.completionFieldVirtualId]
			.map((_id, _index) => ({ id: _id, constant: null, type: OperandType.FIELD, index: _index, isOperandBlockInPrimarySegment: null }));

		setDisabled(false);
		setFieldsByIdMap(newFieldsByIdMap);
		setCompletionFieldId(form.completionFieldVirtualId);
		setCalculableFields(newCalculableFields);

		return Object.values(newFieldsByIdMap);

	}, [reportBlock, fieldIds, fieldsByIdMap]);

	const updateFields = React.useCallback((form: ReportBlockFormModel) => {
		let _fields: ReportBlockFieldFormModel[] = [];

		if (!form.completionFieldVirtualId && completionFieldId) {
			// If completion status was removed
			_fields = removeCompletionField();
		} else if (form.completionFieldVirtualId && !completionFieldId) {
			// If completion status was added
			_fields = addCompletionField(form);
		} else {
			_fields = Object.values(fieldsByIdMap);
		}

		return _fields;
	}, [addCompletionField, completionFieldId, removeCompletionField, fieldsByIdMap]);

	const closeConfirmationModal = React.useCallback(() => {
		setConfirmationModal(null);
	}, []);

	const upsertField = React.useCallback((form: ReportBlockFieldFormModel, targetIndex: Nullable<number>) => {
		const fieldId = form.virtualId;
		const oldField = fieldsByIdMap[fieldId];

		form.reportBlockVirtualId = reportBlock.virtualId;

		// If oldField exists means we are doing update
		if (oldField) {
			// Replace existing value in field map
			const newFieldsByIdMap = {
				...fieldsByIdMap,
				[fieldId]: form,
			};

			// Case unit or field type has changed
			if (_hasBreakingChange(oldField, form)) {
				const calculationsWithRemovedOptions: string[] = [];

				// Iterate through all the fields
				for (const [_fieldId, _field] of Object.entries(newFieldsByIdMap)) {
					// if field is not calculated just skip
					if (_field.fieldType !== ReportBlockFieldEnums.Type.CALCULATED) {
						continue;
					}

					// Check if updated field is used in calculation
					const optionIndex = _field.calculatedFieldOptions?.findIndex((_option) => _option.id === form.virtualId) ?? -1;
					if (optionIndex < 0) {
						// If not used in calculation skip
						continue;
					}

					// Case operation uses units and the units are different
					if (oldField.unit !== form.unit && _field?.operationType && CalculationsWithUnit[_field.operationType] && _field.unit !== form.unit) {
						const calculatedFieldUnit = _field.unit;
						const newOperandUnit = form.unit;

						// Get all other operand with units different from the new field unit
						const operandsWithUnits = (_field.calculatedFieldOptions ?? []).filter(
							(_option) => {
								if (!_option.id || _option.id === form.virtualId) {
									return false;
								}
								const _operandField = fieldsByIdMap[_option.id];
								return _operandField?.unit && _operandField.unit !== newOperandUnit;
							}
						);

						if (newOperandUnit) {
							// If there are operands with units different from the new field unit remove the field from calculation
							if (!!operandsWithUnits?.length) {
								// Remove option from calc field options
								const newCalculatedFieldOptions = _field.calculatedFieldOptions?.filter((_option) => {
									return _option.id !== fieldId;
								});
								newFieldsByIdMap[_fieldId] = { ..._field, calculatedFieldOptions: newCalculatedFieldOptions ?? null };

								calculationsWithRemovedOptions.push(_field.name);
							} else {
								// set current calculated unit to updated field unit
								newFieldsByIdMap[_fieldId] = { ..._field, unit: newOperandUnit };
							}
						} else if (calculatedFieldUnit && !operandsWithUnits.length) {
							// Case when calculated field has unit and no other operands have units
							// Remove unit from calculated field
							newFieldsByIdMap[_fieldId] = { ..._field, unit: null };
						}
					} else if (_field.operationType && !OPERATION_TYPE_FILTER[_field.operationType](form)) {
						// Type of field has changed and is not complicit with calculation

						// Remove option from calc field options
						const newCalculatedFieldOptionIds = _field.calculatedFieldOptions?.filter((_option) => {
							return _option.id !== fieldId;
						});
						newFieldsByIdMap[_fieldId] = { ..._field, calculatedFieldOptions: newCalculatedFieldOptionIds ?? null };

						calculationsWithRemovedOptions.push(_field.name);
					}
				}

				if (!!calculationsWithRemovedOptions.length) {
					setConfirmationModal({
						showModal: true,
						confirmText: 'Close',
						modalStyle: 'warning',
						showCancel: false,
						hideOnConfirm: true,
						closeModal: closeConfirmationModal,
						confirmAction: null,
						body: _getRemovedCalculationModalBody(calculationsWithRemovedOptions),
						title: REMOVED_CALCULATION_OPERAND_TITLE,
					});
				}
			}

			// Get id
			setDisabled(false);
			setHasIdField(fieldIds.some(_getIsIdFieldType(fieldsByIdMap)));
			setFieldsByIdMap(newFieldsByIdMap);

		} else {
			// If old field does not exist, add new field
			const newFieldIds = [...fieldIds];
			// If no index, push new field at end, else splice field id array
			if (targetIndex === null) {
				newFieldIds.push(fieldId);
			} else {
				newFieldIds.splice(targetIndex, 0, fieldId);
			}

			const newFieldsByIdMap = { ...fieldsByIdMap };
			newFieldsByIdMap[fieldId] = form;

			const newCalculableFields: CalculatedFieldOptionFormModel[] = (completionFieldId ? [...newFieldIds, completionFieldId] : newFieldIds)
				.map((_id, _index) => ({ id: _id, constant: null, type: OperandType.FIELD, index: _index, isOperandBlockInPrimarySegment: null }));

			setDisabled(false);
			setFieldIds(newFieldIds);
			setFieldsByIdMap(newFieldsByIdMap);
			setCalculableFields(newCalculableFields);
			setHasIdField(newFieldIds.some(_getIsIdFieldType(fieldsByIdMap)));
		}
	}, [completionFieldId, fieldIds, reportBlock, fieldsByIdMap, closeConfirmationModal]);

	const removeField = React.useCallback((id: string) => {
		const newFieldIds = [...fieldIds];
		const newFieldsByIdMap = omitObject(fieldsByIdMap, [id]);

		const fieldIndex = fieldIds.findIndex((_id) => _id === id);
		if (fieldIndex < 0) {
			throw new Error('Field not found');
		}
		newFieldIds.splice(fieldIndex, 1);

		for (const _field of Object.values(newFieldsByIdMap)) {
			if (_field.fieldType !== ReportBlockFieldEnums.Type.CALCULATED) {
				continue;
			}

			const optionIndex = _field.calculatedFieldOptions?.findIndex((_option) => _option.id === id) ?? -1;
			if (optionIndex < 0) {
				continue;
			}

			// Create new options and remove the field
			const newOptionIds = _field.calculatedFieldOptions ? [..._field.calculatedFieldOptions] : [];
			newOptionIds.splice(optionIndex, 1);

			// Reindex calculation options and return
			newFieldsByIdMap[_field.virtualId] = { ..._field, calculatedFieldOptions: newOptionIds };
		}

		const newCalculableFields: CalculatedFieldOptionFormModel[] = (completionFieldId ? [...newFieldIds, completionFieldId] : newFieldIds)
			.map((_id, _index) => ({ id: _id, constant: null, type: OperandType.FIELD, index: _index, isOperandBlockInPrimarySegment: null }));

		setDisabled(false);
		setFieldIds(newFieldIds);
		setCalculableFields(newCalculableFields);
		setHasIdField(newFieldIds.some(_getIsIdFieldType(fieldsByIdMap)));
		setFieldsByIdMap(newFieldsByIdMap);

	}, [completionFieldId, fieldIds, fieldsByIdMap]);

	const onDragEnd = React.useCallback(({ draggableId, source, destination }: DropResult) => {
		if (!destination) {
			return;
		}

		const { droppableId: sourceDroppableId, index: sourceIndex } = source;
		const { droppableId: destinationDroppableId, index: droppingIndex } = destination;

		const isSameDroppableZone = sourceDroppableId === BLOCK_FIELD_LIST_ID && destinationDroppableId === BLOCK_FIELD_LIST_ID;
		const isSameDroppableIndex = sourceIndex === dropIndex;

		if (sourceDroppableId === DEFAULT_FIELD_LIST_ID && destinationDroppableId === BLOCK_FIELD_LIST_ID) {
			// Opens create new report field modal
			setDropIndex(droppingIndex);
			setDropFieldType(ReportBlockFieldEnums.Type[draggableId]);
			setShowReportBlockFieldModal(true);
		} else if (isSameDroppableZone && !isSameDroppableIndex && (!!droppingIndex || droppingIndex === 0)) {
			// Reorder fields
			const _fieldId = fieldIds[sourceIndex];

			const originalIndex = fieldIds.findIndex((_id) => _id === _fieldId);
			if (originalIndex < 0) {
				throw new Error('Cannot find field');
			}

			const newFieldIds = [...fieldIds];
			newFieldIds.splice(originalIndex, 1);
			newFieldIds.splice(droppingIndex, 0, _fieldId);

			setFieldIds(newFieldIds);
		}
	}, [fieldIds, dropIndex]);

	const openSaveAsReportBlockModal = React.useCallback(() => {
		setShowSaveAsReportBlockModal(true);
	}, []);

	const closeSaveAsReportBlockModal = React.useCallback(() => {
		setShowSaveAsReportBlockModal(false);
	}, []);

	const openReportBlockModal = React.useCallback(() => {
		setShowReportBlockModal(true);
	}, []);

	const closeReportBlockModal = React.useCallback(() => {
		setShowReportBlockModal(false);
	}, []);

	const uploadFields = React.useCallback(async () => {
		const ids = completionFieldId
			? [...fieldIds, completionFieldId]
			: fieldIds;
		return await Promise.all(ids.map(async (_fieldId) => {
			const _field = fieldsByIdMap[_fieldId];
			if (ReportBlockFieldEnums.ImmutableFields[_field?.fieldType]) {
				if (_field.imageFile) {
					const image = await uploadImage({ imageUrl: _field.imageFile });
					_field.defaultValue = image.imageUrl;
				}
				return _field;
			}
			return _field;
		}));
	}, [fieldIds, completionFieldId, uploadImage, fieldsByIdMap]);

	const save = React.useCallback(async () => {
		setIsSaving(true);

		const newFields = await uploadFields();
		onSubmit(ReportBlockFieldFormModel.bulkToReportBlockRM(newFields));
		setIsSaving(false);
	}, [onSubmit, uploadFields]);

	const editReportBlock = React.useCallback(async (form: ReportBlockFormModel) => {
		const newFields = updateFields(form);
		setDisabled(false);
		onEditBlock(ReportBlockFormModel.toRequestModel(form), ReportBlockFieldFormModel.bulkToReportBlockRM(newFields));
	}, [onEditBlock, updateFields]);

	const saveAs = React.useCallback(async (form: ReportBlockFormModel) => {
		const newFields = updateFields(form);
		await editReportBlock(form);
		onSaveAs?.(form, ReportBlockFieldFormModel.bulkToReportBlockRM(newFields));
	}, [editReportBlock, onSaveAs, updateFields]);

	const openReportBlockFieldModal = React.useCallback((id: Nullable<string>) => {
		setEditFieldId(id);
		setShowReportBlockFieldModal(true);
	}, []);

	const closeReportBlockFieldModal = React.useCallback(() => {
		setShowReportBlockFieldModal(false);
		setDropFieldType(null);
		setDropIndex(null);
		setEditFieldId(null);
	}, []);

	const onPreviewToggle = React.useCallback(() => {
		setIsPreviewInFocus((prev) => !prev);
	}, []);

	const getReportBlockModalProps = React.useCallback(() => {
		return {
			closeModal: showReportBlockModal ? closeReportBlockModal : closeSaveAsReportBlockModal,
			onSubmit: showReportBlockModal ? editReportBlock : saveAs,
			title: _getTitle(showReportBlockModal, reportBlock?.id ?? null),
			showModal: showReportBlockModal || showSaveAsReportBlockModal,
			disableBlockTypeDropdown: showReportBlockModal,
			fieldIds: fieldIds,
			fieldsByIdMap,
		};
	}, [
		closeReportBlockModal,
		closeSaveAsReportBlockModal,
		editReportBlock,
		reportBlock?.id,
		saveAs,
		showReportBlockModal,
		showSaveAsReportBlockModal,
		fieldIds,
		fieldsByIdMap,
	]);

	const openUpdateReportBlockConfirmationModal = React.useCallback(() => {
		setConfirmationModal(
			{
				showModal: true,
				size: 'md',
				modalStyle: 'warning',
				hideOnConfirm: false,
				closeModal: closeConfirmationModal,
				confirmAction: save,
				body: _getConfirmationModalBody(reportBlock.name, reportTypes ?? []),
				title: _getConfirmationModalTitle(reportBlock.name),
			}
		);
	}, [closeConfirmationModal, reportBlock, reportTypes, save]);

	const renderHeader = React.useCallback(() => {

		const onSave = !!reportBlock.id
			? openUpdateReportBlockConfirmationModal
			: save;

		return (
			<Header
				disabled={disabled}
				hasIncompatibleFields={hasIncompatibleFields}
				isPreviewInFocus={isPreviewInFocus}
				isPrimaryBlock={reportBlock.isMain}
				maxColumnspanBreached={maxColumnspanBreached}
				onEditClick={openReportBlockModal}
				onPreviewClick={onPreviewToggle}
				onSaveAsClick={openSaveAsReportBlockModal}
				onSaveClick={onSave}
				reportBlockName={reportBlock.name}
				showPreviewToggle={showPreviewToggle}
			/>
		);
	}, [
		disabled,
		isPreviewInFocus,
		onPreviewToggle,
		openReportBlockModal,
		openSaveAsReportBlockModal,
		openUpdateReportBlockConfirmationModal,
		reportBlock.id,
		reportBlock.isMain,
		reportBlock.name,
		save,
		showPreviewToggle,
		maxColumnspanBreached,
		hasIncompatibleFields,
	]);

	const renderBlockInfo = React.useCallback(() => {
		return (
			<Col sm={DISPLAY_TYPE_COL_SIZE[displayType].BLOCK_INFO}>
				<div className="form-box">
					{renderHeader()}
					<BlockInfo
						fieldIds={fieldIds}
						fieldsByIdMap={fieldsByIdMap}
						hasIncompatibleFields={hasIncompatibleFields}
						isDraggable={true}
						maxColumnspanBreached={maxColumnspanBreached}
						openFieldModal={openReportBlockFieldModal}
						removeField={removeField}
						upsertField={upsertField}
					/>
				</div>
			</Col>
		);
	}, [fieldIds, displayType, openReportBlockFieldModal, removeField, renderHeader, upsertField, maxColumnspanBreached, hasIncompatibleFields, fieldsByIdMap]);

	const renderPreview = React.useCallback(() => {
		return (
			<Col className="report-block-form__sticky-sidebar report-block-form__preview" sm={DISPLAY_TYPE_COL_SIZE[displayType].PREVIEW}>
				{showPreviewToggle && renderHeader()}
				<Preview
					completionFieldId={completionFieldId}
					fieldIds={fieldIds}
					fieldsByIdMap={fieldsByIdMap}
					hasCompletionStatus={hasCompletionStatus}
					id={reportBlock.virtualId}
					isMain={isMain}
					isRepeating={isRepeating}
					name={name}
					reportBlockByIdMap={reportBlockByIdMap}
				/>
			</Col>
		);
	}, [
		completionFieldId,
		fieldIds,
		fieldsByIdMap,
		reportBlockByIdMap,
		displayType,
		hasCompletionStatus,
		isMain,
		isRepeating,
		name,
		renderHeader,
		reportBlock,
		showPreviewToggle,
	]);

	const fieldInitialState = React.useMemo(() => {
		if (dropFieldType) {
			return _getInitialFieldState(fieldIds, fieldsByIdMap, dropFieldType, editFieldId, dropIndex ?? undefined);
		} else {
			return _getInitialFieldState(fieldIds, fieldsByIdMap, dropFieldType, editFieldId, dropIndex ?? undefined);
		}
	}, [fieldIds, fieldsByIdMap, dropFieldType, editFieldId, dropIndex]);

	const hideIdField = React.useMemo(() => {
		return hasIdField && fieldInitialState?.fieldType !== ReportBlockFieldEnums.Type.ID;
	}, [fieldInitialState?.fieldType, hasIdField]);

	const showFieldList = React.useMemo(() => !!DISPLAY_TYPE_COL_SIZE[displayType].FIELD_LIST, [displayType]);

	const showBlockInfo = React.useMemo(() => {
		return !!DISPLAY_TYPE_COL_SIZE[displayType].BLOCK_INFO && (!isPreviewInFocus || !showPreviewToggle);
	}, [displayType, isPreviewInFocus, showPreviewToggle]);

	const showPreview = React.useMemo(() => {
		return !!DISPLAY_TYPE_COL_SIZE[displayType].PREVIEW && (isPreviewInFocus || !showPreviewToggle);
	}, [displayType, isPreviewInFocus, showPreviewToggle]);

	if (loading || !fieldIds || isSaving) {
		return <Loading />;
	}

	return (
		<DragDropContext onDragEnd={onDragEnd} >
			<Row className="row--non-padded">
				{showFieldList &&
					<Col className="report-block-form__sticky-sidebar" sm={DISPLAY_TYPE_COL_SIZE[displayType].FIELD_LIST}>
						<FieldList
							hideIdField={hasIdField || !reportBlock.isMain}
							repeatableBlockType={reportBlock.repeatableBlockType}
						/>
					</Col>
				}
				{showBlockInfo && renderBlockInfo()}
				{showPreview && renderPreview()}
			</Row>
			<ReportBlockModal
				firstInitialValues={reportBlock}
				{...getReportBlockModalProps()}
			/>
			<CreateReportBlockFieldModal
				closeModal={closeReportBlockFieldModal}
				fields={calculableFields}
				fieldsByIdMap={fieldsByIdMap}
				hideIdField={hideIdField}
				initialValues={fieldInitialState}
				isPrimaryBlock={reportBlock.isMain}
				onSubmit={upsertField}
				showModal={showReportBlockFieldModal}
			/>
			{confirmationModal &&
				<ConfirmationModal
					{...confirmationModal}
				/>
			}
		</DragDropContext>
	);
};

function mapDispatchToProps() {
	return {
		uploadImage: ReportBlockActions.uploadReportBlockFieldImage,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(ReportBlockForm);
