import * as React from 'react';
import { connect } from 'react-redux';

import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';
import type NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import type { RootState } from 'af-reducers';

import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';
import type { EmployeeVM } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import OrderInfo from '../OrderInfo';
import ResourceDroppable from '../ResourceDroppable';

import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import type SharedModalProps from '../../Shared/ModalProps';

interface OwnProps extends SharedModalProps {
	// Card OwnProps
	/** `MM-DD-YYYY` */
	dueDate: string;
	hasPermissionsToEditScheduleBoard: boolean;
	ignorePlaceholders?: boolean;
	isCalculationsView?: boolean;
	isDragAndDropDisabled: boolean;
	maxResourceItems: number;
	workOrderCode: string;
	/** prop not used if `isCalculationsView = true` */
	lastOpenedOrderCode?: string;
	// Card StateProps
	isCardDisabled: boolean;
	notificationStatus: Nullable<NotificationStatusEnum>;
	// CardData specific props
	isCopyPlaceholder?: boolean;
	isDragging: boolean;
}

interface StateProps {
	hasProjectManagerDuplicateLastName: boolean;
	hasSuperintendentDuplicateLastName: boolean;
	isShowNotesActive: boolean;
	resourceIds: number[];
	workOrder: ScheduleBoardWorkOrderViewModel;
	projectCode: Nullable<string>;
}

type Props = OwnProps & StateProps;

class CardData extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		workOrder: {} as ScheduleBoardWorkOrderViewModel,
		resourceIds: [],
		isCopyPlaceholder: false,
	};

	static getEmployeeLabel = (employee: Nullable<EmployeeVM>, isDuplicate: boolean) => {
		if (!employee) {
			return 'N/A';
		}
		const { firstName, lastName } = employee;
		return `${firstName[0]}${isDuplicate ? firstName[1] : ''} ${lastName}`;
	};

	openWorkOrderNoteModal = () => {
		const { setWorkOrderNoteModalData, workOrder } = this.props;
		if (
			workOrder.status !== WorkOrderStatusEnum.LOCKED
			&& !workOrder.isPaused
			&& workOrder.status !== WorkOrderStatusEnum.CANCELED
		) {
			setWorkOrderNoteModalData(workOrder.code, workOrder.dueDate);
		}
	};

	render() {
		const {
			dueDate,
			hasPermissionsToEditScheduleBoard,
			hasProjectManagerDuplicateLastName,
			hasSuperintendentDuplicateLastName,
			ignorePlaceholders,
			isCalculationsView,
			isCardDisabled,
			isCopyPlaceholder,
			isDragAndDropDisabled,
			isDragging,
			isShowNotesActive,
			lastOpenedOrderCode,
			maxResourceItems,
			notificationStatus,
			resourceIds,
			workOrder,
			workOrderCode,
			setWorkOrderModalId,
			setEmployeeModalData,
			setEquipmentModalData,
			setTemporaryEmployeeModalData,
			setEmployeeModalVisibility,
			setEquipmentModalVisibility,
			currentWorkOrderModalId,
			projectCode,
		} = this.props;

		const supervisorName = CardData.getEmployeeLabel(workOrder.supervisor, hasSuperintendentDuplicateLastName);
		const projectManagerName = CardData.getEmployeeLabel(workOrder.projectManager, hasProjectManagerDuplicateLastName);
		const shouldShowNotes = isShowNotesActive && !isCalculationsView;
		const isWorkOrderCanceled: boolean = workOrder.status === WorkOrderStatusEnum.CANCELED;
		const droppableId = ScheduleBoardUtil.generateDroppableId(ScheduleBoardContext.BOARD, ScheduleBoardProperty.RESOURCE, dueDate, workOrder.code);

		const isResourcesContainerReadOnly = isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard;
		const resourcesContainerClassName = `schedule-board-card-resources ${isResourcesContainerReadOnly ? `items-${maxResourceItems}` : ''}`;
		const dragAndDropDisabled = isDragAndDropDisabled || workOrder.isPaused;

		const className = projectCode && process.env.FTD_PROJECT !== 'true' ? 'project' : '';

		return (
			<div className={className}>
				<OrderInfo
					currentWorkOrderModalId={currentWorkOrderModalId}
					dueDate={dueDate}
					isCalculationsView={!!isCalculationsView}
					isDragging={isDragging}
					lastOpenedOrderCode={lastOpenedOrderCode}
					notificationStatus={notificationStatus}
					projectManagerName={projectManagerName}
					setWorkOrderModalId={setWorkOrderModalId}
					supervisorName={supervisorName}
					workOrder={workOrder}
				/>
				<div className={resourcesContainerClassName}>
					<ResourceDroppable
						draggablePrefix={ScheduleBoardContext.BOARD}
						droppableId={droppableId}
						dueDate={dueDate}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						ignorePlaceholders={!!ignorePlaceholders}
						isCalculationsView={!!isCalculationsView}
						isCardDisabled={isCardDisabled}
						isCopyPlaceholder={isCopyPlaceholder}
						isDragAndDropDisabled={dragAndDropDisabled}
						isWOLocked={workOrder.status === WorkOrderStatusEnum.LOCKED}
						isWorkOrderCanceled={isWorkOrderCanceled}
						resourceIds={resourceIds}
						setEmployeeModalData={setEmployeeModalData}
						setEmployeeModalVisibility={setEmployeeModalVisibility}
						setEquipmentModalData={setEquipmentModalData}
						setEquipmentModalVisibility={setEquipmentModalVisibility}
						setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
						workOrderCode={workOrderCode}
						wrappedColumns={true}
					/>
				</div>
				{shouldShowNotes &&
					<div className="schedule-board-card-notes" onClick={this.openWorkOrderNoteModal}>
						{
							workOrder.workOrderNotes ??
							<div className="schedule-board-card-add-note">+ Add a Note</div>
						}
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { dueDate, workOrderCode } = ownProps;
	const {
		employees,
		isShowNotesActive,
		workOrdersByDateDictionary,
	} = state.scheduleBoard;
	const workOrdersOnDateDict: ScheduleBoardOnDateViewModel = workOrdersByDateDictionary[dueDate];
	const workOrder: ScheduleBoardWorkOrderViewModel = workOrdersOnDateDict?.workOrders?.[workOrderCode];
	const employeesDict = employees ?? {};

	return {
		hasProjectManagerDuplicateLastName: !!(workOrder?.projectManagerId && employeesDict[workOrder?.projectManagerId]?.duplicateDisplayNameExists) || false,
		hasSuperintendentDuplicateLastName: !!(workOrder?.supervisorId && employeesDict[workOrder?.supervisorId]?.duplicateDisplayNameExists) || false,
		isShowNotesActive: isShowNotesActive,
		resourceIds: workOrder?.workOrderResourceLookups ?? undefined,
		workOrder,
		projectCode: workOrder?.projectJobCode ?? null,
	};
}

export default connect<StateProps, OwnProps>(mapStateToProps)(CardData);
