import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { CellContext } from '@tanstack/react-table';
import type { CustomRouteComponentProps } from 'react-router-dom';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import { QuantityUnitMap } from 'acceligent-shared/enums/quantityUnit';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as AccountingActions from 'af-actions/accounting';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import Breadcrumbs from 'af-components/Breadcrumbs';
import DateFilter from 'af-components/DateFilter';
import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import type { TableProps } from 'af-components/Table/types';
import TextCell from 'af-components/Table/Cells/TextCell';
import TabNavigation from 'af-components/TabNavigation';
import LinkCell from 'af-components/Table/Cells/LinkCell';
import DollarCell from 'af-components/Table/Cells/DollarCell';
import Tooltip from 'af-components/Tooltip';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import { downloadCSV } from 'af-utils/csv.utils';
import * as SettingsUtils from 'af-utils/settings.util';
import { getFullClientUrl } from 'af-utils/http.util';
import { convertUnits } from 'ab-utils/unitConversion.util';

import type { RootState } from 'af-reducers';

import { CompanyWorkSummaryVM } from 'ab-viewModels/accounting/workSummaryTable.viewModel';

import * as SettingsKeys from 'af-constants/settingsKeys';

import CLIENT from 'af-routes/client';

import styles from './styles.module.scss';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

type Props = ConnectedProps<typeof connector> & SettingProps & CustomRouteComponentProps;

const DETAILED_VIEW_TAB = { ID: 1, LABEL: 'DETAILED' };
const CONDENSED_VIEW_TAB = { ID: 2, LABEL: 'CONDENSED' };

const TABS = [
	{ id: DETAILED_VIEW_TAB.ID, label: DETAILED_VIEW_TAB.LABEL },
	{ id: CONDENSED_VIEW_TAB.ID, label: CONDENSED_VIEW_TAB.LABEL },
];

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormat.FULL_DATE);

};

const BREADCRUMBS = [{ label: 'Work Summary' }];

const JobWorkSummary: React.FC<Props> = (props) => {

	const {
		companyName,
		fetchTable,
		getWorkSummaryTableCSV,
		location,
	} = props;

	const tableRef = React.useRef<TableRef<CompanyWorkSummaryVM>>(null);
	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(props.period);
	const [activeTab, setActiveTab] = React.useState<number>(1);

	const onExportWorkSummaryClick = React.useCallback(async () => {
		const result = await getWorkSummaryTableCSV(
			TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY),
			TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY)
		);
		const csvData = CompanyWorkSummaryVM.toCSVData(result, activeTab === DETAILED_VIEW_TAB.ID);
		downloadCSV(csvData, `${companyName}_work_summary_${activeTab === DETAILED_VIEW_TAB.ID ? 'detailed' : 'condensed'}.csv`);
	}, [getWorkSummaryTableCSV, startDate, endDate, activeTab, companyName]);

	const buttons = React.useMemo(() => [
		{ label: 'Export Work Summary', icon: 'icon-download', type: TableButtonType.LINK, hasPermission: true, onClick: onExportWorkSummaryClick },
	], [onExportWorkSummaryClick]);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
	}, []);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected, _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
	}, []);

	const renderAdditionalFilter = React.useCallback(() => {
		return (
			<DateFilter
				changePeriod={changePeriod}
				endDate={endDate}
				onChange={filterByDate}
				period={period}
				startDate={startDate}
			/>
		);
	}, [changePeriod, endDate, filterByDate, period, startDate]);

	const renderTextCell = React.useCallback(() => (cell: CellContext<CompanyWorkSummaryVM, string>) => {
		return <TextCell value={cell.getValue()} />;
	}, []);

	const renderDefinitionField = React.useCallback(() => (cell: CellContext<CompanyWorkSummaryVM, string>) => {
		const className: string[] = [];
		return (
			<TextCell className={className.join('')} value={cell.renderValue() ?? ''} />
		);
	}, []);

	const renderUnitPriceCell = React.useCallback(() => (cell) => {
		const _value = cell.getValue();

		if (!_value) {
			return null;
		}

		const _billingUnit = QuantityUnitMap[cell.row.original.selectedBillingCode?.unit?.replace('/', '_')];
		const _unit = QuantityUnitMap[cell.row.original.unit?.replace('/', '_')];

		const convertedUnitPrice = _unit !== _billingUnit ? convertUnits(_value, QuantityUnitMap[_unit], QuantityUnitMap[_billingUnit]) : null;

		const tooltipMessage = convertedUnitPrice
			? <>
				<div>Quantity tracked in {_unit?.replace('_', '/')}</div>
				<div>Billing code in {_billingUnit?.replace('_', '/')}</div>
				</>
			: null;

		const _renderValue = convertedUnitPrice
			? `$${parseFloat(convertedUnitPrice.toFixed(8))} per ${_unit ? _unit?.replace('_', '/') : 'N/A'} ($${parseFloat(_value.toFixed(4))} per ${_billingUnit?.replace('_', '/')})`
			: `$${parseFloat(_value.toFixed(4))} per ${_unit ? _unit?.replace('_', '/') : 'N/A'}`;

		const textCell = <TextCell value={_renderValue} />;
		return (
			tooltipMessage ? <Tooltip message={tooltipMessage}>{textCell}</Tooltip> : textCell

		);
	}, []);

	const renderPriceCell = React.useCallback((isUnitPrice: boolean) => (cell: CellContext<CompanyWorkSummaryVM, number>) => {
		if (!cell.getValue()) {
			return null;
		}

		return (
			<DollarCell isUnitPrice={isUnitPrice} value={+cell.getValue()}/>
		);
	}, []);

	const renderLinkCell = React.useCallback(() =>
		(_cell: CellContext<CompanyWorkSummaryVM, unknown>) => {
			const { state: { orgAlias } } = location;

			if (!_cell.cell.row.original.workOrderId) {
				return (
					<span className={styles['job-work-summary__empty-cell']}>
						N/A
					</span>
				);
			}

			const path = _cell.row.original.workOrder
				? getFullClientUrl(orgAlias, CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(`${_cell.row.original.workOrderId}`, orgAlias, companyName))
				: '';

			return <LinkCell label={_cell.row.original.workOrder!} path={path} />;
		}, [companyName, location]);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableContent<CompanyWorkSummaryVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;
		const result = await fetchTable(
			new TableQuery({ page, pageSize, sortBy, filterByText }),
			TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY),
			TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY)
		);
		return result;
	}, [fetchTable, startDate, endDate]);

	const onTabChange = React.useCallback((id: number) => setActiveTab(id), []);

	const columns: TableProps<CompanyWorkSummaryVM>['columns'] = React.useMemo(() => [
		{
			id: 'jobId',
			accessor: 'workRequest',
			header: 'Job',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'workOrder',
			accessor: 'workOrder',
			header: 'Work Order',
			cell: renderLinkCell(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'startDate',
			accessor: 'startDate',
			header: 'Date',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'quantity',
			accessor: 'quantity',
			header: 'Quantity',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'description',
			accessor: 'description',
			header: 'Description',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'billingCode',
			accessor: 'billingCode',
			header: 'Billing Code',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'unit',
			accessor: 'unit',
			header: 'Unit',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'unitPrice',
			accessor: 'unitPrice',
			header: 'Unit Price ($)',
			cell: renderUnitPriceCell(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'revenue',
			accessor: 'revenue',
			header: 'Revenue ($)',
			cell: renderPriceCell(false),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'work',
			accessor: 'work',
			header: 'Work',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			isHidden: activeTab === CONDENSED_VIEW_TAB.ID,
		},
		{
			id: 'type',
			accessor: 'type',
			header: 'Type',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			isHidden: activeTab === CONDENSED_VIEW_TAB.ID,
		},
		{
			id: 'definition1',
			accessor: 'definition1',
			header: 'Definition 1',
			cell: renderDefinitionField(),
			enableSorting: true,
			autoHideEmptyColumn: true,
			enableHiding: true,
			isHidden: activeTab === CONDENSED_VIEW_TAB.ID,
		},
		{
			id: 'definition2',
			accessor: 'definition2',
			header: 'Definition 2',
			cell: renderDefinitionField(),
			enableSorting: true,
			autoHideEmptyColumn: true,
			enableHiding: true,
			isHidden: activeTab === CONDENSED_VIEW_TAB.ID,
		},
		{
			id: 'definition3',
			accessor: 'definition3',
			header: 'Definition 3',
			cell: renderDefinitionField(),
			enableSorting: true,
			autoHideEmptyColumn: true,
			enableHiding: true,
			isHidden: activeTab === CONDENSED_VIEW_TAB.ID,
		},
		{
			id: 'definition4',
			accessor: 'definition4',
			header: 'Definition 4',
			cell: renderDefinitionField(),
			enableSorting: true,
			autoHideEmptyColumn: true,
			enableHiding: true,
			isHidden: activeTab === CONDENSED_VIEW_TAB.ID,
		},

	], [renderTextCell, renderLinkCell, renderPriceCell, renderUnitPriceCell, activeTab, renderDefinitionField]);

	return (
		<div className="form-segment form-segment--maxi">
			<Breadcrumbs items={BREADCRUMBS} />
			<TabNavigation
				active={activeTab}
				onClick={onTabChange}
				tabs={TABS}
			/>
			<TableNew
				additionalFilter={renderAdditionalFilter}
				buttons={buttons}
				columns={columns}
				fetch={fetchRows}
				hasSearchInput={true}
				hasSubRows={false}
				ref={tableRef}
				selectable={false}
				tableName={TableNameEnum.JOB_WORK_SUMMARY}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		getWorkSummaryTableCSV: AccountingActions.findCompanyWorkSummaryTableCSV,
		fetchTable: AccountingActions.findCompanyWorkSummaryTable,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.JOB_WORK_SUMMARY_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.JOB_WORK_SUMMARY_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.JOB_WORK_SUMMARY_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(JobWorkSummary);
