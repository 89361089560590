/* eslint-disable @typescript-eslint/no-explicit-any */

import { SubmissionError } from 'redux-form';
import type { Dispatch } from 'redux';
import * as qs from 'query-string';

import type UserPermission from 'acceligent-shared/enums/userPermission';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import { getCurrentOrgAlias } from 'af-utils/window.util';
import type { HttpError } from 'af-utils/http.util';

import { isAllowed } from 'ab-utils/auth.util';

import CLIENT from 'af-constants/routes/client';
import { SEARCH_DELAY } from 'ab-common/constants/value';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { _logoutUser } from 'af-actions/authentication/authentication.actions';

export interface ErrorOverride<T = any> {
	err400?: (error) => T;
	err401?: (error) => T;
	err403?: (error) => T;
	err404?: (error) => T;
	err409?: (error) => T;
	err423?: (error) => T;
	err500?: (error) => T;
	err503?: (error) => T;
	default?: (error) => T;
}

export interface ResponseDetails<T> {
	success: boolean;
	details?: T;
}

const _callDefault = (error, override: ErrorOverride = {}): void => {
	if (override.default) {
		override.default(error);
	}
};

const _isHttpError = (error): error is HttpError => {
	return !!error.response;
};

async function _errorHandler<T>(
	action: () => Promise<T>,
	dispatch: Dispatch<any>,
	redirectTo: (url: string) => void,
	override: ErrorOverride = {}
): Promise<T | undefined> {
	try {
		return await action();
	} catch (error) {
		if (!_isHttpError(error)) {
			console.error(error);
			return;	// the logic below will only break when doing `error.response.status`
		}

		const orgAlias = getCurrentOrgAlias();

		if (!orgAlias || !error?.response?.status) {
			console.error('[ERROR HANDLER] Organization alias not provided');
			return;
		}

		switch (error.response.status) {
			case 400:
				if (override.err400) {
					return override.err400(error);
				} else {
					_callDefault(error, override);
					throw new SubmissionError(error.response.data!);
				}
				break;
			case 401:
				if (override.err401) {
					return override.err401(error);
				} else {
					_callDefault(error, override);
					_logoutUser(orgAlias, dispatch);
					redirectTo(CLIENT.AUTH.LOGIN(orgAlias));
					return;
				}
				break;
			case 403:
				if (override.err403) {
					return override.err403(error);
				} else {
					_callDefault(error, override);
					redirectTo(CLIENT.ERROR.ERR403(orgAlias));
					return;
				}
				break;
			case 404:
				if (override.err404) {
					return override.err404(error);
				} else {
					_callDefault(error, override);
					redirectTo(CLIENT.ERROR.ERR404(orgAlias));
					return;
				}
				break;
			case 409:
				if (override.err409) {
					return override.err409(error);
				} else {
					_callDefault(error, override);
					throw new SubmissionError(error.response.data!);
				}
				break;
			case 423:
				if (override.err423) {
					return override.err423(error);
				} else {
					_callDefault(error, override);
					throw new SubmissionError(error.response.data!);
				}
				break;
			case 500:
				if (override.err500) {
					return override.err500(error);
				} else {
					_callDefault(error, override);
					redirectTo(CLIENT.ERROR.ERR500(orgAlias));
					return;
				}
				break;
			case 503:
				if (override.err503) {
					return override.err503(error);
				} else {
					// TODO: Make 503 page
					_callDefault(error, override);
					redirectTo(CLIENT.ERROR.ERR500(orgAlias));
					return;
				}
				break;
			default:
				break;
		}
	}
}

/**
 * TODO: check which functions expect a result and which do not, and split this function into 2 versions
 * First version should never consider error function returning a value (only throw an error), so that if the call was
 * accurate, we should never bother ourselves with erroneous responses
 *
 * Second version should cover the case where error response sends an actual value which we are going to use.
 *
 * Current version is a mixture of both, but we're typing it as "yeah we have a response for you" because we're brave
 */
export function errorHandler<T>(
	action: () => Promise<T>,
	dispatch: Dispatch<any>,
	redirectTo: (url: string) => void,
	override: ErrorOverride = {}
): Promise<T> {
	return _errorHandler(action, dispatch, redirectTo, override) as Promise<T>;
}

/**
 * A function that emits a side effect and does not return anything.
 */
type Procedure = (...args: any[]) => void;
type Options = {
	isImmediate: boolean;
};
export function debounce<F extends Procedure>(
	func: F,
	waitMilliseconds: number = SEARCH_DELAY,
	options: Options = { isImmediate: false }
): F {
	let timeoutId: NodeJS.Timeout | undefined;
	return function (this: any, ...args: any[]) {

		const doLater = () => {
			timeoutId = undefined;
			if (!options.isImmediate) {
				func.apply(this, args);
			}
		};

		const shouldCallNow = options.isImmediate && timeoutId === undefined;

		if (timeoutId !== undefined) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(doLater, waitMilliseconds);

		if (shouldCallNow) {
			func.apply(this, args);
		}
	} as any;
}

export const defaultRedirectUrlStrict = (
	orgAlias: string,
	companyName: string,
	companyPermissions: string[],
	isCompanyAdmin: boolean,
	role: UserPermission
): Nullable<string> => {
	if (isLmsLogin(window.location.search)) {
		return CLIENT.LMS(orgAlias);
	}
	if (
		!DisabledFeatures.Dashboard &&
		isAllowed(PagePermissions.COMPANY.DASHBOARD, companyPermissions, isCompanyAdmin, role)
	) {
		return CLIENT.COMPANY.DASHBOARD.ROOT(orgAlias, companyName);
	} else if (isAllowed(PagePermissions.COMPANY.JOBS, companyPermissions, isCompanyAdmin, role)) {
		return CLIENT.COMPANY.JOBS.ROOT(orgAlias, companyName);
	} else if (
		isAllowed(PagePermissions.COMPANY.WORK_ORDERS.SCHEDULE_BOARD, companyPermissions, isCompanyAdmin, role)
	) {
		return CLIENT.COMPANY.SCHEDULE_BOARD.ROOT(orgAlias, companyName);
	} else if (isAllowed(PagePermissions.COMPANY.WORK_ORDERS, companyPermissions, isCompanyAdmin, role)) {
		return CLIENT.COMPANY.WORK_ORDERS.ROOT(orgAlias, companyName);
	} else if (isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE, companyPermissions, isCompanyAdmin, role)) {
		return CLIENT.COMPANY.FIELD_REPORT.ROOT(orgAlias, companyName);
	} else {
		return null;
	}
};

export const defaultRedirectUrl = (
	orgAlias: string,
	companyName: string,
	companyPermissions: string[],
	isCompanyAdmin: boolean,
	role: UserPermission
): string => {
	return (
		defaultRedirectUrlStrict(orgAlias, companyName, companyPermissions, isCompanyAdmin, role) ?? CLIENT.COMPANY.SETTINGS.ACCOUNT(orgAlias, companyName)
	);
};

/** LMS is NOT a typo of SMS - it stands for Learning Management System */
export const isLmsLogin = (search: string) => {
	const params = qs.parse(search) as { lms: string; };
	return 'lms' in params;
};

export const isLmsExternalLogin = (search: string) => {
	const params = qs.parse(search) as { lmsExternal: string; };
	return 'lmsExternal' in params;
};
