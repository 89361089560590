import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import type QuantityUnitType from 'acceligent-shared/enums/quantityUnit';

import { moneyNormalizer } from 'ab-utils/formatting.util';
import { bemElement } from 'ab-utils/bem.util';

import type JobUpsertFM from '../../../../formModel';

import type { CustomFormErrors } from './BillingCodeArray';

type BillingCodeFM = JobUpsertFM['billingCodes'][0];

interface BillingCodeModel {
	id: number;
	lineItemNumber: number;
	customerNumber: Nullable<number>;
	customerId: string;
	ownerNumber: Nullable<string>;
	ownerId: Nullable<string>;
	unit: QuantityUnitType;
	unitPrice: string;
	bidQuantity: Nullable<string>;
	group: Nullable<string>;
	description: string;
}

interface OwnProps {
	billingCode: BillingCodeModel;
	index: number;
	showActions?: boolean;
	onRemove?: (index: number) => void;
	changeEditable?: (index: number, editable: boolean) => void;
	hasDuplicateLineItemNumber?: boolean;
	hasDuplicateCustomerId?: boolean;
	errors?: CustomFormErrors<BillingCodeFM>;
}

type Props = OwnProps;

const BillingCodePreview: React.FC<Props> = React.memo((props) => {
	const {
		billingCode,
		index,
		onRemove,
		changeEditable,
		showActions = true,
		hasDuplicateLineItemNumber = false,
		hasDuplicateCustomerId = false,
		errors,
	} = props;

	const billingCodeInvalid = errors && Object.keys(errors).length > 0;

	const remove = React.useCallback(() => {
		onRemove?.(index);
	}, [onRemove, index]);

	const setEditable = React.useCallback(() => {
		changeEditable?.(index, true);
	}, [changeEditable, index]);

	const billingCodeRowClassName = bemElement('job-upsert__billing-jobs__list__item', 'preview', {
		invalid: hasDuplicateLineItemNumber || hasDuplicateCustomerId || !!billingCodeInvalid,
	});

	const billingCodeFieldClassName = bemElement('job-upsert__billing-jobs__list__item__preview', 'field');

	const lineItemNumberFieldClassName = bemElement('job-upsert__billing-jobs__list__item__preview', 'field', {
		invalid: hasDuplicateLineItemNumber,
	});

	const customerIdFieldClassName = bemElement('job-upsert__billing-jobs__list__item__preview', 'field', {
		invalid: hasDuplicateCustomerId,
		strong: true,
	});

	return (
		<Row >
			<Col className={billingCodeRowClassName} sm={22}>
				<Col className={lineItemNumberFieldClassName} sm={2}>
					{billingCode.lineItemNumber ?? '-'}
				</Col>
				<Col className={billingCodeFieldClassName} sm={2}>
					{billingCode.customerNumber ?? '-'}
				</Col>
				<Col className={customerIdFieldClassName} sm={2}>
					{billingCode.customerId ?? '-'}
				</Col>
				<Col className={billingCodeFieldClassName} sm={2}>
					{billingCode.ownerNumber ?? '-'}
				</Col>
				<Col className={billingCodeFieldClassName} sm={2}>
					{billingCode.ownerId ?? '-'}
				</Col>
				<Col className={billingCodeFieldClassName} sm={2}>
					{billingCode.bidQuantity ?? '-'}
				</Col>
				<Col className={billingCodeFieldClassName} sm={2}>
					{billingCode.unit ? billingCode.unit.replace('_', '/') : '-'}
				</Col>
				<Col className={billingCodeFieldClassName} sm={2}>
					{billingCode.unitPrice ? moneyNormalizer(billingCode.unitPrice, 2, 4) : '-'}
				</Col>
				<Col className={billingCodeFieldClassName} sm={2}>
					{billingCode.group ?? '-'}
				</Col>
				<Col className={billingCodeFieldClassName} sm={4}>
					{billingCode.description ?? '-'}
				</Col>
			</Col>

			{showActions &&
				<Col className="job-upsert__billing-jobs__list__item__actions" sm={2}>
					<span className="icon-edit" onClick={setEditable} />
					<span className="icon-delete" onClick={remove} />
				</Col>
			}
		</Row>
	);
});

export default BillingCodePreview;
