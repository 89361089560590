import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

import type { RootState } from 'af-reducers';

import Cards from './Cards';

import type SharedModalProps from '../../../../Shared/ModalProps';

interface ScrollRefObject {
	getValues: () => {
		scrollLeft: number;
		scrollWidth: number;
		clientWidth: number;
	};
}

interface OwnProps extends SharedModalProps {
	addBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
	droppableId: string;
	dueDate: string;
	forceUnlockOrder: (workOrderId: string) => void;
	hasPermissionsToEditScheduleBoard: boolean;
	isDragAndDropDisabled: boolean;
	lastOpenedOrderCode: string;
	maxWorkOrdersPerDay: number;
	onHorizontalScroll: (scrollPercentage: number, width: number) => void;
	onHorizontalScrollMount: (scrollElement: React.RefObject<ScrollRefObject>) => void;
	onHorizontalScrollUpdate: () => void;
	removeBlankWorkOrder: (dueDate: string, index: number) => Promise<void>;
}

interface StateProps {
	zoomLevel: number; // this attribute is not used in the component. We need it here to update the component when zoom level is changed.
	weeklyViewDateWithToolbar: Nullable<string>;
	workOrdersOrdering: string[];
	copiedWorkOrderCode: Nullable<string>;
	draggedWorkOrderCode: Nullable<string>;
}

type Props = OwnProps & StateProps;

class Board extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		workOrdersOrdering: [],
	};

	_scrollbars: Nullable<React.RefObject<ScrollRefObject>> = null;

	constructor(props: Props) {
		super(props);
		this._scrollbars = React.createRef();
	}

	componentDidMount() {
		const { onHorizontalScrollMount } = this.props;
		if (onHorizontalScrollMount && this._scrollbars) {
			onHorizontalScrollMount(this._scrollbars);
		}
	}

	componentDidUpdate() {
		const { onHorizontalScrollUpdate } = this.props;
		if (onHorizontalScrollUpdate) {
			onHorizontalScrollUpdate();
		}
	}

	handleScroll = () => {
		const { onHorizontalScroll } = this.props;
		if (onHorizontalScroll && this._scrollbars?.current) {
			const { scrollLeft, scrollWidth, clientWidth } = this._scrollbars.current.getValues();
			const scrollPercentage = scrollLeft / (scrollWidth - clientWidth);
			onHorizontalScroll(scrollPercentage, scrollWidth - clientWidth);
		}
	};

	renderTrackHorizontal = () => <div className="track-horizontal display-none" />;

	renderThumbHorizontal = () => <div className="thumb-horizontal display-none" />;

	render() {
		const {
			addBlankWorkOrder,
			copiedWorkOrderCode,
			currentWorkOrderModalId,
			draggedWorkOrderCode,
			droppableId,
			dueDate,
			forceUnlockOrder,
			hasPermissionsToEditScheduleBoard,
			isDragAndDropDisabled,
			lastOpenedOrderCode,
			maxWorkOrdersPerDay,
			removeBlankWorkOrder,
			setWorkOrderModalId,
			setWorkOrderNoteModalData,
			setEmployeeModalData,
			setEmployeeModalVisibility,
			setEquipmentModalData,
			setEquipmentModalVisibility,
			setTemporaryEmployeeModalData,
			weeklyViewDateWithToolbar,
			workOrdersOrdering,
		} = this.props;

		const disableDraggingWhenToolbarOpened: boolean = !!weeklyViewDateWithToolbar && weeklyViewDateWithToolbar !== dueDate;
		const copyingWorkOrder: boolean = !!copiedWorkOrderCode && !!draggedWorkOrderCode && workOrdersOrdering.includes(draggedWorkOrderCode);
		const isDropDisabled: boolean = disableDraggingWhenToolbarOpened || copyingWorkOrder;

		return (
			<Scrollbars
				autoHide={true}
				autoHideDuration={200}
				autoHideTimeout={200}
				className="schedule-board-week-day-cards-wrapper"
				onScroll={this.handleScroll}
				ref={this._scrollbars as React.LegacyRef<Scrollbars>}
				renderThumbHorizontal={this.renderThumbHorizontal}
				renderTrackHorizontal={this.renderTrackHorizontal}
				thumbMinSize={60}
				universal={true}
			>
				<Droppable
					direction="horizontal"
					droppableId={droppableId}
					isDropDisabled={isDropDisabled}
				>
					{(provided, snapshot) => {
						return (
							<div
								className={`schedule-board-week-cards-container-wrapper ${snapshot.isDraggingOver ? 'drag-over' : ''} cards-${maxWorkOrdersPerDay}`}
								ref={provided.innerRef}
							>
								{workOrdersOrdering.length ?
									<div className="schedule-board-week-cards-container">
										<Cards
											addBlankWorkOrder={addBlankWorkOrder}
											currentWorkOrderModalId={currentWorkOrderModalId}
											dueDate={dueDate}
											forceUnlockOrder={forceUnlockOrder}
											hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
											isDragAndDropDisabled={isDragAndDropDisabled}
											lastOpenedOrderCode={lastOpenedOrderCode}
											removeBlankWorkOrder={removeBlankWorkOrder}
											setEmployeeModalData={setEmployeeModalData}
											setEmployeeModalVisibility={setEmployeeModalVisibility}
											setEquipmentModalData={setEquipmentModalData}
											setEquipmentModalVisibility={setEquipmentModalVisibility}
											setTemporaryEmployeeModalData={setTemporaryEmployeeModalData}
											setWorkOrderModalId={setWorkOrderModalId}
											setWorkOrderNoteModalData={setWorkOrderNoteModalData}
										/>
										{provided.placeholder}
									</div> :
									<div className="schedule-board-week-no-work">
										No work assigned for this day.
									</div>
								}
							</div>
						);
					}}
				</Droppable>
			</Scrollbars>
		);
	}
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
	const { dueDate } = ownProps;
	const {
		zoomLevel,
		weeklyViewDateWithToolbar,
		workOrdersByDateDictionary,
		copiedWorkOrderCode,
		draggedWorkOrderCode,
	} = state.scheduleBoard;

	return {
		zoomLevel: zoomLevel,
		weeklyViewDateWithToolbar,
		workOrdersOrdering: workOrdersByDateDictionary?.[dueDate]?.workOrdersOrdering,
		copiedWorkOrderCode,
		draggedWorkOrderCode,
	};
};

export default connect<StateProps, null, OwnProps>(mapStateToProps)(Board);
