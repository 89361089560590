import * as React from 'react';

import { AdditionalColors, ColorHex } from 'acceligent-shared/enums/color';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import type NotificationStatusEnum from 'acceligent-shared/enums/notificationStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import * as ColorUtils from 'ab-utils/color.util';
import { moneyNormalizer, formatDecimalNumber } from 'ab-utils/formatting.util';
import { isFirstRevision } from 'ab-utils/scheduleBoard.util';

import StatusIcon from 'af-components/StatusIcon';
import Tooltip from 'af-components/Tooltip';

import { SCREEN_BREAKPOINT_S, SCREEN_BREAKPOINT_M, SCREEN_BREAKPOINT_L, SCREEN_BREAKPOINT_XL } from 'af-constants/values';

import AttachmentsBadge from '../AttachmentsBadge';
import CopyProgressBar from '../CopyProgressBar';
import NightShiftBadge from '../NightShiftBadge';
import PressAndHoldAnchor from '../PressAndHoldAnchor';

interface OwnProps {
	/**
	 * Only partial in Edit Production Data mode
	 * I'll leave it only labeled as partial here to make sure it's handled, but we should make it more accurate after eliminating produciton data editing
	 */
	workOrder: Partial<ScheduleBoardWorkOrderViewModel>;
	supervisorName: string;
	projectManagerName: string;
	scheduleBoardView: ScheduleBoardView;
	isCalculationsView: boolean;
	notificationStatus: Nullable<NotificationStatusEnum>;
	zoomLevel: number;
	isAllowedToEditWorkOrders: boolean;
	isAllowedToViewProdData: boolean;
	notificationsEnabled?: boolean;
	isDragging?: boolean;
	openOrderInfoModal: () => void;
	setCopiedWorkOrderCode: (code: Nullable<string>) => void;
}

type Props = OwnProps;

interface State {
	isShortCardInfo: boolean;
}

export default class Info extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		isDragging: false,
	};

	state: State = {
		isShortCardInfo: false,
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	componentDidUpdate() {
		// Needed for when zoom level changes
		this.updateWindowDimensions();
	}

	updateWindowDimensions = () => {
		const { zoomLevel } = this.props;
		const { isShortCardInfo } = this.state;

		const isShort = (window.innerWidth < SCREEN_BREAKPOINT_S)
			|| (window.innerWidth < SCREEN_BREAKPOINT_M)
			|| (window.innerWidth < SCREEN_BREAKPOINT_L && zoomLevel < 2)
			|| (window.innerWidth < SCREEN_BREAKPOINT_XL && zoomLevel < 1);

		if (isShort === isShortCardInfo) {
			return;
		}

		this.setState(() => ({ isShortCardInfo: isShort }));
	};

	onHoldEnd = () => {
		const { setCopiedWorkOrderCode, workOrder: { code } } = this.props;
		if (!code) {
			throw new Error('Code is required');
		}
		setCopiedWorkOrderCode(code);
	};

	onRelease = () => {
		const { setCopiedWorkOrderCode, isDragging } = this.props;
		if (!isDragging) {
			setCopiedWorkOrderCode(null);
		}
	};

	renderAddress = () => {
		const { workOrder } = this.props;
		const { title, customer, address } = workOrder;

		let tooltipTitle = '';
		tooltipTitle += customer ? `${customer}\n` : '';
		tooltipTitle += title ? `${title}\n` : '';
		tooltipTitle += address ?? '';

		return (
			<div className="schedule-board-card-address">
				<div className="schedule-board-card-address-content" title={tooltipTitle}>
					{customer && <><span>{customer}</span><br /></>}
					{title && <><span>{title}</span><br /></>}
					<span>{address}</span>
				</div>
			</div>
		);
	};

	renderCanceledLabel = () => {
		return (
			<div className="schedule-board-card-canceled">
				<span>canceled</span>
			</div>
		);
	};

	renderInfo = (isCopying: boolean, copyFinished: boolean) => {
		const {
			workOrder,
			scheduleBoardView,
			projectManagerName,
			supervisorName,
			isCalculationsView,
			notificationStatus,
			notificationsEnabled,
			isAllowedToViewProdData,
		} = this.props;

		const {
			dueDate,
			revenue,
			jobHours,
			shopHours,
			travelHours,
			crewTypeColor,
			customCrewType,
			isInternal,
			isNightShift,
			codeStripped,
			status,
			revision,
			workStart,
			workEnd,
			timeToEnd,
			timeToStart,
			shift,
			isPaused,
			projectJobCode,
			isUnion,
			isPrevailingWage,
			jobStatusColor,
			jobStatusName,
		} = workOrder;

		const {
			isShortCardInfo,
		} = this.state;

		const totalHours = (jobHours ?? 0) + (shopHours ?? 0) + (travelHours ?? 0);
		const isDraft: boolean = status === WorkOrderStatus.DRAFT;
		const isWeeklyView: boolean = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW;
		const isCanceled: boolean = status === WorkOrderStatus.CANCELED;

		const nightShiftWorkStartDateFormatted = TimeUtils.formatDate(dueDate, TimeFormat.SHORT_DATE, TimeFormat.DATE_ONLY);
		const daysToAdd = timeToStart && timeToEnd && timeToStart > timeToEnd ? 1 : 0;
		const nightShiftWorkEndDate = dueDate && TimeUtils.addDays(TimeUtils.parseDate(dueDate, TimeFormat.DATE_ONLY), daysToAdd);
		const nightShiftWorkEndDateFormatted = TimeUtils.formatDate(nightShiftWorkEndDate, TimeFormat.SHORT_DATE);

		let headerBackgroundClassName = '';
		if (!isInternal) {
			headerBackgroundClassName = !crewTypeColor ?
				ColorUtils.getColorBackgroundClass(AdditionalColors.GREY) :
				ColorUtils.getColorBackgroundClass(crewTypeColor);
		}

		const displayProjectCode = !!projectJobCode && process.env.FTD_PROJECT !== 'true';

		return (
			<>
				<div>
					<div className={`schedule-board-card-crew ${headerBackgroundClassName}`}>
						{workOrder.hasAttachments && <AttachmentsBadge />}
						{
							!!jobStatusColor &&
							<div className="schedule-board-card-job-status" >
								<Tooltip
									message={`${jobStatusName}`}
									placement="top-start"
								>
									<div className="schedule-board-card-job-tooltip" style={{ backgroundColor: ColorHex[jobStatusColor] ?? undefined }} />
								</Tooltip>
							</div>
						}
						<div className="schedule-board-card-status">
							<div className="schedule-board-card-status-icon">
								<StatusIcon
									firstRevision={revision ? isFirstRevision(revision) : undefined}
									isPaused={isPaused}
									notificationsEnabled={notificationsEnabled}
									notificationStatus={notificationStatus}
									status={status}
								/>
							</div>
						</div>
						<div className="schedule-board-card-code">
							{isInternal ?
								<span title={customCrewType ?? ''}>{customCrewType}</span> :
								<span title={`${codeStripped}${revision && !isDraft ? ` REV ${revision}` : ''}`}>
									{codeStripped}{revision && !isDraft ? ` REV ${revision}` : ''}
								</span>
							}
						</div>
						{
							displayProjectCode &&
							<div className="schedule-board-card-project-code">
								{projectJobCode}
							</div>
						}
					</div>
					{
						// If we put ?? it won't how PW if isUnion is false
						// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
						(isUnion || isPrevailingWage) &&
						<div
							className="schedule-board-card-flags"
						>
							{
								isUnion &&
								<div className="schedule-board-card-flag">U</div>
							}
							{
								isPrevailingWage &&
								<div className="schedule-board-card-flag">PW</div>
							}
						</div>
					}
					{
						!isCalculationsView && isAllowedToViewProdData && !isInternal &&
						<div
							className="schedule-board-card-metrics"
							title={`${!revenue ? '-' : moneyNormalizer(revenue)} / ${!totalHours ? '-' : `${formatDecimalNumber(totalHours, 0, 2)} HR`}`}
						>
							{!revenue ? '-' : moneyNormalizer(revenue)} / {!totalHours ? '-' : `${formatDecimalNumber(totalHours, 0, 2)} HR`}
						</div>
					}
					{
						workOrder.status === WorkOrderStatus.LOCKED &&
						<div
							className="schedule-board-card-locked"
						>
							APPROVED (LOCKED)
						</div>
					}
					{
						workOrder.isPaused &&
						<div
							className="schedule-board-card-on-hold"
						>
							ON HOLD
						</div>
					}
					{isCanceled ? this.renderCanceledLabel() : this.renderAddress()}
					<div
						className="schedule-board-card-supervisor"
						title={`${projectManagerName} / ${supervisorName}`}
					>
						{projectManagerName} / {supervisorName}
					</div>
					<div className="schedule-board-card-shop-work">
						{isNightShift && <div className="night-shift-icon-container"><NightShiftBadge /></div>}
						<div className={`shift-item ${isShortCardInfo ? 'centered' : ''}`}>
							{!isShortCardInfo && <label>{shift ?? 'N/A'}</label>}
							{
								!isNightShift
									? <span className="schedule-board-card-shift" title={`${workStart} - ${workEnd}`}>{workStart} - {workEnd}</span>
									: (
										<div className="schedule-board-card-night-shift">
											<span className="schedule-board-card-shift" title={`${nightShiftWorkStartDateFormatted} ${workStart}`}>{nightShiftWorkStartDateFormatted} {workStart}</span>
											<span className="schedule-board-card-shift" title={`${nightShiftWorkEndDateFormatted} ${workEnd}`}>{nightShiftWorkEndDateFormatted} {workEnd}</span>
										</div>
									)
							}
						</div>
					</div>
				</div>
				{isWeeklyView &&
					<CopyProgressBar
						copyFinished={copyFinished}
						isCopying={isCopying}
					/>
				}
			</>
		);
	};

	render() {
		const { openOrderInfoModal, scheduleBoardView, workOrder } = this.props;
		const isWeeklyView = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW;

		return (
			<PressAndHoldAnchor
				onClick={openOrderInfoModal}	// NOTE: will not open on calculations
				onHoldEnd={this.onHoldEnd}
				onRelease={this.onRelease}
				regularAnchor={!isWeeklyView || workOrder.jobStatus === WorkRequestStatus.FINISHED}
			>
				{this.renderInfo}
			</PressAndHoldAnchor>
		);
	}

}
