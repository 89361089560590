import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps} from 'react-redux';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import type { JobPayrollTableCSVComplexValueGetterLookup} from 'ab-viewModels/accounting/jobPayrollTable.viewModel';
import { JobPayrollTableRowVM } from 'ab-viewModels/accounting/jobPayrollTable.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';
import BackButton from 'af-components/BackButton';
import SocketEvent from 'ab-enums/socketEvent.enum';
import DateInput from 'af-components/Controls/DateInput';

import LoadingOverlay from 'af-components/LoadingOverlay';

import type { RootState } from 'af-reducers';

import { getFullClientUrl } from 'af-utils/http.util';
import socket from 'af-utils/socket.util';
import { downloadCSV } from 'af-utils/csv.utils';

import CLIENT from 'af-routes/client';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

const JOB_PAYROLL_EXPORT_BREADCRUMBS = [{ label: 'Job Payroll Export Beta' }];

const JobPayrollExportBeta: React.FC<Props> = (props: Props) => {
	const { companyId, companyName, location: { state: { orgAlias } } } = props;

	const [startDate, setStartDate] = React.useState<Date>(new Date());
	const [endDate, setEndDate] = React.useState<Date>(new Date());

	const [showExportingModal, setShowDownloadingModal] = React.useState(false);
	const [jobPayrolls, setJobPayrolls] = React.useState<Nullable<JobPayrollTableRowVM[]>>(null);

	const handleStartDateChange = React.useCallback((newDate: Date) => {
		setStartDate(newDate);
	}, []);
	const handleEndDateChange = React.useCallback((newDate: Date) => {
		setEndDate(newDate);
	}, []);

	const csvValueGetters = React.useMemo<JobPayrollTableCSVComplexValueGetterLookup>(() => ({
		'reportUrl': (parentRow, childRow) => {
			if (!childRow) {
				throw new Error('Child row not provided');
			}
			return getFullClientUrl(orgAlias, CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(childRow.workOrderId.toString(), orgAlias, companyName));
		},
	}), [companyName, orgAlias]);

	React.useEffect(() => {
		if (jobPayrolls && csvValueGetters) {
			const csvData = JobPayrollTableRowVM.toCSVData(jobPayrolls, csvValueGetters, true);
			const newFileName = `${companyName}_job_payroll_${TimeUtils.formatDate(startDate, TimeFormat.SHORT_DATE)}_-_${TimeUtils.formatDate(endDate, TimeFormat.SHORT_DATE)}`;

			setJobPayrolls(null!);
			downloadCSV(csvData, `${newFileName}.csv`);

			setShowDownloadingModal(false);
		}
	}, [csvValueGetters, jobPayrolls, companyName, startDate, endDate]);

	const handleDownload = React.useCallback((e) => {
		e.preventDefault();

		setShowDownloadingModal(true);
		const startDateDBFormat = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);
		const endDateDBFormat = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);

		socket.connection?.emit(SocketEvent.V2.FE.ACCOUNTING.EXPORT_JOB_PAYROLL_BETA, {
			startDate: startDateDBFormat,
			endDate: endDateDBFormat,
			companyId,
		});

	}, [companyId, endDate, startDate]);

	React.useEffect(() => {
		socket.connection?.subscribe(SocketEvent.V2.BE.ACCOUNTING.EXPORT_JOB_PAYROLL_BETA, (data) => {
			setJobPayrolls(data);
		});

		return () => {
			socket.connection?.unsubscribe(SocketEvent.V2.BE.ACCOUNTING.EXPORT_JOB_PAYROLL_BETA);
		};
	}, []);

	return (
		<>
			<div className="form-segment">
				<Breadcrumbs items={JOB_PAYROLL_EXPORT_BREADCRUMBS} />
				<Form onSubmit={handleDownload}>
					<div className="form-box">
						<Row className="row--padded-top">
							<Col lg={6} md={8}>
								<DateInput
									label="Start date"
									onValueChange={handleStartDateChange}
									selectedValue={startDate}
								/>
							</Col>
							<Col lg={6} md={8}>
								<DateInput
									label="End date"
									onValueChange={handleEndDateChange}
									selectedValue={endDate}
								/>
							</Col>
						</Row>
						<Row className="row--submit">
							<BackButton />
							<button className="btn btn-primary" type="submit">
								Download
							</button>
						</Row>
					</div>
				</Form>
			</div>
			<LoadingOverlay
				label="Please wait while data is being loaded"
				show={showExportingModal}
			/>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		companyId: companyData.id,
	};
}

const connector = connect(mapStateToProps);

export default connector(JobPayrollExportBeta);
