import type { Dispatch, AnyAction } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import API from 'af-constants/routes/api';

import type { EmployeeRM } from 'ab-requestModels/employee/update.requestModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { RESOURCE_STATUS } from 'af-constants/reduxForms';

import type { EmployeeVM } from 'ab-viewModels/employee/employee.viewModel';
import type { EmployeeOptionVM as ExtendedOptionVM } from 'ab-viewModels/employee/extendedOption.viewModel';
import type { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';
import type { EmployeeListViewModel } from 'ab-viewModels/employee/list.viewModel';
import type { EmployeeTableVM } from 'ab-viewModels/employee/table.viewModel';
import type { FieldReportAccessOptionVM } from 'ab-viewModels/employee/fieldReportAccessOption.viewModel';
import type { LatestNotificationsByEmployeeViewModel } from 'ab-viewModels/latestNotificationsForEmployees.viewModel';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { toRequestModelWithFormattedPhoneNumbers } from 'ab-utils/form.util';

import type { GetRootState } from 'af-reducers';

import type { EmployeeStatusHistoryTableViewModel } from 'ab-viewModels/employeeStatusHistoryTable.viewModel';
import type * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import * as SetEmployeeToUnavailableAPI from 'ab-api/web/employee/setToUnavailable';

export function update(form: EmployeeRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.RESOURCES.EMPLOYEE.EDIT(form.id.toString(), TimeUtils.formatDate(new Date())), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downEmployee(employeeId: number, form: SetEmployeeToUnavailableAPI.W_Employee_SetToUnavailable_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(SetEmployeeToUnavailableAPI.URL(employeeId), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			let employee = await http.get<EmployeeVM>(API.V1.RESOURCES.EMPLOYEE.FIND_BY_ID(id));
			employee = toRequestModelWithFormattedPhoneNumbers(employee, ['phoneNumber', 'countryCode']);
			return employee;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EmployeeListViewModel>(API.V1.RESOURCES.EMPLOYEE.LIST());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<EmployeeTableVM>>(API.V1.RESOURCES.EMPLOYEE.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllSuperintendents() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EmployeeOptionVM[]>(API.V1.RESOURCES.EMPLOYEE.SUPERINTENDENTS());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForShipping() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EmployeeOptionVM[]>(API.V1.RESOURCES.EMPLOYEE.FIND_FOR_SHIPPING());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllSuperintendentsForFieldReport(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => await http.get<FieldReportAccessOptionVM[]>(API.V1.RESOURCES.EMPLOYEE.SUPERINTENDENTS_FOR_FIELD_REPORT(workOrderId));
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllProjectManagers() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EmployeeOptionVM[]>(API.V1.RESOURCES.EMPLOYEE.PROJECT_MANAGERS());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllSuperintendentsAndProjectManagers() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EmployeeOptionVM[]>(API.V1.RESOURCES.EMPLOYEE.SUPERINTENDENTS_AND_PROJECT_MANAGERS());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllEmployeesForWorkOrders() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<EmployeeOptionVM[]>(API.V1.RESOURCES.EMPLOYEE.WORK_ORDER_EMPLOYEES());
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllEmployeesForWorkOrdersForDueDate(dueDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<ExtendedOptionVM[]>(API.V1.RESOURCES.EMPLOYEE.WORK_ORDER_EMPLOYEES_BY_DUE_DATE(dueDate));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findStatusHistoryForEmployee(
	employeeId: string,
	tableRequestModel: TableQuery,
	startDate: Date,
	endDate: Date,
	filterByStatusId: number
) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);
			return await http.get<TableContent<EmployeeStatusHistoryTableViewModel>>(
				API.V1.RESOURCES.EMPLOYEE.STATUS_HISTORY(employeeId, data, startDateString, endDateString, filterByStatusId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllStatusesForEmployee(employeeId: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ResourceStatusesViewModel.Default>(
				API.V1.RESOURCES.EMPLOYEE.ALL_STATUSES_FOR_EMPLOYEE(employeeId),
				{ submitting: RESOURCE_STATUS }
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findLatestNotificationsForEmployeesOnWorkOrder(employeeIds: number[], workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<LatestNotificationsByEmployeeViewModel>(
				API.V1.RESOURCES.EMPLOYEE.FIND_LATEST_NOTIFICATIONS_FOR_WORK_ORDER(employeeIds, workOrderId)
			);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
