import type UpsertTemporaryEmployeeRM from 'ab-requestModels/temporaryEmployee.requestModel';

import type TemporaryEmployeeVM from 'ab-viewModels/temporaryEmployee/temporaryEmployee.viewModel';

export const fromVMtoRM = (employee: TemporaryEmployeeVM): UpsertTemporaryEmployeeRM => {
	return {
		firstName: employee.firstName,
		lastName: employee.lastName,
		agencyId: employee.agencyId,
		email: employee.email,
		phoneNumber: employee.phoneNumber,
	};
};
