import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { DraggableProvided, DraggableStateSnapshot, DraggableElement } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import { EQUIPMENT_PLACEHOLDER } from 'ab-common/constants/scheduleBoard';

import DraggableEquipment from './Draggable';
import DraggableEquipmentWithReason from './DraggableWithReason';
import DraggableLoadingPlaceholder from 'af-root/scenes/Company/ScheduleBoard/Shared/DraggableLoadingPlaceholder';

import type { EquipmentModalProps } from '../../Shared/ModalProps';

interface OwnProps extends EquipmentModalProps {
	equipmentId: number;
	draggableId: string;
	index: number;
	dueDate: string;
	droppableId: string;
	isToolbar: boolean;
	isCopyPlaceholder?: boolean;
	isCardDisabled?: boolean;
	isCalculationsView?: boolean;
	isWorkOrderCanceled?: boolean;
	hasReason?: boolean;
	resourceId?: number;
	workOrderEquipmentId?: number;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	isWOLocked: boolean;
}

interface StateProps {
	isDisabled: boolean;
}

interface DispatchProps {
	findWorkOrderEquipmentById: typeof ScheduleBoardActions.findWorkOrderEquipmentById;
	findToolbarEquipmentById: typeof ScheduleBoardActions.findToolbarEquipmentById;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface DraggableProps extends Props {
	provided?: DraggableProvided;
	snapshot?: DraggableStateSnapshot;
}

interface State {
	isModalOpenedForCurrentEquipment: boolean;
}

class EquipmentDraggable extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		isCalculationsView: false,
	};

	state: State = {
		isModalOpenedForCurrentEquipment: false,
	};

	componentDidUpdate(prevProps: Props) {
		const { equipmentId: nextEquipmentId, isDisabled: nextEquipmentIsDisabled } = this.props;
		const { setEquipmentModalData, equipmentId, isDisabled } = prevProps;
		const { isModalOpenedForCurrentEquipment } = this.state;

		if (isModalOpenedForCurrentEquipment && isDisabled && !nextEquipmentIsDisabled && nextEquipmentId === equipmentId) {
			setEquipmentModalData(null, null);
			this.setState(() => ({ isModalOpenedForCurrentEquipment: true }));
			this.fetchModalData();
		}
	}

	fetchModalData = async () => {
		const {
			setEquipmentModalData,
			setEquipmentModalVisibility,
			findToolbarEquipmentById,
			findWorkOrderEquipmentById,
			equipmentId,
			isToolbar,
			workOrderEquipmentId,
			dueDate,
		} = this.props;

		const equipment = isToolbar
			? await findToolbarEquipmentById(equipmentId, dueDate)
			: await findWorkOrderEquipmentById(workOrderEquipmentId!, dueDate);

		setEquipmentModalData(equipment, dueDate);
		setEquipmentModalVisibility(true);
		this.setState(() => ({ isModalOpenedForCurrentEquipment: true }));
	};

	openModal = () => {
		const { isCalculationsView } = this.props;
		if (isCalculationsView) {
			return;
		}
		this.fetchModalData();
	};

	renderDraggable = (props) => {
		const {
			dueDate,
			equipmentId,
			hasPermissionsToEditScheduleBoard,
			hasReason,
			isDragAndDropDisabled,
			isToolbar,
			isCardDisabled,
			isWorkOrderCanceled,
			provided = {} as DraggableProvided,
			resourceId,
			snapshot = {} as DraggableStateSnapshot,
			isWOLocked,
		} = props;

		if (hasReason) {
			return (
				<DraggableEquipmentWithReason
					draggableProps={provided.draggableProps}
					dragHandleProps={provided.dragHandleProps}
					dueDate={dueDate}
					equipmentId={equipmentId}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					innerRef={provided.innerRef}
					isDragging={snapshot.isDragging}
					isToolbar={isToolbar}
					onClick={this.openModal}
				/>
			);
		}

		return (
			<DraggableEquipment
				draggableProps={provided.draggableProps}
				draggingOver={snapshot.draggingOver}
				dragHandleProps={provided.dragHandleProps}
				dueDate={dueDate}
				equipmentId={equipmentId}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				innerRef={provided.innerRef}
				isCardDisabled={isCardDisabled}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isDragging={snapshot.isDragging}
				isDropAnimating={snapshot.isDropAnimating}
				isToolbar={isToolbar}
				isWOLocked={isWOLocked}
				isWorkOrderCanceled={isWorkOrderCanceled}
				onClick={this.openModal}
				resourceId={resourceId}
			/>
		);
	};

	render() {
		const {
			draggableId,
			equipmentId,
			index,
			isDisabled,
			isCopyPlaceholder,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
		} = this.props;

		// loading indicator when assigning equipment from toolbar
		if (equipmentId === EQUIPMENT_PLACEHOLDER) {
			return <DraggableLoadingPlaceholder />;
		}

		// copy of dragged item in source droppable zone
		if (isCopyPlaceholder || isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard) {
			return (
				<div>
					{this.renderDraggable(this.props)}
				</div>
			);
		}

		return (
			<Draggable
				draggableId={draggableId}
				index={index}
				isDragDisabled={isDisabled}
			>
				{(provided, snapshot) => (
					React.createElement(this.renderDraggable, { ...this.props, provided, snapshot }, null) as DraggableElement<DraggableProps>
				)}
			</Draggable>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { equipmentId, isToolbar, isCardDisabled, resourceId, dueDate } = ownProps;
	const {
		equipment: equipmentDict,
		draggedResourceId,
		draggedEquipmentId,
		weeklyViewDateWithToolbar,
		scheduleBoardView,
	} = state.scheduleBoard;

	let isDisabled = false;

	const equipment = equipmentDict?.[equipmentId];

	if (!isToolbar) {
		const disableDraggingWhenToolbarOpened = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW &&
			(!weeklyViewDateWithToolbar || weeklyViewDateWithToolbar !== dueDate);
		isDisabled = !!isCardDisabled ||
			disableDraggingWhenToolbarOpened ||
			!!(equipment?.isDisabled && resourceId !== draggedResourceId);
	} else {
		// if equipment is disabled and `draggedEquipmentId` is not set => it's dragged by another user or in another tab
		isDisabled = !!equipment?.isDisabled && !draggedEquipmentId;
	}

	return {
		isDisabled,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findWorkOrderEquipmentById: ScheduleBoardActions.findWorkOrderEquipmentById,
		findToolbarEquipmentById: ScheduleBoardActions.findToolbarEquipmentById,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(EquipmentDraggable);
