import * as React from 'react';
import type { CustomRouteComponentProps} from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';
import CLIENT from 'af-constants/routes/client';

import type * as User from 'ab-viewModels/user.viewModel';

import { isAllowed } from 'ab-utils/auth.util';

import { defaultRedirectUrlStrict } from 'af-utils/actions.util';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

function mapStateToProps(state: RootState) {
	const isLoading = !state.user.organizationData || !state.user.userData;

	return {
		companyData: state.user?.companyData ?? undefined,
		organizationData: state.user?.organizationData ?? undefined,
		userData: state.user?.userData ?? undefined,
		isLoading,
	};
}

const connector = connect(mapStateToProps);

export function withPermission<TProps extends Partial<CustomRouteComponentProps>>(permissionPage) {
	return (ComposedComponent: React.ComponentType<TProps>) => {
		class PermissionManagement extends React.PureComponent<Props> {
			static defaultProps: Partial<Props> = {
				userData: undefined,
				companyData: {} as User.CompanyData,
				organizationData: {} as User.OrganizationData,
				isLoading: false,
			};

			render() {
				const { companyData, userData, isLoading, organizationData, location: { state: { orgAlias } } } = this.props;

				if (!userData || !companyData || !organizationData) {
					return React.createElement(Redirect, { to: CLIENT.AUTH.LOGIN(orgAlias) });
				}

				if (isLoading) {
					return null;
				}

				const _isAllowed: boolean = isAllowed(
					permissionPage,
					companyData.permissions,
					companyData.isCompanyAdmin,
					userData.role
				);

				if (_isAllowed) { // standard scenario
					const routerProps: Required<CustomRouteComponentProps> = {
						history: this.props.history,
						location: this.props.location,
						match: this.props.match,
						staticContext: this.props.staticContext!,
					};
					return React.createElement(ComposedComponent, routerProps as TProps);
				}

				const defaultRedirectUrl = defaultRedirectUrlStrict(
					organizationData.alias,
					companyData.name,
					companyData.permissions,
					companyData.isCompanyAdmin,
					userData.role
				);

				if (defaultRedirectUrl) {
					return React.createElement(Redirect, { to: defaultRedirectUrl });
				}

				return React.createElement(Redirect, { to: CLIENT.ERROR.ERR403(organizationData.alias) });
			}
		}

		return connector(PermissionManagement);
	};
}
