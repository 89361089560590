import * as React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import CDLStatus from 'acceligent-shared/enums/cdlStatus';
import WorkOrderStatusEnum from 'acceligent-shared/enums/workOrderStatus';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import AssignmentsModalSection from 'af-root/scenes/Company/ScheduleBoard/Shared/AssignmentsModalSection';
import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';
import ResourceSkillList from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceModals/SkillsList';

import ImageTag from 'af-components/Image';
import CustomModal from 'af-components/CustomModal';
import SegmentLabel from 'af-components/SegmentLabel';
import LockedValue from 'af-components/LockedValue';
import LabelWithColor from 'af-components/LabelWithColor';

import AssignableResourceType from 'acceligent-shared/enums/assignableResourceType';
import type ScheduleBoardEmployeeModalVM from 'ab-viewModels/scheduleBoardEmployeeModal.viewModel';
import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardEmployeePerDiemAssignmentTypeVM } from 'ab-viewModels/scheduleBoardEmployeePerDiemAssignment.viewModel';

import type ScheduleBoardEmployeePerDiemAssignmentRM from 'ab-requestModels/scheduleBoardEmployeePerDiemAssignment.requestModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import SocketEvent from 'ab-enums/socketEvent.enum';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';
import { MAX_EMPLOYEE_ASSIGNMENTS_PER_DAY } from 'ab-common/constants/scheduleBoard';

import type { RootState } from 'af-reducers';

import { isAllowed } from 'ab-utils/auth.util';
import { formatPhoneNumber } from 'ab-utils/phone.util';

import socket from 'af-utils/socket.util';
import type { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';

import styles from './styles.module.scss';
import SettingsButton from './SettingsButton';

interface OwnProps {
	date: Nullable<string>;
	createEmployeeAssignment: (
		type: AssignableResourceType,
		date: string,
		workOrderId: number,
		isAvailable: boolean,
		resource: ScheduleBoardEmployeeModalVM
	) => boolean;
	employee: Nullable<ScheduleBoardEmployeeModalVM>;
	hasPermissionsToEditScheduleBoard: boolean;
	removeEmployeeAssignment: (dueDate: string, workOrderId: number, workOrderCode: string, employeeId: number) => void;
	setEmployeeModalData: (employee: Nullable<ScheduleBoardEmployeeModalVM>, date: Nullable<string>) => void;
	updateWorkOrderEmployeePerDiem: (workOrderId: number, employeeId: number, perDiem: boolean, dueDate: string) => void;
	openWorkOrderHistoryModal: () => void;
	setEmployeeModalVisibility: (showEmployeeModal: boolean) => void;
	showEmployeeModal: boolean;
}

interface StateProps {
	assignableWorkOrders: ScheduleBoardWorkOrderViewModel[];
	assignedWorkOrders: ScheduleBoardWorkOrderViewModel[];
	isAllowedToManagePerDiems: boolean;
}

type Props = OwnProps & StateProps;

interface State {
	employee: Nullable<ScheduleBoardEmployeeModalVM>;
	showEmployeeModal: boolean;
}

class EmployeeModal extends React.PureComponent<Props> {

	state: State = {
		employee: this.props.employee,
		showEmployeeModal: this.props.showEmployeeModal,
	};

	componentDidMount() {
		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EMPLOYEE_PER_DIEM, this.updatePerDiem, true);
	}

	componentDidUpdate(prevProps: Props) {
		const { employee, showEmployeeModal } = this.props;
		if (prevProps.employee !== employee) {
			this.setState(() => ({ employee }));
		}
		if (prevProps.showEmployeeModal !== showEmployeeModal) {
			this.setState(() => ({ showEmployeeModal }));
		}
	}

	componentWillUnmount() {
		socket.connection?.removeHandler(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EMPLOYEE_PER_DIEM, this.updatePerDiem);
	}

	updatePerDiem = (data: ScheduleBoardEmployeePerDiemAssignmentRM) => {
		if (data.employeeId === this.props.employee?.id) {
			const { employee } = this.state;
			const { workOrderId, perDiem, workOrderEmployeeId, employeeId } = data;

			const newPerDiemAssignment: ScheduleBoardEmployeePerDiemAssignmentTypeVM = {};
			if (perDiem) {
				newPerDiemAssignment[workOrderId] = { workOrderEmployeeId, employeeId, workOrderId };
			}

			if (!employee) {
				throw new Error('Employee not defined');
			}

			this.setState(() => ({
				employee: {
					...employee,
					perDiemAssignments: {
						...employee.perDiemAssignments,
						...newPerDiemAssignment,
					},
				},
			}));
		}
	};

	closeModal = async () => {
		const { setEmployeeModalVisibility } = this.props;
		setEmployeeModalVisibility(false);
	};

	createEmployeeAssignment = async (workOrderId: number) => {
		const { createEmployeeAssignment, date, hasPermissionsToEditScheduleBoard } = this.props;
		const { employee } = this.state;

		if (!hasPermissionsToEditScheduleBoard) {
			return false;
		}

		if (!employee || !date) {
			throw new Error('Employee not defined');
		}
		return await createEmployeeAssignment(
			AssignableResourceType.EMPLOYEE,
			date,
			workOrderId,
			employee.employeeStatus?.available ?? true,
			employee
		);
	};

	removeEmployeeAssignment = (workOrderId: number, workOrderCode: string, itemId: number) => {
		const { removeEmployeeAssignment, date, hasPermissionsToEditScheduleBoard } = this.props;
		if (!hasPermissionsToEditScheduleBoard) {
			return;
		}

		if (!date) {
			throw new Error('Assignment not defined');
		}
		removeEmployeeAssignment(date, workOrderId, workOrderCode, itemId);
	};

	updateWorkOrderEmployeePerDiem = (workOrderId: number, employeeId: number, perDiem: boolean) => {
		const { updateWorkOrderEmployeePerDiem, date, hasPermissionsToEditScheduleBoard } = this.props;
		if (!hasPermissionsToEditScheduleBoard) {
			return;
		}

		if (!date) {
			throw new Error('Assignment not defined');
		}
		updateWorkOrderEmployeePerDiem(workOrderId, employeeId, perDiem, date);
	};

	renderLoadingModalContent = () => {
		return (<Loading onHide={this.closeModal} />);
	};

	renderDataModalContent = () => {
		const {
			assignableWorkOrders,
			assignedWorkOrders,
			hasPermissionsToEditScheduleBoard,
			isAllowedToManagePerDiems,
			openWorkOrderHistoryModal,
		} = this.props;
		const { employee } = this.state;
		if (!employee) {
			throw new Error('Employee not defined');
		}

		const {
			cdlStatus,
			countryCode,
			email,
			employeeStatus,
			firstName,
			id,
			imageUrl,
			jobCode,
			laborType,
			lastName,
			office,
			perDiemAssignments,
			phoneNumber,
			skills,
			wageClassification,
			assignableToWorkOrder,
		} = employee;
		const employeeStatusName = employeeStatus?.name ?? '-';

		const assignedInOffices = assignedWorkOrders.filter((wo) => !!wo.officeNickname);
		const supervisorOnWorkOrders = assignedWorkOrders.filter((wo) => wo.supervisorId === id);

		const scheduleBoardBaseClassName = 'schedule-board-modal';
		const headerClassName = `${scheduleBoardBaseClassName}__big-header`;
		const headerLeft = `${scheduleBoardBaseClassName}__big-header-left`;
		const headerRight = `${scheduleBoardBaseClassName}__big-header-right`;

		return (
			<>
				<CustomModal.Header className={`${styles[headerClassName]} `}>
					<div className={`${styles[headerLeft]} `}>
						<span>{firstName} {lastName}</span>
					</div>
					<div className={`${styles[headerRight]} `}>
						<LabelWithColor color={office?.color ?? null} text={office?.nickname ?? '-'} />
						<SettingsButton
							openWorkOrderHistoryModal={openWorkOrderHistoryModal}
						/>
					</div>
				</CustomModal.Header>
				<CustomModal.Body>
					<div className="schedule-board-modal__resource__row schedule-board-modal__resource__row--flex">
						<div className="schedule-board-modal__resource__avatar-wrapper">
							<ImageTag
								className="schedule-board-modal__resource__avatar"
								fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
								minSize={BlobStorageImageSizeContainer.SIZE_200X200}
								src={imageUrl}
								tryOriginal={true}
								tryRoot={true}
							/>
							{cdlStatus === CDLStatus.NO_CDL &&
								<div className="schedule-board-modal__resource__avatar-wrapper__no-cdl">
									<span>No CDL</span>
								</div>
							}
						</div>
						<div className="schedule-board-modal__resource__main-details">
							<div className="schedule-board-modal__resource__main-details__item">
								<LockedValue
									label="Labor Type"
									value={laborType}
								/>
							</div>
							<div className="schedule-board-modal__resource__main-details__item">
								<LockedValue
									label="Wage Classification"
									value={wageClassification}
								/>
							</div>
							<div className="schedule-board-modal__resource__main-details__item">
								<ResourceSkillList skills={skills} title="Skills" />
							</div>
						</div>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Contact Information" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Mobile Phone"
									value={formatPhoneNumber(phoneNumber, countryCode)}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Email"
									value={email}
								/>
							</Col>
							<Col md={12} />
						</Row>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Current Status" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Status"
									value={employeeStatusName}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job"
									value={jobCode}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job Locations"
									value={assignedInOffices.length > 0
										? assignedInOffices.map((wo) => wo.officeNickname).join(', ')
										: '-'
									}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Supervisor on Work Orders"
									value={
										supervisorOnWorkOrders.length > 0
											? supervisorOnWorkOrders.map((wo) => wo.code).join(', ')
											: '-'
									}
								/>
							</Col>
						</Row>
					</div>
					<hr />
					<AssignmentsModalSection
						assignableWorkOrders={assignableWorkOrders}
						assignedWorkOrders={assignedWorkOrders}
						createAssignment={this.createEmployeeAssignment}
						isAllowedToManagePerDiems={isAllowedToManagePerDiems}
						isEditable={assignableToWorkOrder && hasPermissionsToEditScheduleBoard}
						itemId={id}
						maxItems={MAX_EMPLOYEE_ASSIGNMENTS_PER_DAY}
						perDiemAssignments={perDiemAssignments}
						removeAssignment={this.removeEmployeeAssignment}
						showPerDiem={isAllowedToManagePerDiems}
						updateWorkOrderEmployeePerDiem={this.updateWorkOrderEmployeePerDiem}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={this.closeModal}
						variant="info"
					>
						Close
					</Button>
				</CustomModal.Footer>
			</>
		);
	};

	render() {
		const { employee, showEmployeeModal } = this.state;
		return (
			<CustomModal
				closeModal={this.closeModal}
				modalStyle="info"
				showModal={showEmployeeModal}
				size="xl"
			>
				{employee ? this.renderDataModalContent() : this.renderLoadingModalContent()}
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState, props: OwnProps): StateProps {
	const { employee, date } = props;
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToManagePerDiems: boolean = isAllowed(PagePermissions.COMPANY.PER_DIEMS, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	const workOrdersDictionary = date ? state?.scheduleBoard?.workOrdersByDateDictionary?.[date] : null;
	const assignedWorkOrderCodes = employee?.id
		? workOrdersDictionary?.employeeAssignments?.[employee?.id] ?? []
		: [];
	const assignedWorkOrders = assignedWorkOrderCodes
		.map((workOrderCode) => workOrdersDictionary?.workOrders?.[workOrderCode])
		.filter((_wo): _wo is ScheduleBoardOnDateViewModel['workOrders'][string] => (!!_wo));

	return {
		isAllowedToManagePerDiems,
		assignedWorkOrders,
		assignableWorkOrders: Object.keys(workOrdersDictionary?.workOrders ?? {})
			.reduce((_acc, _woCode) => {
				if (
					!assignedWorkOrderCodes.includes(_woCode)
					&& workOrdersDictionary?.workOrders?.[_woCode]?.status !== WorkOrderStatusEnum.CANCELED
					&& workOrdersDictionary?.workOrders?.[_woCode]?.status !== WorkOrderStatusEnum.LOCKED
					&& !workOrdersDictionary?.workOrders?.[_woCode]?.isPaused
				) {
					_acc.push(workOrdersDictionary!.workOrders[_woCode]);
				}
				return _acc;
			}, [] as ScheduleBoardWorkOrderViewModel[]),
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(EmployeeModal);
