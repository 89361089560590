import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Button, Col, Row } from 'react-bootstrap';

import type ImportBillingCodeRM from 'ab-requestModels/billingCode/importBillingCode.requestModel';

import Accordion from 'af-components/Accordion';

import type JobUpsertFM from '../../../../formModel';

import BillingCodeEdit from './BillingCodeEdit';
import BillingCodePreview from './BillingCodePreview';
import BillingCodeHeader from './BillingCodeHeader';

export type BillingCodeFM = Required<JobUpsertFM>['billingCodes'][0] & { editable: boolean; };

export type CustomFormErrors<Model> = { [K in keyof Model]: Model[K] extends object ? CustomFormErrors<Model[K]> : string };
export type BillingCodeErrors = CustomFormErrors<BillingCodeFM[]>;

export interface OwnProps {
	change: (fieldName: string, value: Metadata | string | number | boolean | null) => void;
	importedBillingCodes: ImportBillingCodeRM[];
	billingCodeSyncErrors: BillingCodeErrors;
	billingCodeSubmitErrors: BillingCodeErrors;
	lineItemNumberCounter: Record<number, number>;
	customerIdCounter: Record<string, number>;
}

type Props = OwnProps & WrappedFieldArrayProps<BillingCodeFM>;

const BillingCodeArray: React.FC<Props> = React.memo((props) => {
	const [billingCodesCollapsed, setBillingCodesCollapsed] = React.useState<boolean>(false);

	const {
		fields,
		change,
		importedBillingCodes,
		billingCodeSyncErrors,
		billingCodeSubmitErrors,
		lineItemNumberCounter,
		customerIdCounter,
	} = props;

	const onAdd = React.useCallback(() => {
		if (billingCodesCollapsed) {
			setBillingCodesCollapsed(false);
		}
		fields.push({ editable: true } as BillingCodeFM);
	}, [billingCodesCollapsed, fields]);

	const onRemove = React.useCallback((index: number) => {
		fields.remove(index);
	}, [fields]);

	const changeEditable = React.useCallback((index: number, editable: boolean) => {
		change(`billingCodes[${index}].editable`, editable);
	}, [change]);

	const handleChangeBillingCodesCollapsed = React.useCallback((newBillingCodesCollapsed: boolean) => {
		setBillingCodesCollapsed(newBillingCodesCollapsed);
	}, []);

	const errors = React.useMemo(() => {
		const billingCodeErrors: BillingCodeErrors = [];

		if (billingCodeSyncErrors) {
			for (const billingCodeIndex of Object.keys(billingCodeSyncErrors)) {
				billingCodeErrors[billingCodeIndex] = {
					...billingCodeErrors[billingCodeIndex],
					...billingCodeSyncErrors[billingCodeIndex],
				};
			}
		}

		if (billingCodeSubmitErrors) {
			for (const billingCodeIndex of Object.keys(billingCodeSubmitErrors)) {
				billingCodeErrors[billingCodeIndex] = {
					...billingCodeErrors[billingCodeIndex],
					...billingCodeSubmitErrors[billingCodeIndex],
				};
			}
		}

		return billingCodeErrors;
	}, [billingCodeSyncErrors, billingCodeSubmitErrors]);

	const renderBillingCode = (field: string, index: number) => {
		const billingCode = fields.get(index);
		const hasDuplicateLineItemNumber: boolean = lineItemNumberCounter[billingCode.lineItemNumber] > 1;
		const hasDuplicateCustomerId: boolean = customerIdCounter[billingCode.customerId] > 1;

		return billingCode.editable
			? (
				<BillingCodeEdit
					change={change}
					changeEditable={changeEditable}
					errors={errors[index]}
					fieldName={field}
					index={index}
					key={index}
					onRemove={onRemove}
				/>
			)
			: (
				<BillingCodePreview
					billingCode={billingCode}
					changeEditable={changeEditable}
					errors={errors[index]}
					hasDuplicateCustomerId={hasDuplicateCustomerId}
					hasDuplicateLineItemNumber={hasDuplicateLineItemNumber}
					index={index}
					key={index}
					onRemove={onRemove}
					showActions={true}
				/>
			);
	};

	React.useEffect(() => {
		// Can't use spread operator on type BillingCodeVM[]
		for (let index = 0; index < importedBillingCodes.length; index++) {
			const billingCode = importedBillingCodes[index];

			fields.push(
				{
					customerId: billingCode.customerId,
					customerNumber: billingCode.customerNumber,
					ownerId: billingCode.ownerId,
					ownerNumber: billingCode.ownerNumber,
					unit: billingCode.unit,
					unitPrice: billingCode.unitPrice,
					bidQuantity: billingCode.bidQuantity,
					group: billingCode.group,
					lineItemNumber: billingCode.lineItemNumber,
					description: billingCode.description,
					editable: false,
				} as BillingCodeFM
			);
		}
		// `billingCodes` intentionally disregarded as it would trigger a loop where fields would get updated until we're out of memory
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [importedBillingCodes]);

	const billingCodes = fields.getAll() ?? [];

	const anyReadOnly = billingCodes.some((billingCode) => !billingCode.editable);

	return (
		<div className="job-upsert__billing-jobs__list">
			{billingCodes?.length > 0 &&
				<Accordion
					className="job-upsert__billing-jobs__list__accordion"
					collapseText="Hide Billing Codes"
					expandText="Show Billing Codes"
					isCollapsed={billingCodesCollapsed}
					onChange={handleChangeBillingCodesCollapsed}
				>
					{anyReadOnly && <BillingCodeHeader />}
					<div className="job-upsert__billing-jobs__list__items">{fields.map(renderBillingCode)}</div>
				</Accordion>
			}
			<Row>
				<Col sm={24}>
					<Button className="btn btn--link" onClick={onAdd}>
						<span className="icon-plus" />
						<span>Add Billing Code</span>
					</Button>
				</Col>
			</Row>
		</div>
	);
});

export default BillingCodeArray;
