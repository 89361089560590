import { getShortAddress } from 'acceligent-shared/utils/address';
import * as ContactUtils from 'acceligent-shared/utils/contact';

import { EmailTypesArray } from 'acceligent-shared/enums/contactMethodType';

import type ContactLookupBase from 'ab-domain/models/contactLookup/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type WorkRequestBase from 'ab-domain/models/workRequest/base';

export type W_Job_FindAllJbosForInvoices_VM = W_Job_FindAllJbosForInvoices_VM_Single[];

export class W_Job_FindAllJbosForInvoices_VM_Single {
	id: number;
	jobCode: string;
	isInternal: boolean;
	title: Nullable<string>;
	customerCompany: Nullable<string>;
	customerFormatted: Nullable<string>;
	travelLocationShort: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	office: Nullable<string>;
	billingContact: Nullable<W_Job_FindAllJbosForInvoices_VM_PreviewInvoiceContact>;

	constructor(workRequest: WorkRequestBase) {
		this.id = workRequest.id;
		this.jobCode = workRequest.jobCode;
		this.isInternal = !!workRequest.isInternal;
		this.title = workRequest.title;
		this.customerCompany = workRequest.customerCompanyName ?? workRequest.customerContact?.contact.companyName ?? null;
		this.customerFormatted = workRequest.customerContact?.contact.fullName ? `${workRequest.customerContact.contact.fullName}${this.customerCompany ? `, ${this.customerCompany}` : ''}` : null;
		this.travelLocationShort = workRequest.travelLocation ? getShortAddress(workRequest.travelLocation) : null;
		this.office = workRequest.office?.nickname ?? null;
		this.city = workRequest.travelLocation?.locality ?? null;
		this.state = workRequest.travelLocation?.aa1 ?? null;
		this.billingContact = workRequest.billingContact ? {
			selectedContactMethodEmails: W_Job_FindAllJbosForInvoices_VM_Single.getSelectedContactEmails(workRequest.billingContact),
		} : null;
	}

	private static getSelectedContactEmails(billingContact: ContactLookupBase): string[] {
		const selectedEmailIds = ContactUtils.filterContactMethod(billingContact.contactLookupMethods, EmailTypesArray);
		const emailTypeEmailIdsMap: Record<string, number[]> = {};
		billingContact.contact.contactMethods.forEach((_method) => {
			if (selectedEmailIds.includes(_method.id)) {
				emailTypeEmailIdsMap[_method.type] = emailTypeEmailIdsMap[_method.type]
					? [...emailTypeEmailIdsMap[_method.type], _method.id]
					: [_method.id];
			}
		});
		return billingContact.contact.contactMethods.reduce((_acc: string[], _method: ContactMethodBase) => {
			const selectedIdForMethodType = emailTypeEmailIdsMap[_method.type];
			if (selectedIdForMethodType?.length === 1) {
				_acc.push(_method.value);
			}
			return _acc;
		}, []);
	}

	static bulkConstructor = (workOrders) => workOrders.map(W_Job_FindAllJbosForInvoices_VM_Single._constructorMap);
	private static _constructorMap = (workOrder) => new W_Job_FindAllJbosForInvoices_VM_Single(workOrder);

}

interface W_Job_FindAllJbosForInvoices_VM_PreviewInvoiceContact {
	selectedContactMethodEmails: string[];
}

